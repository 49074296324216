import {SimpleCallback} from "../types/SimpleCallback";

export default abstract class AbstractDataStore<T> {

    private _listeners : SimpleCallback[] = [];

    public subscribe(listener : SimpleCallback) : SimpleCallback {
        this._listeners.push(listener);
        listener();
        return () => {
            this._listeners = this._listeners.filter(i => i !== listener);
        }
    }

    protected _callListeners() : void {
        for (let listener of this._listeners) {
            listener();
        }
    }

    public abstract getData() : T;

}
