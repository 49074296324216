import {LinkDefinition} from "../route/types";
import useRouteMatches from "./useRouteMatches";
import {useMemo} from "react";

export type UseBreadcrumbsReturn = LinkDefinition[]|null;

export default function useBreadcrumbs() : UseBreadcrumbsReturn {
    const matches = useRouteMatches();

    return useMemo<UseBreadcrumbsReturn>(() => {
        const _res : LinkDefinition[] = [];
        matches.map(({ breadcrumb }) => _res.push(...breadcrumb))

        if (_res.length < 2) {
            return null;
        }

        return _res;
    }, [ matches ])
}
