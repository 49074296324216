import * as React from 'react';
import {Col, Form, FormGroup} from 'react-bootstrap'
import useFormError, {FormErrorReturn} from "../hooks/useFormError";
import {useFormOptionsContext} from "../Forms/FormBody/FormOptionsContext";
import {useFormContext} from "react-hook-form";
import {UseFormReturn} from "react-hook-form/dist/types";
import classNames from "classnames";
import Label, {LabelProps} from "./Label";

export type FormGroupContainerChildrenParams = {
    controlId: string,
    register: UseFormReturn['register'],
    control: UseFormReturn['control'],
    props: any,
} & FormErrorReturn

export type FormGroupContainerBaseProps = {
    name: string,
    controlId?: string,
    label?: LabelProps['children'],
    text?: React.ReactNode,
    groupClass?: HTMLDivElement['className'],
    layout?: 'horizontal',
    tooltip?: LabelProps['tooltip'],
    tooltipIcon?: LabelProps['tooltipIcon'],
    tooltipPlacement?: LabelProps['tooltipPlacement'],
}

export type FormGroupContainerProps = FormGroupContainerBaseProps & {
    children: (params: FormGroupContainerChildrenParams) => JSX.Element,
}

type FormGroupBodyWrapperProps = {
    isHorizontal: boolean,
    children : React.ReactNode,
}

const FormGroupBodyWrapper = ({ isHorizontal, children } : FormGroupBodyWrapperProps) => {
    if (isHorizontal) {
        return (
            <Col xs={9}>
                {children}
            </Col>
        )
    }

    return <React.Fragment>{children}</React.Fragment>;
}

const FormGroupContainer = ({ name, controlId: clientControlId, label, text, groupClass, layout, children, tooltip, tooltipPlacement, tooltipIcon, ...props }: FormGroupContainerProps) => {

    const { name: formName } = useFormOptionsContext();

    const { register, control } = useFormContext();

    const { isError, errorMessage } = useFormError(name);

    const controlId = clientControlId ?? `${formName}.${name}`;

    const isHorizontal = layout === 'horizontal';

    return (
        <FormGroup className={classNames(groupClass ?? 'mb-3', { 'row' : isHorizontal })} controlId={controlId}>
            {Boolean(label) && (
                <Label
                    children={label}
                    tooltip={tooltip}
                    tooltipIcon={tooltipIcon}
                    tooltipPlacement={tooltipPlacement}
                    isHorizontal={isHorizontal}
                />
            )}
            <FormGroupBodyWrapper isHorizontal={isHorizontal}>
                {children({ isError, errorMessage, controlId, register, control, props })}
                {Boolean(text) && <Form.Text>{text}</Form.Text>}
            </FormGroupBodyWrapper>
        </FormGroup>
    );
}

export default FormGroupContainer
