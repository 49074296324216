import * as React from 'react';
import {useDatatableContext} from "../../DatatableContext";
import useModal from "../../../hooks/useModal";
import {useEffect} from "react";
import FiltersModal from "../Filters/FiltersModal";
import TableSettingsModal from "./TableSettingsModal";
import TableSortModal from "./TableSortModal";
import {useLocation} from "react-router-dom";
import {SimpleCallback} from "../../../types/SimpleCallback";
import { GlobalActionButtonDropdownItems } from "../../../AppHelpers/Navigation";
import {useAppNavigation} from "../../../Application";

const TableGlobalButtons = () => {

    const appNavigation = useAppNavigation();

    const { config: { tableMode, settingsShow }, isFilters, isSort, data: { options: { createAction } } } = useDatatableContext();

    const filtersModalState = useModal();
    const sortModalState = useModal();
    const settingsModalState = useModal();

    const location = useLocation();

    useEffect(() => {

        if (tableMode === 'simple') {
            return;
        }

        const unregisterCallbacks : SimpleCallback[] = [];
        const items : GlobalActionButtonDropdownItems = {};

        if (settingsShow) {
            items['settings'] = {
                icon: 'cog',
                title: 'Налаштування',
                handler: () => settingsModalState.open(),
            }
        }

        if (isFilters) {
            items['filters'] = {
                icon: 'filter-cog',
                title: 'Фільтрація',
                handler: () => filtersModalState.open(),
            }
        }

        if (isSort) {
            items['sort'] = {
                icon: 'sort',
                title: 'Сортування',
                handler: () => sortModalState.open(),
            }
        }

        if (Object.keys(items).length > 0) {
            unregisterCallbacks.push(
                appNavigation.GlobalButtons.registerCustomButton('datatable', {
                    icon: 'table-settings',
                    title: 'Таблиця',
                    variant: 'secondary',
                    items,
                })
            );
        }

        if (createAction) {
            unregisterCallbacks.push(
                appNavigation.GlobalButtons.registerDropdownItem('create', createAction.key, {
                    icon: createAction.icon,
                    title: createAction.title,
                    url: `${location.pathname}/create`,
                })
            );
        }

        return () => {
            unregisterCallbacks.forEach(unregister => unregister());
        }

    }, [ tableMode, settingsShow, isFilters, isSort, createAction?.key ]);

    if (tableMode === 'simple') {
        return null;
    }

    return (
        <React.Fragment>
            {isFilters && <FiltersModal open={filtersModalState.state} closeModal={filtersModalState.close} />}
            {isSort && <TableSortModal state={sortModalState.state} close={sortModalState.close} />}
            {settingsShow && <TableSettingsModal state={settingsModalState.state} close={settingsModalState.close} />}
        </React.Fragment>
    );
}

export default TableGlobalButtons
