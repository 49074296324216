import React from "react";
import {LogoProps} from "../../types/LogoProps";

const DefaultLogo = (props : LogoProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 310 57.26"
        {...props}
    >
        <path d="M69.17 8.2v.92a5.91 5.91 0 0 0-2.88 1.14A10.24 10.24 0 0 0 63.81 14L51.32 42.59h-1l-13.42-29a15.29 15.29 0 0 0-1.45-2.73 4.63 4.63 0 0 0-1.62-1.2 7.88 7.88 0 0 0-2.61-.56v-.9h14.66v.92a7.06 7.06 0 0 0-3.23.79 1.78 1.78 0 0 0-.74 1.46 10.69 10.69 0 0 0 1.22 3.87L52.24 35l8.45-19.47a12.45 12.45 0 0 0 1.24-4 1.85 1.85 0 0 0-.76-1.38 5.21 5.21 0 0 0-2.59-.93l-.45-.07V8.2ZM81.22 30.57H67.35l-2.43 5.3A8 8 0 0 0 64 38.8a1.68 1.68 0 0 0 .78 1.35 7.18 7.18 0 0 0 3.37.76v.91H56.89v-.91a6.25 6.25 0 0 0 2.9-1 15 15 0 0 0 3-4.84L75.38 7.43h.92l12.47 28a12.57 12.57 0 0 0 2.73 4.38 5.85 5.85 0 0 0 3.42 1.13v.91H80.79v-.91a5.58 5.58 0 0 0 2.89-.67 1.72 1.72 0 0 0 .76-1.39 9.91 9.91 0 0 0-1.06-3.45Zm-.75-1.84L74.4 15.14l-6.23 13.59ZM30 12.4a17.7 17.7 0 0 0-5.35-3.62c0 .89.2 3.14.8 3.83Q29 16.86 29 25.48q0 8-3.29 11.66a10.68 10.68 0 0 1-8.34 3.69 9.49 9.49 0 0 1-7.6-3.37Q6 33 6.05 24.79c0-4.59.91-8.21 2.68-10.91a.52.52 0 0 1-.11-.1A1.77 1.77 0 0 1 8.1 13l-.38-.35-.35-.25c-.2-.06-.4-.1-.59-.17a4.41 4.41 0 0 1-.9-.43l-.07.05A16.54 16.54 0 0 0 0 24.89a17 17 0 0 0 17.46 17.7 17.1 17.1 0 0 0 12.46-5.06 17.06 17.06 0 0 0 5.15-12.72A16.6 16.6 0 0 0 30 12.4Z" />
        <text
            transform="matrix(1.09 0 0 1 98.79 41.82)"
            style={{
                fontSize: "50.33px",
                fontFamily: "TimesNewRomanPSMT,Times New Roman",
                letterSpacing: "-.02em",
            }}
        >
            {"STUDIO"}
        </text>
        <path d="M24.54 27.79a1.22 1.22 0 0 0-.1-.63l-.18-.71a.48.48 0 0 0 .15-.29.5.5 0 0 0-.23-.46.61.61 0 0 0-.14-.07c0-.07-.86-3.23-1.05-4.85a5.86 5.86 0 0 1 0-.93 4.73 4.73 0 0 0-.05-1.33 4.75 4.75 0 0 0-.38-.94 4.22 4.22 0 0 1-.43-1.2A2.87 2.87 0 0 0 22 16a1.57 1.57 0 0 1-.09-.6 2.51 2.51 0 0 1 .14-.52 2.77 2.77 0 0 0 .15-.49.67.67 0 0 0 .1-.44v-.05h-.1a2.51 2.51 0 0 0-.5-1.32 11.67 11.67 0 0 0-.84-.93c.09-.14.2-.29.28-.43a6.79 6.79 0 0 0 .71-1.8 8.66 8.66 0 0 0 .51-1.21c.12-.32.24-.63.29-.72l.05-.11h-.12a2 2 0 0 0-.68.12 2.5 2.5 0 0 0-.05-.29v-.1h-.06a4.51 4.51 0 0 0-2.62 1.45c0-.39-.46-.63-.92-.68a9.14 9.14 0 0 0-4.63 1.19c-.65.29-1.32.59-2 .82a21.29 21.29 0 0 1-2.92.68l-.54.1c-.43.08-.86.18-1.29.28a9.16 9.16 0 0 0-3.27 4.33 19.32 19.32 0 0 0-1.23 5.77 28.09 28.09 0 0 0-.08 7.27A20 20 0 0 0 2.94 31a21.59 21.59 0 0 1 4.56-4.35c1.4-1 2.55-1.31 3.52-1l.1.16A7.5 7.5 0 0 0 12.43 27c.18.15.35.29.5.43a.37.37 0 0 0-.07.45.89.89 0 0 0 .3.31 1.35 1.35 0 0 0 .52.27h.05a3.88 3.88 0 0 1 .84 1 .65.65 0 0 0 0 .13c-.09.41.3.75.67.91a2 2 0 0 0 .63.17H16l.16.07c.15.19.36.41.58.65a9.61 9.61 0 0 1 .9 1.05 2.42 2.42 0 0 1 .36 1.25 4.39 4.39 0 0 0 .8 2.07 2.24 2.24 0 0 0 1.39.65 1.35 1.35 0 0 0 .61-.09 2.92 2.92 0 0 0 2.74-1.06c.12-.14.34-.34.47-.47l.15-.14a.38.38 0 0 0 .12-.09.6.6 0 0 0 .09-.13 1.83 1.83 0 0 0 .25-.35 4 4 0 0 0 .38-1.72v-.29a16.34 16.34 0 0 0-.46-4.28Z" />
    </svg>
)

DefaultLogo.defaultProps = {
    fill: '#fff',
}

export default DefaultLogo;
