import * as React from 'react';
import BouncingLoader from "./BouncingLoader";

const Preloader = () => {
    return (
        <div id='status'>
            <BouncingLoader />
        </div>
    );
}

export default Preloader
