import * as React from 'react';
import ActionDisplay from "./ActionDisplay";
import useExternalStore from "../../../hooks/useExternalStore";
import {useAppActions} from "../../../Application";

const ActionsDisplay = () => {

    const appActions = useAppActions();
    const actions = useExternalStore(appActions);

    return (
        <React.Fragment>
            {Object.entries(actions).map(([key, action]) => <ActionDisplay key={key} action={action} />)}
        </React.Fragment>
    );
}

export default ActionsDisplay
