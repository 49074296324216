import {CrudActionFormsSimple} from "../types";
import {RouteObject} from "react-router-dom";
import prepareHandle from "../../helpers/prepareHandle";
import prepareLoader from "../../helpers/prepareLoader";
import prepareAction from "../../helpers/prepareAction";
import {SimpleCrudActionParams} from "./SimpleCrudActionParams";
import wrapElement from "../../helpers/wrapElement";
import {TitlingRouteProps} from "../../types";
import prepareLoaderConfig from "./prepareLoaderConfig";

type MakeSimpleCrudCreateFormParams = SimpleCrudActionParams & {
    initialData?: Record<string, any>,
}

export default function makeSimpleCrudCreateForm(options : TitlingRouteProps & CrudActionFormsSimple, params : MakeSimpleCrudCreateFormParams) : RouteObject {
    return {
        path: 'create',
        handle: prepareHandle(
            options,
            params.isModal,
            prepareAction({
                endpoint: params.baseEndpoint,
                method: 'create',
            }),
        ),
        loader: prepareLoader(
            prepareLoaderConfig({
                helpers: options.helpers,
                relations: options.relations,
            }, {
                baseEndpoint: params.baseEndpoint,
                paramName: params.paramName,
                initialData: params.initialData,
            })
        ),
        element: wrapElement(options.element, params.isModal, '../'),
    }
}
