import * as React from 'react';

export type TopOverlayProps = {
    children: React.ReactNode,
}

const TopOverlay = ({ children }: TopOverlayProps) => {
    return (
        <div className='top-overlay'>
            {children}
        </div>
    );
}

export default TopOverlay
