import * as React from 'react';
import {useAppNavigation} from "../../../Application";

const TopbarRow = () => {

    const appNavigation = useAppNavigation();

    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (!ref.current) return;
        appNavigation.Topbar.Row.setContainer(ref.current);
    }, [])

    return (
        <div ref={ref} className='navbar-data-row' />
    );
}

export default TopbarRow
