import {CrudActionShowExtend} from "../types";
import {RouteObject} from "react-router-dom";
import prepareHandle from "../../helpers/prepareHandle";
import prepareLoader from "../../helpers/prepareLoader";

export default function makeExtendCrudShow(options : CrudActionShowExtend) : RouteObject {
    return {
        path: ':id',
        handle: prepareHandle(options),
        loader: prepareLoader(options.loader),
        element: options.element,
        children: options.children,
    }
}
