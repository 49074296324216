import * as React from 'react';
import SideMenuWrapper from "./SideMenuWrapper";
import FlexCentered from "../../../../components/FlexCentered";
import BouncingLoader from "../../../../components/BouncingLoader";
import {NavFallbackProps} from "../NavFallbackProps";

const SideMenuFallback = ({ state }: NavFallbackProps) => {
    return (
        <SideMenuWrapper isOpen={false}>
            <FlexCentered>
                {state === 'loading' ? (
                    <React.Fragment>
                        <BouncingLoader />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <i className='mdi mdi-alert fs-1 text-danger' />
                        <div className='font-18 text-danger'>Помилка завантаження</div>
                    </React.Fragment>
                )}
            </FlexCentered>
        </SideMenuWrapper>
    );
}

export default SideMenuFallback
