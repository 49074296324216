import {LoaderFunction} from "react-router-dom";
import makeLoader, {LoaderDefinition} from "../../loaders";

export default function prepareLoader(loaderDef : LoaderDefinition | undefined) : LoaderFunction | undefined {
    if (typeof loaderDef === 'undefined') {
        return undefined;
    }

    return makeLoader(loaderDef);
}
