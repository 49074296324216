import {App} from "../../Application";
import {isWebsocketConfig, WebsocketConfig} from "./types/WebsocketConfig";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import {ChannelData, ChannelListener} from "./types/ChannelListener";
import {SimpleCallback} from "../../types/SimpleCallback";

export default class Websocket {

    private readonly _app: App;

    private _clientInternal: Echo|undefined = undefined;
    private _channels: Record<string, ChannelData> = {};

    constructor(app: App) {
        this._app = app;
    }

    private get _config() : WebsocketConfig {
        const config = this._app.config.external?.websocket;

        if (!isWebsocketConfig(config)) {
            throw new Error("Websocket config not found");
        }

        return config;
    }

    private _initClient() {
        if (this._clientInternal) {
            return;
        }

        this._clientInternal = new Echo({
            broadcaster: 'pusher',
            Pusher: Pusher,
            authEndpoint: this._config.authEndpoint,
            key: this._config.appKey,
            wsHost: this._config.host,
            wsPort: this._config.port,
            wssPort: this._config.port,
            wsPath: this._config.path,
            encrypted: true,
            disableStats: true,
            forceTLS: false,//(import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
            enabledTransports: ['ws', 'wss'],
        });
    }

    private get _client() : Echo {
        this._initClient();

        if (!this._clientInternal) {
            throw new Error("Websocket client not initialized");
        }

        return this._clientInternal;
    }

    private _resolveChannel(channelName: string) : ChannelData {
        if (!this._channels[channelName]) {
            this._channels[channelName] = {
                channelName,
                instance: this._client.private(channelName),
                listeners: []
            };
        }

        return this._channels[channelName];
    }

    private _ensureChannelIsActive(channelName: string) {
        const channel = this._resolveChannel(channelName);
        if (channel.listeners.length > 0) return;

        this._client.leave(channelName);
        delete this._channels[channelName];
    }

    public listen(channelName: string, events : string|string[], callback: ChannelListener) : SimpleCallback {
        const _events = Array.isArray(events) ? events : [ events ];

        const channel = this._resolveChannel(channelName);

        for (const event of _events) {
            channel.instance.listen(event, callback);
        }

        channel.listeners.push(callback);

        return () => {
            for (const event of _events) {
                channel.instance.stopListening(event, callback);
            }

            channel.listeners = channel.listeners.filter(i => i !== callback);

            this._ensureChannelIsActive(channelName);
        }
    }

}