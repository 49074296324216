import React from "react";
import Row from "./Row"
import {useDatatableContext} from "../../DatatableContext";

const Body = () => {

    const { rows } = useDatatableContext();

    return (
        <tbody>
        {rows.map((row, index) => (
            <Row
                row={row}
                index={index}
                key={'row-' + row._id}
            />
        ))}
        </tbody>
    );
}

export default Body
