import * as React from 'react';
import {useBaseAppConfig} from "../../Application";

const AppCopyright = () => {

    const { appCreateYear, appName } = useBaseAppConfig();

    const years = React.useMemo(() => {

        const createYear = appCreateYear ?? (new Date).getFullYear();
        const currentYear = (new Date).getFullYear();

        return (createYear !== currentYear ? `${createYear} - ` : '') + currentYear.toString();

    }, []);

    return (
        <>
            {years}
            {' © '}
            {appName}
        </>
    );
}

export default AppCopyright
