import * as React from 'react';
import {connectMenu, ContextMenu, ContextMenuTrigger, MenuItem} from "@firefox-devtools/react-contextmenu";
import {FolderID} from "../../types/MediaFolder";
import {useMediaLibraryService} from "../../services/MediaLibraryService";

export const menuType: string = 'folder-context';

type FolderContextMenuProps = {
    id: string,
    trigger?: {
        folderId: FolderID,
        folderName: string,
    },
}

const FolderContextMenu = ({ id, trigger }: FolderContextMenuProps) => {

    const manager = useMediaLibraryService().folderManager;

    return (
        <ContextMenu id={id}>
            <MenuItem onClick={() => manager.openFolderEdit(trigger?.folderId ?? 0)}>Редагувати</MenuItem>
            <MenuItem onClick={() => manager.openFolderCreate(trigger?.folderId ?? 0)}>Створити папку</MenuItem>
            <MenuItem onClick={() => manager.openFolderDelete(trigger?.folderId ?? 0, trigger?.folderName ?? '')}>Видалити</MenuItem>
        </ContextMenu>
    );
}

export const FolderConnectedContextMenu = connectMenu(menuType)(FolderContextMenu);

export type FolderContextMenuTriggerProps = {
    folderId: FolderID,
    folderName: string,
    children: React.ReactNode,
}

export const FolderContextMenuTrigger = ({ folderId, folderName, children } : FolderContextMenuTriggerProps) => {
    return (
        <ContextMenuTrigger id={menuType} folderId={folderId} folderName={folderName} renderTag='span' collect={(data) => data}>
            {children}
        </ContextMenuTrigger>
    );
}
