import * as React from 'react';
import Tooltip, {TooltipProps} from "../../components/Tooltip";

export type LabelTooltipProps = {
    text: TooltipProps['text'],
    icon?: string,
    placement?: TooltipProps['placement'],
}

const LabelTooltip = ({ text, icon = 'help-circle-outline', placement }: LabelTooltipProps) => {
    return (
        <Tooltip text={text} placement={placement}>
            <i className={`mdi mdi-${icon} link-secondary ms-1`} />
        </Tooltip>
    );
}

export default LabelTooltip
