import {CrudActionFormsExtend} from "../types";
import {RouteObject} from "react-router-dom";
import prepareHandle from "../../helpers/prepareHandle";
import prepareLoader from "../../helpers/prepareLoader";
import prepareAction from "../../helpers/prepareAction";
import {TitlingRouteProps} from "../../types";

export default function makeExtendCrudUpdateForm(options: TitlingRouteProps & CrudActionFormsExtend) : RouteObject {
    return {
        path: ':id/edit',
        handle: prepareHandle(options, false, prepareAction(options.action)),
        loader: prepareLoader(options.loader),
        element: options.element,
    }
}
