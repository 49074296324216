import * as React from 'react';
import {useAppNavigation} from "../../Application";
import {TopbarRowOptions} from "./TopbarRow";

export type AsTopbarRowProps = TopbarRowOptions & {
    children: React.ReactNode,
}

const AsTopbarRow = ({ children, ...opts }: AsTopbarRowProps) => {

    const appNavigation = useAppNavigation();

    React.useEffect(() => {
        return () => {
            appNavigation.Topbar.Row.handlePortalUnmount();
        }
    }, []);

    return appNavigation.Topbar.Row.createPortal(
        children,
        opts,
    );
}

export default AsTopbarRow
