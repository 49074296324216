import * as React from 'react';

const DevCopyright = () => {
    return (
        <>
            Developed in <a href="https://ova.in.ua">OVA Studio</a>
        </>
    );
}

export default DevCopyright
