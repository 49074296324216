import * as React from "react";
import {MediaDisplayProps} from "./MediaDisplayProps";
import PictureRender from "../PictureRender";

const MediaVideoDisplay = ({ data, caption, lazy }: MediaDisplayProps) => {
    if (!data.url || !data.image) {
        return null;
    }

    return (
        <figure className='ova-engine-video' data-video-local={data.url}>
            <PictureRender data={data.image} alt={caption} lazy={lazy} className='ova-engine-video-placeholder' />
            {caption && <figcaption>{caption}</figcaption>}
        </figure>
    )
}

export default MediaVideoDisplay;
