import AbstractDataStore from "../../abstracts/AbstractDataStore";
import {
    AuthUserData,
    isAuthUserData,
} from "./types/AuthUserData";
import Authenticator from "./Authenticator";
import {AuthConfig} from "./types/AuthConfig";
import API, {ApiError, isApiNotAuthorizedError} from "@ova-studio/api-helper";

export type AuthData = AuthUserData|null|false

export default class Auth extends AbstractDataStore<AuthData> {

    private _data: AuthData = null;

    private readonly _config: AuthConfig;
    public readonly authenticator: Authenticator;

    constructor(config: AuthConfig) {
        super();
        this._config = config;
        void this.refresh();
        this.authenticator = new Authenticator(config, this);

        API.registerGlobalErrorHandler(this._handleApiError.bind(this));
    }

    private _handleApiError(e: ApiError<any>) : void {
        if (!this.isAuth()) return;
        if (!isApiNotAuthorizedError(e)) return;

        void this.refresh();
    }

    public async refresh() : Promise<void> {
        return new Promise<void>(async (resolve) => {
            try {
                this._data = await this._config.authUserResolver();
                this._callListeners();
                resolve();
            } catch (e) {
                this._data = false;
                this._callListeners();
                resolve();
            }
        });
    }

    public logout() : Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                await this._config.logoutHandler();
                this._data = null;
                this._callListeners();
                void this.refresh();
                resolve();
            } catch (e) {
                reject(e);
            }
        });
    }

    public isAuth() : boolean {
        return isAuthUserData(this._data);
    }

    private _can(ability: string) : boolean {
        if (!isAuthUserData(this._data)) return false;
        if (!this._data.permissions) return false;
        return this._data.permissions.includes(ability);
    }

    public can(ability: string|string[]) : boolean {
        if (!this.isAuth()) return false;

        if (Array.isArray(ability)) {
            return ability.every(ability => this._can(ability));
        }

        return this._can(ability);
    }

    public canAny(ability: string|string[]) : boolean {
        if (!this.isAuth()) return false;

        if (Array.isArray(ability)) {
            return ability.some(ability => this._can(ability));
        }

        return this._can(ability);
    }

    getData() : AuthData {
        return this._data;
    }
}
