import * as React from 'react';
import GlobalDropdownButtonItemBasic from "./GlobalDropdownButtonItemBasic";
import {GlobalActionButtonHandler} from "../../../../../AppHelpers/Navigation";

type GlobalDropdownButtonItemHandlerProps = {
    data: GlobalActionButtonHandler,
}

const GlobalDropdownButtonItemHandler = ({ data }: GlobalDropdownButtonItemHandlerProps) => {
    return (
        <GlobalDropdownButtonItemBasic
            options={data}
            onClick={data.handler}
        />
    );
}

export default GlobalDropdownButtonItemHandler
