export type AuthUserData = {
    id: number|string,
    name: string,
    username: string,
    position?: string,
    avatar?: string,
    permissions?: string[],
}

export function isAuthUserData(data: any) : data is AuthUserData {
    return typeof data === 'object' && data !== null && 'id' in data && 'name' in data && 'username' in data;
}
