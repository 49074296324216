import * as React from 'react';
import {Container} from "react-bootstrap";
import {Link} from "react-router-dom";
import Footer from "../AppLayout/Footer";

export type InfoPageProps = {
    image?: any,
    title: React.ReactNode,
    message?: React.ReactNode,

    btnLink?: string,
    btnText?: React.ReactNode,
}

const BaseInfoPage = ({ image, title, message, btnLink, btnText } : InfoPageProps) => {
    return (
        <Container className='vh-100 d-flex align-items-center justify-content-center'>
            <div className='text-center'>
                {Boolean(image) && <img src={image} style={{ maxHeight: '50vh' }} alt="" />}
                <h3 className="mt-4">{title}</h3>
                {Boolean(message) && <div className='text-muted'>{message}</div>}
                {btnLink && btnText && <div className='mt-2'><Link to={btnLink} className='btn btn-primary'>{btnText}</Link></div>}
            </div>
            <Footer className='footer-alt' />
        </Container>
    );
}

export default BaseInfoPage
