import React from "react";
import {useLoaderData} from "react-router-dom";
import FormSelectAwait from "./FormSelectAwait";
import {FormSelectBaseProps} from "./FormSelect";
import {FormSelectOption} from "./BaseInputProps";

export type FormSelectAwaitLoaderProps = FormSelectBaseProps & {
    listKey: string,
}

const FormSelectAwaitLoader = ({ listKey, ...props } : FormSelectAwaitLoaderProps) => {

    const { [listKey] : awaiter } = useLoaderData() as Record<string, Promise<FormSelectOption[]>>;

    return (
        <FormSelectAwait
            {...props}
            awaiter={awaiter}
        />
    )
}

export default FormSelectAwaitLoader
