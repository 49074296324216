// noinspection JSUnusedGlobalSymbols

export enum CaptionMode {
    Text = 'text',
    Icon = 'icon',
    None = 'none',
}

export type MobileTableRow = {
    [key: string]: {
        captionMode: CaptionMode,
        toEnd: boolean,
    }
}

export type MobileTableCardConfig = {
    fixed: MobileTableRow[],
    collapsed?: MobileTableRow[],
}
