import * as React from "react";
import {useLoaderData, useNavigate} from "react-router-dom";
import {FormValues} from "../types";
import CallbackForm, {CallbackFormProps} from "./CallbackForm";
import {FieldValues} from "react-hook-form/dist/types/fields";
import useRouteMatches from "../../hooks/useRouteMatches";
import {useMemo} from "react";

export type LoaderFormProps = Omit<CallbackFormProps, 'handleSubmit' | 'data'> & {
    onSuccess?: (data: FieldValues, resultData: FieldValues|void) => void,
}

const LoaderForm = ({ onSuccess, children, ...props } : LoaderFormProps) => {

    const { data: loaderData } = useLoaderData() as { data: FormValues };

    const navigate = useNavigate();

    const matches = useRouteMatches();

    const { actionHandler } = useMemo(() => {
        return matches.slice(-1)[0] ?? {};
    }, [ matches ]);

    const { url: parentUrl } = useMemo(() => {
        return matches.slice(-2)[0] ?? {};
    }, [ matches ]);

    const handleSubmit = React.useCallback(async (data: FormValues) => {
        if (!actionHandler) {
            throw new Error('No action handler found');
        }

        const result = await actionHandler(data);

        if (onSuccess) {
            onSuccess(data, result);
        } else if (parentUrl) {
            navigate(parentUrl);
        }

        return result;
    }, [ actionHandler, parentUrl ])


    return (
        <CallbackForm {...props} data={loaderData} handleSubmit={handleSubmit}>
            {children}
        </CallbackForm>
    );
}

export default LoaderForm
