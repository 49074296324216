export function deleteRecord<T>(obj : Record<string, T>, key: string) : Record<string, T> {
    return Object.entries(obj)
        .filter(([_key]) => _key !== key)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
}

export function sortRecords<T>(obj : Record<string, T>, selector: (obj : T) => number) : Record<string, T> {
    return Object.entries(obj)
        .sort(([,a],[,b]) => selector(a) - selector(b))
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
}

export function ArrayIsNotEmpty(arr : any[]) : boolean {
    return arr.length > 0
}
