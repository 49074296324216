import * as React from "react";
import {Controller} from "react-hook-form";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {FormControl, Spinner} from "react-bootstrap";
import API from "@ova-studio/api-helper";
import {FormSelectOption} from "./BaseInputProps";
import FormGroupContainer, {FormGroupContainerBaseProps} from "./FormGroupContainer";

type FormTypeaheadSelectOptions = {
    endpoint: string;
    multiple?: boolean;
    clearButton?: boolean;
    promptText?: string;
    emptyLabel?: string;
    placeholder?: string;
    searchText?: React.ReactNode;
}

type SelectValue = string | string[] | null;

type RawSelectValue = Array<string|Record<string, any>>;

type FormTypeaheadSelectBodyProps = FormTypeaheadSelectOptions & {
    controlId: string;
    isError: boolean;
    value: SelectValue;
    onChange: (selected: SelectValue) => void;
}

const normalizeValues = (value: RawSelectValue): FormSelectOption[] => {
    return value.map((option) => {
        if (typeof option === 'string') {
            return { value: option, label: option };
        }

        return { value: option.value, label: option.label };
    });
}

const prepareValuesForSave = (value: FormSelectOption[], multiple?: boolean): SelectValue => {
    if (multiple) {
        return value.map((option) => option.value.toString());
    }

    return value.length > 0 ? value[0].value.toString() : null;
}

const prepareValuesForLoad = (value: SelectValue): string[] => {
    if (Array.isArray(value)) {
        return value;
    }

    if (value) {
        return [ value ];
    }

    return [];
}

const FormTypeaheadSelectBody = ({ controlId, isError, value: inputValue, onChange, endpoint, multiple, clearButton, promptText, emptyLabel, placeholder, searchText } : FormTypeaheadSelectBodyProps) => {

    const loadedValue = React.useRef<SelectValue>();

    const [ value, setValue ] = React.useState<FormSelectOption[]>([]);
    const [ options, setOptions] = React.useState<FormSelectOption[]>([]);
    const [ isLoading, setLoading ] = React.useState<boolean>(false);
    const [ isLoadingValues, setLoadingValues ] = React.useState<boolean>(false);

    const handleChange = React.useCallback((selected: RawSelectValue) => {
        const values = normalizeValues(selected);
        setValue(values);

        const saveValue = prepareValuesForSave(values, multiple);
        onChange(saveValue);
        loadedValue.current = saveValue;
    }, []);

    const handleSearch = React.useCallback((query: string) => {
        setLoading(true);

        API.getData<FormSelectOption[]>(endpoint, { query })
            .then((data) => setOptions(data))
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    }, []);

    React.useEffect(() => {
        if (inputValue === loadedValue.current) {
            return;
        }

        setLoadingValues(true);

        API.getData<FormSelectOption[]>(endpoint, { ids: prepareValuesForLoad(inputValue) })
            .then((data) => setValue(data))
            .catch((error) => console.error(error))
            .finally(() => setLoadingValues(false));

    }, [ inputValue ]);

    return (
        <AsyncTypeahead
            id={controlId}
            isLoading={isLoading || isLoadingValues}
            filterBy={() => true}
            minLength={3}
            clearButton={clearButton}
            multiple={multiple}
            promptText={promptText ?? "Почніть вводити..."}
            emptyLabel={emptyLabel ?? "Нічого не знайдено"}
            placeholder={placeholder ?? "Почніть вводити для пошуку"}
            searchText={searchText ?? <React.Fragment><Spinner animation='border' size='sm' className='me-1' /> Іде пошук, зачекайте...</React.Fragment>}
            onSearch={handleSearch}
            options={options}
            isInvalid={isError}
            selected={value}
            onChange={handleChange}
            labelKey='label'
        />
    );
}

export type FormTypeaheadSelectProps = FormGroupContainerBaseProps & FormTypeaheadSelectOptions;

const FormTypeaheadSelect = ({ name, endpoint, multiple, clearButton, promptText, emptyLabel, placeholder, searchText, ...props }: FormTypeaheadSelectProps) => {
    return (
        <FormGroupContainer name={name} {...props}>
            {({ control, isError, errorMessage, controlId,  }) => (
                <React.Fragment>
                    <Controller
                        control={control}
                        name={name}
                        render={({ field: { value, onChange } }) => (
                            <FormTypeaheadSelectBody
                                controlId={controlId}
                                endpoint={endpoint}
                                multiple={multiple}
                                clearButton={clearButton}
                                promptText={promptText}
                                emptyLabel={emptyLabel}
                                placeholder={placeholder}
                                searchText={searchText}
                                isError={isError}
                                value={value ?? []}
                                onChange={onChange}
                            />
                        )}
                    />
                    {isError && <FormControl.Feedback type='invalid'>{errorMessage}</FormControl.Feedback>}
                </React.Fragment>
            )}
        </FormGroupContainer>
    )
}

export default FormTypeaheadSelect;
