import Websocket, {ChannelListener} from "../../AppHelpers/Websocket";
import {SimpleCallback} from "../../types/SimpleCallback";

export default abstract class AbstractWebsocketAdapter {
    private readonly _ws: Websocket;
    private _onDestroy: SimpleCallback[] = [];

    protected constructor(ws: Websocket) {
        this._ws = ws;
    }

    public abstract init() : void;

    protected _initListener(channelName: string, events: string|string[], listener: ChannelListener) : void {
        this._onDestroy.push(this._ws.listen(channelName, events, listener));
    }

    public destroy() : void {
        this._onDestroy.forEach(cb => cb());
    }
}