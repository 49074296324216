import * as React from 'react';
import emptyImage from "../../assets/images/file-searching.svg";
import {Button} from "react-bootstrap";
import {useDatatableContext} from "../DatatableContext";

const TableEmpty = () => {

    const { isActiveFilters, setSearchParams } = useDatatableContext();

    return (
        <div className='d-flex flex-column gap-2 align-items-center'>
            <img src={emptyImage} className='w-100' style={{ maxWidth: '250px' }} alt='' />
            <div className='fs-2'>Немає жодного запису</div>
            {isActiveFilters && (
                <React.Fragment>
                    <p>Спробуйте пошук без фільтрів</p>
                    <Button variant='outline-secondary' size='sm' onClick={() => setSearchParams()}>Скинути фільтр</Button>
                </React.Fragment>
            )}
        </div>
    );
}

export default TableEmpty
