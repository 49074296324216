import * as React from 'react';
import {FormControl} from "react-bootstrap";
import {useMediaLibraryData, useMediaLibraryDataStore} from "../../MediaLibraryContext";

type SearchInputProps = {
    show?: true,
}

const SearchInput = ({ show }: SearchInputProps) => {

    const store = useMediaLibraryDataStore();
    const { search } = useMediaLibraryData();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        store.search = e.target.value
    }

    return (
        <div className={show ? 'w-100' : 'd-none d-lg-block ms-auto'}>
            <FormControl size='sm' placeholder='Пошук...' value={search} onChange={handleChange} />
        </div>
    );
}

export default SearchInput
