import * as React from 'react';
import {AppOptions} from "./types/AppOptions";
import App from "./App";
import AppContext from "./AppContext";
import AppRouter from "../AppWrapper/AppRouter";
import {AppHelpers} from "../AppHelpers";

const Application = (appOptions: AppOptions) => {

    const app = React.useMemo(() => new App(appOptions), []);

    return (
        <AppContext.Provider value={app}>
            <AppRouter />
            <AppHelpers />
        </AppContext.Provider>
    );
}

export default Application
