import {RouteObject} from "react-router-dom";
import {CrudActionShowSimple} from "../types";
import prepareHandle from "../../helpers/prepareHandle";
import prepareLoader from "../../helpers/prepareLoader";
import {SimpleCrudActionParams} from "./SimpleCrudActionParams";
import wrapElement from "../../helpers/wrapElement";
import prepareLoaderConfig from "./prepareLoaderConfig";

export default function makeSimpleCrudShow(options : CrudActionShowSimple, params : SimpleCrudActionParams) : RouteObject {
    return {
        path: `:${params.paramName}`,
        handle: prepareHandle(options, params.isModal),
        loader: prepareLoader(
            prepareLoaderConfig({
                helpers: options.helpers,
                relations: options.relations,
            }, {
                baseEndpoint: params.baseEndpoint,
                paramName: params.paramName,
                dataType: 'show',
            })
        ),
        shouldRevalidate: (args) => {
            if (options.shouldRevalidate === 'disable-on-stalled-params') {
                return args.currentParams[params.paramName] !== args.nextParams[params.paramName];
            }

            if (typeof options.shouldRevalidate === 'function') {
                return options.shouldRevalidate(args);
            }

            return args.defaultShouldRevalidate;
        },
        element: wrapElement(options.element, params.isModal, '../../'),
        children: options.children,
    }
}
