import AbstractWebsocketAdapter from "./AbstractWebsocketAdapter";
import Websocket from "../../AppHelpers/Websocket";
import ArrayDataStore from "../store/ArrayDataStore";

export enum EventAction {
    Create = 'create',
    Update = 'update',
    UpdateOrCreate = 'updateOrCreate',
    Delete = 'delete',
}

export type WebsocketArrayDataStoreAdapterOptions = {
    channelName: string;
    actionsMap: Record<string, EventAction>;
}

export default class WebsocketArrayDataStoreAdapter extends AbstractWebsocketAdapter {

    private readonly _store: ArrayDataStore<any>;
    private readonly _options: WebsocketArrayDataStoreAdapterOptions;

    constructor(ws: Websocket, store: ArrayDataStore<any>, options: WebsocketArrayDataStoreAdapterOptions) {
        super(ws);
        this._store = store;
        this._options = options;
    }

    private _handleAction(action: EventAction, data: any) {
        switch (action) {
            case EventAction.Create:
                this._store.updateOrAddItem(data);
                break;
            case EventAction.Update:
                this._store.updateItemData(data);
                break;
            case EventAction.UpdateOrCreate:
                this._store.updateOrAddItem(data);
                break;
            case EventAction.Delete:
                this._store.removeItem(data);
                break;
            default:
                throw new Error(`Unknown action: ${action}`);
        }
    }

    public init() {
        for (const [eventName, action] of Object.entries(this._options.actionsMap)) {
            this._initListener(this._options.channelName, eventName, (data) => {
                this._handleAction(action, data);
            });
        }
    }
}