import {Params} from "react-router-dom";
import {replacePlaceholders} from "../route/helpers/prepareHandle";

export type MakeEndpointOptions = {
    endpoint: string,
    subEndpoint?: string,
    paramName?: string,
}

export default function makeEndpoint(opts : MakeEndpointOptions, params : Params) : string {

    let endpoint = replacePlaceholders(opts.endpoint, params);

    if (opts.paramName) {
        endpoint+= '/' + params[opts.paramName];
    }

    if (opts.subEndpoint) {
        endpoint+= '/' + opts.subEndpoint;
    }

    return endpoint;

}
