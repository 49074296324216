import * as React from "react";
import FormMediaSelectWrapper, {FormMediaSelectWrapperProps} from "./FormMediaSelectWrapper";
import {InputGroup} from "react-bootstrap";
import {Form, InputGroupContainerBaseProps} from "@ova-studio/react-hyper-admin";
import FormMediaSelectBody from "./FormMediaSelectBody";
import clsx from "clsx";

type FormMediaSelectInputProps = Omit<FormMediaSelectWrapperProps, 'children'> & InputGroupContainerBaseProps;

const FormMediaSelectInput = ({ name, types, ...props }: FormMediaSelectInputProps) => {

    return (
        <FormMediaSelectWrapper name={name} types={types}>
            <Form.InputGroupContainer name={name} {...props}>
                {({ isError }) => (
                    <React.Fragment>
                        <FormMediaSelectBody.Media>
                            {(data) => data ? (
                                <React.Fragment>
                                    <InputGroup.Text className='p-0'>
                                        <img
                                            src={data.src}
                                            alt={data.name}
                                            style={{ height: 36 }}
                                        />
                                    </InputGroup.Text>
                                    <InputGroup.Text className={clsx('flex-grow-1', isError && 'text-danger')}>
                                        {data.name}
                                    </InputGroup.Text>
                                </React.Fragment>
                            ) : (
                                <InputGroup.Text className='flex-grow-1 lh-1'>
                                    <i className='mdi mdi-loading mdi-spin font-20 me-1' />
                                    Завантаження...
                                </InputGroup.Text>
                            )}
                        </FormMediaSelectBody.Media>
                        <FormMediaSelectBody.Button/>
                    </React.Fragment>
                )}
            </Form.InputGroupContainer>
        </FormMediaSelectWrapper>
    )
}

export default FormMediaSelectInput;
