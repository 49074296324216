import * as React from "react";
import API, {ApiSendMethods, isApiValidationError, getApiErrorMessage} from "@ova-studio/api-helper";
import useConfirm from "./useConfirm";
import {FieldValues} from "react-hook-form/dist/types/fields";
import useModal from "./useModal";
import Modal from "../components/Modal";
import Form, {DynamicFormFields} from "../Form";
import {isDynamicFormFields} from "../Form/dynamic-form/types";
import DynamicFormBody from "../Form/dynamic-form/DynamicFormBody";
import {Alert, Button} from "react-bootstrap";
import {useRevalidator} from "react-router-dom";

export type DeleteActionOptions = {
    endpoint: string,
    title?: string,
    confirmText?: string,
    method?: ApiSendMethods,
}

export type UseDeleteActionReturn = {
    handleClick: () => void,
    helpers: React.ReactNode,
}

export default function useDeleteAction({ endpoint, title, confirmText, method }: DeleteActionOptions) : UseDeleteActionReturn {

    const [ message, setMessage ] = React.useState<string>();
    const [ dynamicForm, setDynamicForm ] = React.useState<DynamicFormFields>();
    const { revalidate } = useRevalidator();

    const handleContinue = React.useCallback((data?: FieldValues) => new Promise<void>(async (resolve, reject) => {
        try {
            await API[method ?? 'delete'](endpoint, data ?? {});
            revalidate();
            resolve();
        } catch (e) {
            if (data) { // Form
                reject(e)
                return;
            }

            if (isApiValidationError(e)) {
                if (isDynamicFormFields(e.response?.data?.form)) {
                    setMessage(e.response?.data?.message)
                    setDynamicForm(e.response?.data?.form);
                    formModalState.open();
                    return;
                }
            }

            reject(getApiErrorMessage(e));
        }
    }), []);

    const handleCancel = React.useCallback(() => {
        formModalState.close();
        setMessage(undefined);
        setDynamicForm(undefined);
    }, []);

    const formModalState = useModal();

    const confirm = useConfirm({
        title: title ?? 'Видалити',
        text: confirmText ?? 'Ви дійсно хочете видалити?',
        submitText: 'Видалити',
        color: 'danger',
        handleContinue,
    });

    const helpers = (
        <React.Fragment>
            {confirm.ConfirmModal}
            <Modal state={formModalState} title={title ?? 'Видалити'} titleBg='danger'>
                <Form.Callback handleSubmit={handleContinue} showSuccess={false}>
                    <Modal.Body>
                        {message && <Alert variant='info'>{message}</Alert>}
                        <DynamicFormBody fields={dynamicForm ?? []} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handleCancel} variant='secondary'>Скасувати</Button>
                        <Form.Submit variant='danger'>Видалити</Form.Submit>
                    </Modal.Footer>
                </Form.Callback>
            </Modal>
        </React.Fragment>
    )

    return {
        handleClick: () => confirm.handleClick(),
        helpers,
    }
}
