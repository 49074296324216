import * as React from "react";
import {Button} from "react-bootstrap";
import {SearchResultItem} from "../types";
import {useAppSearch} from "../../../Application";

export type SearchResultLinksProps = {
    result: SearchResultItem,
}

const SearchResultLinks = ({ result }: SearchResultLinksProps) => {
    const search = useAppSearch();

    const handleClick = React.useCallback((e : React.MouseEvent, isEdit: boolean) => {
        e.preventDefault();
        e.stopPropagation();
        search.handleResultSelect(result, isEdit);
    }, [])

    return (
        <React.Fragment>
            {result.edit_url && <Button variant='link' className='p-0 m-0 ms-1 mdi mdi-pencil font-20' onClick={(e) => handleClick(e, true)} />}
            {result.view_url && <Button variant='link' className='p-0 m-0 ms-1 mdi mdi-eye font-20' onClick={(e) => handleClick(e, false)} />}
        </React.Fragment>
    )
}

export default SearchResultLinks;