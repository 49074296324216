import * as React from "react";
import {useDatatableContext} from "../../DatatableContext";
import RowCheckbox from "../Components/RowCheckbox";
import RowIndexToggle from "./RowIndexToggle";
import {isBasePaginationData} from "../../types/DatatableData";

export default function useRowIndex(id : number|string, index: number) : React.ReactNode|null {

    const { hasGroupActions, data: { options: { hasIndexes }, pagination } } = useDatatableContext();

    if (!hasGroupActions && !hasIndexes) {
        return null;
    }

    if (!isBasePaginationData(pagination)) {
        throw new Error("Index can only be used with base pagination data.");
    }

    const idx = index + pagination.from;

    if (!hasIndexes) {
        return <RowCheckbox id={id} />
    }

    if (!hasGroupActions) {
        return <span>{idx}</span>
    }

    return <RowIndexToggle id={id} index={idx} />

}
