import * as React from 'react';
import {AuthenticatorData, AuthenticatorState} from "../../AppHelpers";
import Form from "../../Form";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {Button} from "react-bootstrap";
import {useAppAuth} from "../../Application";

type LoginFormCodeProps = {
    data: AuthenticatorData<AuthenticatorState.SecondFactor>,
}

const LoginFormCode = ({ data }: LoginFormCodeProps) => {

    const appAuth = useAppAuth();
    const handleSubmit = React.useCallback((data: FieldValues) => appAuth.authenticator.loginSecondFactor(data), []);
    const handleResend = React.useCallback(() => appAuth.authenticator.resendSecondFactor(), []);

    return (
        <Form.Callback handleSubmit={handleSubmit} showSuccess={false}>
            <div>
                <div>{data.info}</div>
                <Form.Input name='code' size='lg' className='text-center' groupClass='' />
                <Button variant='link' disabled={!data.allowResend} onClick={handleResend} className='px-0'>Відправити код ще раз</Button>
            </div>
            <div className='d-flex align-items-center mt-2'>
                <Form.Check name='remember' label="Запам'ятати пристрій" />
                <Form.Submit className='ms-auto' enable={true}>Продовжити</Form.Submit>
            </div>
        </Form.Callback>
    );
}

export default LoginFormCode
