import * as React from 'react';
import {FlexCenteredInfo} from "@ova-studio/react-hyper-admin";
import {MediaTypeIcon} from "../../types/MediaType";
import {Media} from "../../types/Media";
import {useMediaLibraryData, useMediaLibraryDataStore} from "../../MediaLibraryContext";
import {MediaContextMenuTrigger} from "./MediaContextMenu";

type MediaCardProps = {
    data: Media,
}

const MediaCard = ({ data }: MediaCardProps) => {

    const store = useMediaLibraryDataStore();
    const { selectedMedia } = useMediaLibraryData();

    const isSelected = React.useMemo<boolean>(() => selectedMedia.some(i => i.id === data.id), [ selectedMedia ])

    return (
        <MediaContextMenuTrigger
            as='div'
            media={data}
            triggerOnLeftClick={!store.allowSelect}
        >
            <div onClick={() => store.handleMediaClick(data)} role='button' className={`media-card ${isSelected ? 'media-card-selected' : ''}`}>
                {data.thumb?.url ? (
                    <img
                        src={data.thumb.url}
                        alt={data.filename}
                        style={{ objectFit: 'cover', backgroundImage: `url(${data.thumb.pixel})` }}
                        className='media-card-preview'
                    />
                ) : (
                    <FlexCenteredInfo icon='image-off-outline' text="Немає прев'ю" />
                )}
                <div className='media-card-info'>
                    <i className={`mdi mdi-${MediaTypeIcon[data.type]} me-1`} />
                    {data.filename}
                </div>
            </div>
        </MediaContextMenuTrigger>
    )
}

export default MediaCard
