import * as React from 'react';
import {Col, Row} from "react-bootstrap";
import CardWrapper from "./CardWrapper";
import Topbar from "./Topbar";
import MediaListWrapper from "./MediaListWrapper";
import {FolderView} from "./FolderView";

const Body = () => {
    return (
        <React.Fragment>
            <Row className='g-1' style={{ height: 700 }}>
                <Col lg={3} className='d-none d-lg-block'>
                    <CardWrapper>
                        <div className='h-100 position-relative'>
                            <FolderView
                                className='custom-scrollbar scroll-vertical position-absolute top-0 bottom-0 start-0 end-0'
                            />
                        </div>
                    </CardWrapper>
                </Col>
                <Col lg={9}>
                    <CardWrapper>
                        <div className='h-100 d-flex flex-column align-items-stretch'>
                            <Topbar />
                            <div className='flex-grow-1 position-relative'>
                                <MediaListWrapper />
                            </div>
                        </div>
                    </CardWrapper>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default Body
