import {HasHelpersAndRelations, HelperWithCondition} from "../types";
import {SimpleCrudActionParams} from "./SimpleCrudActionParams";
import {ConfigLoaderDefinition, LoaderConfig} from "../../../loaders";
import QueryString from "qs";

function parseEndpoint(endpoint : string) : { url: string, params: Record<string, any> } {
    const [url, query] = endpoint.split('?');
    const params = query?.length > 0 ? QueryString.parse(query) : {};

    return { url, params }
}

function prepareHelper(value: string | HelperWithCondition) : LoaderConfig {
    const { url, params } = parseEndpoint(typeof value === "string" ? value : value.endpoint);

    return {
        condition: typeof value !== "string" ? value.condition : undefined,
        endpoint: url,
        queryString: typeof value === "string" ? undefined : value.queryString,
        params: { type: 'helper', ...params },
    }
}

function prepareRelation(baseEndpoint: string, value: string | HelperWithCondition) : LoaderConfig {
    const { url, params } = parseEndpoint(typeof value === "string" ? value : value.endpoint);

    return {
        endpoint: baseEndpoint,
        paramName: 'id',
        subEndpoint: url,
        condition: typeof value !== "string" ? value.condition : undefined,
        queryString: typeof value === "string" ? undefined : value.queryString,
        params: { type: 'helper', ...params },
    }
}

type Options = {
    baseEndpoint: SimpleCrudActionParams['baseEndpoint'],
    paramName: SimpleCrudActionParams['paramName'],
    dataType?: string,
    initialData?: Record<string, any>,
}

export default function prepareLoaderConfig(helpers: HasHelpersAndRelations, options: Options) : ConfigLoaderDefinition {

    const configSync : ConfigLoaderDefinition['sync'] = { }
    const configDefer : ConfigLoaderDefinition['defer'] = { }

    if (options.initialData) {
        configSync.data = {
            data: options.initialData,
        }
    } else if (options.dataType) {
        configSync.data = {
            endpoint: options.baseEndpoint,
            paramName: options.paramName,
            params: { type: options.dataType },
        }
    }

    if (helpers.helpers) {
        for (const [key, value] of Object.entries(helpers.helpers)) {
            if (typeof value !== "string" && value.isSync) {
                configSync[key] = prepareHelper(value);
                continue;
            }

            configDefer[key] = prepareHelper(value);
        }
    }

    if (helpers.relations) {
        for (const [key, value] of Object.entries(helpers.relations)) {
            if (typeof value !== "string" && value.isSync) {
                configSync[key] = prepareRelation(options.baseEndpoint, value);
                continue;
            }

            configDefer[key] = prepareRelation(options.baseEndpoint, value);
        }
    }

    return {
        sync: Object.keys(configSync).length > 0 ? configSync : undefined,
        defer: Object.keys(configDefer).length > 0 ? configDefer : undefined,
    }
}
