import * as React from "react";
import {useDatatableContext} from "../../../DatatableContext";
import SmallCol from "./SmallCol";
import HeaderCheckCell from "./HeaderCheckCell";
import IndexCell from "./IndexCell";
import ColHeaderCell from "./ColHeaderCell";

const Header = () => {

    const {
        showColumns,
        hasGroupActions,
        data: { options: { hasIndexes, hasRowActions } }
    } = useDatatableContext();

    return (
        <thead>
        <tr>
            {hasGroupActions && <HeaderCheckCell />}
            {hasIndexes && <IndexCell />}
            {showColumns.map((col) => <ColHeaderCell key={'col-' + col.key} data={col} />)}
            {hasRowActions && <SmallCol />}
        </tr>
        </thead>
    );
}

export default Header
