import * as React from "react";
import {Navigate, RouteObject} from "react-router-dom";
import ErrorBoundary from "../AppWrapper/InfoPages/ErrorBoundary";
import {makeSimpleRoute} from "../route";

const LoginLayout = React.lazy(() => import('../AppWrapper/Login/LoginLayout'))
const LoginForm = React.lazy(() => import('../AppWrapper/Login/LoginForm'))

const guestRoutes : RouteObject[] = [
    {
        path: 'login',
        element: <LoginForm />,
    },
]

export default function wrapGuestRoutes(routes : RouteObject[]) : RouteObject[] {
    return [
        {
            element: <LoginLayout />,
            errorElement: <ErrorBoundary />,
            children: [
                ...guestRoutes,
                ...routes,
                makeSimpleRoute({
                    path: '*',
                    name: 'Перенаправлення на сторінку входу',
                    element: <Navigate to='/login' />,
                }),
            ],
        },
    ];
}
