import * as React from 'react';
import {ControlType, DynamicFormField, isControlType} from "./types";
import FormInput from "../Fields/FormInput";
import FormHiddenInput from "../Fields/FormHiddenInput";
import FormCheck from "../Fields/FormCheck";
import FormDatetimeInput from "../Fields/FormDatetimeInput";
import FormDatetimeRangeInput from "../Fields/FormDatetimeRangeInput";
import FormMap from "../Fields/FormMap";
import FormMaskInput from "../Fields/FormMaskInput";
import FormSelect from "../Fields/FormSelect";
import {Alert} from "react-bootstrap";
import CustomDynamicFormField from "./CustomDynamicFormField";

export type DynamicFormFieldProps = {
    field: DynamicFormField,
}

const DynamicFormField = ({ field }: DynamicFormFieldProps) => {

    if (isControlType(field, ControlType.Input)) {
        return <FormInput {...field.props} />
    }

    if (isControlType(field, ControlType.Hidden)) {
        return <FormHiddenInput {...field.props} />
    }

    if (isControlType(field, ControlType.Check)) {
        return <FormCheck {...field.props} />
    }

    if (isControlType(field, ControlType.Datetime)) {
        return <FormDatetimeInput {...field.props} />
    }

    if (isControlType(field, ControlType.DatetimeRange)) {
        return <FormDatetimeRangeInput {...field.props} />
    }

    if (isControlType(field, ControlType.Map)) {
        return <FormMap {...field.props} />
    }

    if (isControlType(field, ControlType.MaskInput)) {
        return <FormMaskInput {...field.props} />
    }

    if (isControlType(field, ControlType.Select)) {
        return <FormSelect {...field.props} />
    }

    if (isControlType(field, ControlType.Custom)) {
        return <CustomDynamicFormField {...field.props} />
    }

    return (
        <Alert variant='danger'>
            Field of type {field.type} not found.
        </Alert>
    );
}

export default DynamicFormField
