import * as React from "react";
import {useAppSearch} from "../../../Application";
import useExternalStore from "../../../hooks/useExternalStore";
import BouncingLoader from "../../../components/BouncingLoader";
import SearchResultDisplay from "./SearchResultDisplay";
import HistoryDisplay from "./HistoryDisplay";

const SearchResultWrapper = () => {

    const search = useAppSearch();
    const { isLoading, history, results} = useExternalStore(search);

    if (isLoading) {
        return (
            <BouncingLoader />
        );
    }

    if (results === null) {
        return (
            <React.Fragment>
                {history.length > 0 ? (
                    <HistoryDisplay />
                ) : (
                    <div className='text-muted'>
                        Введіть запит для пошуку
                    </div>
                )}
            </React.Fragment>
        )
    }

    return (
        <SearchResultDisplay />
    )
}

export default SearchResultWrapper;