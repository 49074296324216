import ItemDataStore from "../store/ItemDataStore";
import AbstractWebsocketAdapter from "./AbstractWebsocketAdapter";
import Websocket from "../../AppHelpers/Websocket";

export type WebsocketItemDataStoreAdapterOptions<D> = {
    channelName: string | ((data: D) => string);
    events: string | string[];
}

export default class WebsocketItemDataStoreAdapter extends AbstractWebsocketAdapter {

    private readonly _store: ItemDataStore<any>;
    private readonly _options: WebsocketItemDataStoreAdapterOptions<any>;

    constructor(ws: Websocket, store: ItemDataStore<any>, options: WebsocketItemDataStoreAdapterOptions<any>) {
        super(ws);
        this._store = store;
        this._options = options;
    }

    private get _channelName(): string {
        const data = this._store.getData();
        return typeof this._options.channelName === "function" ? this._options.channelName(data) : this._options.channelName;
    }

    public init() {
        this._initListener(this._channelName, this._options.events, (data) => {
            this._store.updateData(data);
        });
    }
}