import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["activeIndex", "id", "isFocused", "isMenuShown", "multiple", "onClick", "onFocus", "placeholder"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import cx from 'classnames';
import getMenuItemId from './getMenuItemId';
import hasOwnProperty from './hasOwnProperty';
var getInputProps = function getInputProps(_ref) {
  var activeIndex = _ref.activeIndex,
    id = _ref.id,
    isFocused = _ref.isFocused,
    isMenuShown = _ref.isMenuShown,
    multiple = _ref.multiple,
    onClick = _ref.onClick,
    onFocus = _ref.onFocus,
    placeholder = _ref.placeholder,
    props = _objectWithoutProperties(_ref, _excluded);
  return function () {
    var _cx;
    var inputProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var className = hasOwnProperty(inputProps, 'className') ? String(inputProps.className) : undefined;
    return _objectSpread(_objectSpread(_objectSpread(_objectSpread({
      // These props can be overridden by values in `inputProps`.
      autoComplete: 'off',
      placeholder: placeholder,
      type: 'text'
    }, inputProps), props), {}, {
      'aria-activedescendant': activeIndex >= 0 ? getMenuItemId(id, activeIndex) : undefined,
      'aria-autocomplete': 'both',
      'aria-expanded': isMenuShown,
      'aria-haspopup': 'listbox',
      'aria-multiselectable': multiple || undefined,
      'aria-owns': isMenuShown ? id : undefined,
      className: cx((_cx = {}, _defineProperty(_cx, className || '', !multiple), _defineProperty(_cx, "focus", isFocused), _cx))
    }, multiple && {
      inputClassName: className
    }), {}, {
      onClick: onClick,
      onFocus: onFocus,
      role: 'combobox'
    });
  };
};
export default getInputProps;