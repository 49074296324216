import * as React from 'react';
import {FolderID} from "../../types/MediaFolder";
import {
    BouncingLoader,
    FlexCentered,
    FlexCenteredInfo,
    useAppPageContextData,
    useExternalStore
} from "@ova-studio/react-hyper-admin";
import {Button} from "react-bootstrap";
import TreeView from "react-accessible-treeview";
import {FolderConnectedContextMenu} from "./FolderContextMenu";
import {useMediaLibraryService} from "../../services/MediaLibraryService";
import FolderManager from "../../services/FolderManager";
import FolderRow from "./FolderRow";

type FolderViewProps = {
    className?: string,
    onSelect?: (folderId: FolderID | null) => void,
}

const FolderView = ({ className, onSelect }: FolderViewProps) => {
    const folderManager = useMediaLibraryService().folderManager;
    const { folders, selectedFolder, error } = useExternalStore(folderManager);

    const pageContext = useAppPageContextData();

    const handleSelect = (folderId: FolderID | null) => {
        folderManager.selectedFolder = folderId;
        onSelect?.(folderId);
    }

    React.useEffect(() => {
        folderManager.init();
        return () => {
            folderManager.selectedFolder = null;
        }
    }, [])

    if (error) {
        return (
            <FlexCenteredInfo
                color='danger'
                text={error}
                icon='alert-circle-outline'
            />
        )
    }

    if (folders === null) {
        return <FlexCentered><BouncingLoader /></FlexCentered>
    }

    return (
        <React.Fragment>
            <div className={className}>
                <div className='d-flex align-items-center user-select-none'>
                    <FolderRow
                        name='усі медіа'
                        isSelected={!selectedFolder}
                        icon='folder-multiple-image'
                        handleSelect={() => handleSelect(null)}
                    />
                    <div className='ms-auto'>
                        <Button variant='outline-secondary' className='px-1 py-0 mdi mdi-folder-plus font-16'
                                onClick={() => folderManager.openFolderCreate(null)}/>
                    </div>
                </div>
                {pageContext && (
                    <FolderRow
                        name="пов'язані медіа"
                        isSelected={selectedFolder === FolderManager.CONTEXT_FOLDER}
                        icon='folder-pound-outline'
                        handleSelect={() => handleSelect(FolderManager.CONTEXT_FOLDER)}
                    />
                )}
                {folders.length > 1 && (
                    <TreeView
                        data={folders}
                        propagateCollapse={true}
                        onNodeSelect={(node) => handleSelect(node.element.id)}
                        selectedIds={selectedFolder ? [selectedFolder] : []}
                        className='tree-view'
                        nodeRenderer={({element, handleExpand, handleSelect, isBranch, isSelected}) => (
                            <FolderRow
                                name={element.name}
                                isSelected={isSelected}
                                icon={isBranch ? 'folder-plus-outline' : 'folder-outline'}
                                handleSelect={handleSelect}
                                handleIconClick={isBranch ? handleExpand : undefined}
                                contextMenuProps={{ folderId: element.id, folderName: element.name }}
                            />
                        )}
                    />
                )}
                <FolderConnectedContextMenu/>
            </div>
        </React.Fragment>
    );
}

export default FolderView
