export type WebsocketConfig = {
    appKey: string,
    host: string,
    port: string,
    path?: string,
    authEndpoint?: string,
}

export const isWebsocketConfig = (obj: any) : obj is WebsocketConfig => {
    return typeof obj !== 'undefined'
        && typeof obj.appKey === 'string'
        && typeof obj.host === 'string'
        && typeof obj.port === 'string'
        && (typeof obj.path === 'undefined' || typeof obj.path === 'string')
        && (typeof obj.authEndpoint === 'undefined' || typeof obj.authEndpoint === 'string')
}