import * as React from "react";
import {NavMenuCounterValue, NavMenuCounterVariant} from "../../../../AppHelpers/Navigation/types/MainNavigation";
import {useAppNavigation} from "../../../../Application";
import useExternalStore from "../../../../hooks/useExternalStore";
import {Badge} from "react-bootstrap";
import classNames from "classnames";

type NavCounterProps = {
    name: string,
    variant: NavMenuCounterVariant,
}

export const isShownValue = (value: NavMenuCounterValue) : boolean => {
    if (typeof value === 'string') {
        return value !== '';
    }

    if (typeof value === 'number') {
        return value !== 0;
    }

    return value !== null;
}

const NavCounter = ({ name, variant }: NavCounterProps) => {
    const appNavigation = useAppNavigation();
    const { counters } = useExternalStore(appNavigation.MainNavigation);

    const value : NavMenuCounterValue = counters[name] ?? null;

    if (!isShownValue(value)) {
        return null;
    }

    const currentVariant = typeof variant === 'function' ? variant(value) : variant;

    return (
        <Badge bg={currentVariant ?? undefined} className={classNames('float-end', variant && `text-bg-${variant}`)}>
            {value}
        </Badge>
    )
}

export default NavCounter;