import * as React from 'react';
import {Link} from "react-router-dom";
import {useEffect, useMemo} from "react";
import useRouteMatches from "../../../hooks/useRouteMatches";
import {useBaseAppConfig} from "../../../Application";

const Title = () => {

    const { appName } = useBaseAppConfig();

    const matches = useRouteMatches();

    const data = useMemo(() => {
        return matches.slice(-1)[0] ?? null;
    }, [ matches ])

    useEffect(() => {
        document.title = (data?.title ? `${data.title} • ` : '') + appName;
    }, [ data?.title ])

    if (!data) {
        return null;
    }

    return (
        <h4 className="page-title d-flex align-items-center gap-1">
            {data.name}

            {data.titleLinks.map((link, idx) => (
                <Link key={`title-link-${idx}`} to={link.to} className={link.className}>{link.text}</Link>
            ))}

            {data.titleEnd && <div className='ms-auto'>{data.titleEnd}</div>}
        </h4>
    );
}

export default Title
