import * as React from 'react';
import {GlobalActionButtonBaseProps} from "../../../../../AppHelpers/Navigation";

type GlobalButtonBadgeProps = {
    data: GlobalActionButtonBaseProps['badge'],
}

const GlobalButtonBadge = ({}: GlobalButtonBadgeProps) => {
    return (
        <>
        </>
    );
}

export default GlobalButtonBadge
