import * as React from "react";
import {useForm} from "react-hook-form";
import FormBody, {FormBodyProps} from "./FormBody";
import useDefaultValues from "../hooks/useDefaultValues";
import {FormValues, SubmitHandler} from "../types";

export type CallbackFormProps = Omit<FormBodyProps, 'methods' | 'handleSubmit' | 'children'> & {
    data?: FormValues,
    children: React.ReactNode,
    readonly urlValues?: string[],
    handleSubmit: SubmitHandler,
}

const CallbackForm = ({ data, children, urlValues, handleSubmit, ...props } : CallbackFormProps) => {

    const defaultValues = useDefaultValues(data, urlValues);

    const methods = useForm({ defaultValues });

    return (
        <FormBody
            {...props}
            methods={methods}
            handleSubmit={handleSubmit}
            children={children}
        />
    );
}

export default CallbackForm
