import React from "react";
import {DatatableContextData} from "../../DatatableContext/DatatableContext";
import {isBasePaginationData, isCursorPaginationData} from "../../types/DatatableData";

type DisplayInfoProps = {
    pagination: DatatableContextData['data']['pagination'],
    rowsCnt: number,
}

const DisplayInfo = ({ pagination, rowsCnt } : DisplayInfoProps) => {

    if (!rowsCnt) {
        return null;
    }

    if (isBasePaginationData(pagination)) {
        const { from, to, total } = pagination;

        return (
            <div className='table-display-info text-muted small'>
                Показано записи {from} - {to} з {total}
            </div>
        )
    }

    if (isCursorPaginationData(pagination)) {
        return (
            <div className='table-display-info text-muted small'>
                Показано {rowsCnt} записів
            </div>
        )
    }

    return <div className='table-display-info text-muted small'>Показано всі записи</div>
}

export default DisplayInfo
