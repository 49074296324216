import {UseConfirmParams} from "../../hooks/useConfirm";
import {DynamicFormFields} from "../../Form";
import {Variant} from "react-bootstrap/types";
import {ToastCreateFunctionResult} from "../Toasts";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {ComponentType} from "react";

export enum ActionStatus {
    WaitCheck = 'wait-check',
    Confirming = 'confirming',
    WaitData = 'wait-data',
    Queued = 'queued',
    Success = 'success',
    Error = 'error',
}

export type ActionDefinition = {
    name: string,
    icon?: string,
    title: string,
    allowed: boolean,
}

export type ActionOptions = {
    ids: string[]|number[],
    model: string,
}

export type Action = ActionDefinition & ActionOptions

export type ActionsList = Action[]

export type ConfirmableActionCheckData = Omit<UseConfirmParams, 'handleContinue' | 'handleExit' | 'handleClose' | 'initialModalState'>

export type GlobalFormActionCheckData = {
    message?: string,
    values?: FieldValues,
    submitText?: string,
    cancelText?: string,
    color?: Variant,
}

export type FormActionCheckData = GlobalFormActionCheckData & {
    fields: DynamicFormFields,
}

export type CustomFormActionCheckData = GlobalFormActionCheckData & {
    formName: string,
}

export type ContinueActionCheckData = {
    continue: true,
}

export type ActionCheckData = FormActionCheckData | ConfirmableActionCheckData | ContinueActionCheckData

export type ActionResult = {
    message?: string|null,
    data?: any,
    redirect?: string,
};

type ActiveActionDataMap = {
    [ActionStatus.WaitCheck]: undefined,
    [ActionStatus.Confirming]: ConfirmableActionCheckData,
    [ActionStatus.WaitData]: FormActionCheckData | CustomFormActionCheckData,
    [ActionStatus.Queued]: undefined,
    [ActionStatus.Success]: ActionResult,
    [ActionStatus.Error]: ActionResult,
}

type ActiveActionData<T = ActionStatus> = T extends ActionStatus ? ActiveActionDataMap[T] : undefined;

export type ActionStatusData<T = ActionStatus> = { status: T } & { data: ActiveActionData<T> }

export type ActiveAction<T = ActionStatus> = {
    actionId: string,
    endpoint: string,
    action: Action,
    toast: ToastCreateFunctionResult,
    handleRun: (data: FieldValues) => Promise<void>,
    handleCancel: () => void,
    getResultRenderer: () => ComponentType<{ data: any }> | undefined,
    isRunning: boolean,
    handlers?: ActionResultHandlers,
} & ActionStatusData<T>

export type ActiveActions = ActiveAction[];

export type LoadActionOptions = ActionOptions & {
    action: string,
}

export type LoadActionsOptions = ActionOptions & {
    filter?: string[],
}

export type ActionResultStatus = ActionStatus.Success | ActionStatus.Error | ActionStatus.Queued;

export type ActionResultData<T = ActionResultStatus> = {
    status: T,
} & ActiveActionData<T>

export type ActionResultHandlers = {
    onSuccess?: () => void,
    onError?: () => void,
    onAny?: () => void,
}

export type ActionsConfig = {
    endpoint: string,
}