import React, {useContext} from "react";
import {FormStatus} from "../../types";

export type BaseFormOptions = {
    name?: string,
    feedbackType?: 'tooltip'|'inline',
}

export type FormOptionsContextValue = BaseFormOptions & {
    formStatus: FormStatus,
}

export type FormOptionsContextProviderProps = FormOptionsContextValue & {
    children: React.ReactNode,
}

const optionsDefaults : FormOptionsContextValue = {
    name: 'form',
    feedbackType: 'tooltip',
    formStatus: FormStatus.Idle,
};

const FormOptionsContext = React.createContext<FormOptionsContextValue|undefined>(undefined);

export const FormOptionsContextProvider = ({ children, ...options } : FormOptionsContextProviderProps) : JSX.Element => {
    return (
        <FormOptionsContext.Provider value={{ ...optionsDefaults, ...options }}>
            {children}
        </FormOptionsContext.Provider>
    )
}

export function useFormOptionsContext() : FormOptionsContextValue {
    const data = useContext(FormOptionsContext);

    if (!data) {
        throw new Error('FormOptionsContext is not provided');
    }

    return data;
}
