import {useSearchParams} from "react-router-dom";
import {useMemo} from "react";
import {FormValues} from "../types";

function useDefaultValuesWithUrlParams(urlValues: string[], dataValues?: FormValues) : FormValues {
    const [ searchParams ] = useSearchParams();

    const urlValuesData = useMemo<FormValues>(() => {

        if (!urlValues) {
            return {};
        }

        const values : FormValues = {};

        for (let urlValue of urlValues) {
            const val = searchParams.get(urlValue);

            if (val) {
                values[urlValue] = val;
            }
        }

        return values;

    }, [ urlValues ])

    return useMemo<FormValues>(() => ({
        ...urlValuesData,
        ...(dataValues ?? {})
    }), [  ])
}

function useDefaultValuesWithoutUrlParams(dataValues?: FormValues) : FormValues {
    return useMemo<FormValues>(() => ({
        ...(dataValues ?? {})
    }), [  ])
}

export default function useDefaultValues(dataValues?: FormValues, urlValues?: string[]) : FormValues {
    if (urlValues) {
        return useDefaultValuesWithUrlParams(urlValues, dataValues);
    }

    return useDefaultValuesWithoutUrlParams(dataValues);
}
