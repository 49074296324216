import * as React from 'react';
import MediaLibraryContextProvider from "../MediaLibraryContext";
import {MediaLibraryOptions} from "../MediaLibraryContext/MediaLibraryDataStore";

export type WrapperProps = MediaLibraryOptions & {
    children: React.ReactNode,
}

const Wrapper = ({ children, ...opts }: WrapperProps) => {
    return (
        <MediaLibraryContextProvider {...opts}>
            {children}
        </MediaLibraryContextProvider>
    );
}

export default Wrapper
