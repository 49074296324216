import * as React from 'react';
import {
    GlobalActionSimpleButton, isGlobalActionButtonHandler,
    isGlobalActionButtonNavigate
} from "../../../../../AppHelpers/Navigation";
import GlobalDropdownButtonItemNavigate from "./GlobalDropdownButtonItemNavigate";
import GlobalDropdownButtonItemHandler from "./GlobalDropdownButtonItemHandler";

type GlobalDropdownButtonItemProps = {
    data: GlobalActionSimpleButton,
}

const GlobalDropdownButtonItem = ({ data }: GlobalDropdownButtonItemProps) => {
    if (isGlobalActionButtonNavigate(data)) {
        return <GlobalDropdownButtonItemNavigate data={data} />
    }

    if (isGlobalActionButtonHandler(data)) {
        return <GlobalDropdownButtonItemHandler data={data} />
    }

    return null
}

export default GlobalDropdownButtonItem
