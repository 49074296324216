import {LoadActionOptions} from "../types";
import * as React from "react";
import {useAppActions} from "../../../Application";

export type UseActionHandlerReturn = () => Promise<void>;

export default function useActionHandler(options: LoadActionOptions) : UseActionHandlerReturn {
    const appActions = useAppActions();
    return React.useCallback(async () => {
        const data = await appActions.loadAction(options);
        await appActions.handleAction(data);
    }, []);
}
