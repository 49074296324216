import * as React from 'react';
import {DateTime} from "luxon";

export type DateTimeFormatProps = {
    dt: string,
    children: (dt : DateTime) => React.ReactNode,
}

const DateTimeFormat = ({ dt: _dt, children }: DateTimeFormatProps) => {

    const dt = React.useMemo(() => DateTime.fromISO(_dt), [ _dt ]);

    return (
        <time title={dt.toISOTime() ?? undefined}>
            {children(dt)}
        </time>
    );
}

export default DateTimeFormat
