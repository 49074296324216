import * as React from 'react';
import {Card} from "react-bootstrap";

type CardWrapperProps = {
    className?: string,
    children: React.ReactNode,
}

const CardWrapper = ({ className, children }: CardWrapperProps) => {
    return (
        <Card className={className ?? 'h-100 mb-0'}>
            <Card.Body className='p-2'>
                {children}
            </Card.Body>
        </Card>
    );
}

export default CardWrapper
