import * as React from 'react';
import classNames from "classnames";
import TopbarTabs from "./TopbarTabs";
import TopbarBreadcrumbs from "../TopbarBreadcrumbs";
import TopbarRow from "./TopbarRow";
import useExternalStore from "../../../hooks/useExternalStore";
import {useAppNavigation} from "../../../Application";
import SearchDisplay from "../../../AppHelpers/Search/SearchDisplay/SearchDisplay";

const Topbar = () => {

    const appNavigation = useAppNavigation();

    const { isVisible } = useExternalStore(appNavigation.Topbar);
    const { isOpen: isMainNavOpen } = useExternalStore(appNavigation.MainNavigation);

    return (
        <div className={classNames('navbar-custom', { 'navbar-hidden': !isVisible })} style={isMainNavOpen ? { zIndex: 90 } : undefined}>
            <div className='navbar-main-row d-flex align-items-center gap-1'>
                <div className='d-md-none topbar-breadcrumbs'>
                    <TopbarBreadcrumbs />
                </div>
                <SearchDisplay />
            </div>
            <TopbarTabs />
            <TopbarRow />
        </div>
    )
}

export default Topbar;
