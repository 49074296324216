import BaseDatatable from "./Table";
export type { DatatableProps as BaseDatatableProps } from "./Table";

import Datatable from "./LoaderDatatable";
export type { LoaderDatatableProps as DatatableProps } from "./LoaderDatatable";
export type { RenderConfigData } from "./types/RenderConfig";

import { default as defaultConfig } from './defaults/config'

import { default as useSearchParams } from './hooks/useSearchParams'

export default Object.assign(Datatable, {
    Base: BaseDatatable,

    defaultConfig,

    useSearchParams,
})
