import * as React from 'react';
import SmallCol from "./SmallCol";
import FormCheckInput from "react-bootstrap/FormCheckInput";
import {useDatatableContext} from "../../../DatatableContext";

const HeaderCheckCell = () => {

    const {
        isAllRowsSelected,
        setAllRowsSelected,
    } = useDatatableContext();

    return (
        <SmallCol>
            <FormCheckInput
                checked={isAllRowsSelected}
                onChange={e => setAllRowsSelected(e.target.checked)}
            />
        </SmallCol>
    );
}

export default HeaderCheckCell
