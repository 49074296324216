import {
    SearchConfig,
    SearchData, SearchResultCountData, SearchResultDataResponse,
    SearchResultItem,
    SearchResultType,
    SearchTypesConfig,
} from "./types";
import AbstractDataStore from "../../abstracts/AbstractDataStore";
import { debounce } from "lodash";
import defaultSearchResultRender from "./SearchDisplay/DefaultSearchResultRender";
import API, {getApiErrorMessage} from "@ova-studio/api-helper";
import {App} from "../../Application";

export default class Search extends AbstractDataStore<SearchData> {

    private static historyKey = 'ova-admin::search-history';

    private readonly _app: App;

    private readonly _config: SearchConfig;

    private _page: number = 1;

    private _data: SearchData;

    private _inputRef: HTMLInputElement | null = null;

    constructor(app: App, config: SearchConfig) {
        super();

        this._app = app;
        this._config = config;

        this._data = {
            query: '',

            isActive: false,
            isLoading: false,
            isLoadingMore: false,

            selectedType: null,
            types: this._filterAllowedTypes(config.types),
            typesCnt: {},

            selectedResultIdx: 0,
            results: null,
            hasMore: false,
            error: null,

            selectedHistoryIdx: -1,
            history: this._loadHistory(),
        }

        this._initListeners();
    }

    private _filterAllowedTypes(types: SearchTypesConfig) : SearchTypesConfig {
        return Object.entries(types)
            .filter(([ _, data ]) => !data.allowCheck || data.allowCheck(this._app))
            .reduce((acc, [ key, value ]) => ({
                ...acc,
                [key]: value,
            }), {});
    }

    private _loadHistory() : string[] {
        const history = localStorage.getItem(Search.historyKey);

        if (!history) {
            return [];
        }

        try {
            return JSON.parse(history);
        } catch (e) {
            localStorage.removeItem(Search.historyKey);
            return [];
        }
    }

    private _addToHistory(query: string) {
        const history = this._loadHistory();

        const newHistory = ([
                query,
                ...history,
            ])
            .filter(v => v.trim().length > 0)
            .filter((v, idx, arr) => arr.indexOf(v) === idx)
            .slice(0, 20);

        localStorage.setItem(Search.historyKey, JSON.stringify(newHistory));

        this._updateData({ selectedHistoryIdx: -1, history: newHistory });
    }

    private _initListeners() {
        window.addEventListener('keydown', this._handleKeyDown.bind(this));

        this._app.auth.subscribe(() => {
            this._updateData({ types: this._filterAllowedTypes(this._config.types) });
        });
    }

    public handleInputRef(ref: HTMLInputElement) {
        this._inputRef = ref;
    }

    private _handleKeyDown = (e: KeyboardEvent) => {
        if (!this._data.isActive) {
            return;
        }

        const prevent = () => {
            e.preventDefault();
            e.stopPropagation();
        }

        if (e.key === 'Escape') {
            prevent();
            this.close();
        } else if (e.key === 'Enter') {
            prevent();
            this._handleEnter(e.ctrlKey);
        } else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
            prevent();
            this._handleUpDownArrows(e.key === 'ArrowDown');
        } else if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
            prevent();
            this._changeSelectedType(e.key === 'ArrowRight' ? 1 : -1);
        }
    }

    private _changeSelectedType(direction: number) {
        const types = Object.keys(this._data.types);

        const currentIdx = this._data.selectedType ? types.indexOf(this._data.selectedType) : -1;

        let nextIdx = currentIdx + direction;

        while (nextIdx >= 0 && nextIdx < types.length) {
            const type = types[nextIdx];

            const typeCnt = this._data.typesCnt[type] ?? 0;

            if (typeCnt > 0) {
                this.handleTypeSelect(type);
                break;
            }

            nextIdx += direction;
        }
    }

    private _handleUpDownArrows(isDown: boolean) {
        if (this._data.results === null) {
            const nextIdx = this._data.selectedHistoryIdx + (isDown ? 1 : -1);

            if (nextIdx < -1 || nextIdx >= this._data.history.length) {
                return;
            }

            this._updateData({ selectedHistoryIdx: nextIdx, query: this._data.history[nextIdx] ?? ''});
        } else {
            const nextIdx = this._data.selectedResultIdx + (isDown ? 1 : -1);

            if (nextIdx >= this._data.results.length) {
                if (this._data.hasMore) {
                    void this.handleMore();
                }
                return;
            }

            if (nextIdx < -1) {
                return;
            }

            this._updateData({ selectedResultIdx: nextIdx });
        }
    }

    private _getResultUrl(result: SearchResultItem, isEdit: boolean) {
        if (isEdit) {
            return !!result.edit_url?.length ? result.edit_url : result.view_url;
        }

        return result.view_url?.length ? result.view_url : result.edit_url;
    }

    public handleResultSelect(result: SearchResultItem, isEdit: boolean) {
        if (this._data.types[result.type]?.onSelected) {
            this._data.types[result.type]?.onSelected?.(result, isEdit, this._app);
            this.close();
            return;
        }

        const url = this._getResultUrl(result, isEdit);

        if (!url) {
            this.close();
            return;
        }

        if (url.startsWith('http')) {
            const w = window.open(url, '_blank');
            w?.focus();
            this.close();
            return;
        }

        this._app.navigation.navigate(url);
        this.close();
    }

    private _handleEnter(isCtrl: boolean) {
        if (this._data.results === null) {
            this._startSearch();
            return;
        }

        const result = this._data.results[this._data.selectedResultIdx];

        if (!result) {
            return;
        }

        this.handleResultSelect(result, isCtrl);
    }

    private async _loadResultsCount() : Promise<void> {
        const typesCnt = await API.getData<SearchResultCountData>(`${this._config.endpoint}/counters`, { query: this._data.query });
        this._updateData({ typesCnt }, true);
    }

    private async _resolveSelectedType() : Promise<void> {
        if (this._data.selectedType !== null) {
            return;
        }

        for (const [ type, cnt ] of Object.entries(this._data.typesCnt)) {
            if (cnt > 0) {
                this._updateData({ selectedType: type }, true);
                return;
            }
        }
    }

    private async _loadResults() : Promise<void> {
        if (this._data.selectedType === null) {
            this._setEmptyResult();
            return;
        }

        this._page = 1;

        const result = await API.getData<SearchResultDataResponse>(this._config.endpoint, { query: this._data.query, type: this._data.selectedType });

        this._updateData({
            isLoading: false,
            results: result.items,
            hasMore: result.hasMore,
            selectedType: result.type,
        })
    }

    private readonly _loadResultsDebounced = debounce(this._loadResults.bind(this), 300);

    private async _handleSearch() : Promise<void> {
        if (!this._isQueryValid()) {
            this._setEmptyResult();
            return;
        }

        try {
            await this._loadResultsCount();
            await this._resolveSelectedType();
            await this._loadResults();
        } catch (e) {
            console.error('Search error:', e);
            this._updateData({ error: getApiErrorMessage(e) })
        } finally {
            this._updateData({ isLoading: false });
        }
    }

    private readonly _handleSearchDebounced = debounce(this._handleSearch.bind(this), 300);

    public async handleMore() {
        if (this._data.isLoadingMore) {
            return;
        }

        this._updateData({ isLoadingMore: true })

        try {
            const result = await API.getData<SearchResultDataResponse>(this._config.endpoint, { query: this._data.query, type: this._data.selectedType, page: this._page + 1 });
            this._page++;

            this._updateData({
                results: [
                    ...(this._data.results ?? []),
                    ...result.items,
                ],
                hasMore: result.hasMore,
            })
        } catch (e) {
            console.error('Search error:', e);
            this._updateData({ error: getApiErrorMessage(e) })
        } finally {
            this._updateData({ isLoadingMore: false });
        }
    }

    private _isQueryValid() {
        return this._data.query.trim().length >= 3;
    }

    private _cancelLoading() {
        this._handleSearchDebounced.cancel();
        this._loadResultsDebounced.cancel();
    }

    private _cleanSearchData() {
        this._updateData({ results: null, error: null, isLoading: false });
    }

    private _setEmptyResult() {
        this._updateData({ results: null, error: null, isLoading: false });
    }

    private _startSearch() {
        this._cancelLoading();

        if (!this._isQueryValid()) {
            this._cleanSearchData();
            return;
        }

        this._updateData({ isLoading: true, error: null });
        this._handleSearchDebounced();
    }

    public setQuery(query: string) {
        this._updateData({ query });
        this._startSearch();
    }

    public activate() {
        this._updateData({ isActive: true });
    }

    public close() {
        this._addToHistory(this._data.query);

        this._page = 1;

        this._updateData({
            query: '',

            isActive: false,
            isLoading: false,
            isLoadingMore: false,

            selectedType: null,
            typesCnt: {},

            selectedResultIdx: 0,
            results: null,
            hasMore: false,
            error: null,
        });

        this._cancelLoading();
        this._inputRef?.blur();
    }

    public handleTypeSelect(type: SearchResultType | null) {
        this._cancelLoading();

        this._updateData({ selectedType: type, selectedResultIdx: 0 }, true);

        if (!this._isQueryValid()) {
            this._cleanSearchData();
            return;
        }

        this._updateData({ isLoading: true, error: null });
        this._loadResultsDebounced();
    }

    public resolveTypeRenderer(type: SearchResultType) {
        return this._data.types[type]?.render ?? defaultSearchResultRender;
    }

    public resolveTypeIcon(type: SearchResultType) {
        return this._data.types[type]?.icon ?? null;
    }

    private _updateData(data: Partial<SearchData>, silent: boolean = false) {
        this._data = {
            ...this._data,
            ...data,
        };

        if (!silent) {
            this._callListeners();
        }
    }

    getData(): SearchData {
        return this._data;
    }
}