import * as React from 'react';
import {TooltipProps} from "../../components/Tooltip";
import classNames from "classnames";
import {FormLabel as BsFormLabel} from "react-bootstrap";
import LabelTooltip from "./LabelTooltip";
import {useFormOptionsContext} from "../Forms/FormBody/FormOptionsContext";

export type LabelProps = {
    name?: string,
    children: React.ReactNode,
    isHorizontal?: boolean,
    tooltip?: TooltipProps['text'],
    tooltipIcon?: string,
    tooltipPlacement?: TooltipProps['placement'],
}

const Label = ({ name, children, isHorizontal = false, tooltip, tooltipIcon, tooltipPlacement }: LabelProps) => {

    const { name: formName } = useFormOptionsContext();

    const controlId = name ? (formName + '.' + name) : undefined;

    return (
        <BsFormLabel htmlFor={controlId} className={classNames({ 'col-3 col-form-label' : isHorizontal })}>
            {children}
            {tooltip && <LabelTooltip text={tooltip} icon={tooltipIcon} placement={tooltipPlacement} />}
        </BsFormLabel>
    );
}

export default Label
