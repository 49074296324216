import useWebsocketArrayDataStore, {
    UseWebsocketArrayDataStoreOptions,
    UseWebsocketArrayDataStoreReturn
} from "./useWebsocketArrayDataStore";
import {ArrayData, BasicData} from "../../types";
import useLoaderData from "../useLoaderData";

export type UseWebsocketLoaderArrayDataStoreReturn<D extends BasicData> = UseWebsocketArrayDataStoreReturn<D>;

export const useWebsocketLoaderArrayDataStore = <D extends BasicData>(dataKey: string, opts: UseWebsocketArrayDataStoreOptions<D>) : UseWebsocketLoaderArrayDataStoreReturn<D> => {
    const initialData = useLoaderData<ArrayData<D>>(dataKey);
    return useWebsocketArrayDataStore(initialData, opts);
}

export default useWebsocketLoaderArrayDataStore;