import * as React from 'react';
import {SimpleCallback} from "../../../../../types/SimpleCallback";
import {GlobalActionButtonBaseProps} from "../../../../../AppHelpers/Navigation";
import GlobalButtonBadge from "./GlobalButtonBadge";
import {Button} from "react-bootstrap";
import GlobalButtonIcon from "./GlobalButtonIcon";

type GlobalBasicButtonProps = {
    className: string,
    onClick: SimpleCallback,
    options: GlobalActionButtonBaseProps,
}

const GlobalBasicButton = ({ className, onClick, options }: GlobalBasicButtonProps) => {
    return (
        <Button
            title={options.title}
            onClick={onClick}
            className={`rounded-btn ${className}`}
            variant={options.variant ?? 'secondary'}
            disabled={options.disabled}
        >
            <GlobalButtonIcon icon={options.icon} />
            <GlobalButtonBadge data={options.badge} />
        </Button>
    );
}

export default GlobalBasicButton
