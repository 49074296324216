import {ButtonVariant} from "react-bootstrap/types";
import {DeleteActionOptions} from "../../hooks/useDeleteAction";

export type ActionButtonConfig = {
    icon: string,
    title?: string,
    buttonVariant?: ButtonVariant,
}

export type NavigateAction = ActionButtonConfig & {
    route: string,
}

export enum SpecialActions {
    Delete = 'delete',
}

type SpecialActionConfigMap = {
    [SpecialActions.Delete]: DeleteActionOptions,
}

export type SpecialActionConfig<T = SpecialActions> = T extends SpecialActions ? SpecialActionConfigMap[T] : never;

export type SpecialAction<T = SpecialActions> = ActionButtonConfig & {
    action: T,
    config: SpecialActionConfig<T>,
}

export type TableRowAction = NavigateAction|SpecialAction;

export type TableRowActions = TableRowAction[];

export function isNavigateAction(action: any) : action is NavigateAction {
    return typeof action !== 'undefined' && typeof action.route === 'string';
}

export function isSpecialAction<T extends SpecialActions>(action: any, type: T) : action is SpecialAction<T> {
    return typeof action !== 'undefined' && 'action' in action && action.action === type;
}
