import * as React from "react";
import FormGroupContainer, {FormGroupContainerBaseProps} from "./FormGroupContainer";
import {FormSelectOption} from "./BaseInputProps";
import {Controller} from "react-hook-form";
import {Dropdown, DropdownButtonProps} from "react-bootstrap";
import ErrorFeedback from "./ErrorFeedback";
import classNames from "classnames";

export type RenderOptionFunc = (option: FormSelectOption) => React.ReactNode;

export type FormDropdownSelectProps = FormGroupContainerBaseProps & {
    options: FormSelectOption[],
    placeholder?: React.ReactNode,
    disabled?: boolean,
    renderOption?: RenderOptionFunc,
    variant?: DropdownButtonProps['variant'],
    toggleClassName?: string,
}

const defaultRenderOption : RenderOptionFunc = (option: FormSelectOption) => {
    return option.label;
}

const renderActiveOption = (options: FormSelectOption[], value: string | number | null, renderFunc: RenderOptionFunc, placeholder?: React.ReactNode) : React.ReactNode => {
    const selectedOption = options.find(option => option.value === value);
    return selectedOption ? renderFunc(selectedOption) : placeholder;
}

const FormDropdownSelect = ({ name, options, placeholder, variant, disabled, renderOption = defaultRenderOption, toggleClassName, ...props }: FormDropdownSelectProps) => {
    return (
        <FormGroupContainer name={name} {...props}>
            {({ isError, errorMessage, control, controlId }) => (
                <Controller
                    name={name}
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <div className='position-relative'>
                            <Dropdown ref={ref}>
                                <Dropdown.Toggle variant={variant} id={controlId} disabled={disabled} className={classNames(toggleClassName, isError && 'border border-danger')}>
                                    {renderActiveOption(options, value, renderOption, placeholder) ?? <span className='text-muted'>(не вибрано)</span>}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {options.map(option => (
                                        <Dropdown.Item key={option.value} onClick={() => onChange(option.value)}>{renderOption(option)}</Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                            <ErrorFeedback isError={isError} errorMessage={errorMessage} />
                        </div>
                    )}
                />
            )}
        </FormGroupContainer>
    )
}

export default FormDropdownSelect;