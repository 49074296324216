import * as React from "react";
import App from "./App";
import {
    Actions,
    Auth,
    PageContextData,
    HelpersRegistry,
    Navigation,
    PageContext,
    Search,
    Services,
    Toasts, Websocket
} from "../AppHelpers";
import {Router} from "../Router";
import {Validator} from "../types/Validator";
import {BaseAppConfig} from "./types/BaseAppConfig";
import {AppConfig} from "./types/AppConfig";
import useExternalStore from "../hooks/useExternalStore";

const AppContext = React.createContext<App|undefined>(undefined);

export function useAppContext() : App {
    const context = React.useContext(AppContext);
    if (!context) {
        throw new Error('useAppContext must be used within a ApplicationContextProvider');
    }
    return context;
}

export function useAppConfig() : AppConfig {
    return useAppContext().config;
}

export function useBaseAppConfig() : BaseAppConfig {
    return useAppConfig().base;
}

export function useAppExternalConfig<T>(name: string, validator: Validator<T>) : T {
    const data = useAppConfig().external?.[name];

    if (!data || !validator(data)) {
        throw new Error('Invalid external config data');
    }

    return data;
}

export function useAppActions() : Actions {
    return useAppContext().actions;
}

export function useAppAuth() : Auth {
    return useAppContext().auth;
}

export function useAppNavigation() : Navigation {
    return useAppContext().navigation;
}

export function useAppToasts() : Toasts {
    return useAppContext().toasts;
}

export function useAppHelpers() : HelpersRegistry {
    return useAppContext().helpers;
}

export function useAppRouter() : Router {
    return useAppContext().router;
}

export function useAppSearch() : Search {
    return useAppContext().search;
}

export function useAppServices() : Services {
    return useAppContext().services;
}

export function useAppPageContext() : PageContext {
    return useAppContext().pageContext;
}

export function useAppWebsocket() : Websocket {
    return useAppContext().websocket;
}

export function useAppPageContextData() : PageContextData|null {
    const context = useAppPageContext();
    return useExternalStore(context);
}

export function useAppService<T>(key: string, validator: Validator<T>) : T {
    return useAppContext().services.get<T>(key, validator);
}

export default AppContext;
