export function makeMessage(message: string|null|undefined, fallbackMessage: string) : string {
    if (!message) {
        return fallbackMessage;
    }

    if (message.length < 1) {
        return fallbackMessage;
    }

    return message;
}
