import {SimpleCallback} from "../../types/SimpleCallback";
import AbstractDataStore from "../../abstracts/AbstractDataStore";

export type PageContextData = {
    type: string,
    name: string,
    data: Record<string, any>,
}

export default class PageContext extends AbstractDataStore<PageContextData|null> {

    private _data: PageContextData|null = null;

    public register(type: string, name: string, data: Record<string, any>) : SimpleCallback {
        this._data = { type, name, data };
        this._callListeners();

        return () => {
            this._data = null;
            this._callListeners();
        }
    }

    public getData(): PageContextData|null {
        return this._data;
    }

}