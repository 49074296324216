import * as React from 'react';
import {GlobalButtonsData} from "../../../../../AppHelpers";
import GlobalButton from "./GlobalButton";

type GlobalButtonsProps = {
    data: GlobalButtonsData,
}

const GlobalButtons = ({ data } : GlobalButtonsProps) => {

    const buttons = Object.entries(data);

    if (!buttons.length) {
        return null;
    }

    return (
        <div className='rounded-buttons-container'>
            <div role='group' className='rounded-buttons-group'>
                {buttons.map(([ key, button ]) => <GlobalButton key={`btn-${key}`} className={`rounded-button--act-${key}`} data={button} />)}
            </div>
        </div>
    );
}

export default GlobalButtons
