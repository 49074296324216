import * as React from 'react';
import {DatatableRow} from "../../types/DatatableData";
import {DatatableColumnShow} from "../../DatatableContext/DatatableContext";
import CellBody from "../Components/CellBody";

type CellGroupProps = {
    data: DatatableRow,
    cols: DatatableColumnShow[],
}

const CellDisplay = ({ data, cols }: CellGroupProps) => {

    return (
        <React.Fragment>
            {cols.map((column : DatatableColumnShow) => (
                <div key={`col-${column.key}`} data-title={column.title}>
                    <CellBody name={column.key} value={data[column.key] ?? null} data={data} />
                </div>
            ))}
        </React.Fragment>
    );
}

export default CellDisplay
