import * as React from 'react';
import {Dropdown, Spinner} from "react-bootstrap";
import {DropdownToggleProps} from "react-bootstrap/DropdownToggle";
import useAwaitError from "../../../hooks/useAwaitError";
import {Await} from "react-router-dom";
import {ActionResultHandlers, ActionsList, LoadActionsOptions} from "../types";
import classNames from "classnames";
import {UseDropdownMenuOptions} from "@restart/ui/DropdownMenu";
import {useAppActions} from "../../../Application";

const ActionsLoader = () => (
    <Dropdown.ItemText>
        <Spinner animation='border' size='sm' />
        <span className='ms-1'>Завантаження дій</span>
    </Dropdown.ItemText>
)

const ActionsLoadFail = () => {
    const { color, icon, text } = useAwaitError()
    return (
        <Dropdown.ItemText className={`text-${color}`}>
            <i className={`mdi mdi-${icon} me-1`} />
            {text}
        </Dropdown.ItemText>
    )
}

type ActionsDropdownItemProps = {
    icon?: string,
    title: string,
    disabled?: boolean,
    onClick: () => void|Promise<void>,
    handleHide: () => void,
}

const ActionsDropdownItem = ({ icon, title, disabled, onClick, handleHide }: ActionsDropdownItemProps) => {

    const [ loading, setLoading ] = React.useState(false);

    const handleClick = React.useCallback(async (e: React.MouseEvent) => {
        setLoading(true);
        e.preventDefault();
        e.stopPropagation();

        const promise = onClick();
        if (promise instanceof Promise) {
            await promise;
        }

        setLoading(false);
        handleHide();
    }, []);

    return (
        <Dropdown.Item onClick={handleClick} className={classNames({ 'text-muted': disabled })} disabled={loading}>
            {icon && <i className={`mdi mdi-${icon} me-1`} />}
            <span>{title}</span>
        </Dropdown.Item>
    )
}

type AdditionalOption = {
    icon?: string,
    title: string,
    onClick: () => void,
}

type ActionsDropdownBodyProps = LoadActionsOptions & ActionResultHandlers & {
    additionalOptions?: AdditionalOption[],
    handleHide: () => void,
}

const ActionsDropdownBody = ({ handleHide, additionalOptions, onSuccess, onError, onAny, ...options }: ActionsDropdownBodyProps) => {

    const appActions = useAppActions();
    const actionsAwait = React.useMemo(() => appActions.loadActions(options), [])

    return (
        <React.Suspense fallback={<ActionsLoader />}>
            <Await resolve={actionsAwait} errorElement={<ActionsLoadFail />}>
                {(actions : ActionsList) => (
                    <React.Fragment>
                        {actions?.length > 0
                            ? actions.map(action => (
                                <ActionsDropdownItem
                                    key={`action-${action.name}`}
                                    icon={action.icon}
                                    title={action.title}
                                    onClick={() => appActions.handleAction(action, { onSuccess, onError, onAny })}
                                    disabled={!action.allowed}
                                    handleHide={handleHide}
                                />
                            )) : (!additionalOptions && (
                                <Dropdown.ItemText className='text-muted'>
                                    <span>доступних дій немає</span>
                                </Dropdown.ItemText>
                            ))}
                    </React.Fragment>
                )}
            </Await>
            {additionalOptions && additionalOptions.map((option, index) => (
                <ActionsDropdownItem
                    key={`option-${index}`}
                    icon={option.icon}
                    title={option.title}
                    onClick={option.onClick}
                    handleHide={handleHide}
                />
            ))}
        </React.Suspense>
    )
}

export type ActionsDropdownProps = {
    className?: string,
    toggleLabel?: React.ReactNode,
    toggleProps?: DropdownToggleProps,
} & Omit<ActionsDropdownBodyProps, 'handleHide'>;

const popperConfig: UseDropdownMenuOptions['popperConfig'] = {
    strategy: "fixed",
    onFirstUpdate: () => window.dispatchEvent(new CustomEvent('scroll')),
}

const ActionsDropdown = ({ toggleLabel = 'Дії', className, toggleProps, ...options }: ActionsDropdownProps) => {

    const [ show, setShow ] = React.useState(false);

    const handleHide = React.useCallback(() => {
        setShow(false);
    }, []);

    const handleToggle = React.useCallback((nextShow: boolean) => {
        setShow(nextShow);
    }, []);

    return (
        <Dropdown show={show} onToggle={handleToggle} className={className}>
            <Dropdown.Toggle {...toggleProps}>
                {toggleLabel}
            </Dropdown.Toggle>
            <Dropdown.Menu show={show} popperConfig={popperConfig}>
                <ActionsDropdownBody {...options} handleHide={handleHide} />
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default ActionsDropdown
