import * as React from "react";
import {FolderContextMenuTrigger, FolderContextMenuTriggerProps} from "./FolderContextMenu";
import clsx from "clsx";
import {EventCallback} from "react-accessible-treeview";

type FolderRowProps = {
    name: string,
    isSelected: boolean,
    icon: string,
    handleSelect: EventCallback,
    handleIconClick?: EventCallback,
    contextMenuProps?: Omit<FolderContextMenuTriggerProps, 'children'>,
}

const FolderRow = ({ name, isSelected, icon, handleSelect, handleIconClick, contextMenuProps }: FolderRowProps) => {
    return (
        <div
            className={clsx('d-flex align-items-center user-select-none text-truncate', { 'text-primary' : isSelected })}
            role={!handleIconClick ? 'button' : undefined}
            onClick={!handleIconClick ? handleSelect : undefined}
        >
            <i
                className={`mdi mdi-${icon} font-20 me-1`}
                role={handleIconClick ? 'button' : undefined}
                onClick={handleIconClick ? handleIconClick : handleSelect}
            />
            {contextMenuProps ? (
                <FolderContextMenuTrigger {...contextMenuProps}>
                    <span role='button' onClick={handleSelect}>{name}</span>
                </FolderContextMenuTrigger>
            ) : (
                <span role='button' onClick={handleSelect}>{name}</span>
            )}
        </div>
    )
}

export default FolderRow;
