import * as React from 'react';
import {MapContainer, TileLayer, Marker, Popup} from "react-leaflet";
import {CSSProperties, useMemo} from "react";
import {Icon, LatLngBoundsExpression} from "leaflet";

import defaultIconFile from '../assets/images/map-default-icon.png'

const icon = new Icon({ iconUrl: defaultIconFile, shadowUrl: undefined, iconAnchor: [12, 41], popupAnchor: [0, -41] });

type Location = {
    lat: number,
    lng: number,
}

export type ExtendedMarker = {
    location: Location,
    info?: React.ReactNode,
    icon?: Icon,
}

function isExtendedMarker(obj : any) : obj is ExtendedMarker {
    return typeof obj !== 'undefined' && obj.hasOwnProperty('location') && obj.location.hasOwnProperty('lat') && obj.location.hasOwnProperty('lng');
}

export type MarkerDef = Location|ExtendedMarker

export type MapProps = {
    markers: MarkerDef[],
    center?: Location,
    style?: CSSProperties,
}

const Map = ({ markers: userMarkers, center: userCenter, style }: MapProps) => {

    const markers = useMemo<ExtendedMarker[]>(() => {

        return userMarkers.map(marker => {
            if (isExtendedMarker(marker)) {
                return marker;
            }

            return {
                location: marker,
            }
        })

    }, [ userMarkers ])

    const center = useMemo<Location>(() => {

        if (userCenter) {
            return userCenter;
        }

        if (markers.length > 0) {
            return {
                lat: markers.reduce((acc, curr) => acc + curr.location.lat, 0) / markers.length,
                lng: markers.reduce((acc, curr) => acc + curr.location.lng, 0) / markers.length
            }
        }

        return { lat: 49.824421, lng: 23.979513 }

    }, [ markers, userCenter ]);

    const bounds = useMemo<LatLngBoundsExpression|undefined>(() => {

        if (markers.length === 0) {
            return undefined;
        }

        const minMarker = markers.reduce((acc, curr) => {
            return curr.location.lng < acc.location.lng || curr.location.lat < acc.location.lat ? curr : acc;
        });

        const maxMarker = markers.reduce((acc, curr) => {
            return curr.location.lng > acc.location.lng || curr.location.lat > acc.location.lat ? curr : acc;
        });

        return [
            [ minMarker.location.lat, minMarker.location.lng ],
            [ maxMarker.location.lat, maxMarker.location.lng ],
        ]

    }, [ markers ])

    return (
        <MapContainer center={center} bounds={bounds} zoom={bounds ? undefined : 10} boundsOptions={{ padding: [ 5, 5 ] }} style={style ?? { height: 300 }}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {markers.map((marker, idx) => (
                <Marker
                    key={`marker-${idx}`}
                    position={marker.location}
                    icon={marker.icon ?? icon}
                >
                    {!!marker.info && <Popup>{marker.info}</Popup>}
                </Marker>
            ))}
        </MapContainer>
    );
}

export default Map
