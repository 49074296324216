import * as React from 'react';
import AppCopyright from "../Copyright/AppCopyright";
import DevCopyright from "../Copyright/DevCopyright";
import {useBaseAppConfig} from "../../Application";

type FooterProps = {
    className?: string,
}

const Footer = ({ className } : FooterProps) => {

    const { appInfoRender: InfoRenderComponent } = useBaseAppConfig();

    return (
        <footer className={"footer py-2 text-muted small " + (className ?? '')}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 text-start">
                        <AppCopyright />
                        {InfoRenderComponent && <InfoRenderComponent />}
                    </div>
                    <div className="col-md-6">
                        <div className="text-md-end footer-links d-none d-md-block">
                            <DevCopyright />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
