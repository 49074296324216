import {DatatableFilter} from "./DatatableFilters";
import {TableRowActions} from "./TableRowActions";

export type DatatableColumn = {
    key: string,
    title: string|null,
    isSortable: boolean,
}

export type DatatableRow = {
    _id: number|string,
    _actions?: TableRowActions,
    [key: string]: any,
}

export type BasePaginationData = {
    currentPage: number;
    from: number;
    to: number;
    total: number;
    perPage: number;
}

export const isBasePaginationData = (obj: unknown) : obj is BasePaginationData => {
    return typeof obj === 'object'
        && obj !== null
        && 'currentPage' in obj
        && 'from' in obj
        && 'to' in obj
        && 'total' in obj
        && 'perPage' in obj;
}

export type CursorPaginationData = {
    name: string;
    prev: string|null;
    next: string|null;
    perPage: number;
}

export const isCursorPaginationData = (obj: unknown) : obj is CursorPaginationData => {
    return typeof obj === 'object'
        && obj !== null
        && 'name' in obj
        && 'prev' in obj
        && 'next' in obj
        && 'perPage' in obj;
}

export type DatatableData = {
    name: string,
    options: {
        hasRowActions: boolean,
        createAction?: {
            key: string,
            icon: string,
            title: string,
        },

        hasIndexes: boolean,

        actionsModel?: string,
    },
    columns: DatatableColumn[],
    filters: DatatableFilter[],
    data: DatatableRow[],
    pagination: BasePaginationData|CursorPaginationData|null,
}

export function isDatatableData(obj: any) : obj is DatatableData {
    return typeof obj === 'object' && obj !== null && 'name' in obj && 'options' in obj && 'columns' in obj && 'filters' in obj && 'data' in obj && 'pagination' in obj;
}
