import {
    IndexRoute,
    RouteWithAction,
    RouteWithElement,
    RouteWithLoader,
    RouteWithPath,
    TitlingRouteProps
} from "./types";
import {RouteObject} from "react-router-dom";
import prepareHandle from "./helpers/prepareHandle";
import prepareLoader from "./helpers/prepareLoader";
import prepareAction from "./helpers/prepareAction";

export type SimpleRouteOptions = TitlingRouteProps & RouteWithLoader & RouteWithAction
    & RouteWithElement & (RouteWithPath|IndexRoute);

export default function makeSimpleRoute(options : SimpleRouteOptions) : RouteObject {
    const { name, title, titleLinks, docsLink, titleEnd, breadcrumb, loader, element, action, ...globalOptions } = options

    return {
        ...globalOptions,
        loader: prepareLoader(loader),
        handle: prepareHandle({ name, title, titleLinks, docsLink, titleEnd, breadcrumb }, false, prepareAction(action)),
        element: element,
    }
}
