import * as React from "react";
import {SimpleCallback} from "@ova-studio/react-hyper-admin";
import API from "@ova-studio/api-helper";
import {RequestData, UploadingFiles, UploadItem, UploadItemInfo, UploadStatus, UploadType} from "../types/UploadFile";
import MediaLibraryService from "./MediaLibraryService";
import {Media} from "../types/Media";
import {v4 as uuid} from "uuid";
import {FolderID} from "../types/MediaFolder";
import {AxiosProgressEvent} from "axios";
import UploadToastBody, {UploadToastBodyProps} from "../MediaLibrary/UploadToastBody";
import {Variant} from "react-bootstrap/types";

type OnMediaAddListener = (media: Media) => void;
type RelatedUploadHandler = (promise: Promise<Media[]>) => void;

type RelatedBlockOptions = {
    block: HTMLElement,
    handler: RelatedUploadHandler,
    message?: string | ((cnt: number) => string),
}

export default class UploadService {

    private readonly _service: MediaLibraryService;
    private readonly _uploadInput: HTMLInputElement;
    private readonly _dropPopup: HTMLDivElement;

    private _uploadingFiles: UploadingFiles = {};

    private _onMediaAddListeners: OnMediaAddListener[] = [];

    private _relatedBlocks: RelatedBlockOptions[] = [];

    constructor(service: MediaLibraryService) {
        this._service = service;
        this._uploadInput = this._initUploadInput();
        this._dropPopup = this._initDropPopup();

        window.addEventListener('beforeunload', this._handleUnload.bind(this));

        window.addEventListener('dragenter', this._handleDragEvent.bind(this));
        window.addEventListener('dragover', this._handleDragEvent.bind(this));
    }

    private _handleInputChanged(e: Event) {
        e.preventDefault();

        if (e.target instanceof HTMLInputElement && e.target.files) {
            if (e.target.files.length > 0) {
                this.uploadFiles(e.target.files);
            }
            e.target.value = '';
        }
    }

    private _initUploadInput() : HTMLInputElement {
        const input = document.createElement('input');
        input.type = 'file';
        input.multiple = true;
        input.style.display = 'none';
        input.addEventListener('change', this._handleInputChanged.bind(this));
        document.body.appendChild(input);

        return input;
    }

    private _initDropPopup() : HTMLDivElement {
        const div = document.createElement('div');
        div.className = 'media-library-drop-popup';
        div.style.display = 'none';
        div.addEventListener('dragover', this._handleDragEvent.bind(this));
        div.addEventListener('dragleave', this._handleDragEvent.bind(this));
        div.addEventListener('drop', this._handleDragEvent.bind(this));
        document.body.appendChild(div);

        return div;
    }

    private set _dragPopupEnabled(value: boolean) {
        this._dropPopup.style.display = value ? '' : 'none';
    }

    private _handleUnload(e: BeforeUnloadEvent) : string|undefined {
        if (Object.values(this._uploadingFiles).some(i => i.status === UploadStatus.Uploading)) {
            e.preventDefault();
            return 'Завантаження медіа ще не завершено';
        }

        return undefined;
    }

    private _updateBlockMessage(posX: number, posY: number, cnt: number) {
        const block = this._getRelatedBlock(posX, posY);

        if (!block) {
            this._dropPopup.removeAttribute('data-message');
            return;
        }

        const message = typeof block.message === 'function' ? block.message(cnt) : block.message;

        if (message) {
            this._dropPopup.setAttribute('data-message', message);
        } else {
            this._dropPopup.removeAttribute('data-message');
        }
    }

    private _isMediaModalOpen() : boolean {
        return !!document.querySelector('.media-library-modal')?.classList.contains('show');
    }

    private _getRelatedBlock(posX: number, posY: number) : RelatedBlockOptions|undefined {
        if (this._isMediaModalOpen()) {
            return undefined;
        }

        return this._relatedBlocks.find(i => {
            const rect = i.block.getBoundingClientRect();
            return posX >= rect.left && posX <= rect.right && posY >= rect.top && posY <= rect.bottom;
        });
    }

    private _handleDragUpload(files: FileList, posX: number, posY: number) {
        const block = this._getRelatedBlock(posX, posY);

        const promise = this.uploadFiles(files);
        block?.handler(promise);
    }

    private _handleDragEvent(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();

        if (e.type === "dragenter" || e.type === "dragover") {
            if (e.dataTransfer?.items?.[0]?.kind === 'file') {
                this._dragPopupEnabled = true;
                this._updateBlockMessage(e.clientX, e.clientY, e.dataTransfer.items.length);
            }
        } else if (e.type === "dragleave") {
            this._dragPopupEnabled = false;
        } else if (e.type === "drop") {
            this._dragPopupEnabled = false;
            if (!!e.dataTransfer?.files?.length) {
                this._handleDragUpload(e.dataTransfer.files, e.clientX, e.clientY);
            }
        }
    }

    public initDropdownIframe(rootNode: HTMLElement, opts: RelatedBlockOptions) : SimpleCallback {
        const handleIframeDrag = (e: DragEvent) => {
            if (e.dataTransfer?.items?.[0]?.kind === 'file') {
                this._handleDragEvent(e);
            }
        }

        rootNode.addEventListener('dragenter', handleIframeDrag);
        rootNode.addEventListener('dragover', handleIframeDrag);

        this._relatedBlocks.push(opts);

        return () => {
            rootNode.removeEventListener('dragenter', handleIframeDrag);
            rootNode.removeEventListener('dragover', handleIframeDrag);
            this._relatedBlocks = this._relatedBlocks.filter(i => i !== opts);
        }
    }

    public onMediaAdd(listener: OnMediaAddListener) : SimpleCallback {
        this._onMediaAddListeners.push(listener);

        return () => {
            this._onMediaAddListeners = this._onMediaAddListeners.filter(i => i !== listener);
        }
    }

    private _resolveUploadToastBody(data: UploadToastBodyProps['data']) : React.ReactNode {
        return React.createElement(UploadToastBody, { data });
    }

    private _makeUploadItem(info: UploadItemInfo) : string {
        const id = uuid();

        const data = {
            ...info,
            status: UploadStatus.Uploading,
            progress: 0,
        }

        const toast = this._service.app.toasts.createToast({
            icon: 'upload',
            title: `Завантаження медіа`,
            body: this._resolveUploadToastBody(data),
            disableClose: true,
        });

        this._uploadingFiles = {
            ...this._uploadingFiles,
            [id]: {
                ...data,
                toast,
            }
        }

        return id;
    }

    private _getToastVariant(status: UploadStatus) : Variant|undefined {
        switch (status) {
            case UploadStatus.Error:
                return 'danger';
            case UploadStatus.Success:
                return 'success';
            default:
                return undefined;
        }
    }

    private _updateUploadItem(id: string, data: Partial<UploadItem>) : void {
        const item = this._uploadingFiles[id];

        if (!item) {
            throw new Error(`Upload item with id ${id} not found`);
        }

        const newData = {
            ...item,
            ...data,
        }

        this._uploadingFiles = {
            ...this._uploadingFiles,
            [id]: newData,
        }

        const { toast, ...toastData } = newData;

        item.toast.update({
            variant: this._getToastVariant(newData.status),
            body: this._resolveUploadToastBody(toastData),
            disableClose: newData.status === UploadStatus.Uploading,
        });
    }

    private _cleanUpUploadItem(id: string) : void {
        const item = this._uploadingFiles[id];
        if (!item) return;

        item.toast.delete();
        delete this._uploadingFiles[id];
    }

    private get _uploadFolder() : FolderID|null {
        return this._service.folderManager.realSelectedFolder;
    }

    private async _makeUpload(info: UploadItemInfo, data: RequestData) : Promise<Media[]> {
        const itemId = this._makeUploadItem(info);

        const onUploadProgress = (progressEvent : AxiosProgressEvent) => {
            if (!progressEvent.total) return;

            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            this._updateUploadItem(itemId, { progress });
        }

        const payload = {
            ...data,
            folder: this._uploadFolder,
            context: this._service.currentContext,
        }

        try {
            const { data: responseData } = await API.postWithFile(this._service.getEndpoint('media'), payload, { onUploadProgress, timeout: 2 * 60000 });
            this._updateUploadItem(itemId, { status: UploadStatus.Success, progress: 100 });

            const media = responseData as Media[];
            media.forEach(i => this._onMediaAddListeners.forEach(l => l(i)));

            return responseData as Media[];
        } catch (e : any) {
            const errMsg = [ e?.errorMessage, e?.message, 'Помилка завантаження' ].find(i => i && i.length > 0);
            this._updateUploadItem(itemId, { status: UploadStatus.Error, progress: 100, error: errMsg });
            throw new Error(errMsg);
        } finally {
            setTimeout(() => this._cleanUpUploadItem(itemId), 5000);
        }
    }

    public uploadFiles(files: FileList) : Promise<Media[]> {
        if (files.length === 0) {
            return Promise.resolve([]);
        }

        const data : RequestData = { files };

        const info: UploadItemInfo = {
            type: files.length > 1 ? UploadType.FileGroup : UploadType.File,
            name: files.length > 1 ? `${files[0].name} і ще ${files.length - 1} файлів` : files[0].name,
            size: Array.from(files).reduce((acc, file) => acc + file.size, 0),
        }

        return this._makeUpload(info, data);
    }

    public openUploadFileDialog() : void {
        this._uploadInput.click();
    }

    public addFromLinks(links: string[], handler?: RelatedUploadHandler) : Promise<Media[]> {
        const data : RequestData = { links };

        const info: UploadItemInfo = {
            type: links.length > 1 ? UploadType.LinkGroup : UploadType.Link,
            name: links.length > 1 ? `${links[0]} і ще ${links.length - 1} посилань` : links[0],
        }

        const promise = this._makeUpload(info, data);

        if (typeof handler === 'function') {
            handler(promise);
        }

        return promise;
    }

    public openUploadLinkDialog(handler?: RelatedUploadHandler) : void {
        this._service.states?.uploadLinkModal.setData({ handler });
        this._service.states?.uploadLinkModal.open();
    }
}
