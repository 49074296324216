import * as React from "react";
import getExtension from "../../utils/getExtension";
import PictureRender from "../PictureRender";
import {MediaDisplayProps} from "./MediaDisplayProps";

const MediaFileDisplay = ({ data, lazy, caption, disableInteraction }: MediaDisplayProps) => {
    if (!data.url) {
        return null;
    }

    const RenderElement = disableInteraction ? 'span' : 'a';

    return (
        <RenderElement href={data.url} className='ova-engine-document' data-file-type={getExtension(data.url)}>
            <figure>
                {data.preview ? (
                    <PictureRender data={data.preview} alt={caption} lazy={lazy} />
                ) : (
                    <div className="ova-engine-document-placeholder" />
                )}
                <figcaption>{caption ?? data.filename}</figcaption>
            </figure>
        </RenderElement>
    )
}

export default MediaFileDisplay;
