import * as React from 'react';
import MobileTableContext from "./MobileTableContext";
import {MobileTableContextData} from "./MobileTableContextData";
import useMobileTableContext from "./useMobileTableContext";
import useBoolean from "../../../../hooks/useBoolean";
import {useCallback, useEffect} from "react";
import {useDatatableContext} from "../../../DatatableContext";
import {CaptionMode, MobileTableCardConfig} from "./MobileTableCardConfig";
import {useAppNavigation} from "../../../../Application";

type MobileTableContextProviderProps = {
    children: React.ReactNode,
}

const MobileTableContextProvider = ({children}: MobileTableContextProviderProps) => {

    const appNavigation = useAppNavigation();

    const { state: isSelectionMode, toggle: _setSelectionMode } = useBoolean();

    const { rowsSelected, data: { columns } } = useDatatableContext();

    const setSelectionMode = useCallback((state: boolean) => {
        _setSelectionMode(state);
        appNavigation.Topbar.Row.updateOptions({ fixed: state });
    }, [])

    useEffect(() => {

        if (!rowsSelected?.length && isSelectionMode) {
            setSelectionMode(false);
        }

    }, [ rowsSelected?.length ])

    const cardConfig = React.useMemo<MobileTableCardConfig>(() => {

        return {
            fixed: columns.map(c => ({
                [c.key]: {
                    captionMode: CaptionMode.Text,
                    toEnd: false,
                },
            }))
        }

    }, [])

    return (
        <MobileTableContext.Provider value={{ isSelectionMode, setSelectionMode, cardConfig }}>
            {children}
        </MobileTableContext.Provider>
    );
}

export {useMobileTableContext}

export type {MobileTableContextData}

export default MobileTableContextProvider
