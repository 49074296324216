import * as React from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import {useMediaLibraryService} from "../../services/MediaLibraryService";

const UploadButton = () => {

    const service = useMediaLibraryService().upload;

    return (
        <Dropdown as={ButtonGroup}>
            <Button variant="outline-success" size='sm' onClick={service.openUploadFileDialog.bind(service)}>
                <i className='mdi mdi-upload me-1' />
                Завантажити
            </Button>

            <Dropdown.Toggle split variant="outline-success" size='sm' />

            <Dropdown.Menu>
                <Dropdown.Item onClick={service.openUploadLinkDialog.bind(service, undefined)}>
                    <i className='mdi mdi-link me-1' />
                    Вставка з соцмереж
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default UploadButton
