import {ButtonVariant} from "react-bootstrap/types";
import {ReactNode} from "react";
import {UseBooleanReturn} from "../../../hooks/useBoolean";
import {SimpleCallback} from "../../../types/SimpleCallback";

export type GlobalActionButtonBaseProps = {
    icon: string|ReactNode,
    title: string,
    order?: number,
    badge?: true|string|number,
    variant?: ButtonVariant,
    disabled?: boolean,
}

export type GlobalActionButtonNavigate = GlobalActionButtonBaseProps & {
    url: string,
}

export type GlobalActionButtonHandler = GlobalActionButtonBaseProps & {
    handler: () => void,
}

export type GlobalActionButtonPortal = GlobalActionButtonBaseProps & {
    openIcon: string,
    portalRefHandler: (ref: HTMLDivElement|null) => void,
    portalTitle?: ReactNode|false,
    registerRemoteStateHandler?: (options: UseBooleanReturn) => SimpleCallback,
    scrollable?: boolean,
    popoverClassName?: string,
    popoverHeaderClassName?: string,
    popoverBodyClassName?: string,
}

export type GlobalActionSimpleButton = GlobalActionButtonNavigate|GlobalActionButtonHandler;

export type GlobalActionButtonDropdownItems = Record<string, GlobalActionSimpleButton>;

export type GlobalActionButtonDropdown = GlobalActionButtonBaseProps & {
    items: GlobalActionButtonDropdownItems,
}

export type GlobalButton = GlobalActionSimpleButton|GlobalActionButtonDropdown|GlobalActionButtonPortal;

export type CustomDropdownItem = {
    buttonKey: string,
    key: string,
    button: GlobalActionSimpleButton,
}

export type GlobalButtonsData = Record<string, GlobalButton>

export function isGlobalActionButtonNavigate(obj : any) : obj is GlobalActionButtonNavigate {
    return typeof obj !== 'undefined' && obj.hasOwnProperty('url') && typeof obj.url === 'string';
}

export function isGlobalActionButtonHandler(obj : any) : obj is GlobalActionButtonHandler {
    return typeof obj !== 'undefined' && obj.hasOwnProperty('handler') && typeof obj.handler === 'function';
}

export function isGlobalActionButtonDropdown(obj : any) : obj is GlobalActionButtonDropdown {
    return typeof obj !== 'undefined' && obj.hasOwnProperty('items') && typeof obj.items === 'object';
}

export function isGlobalActionButtonPortal(obj : any) : obj is GlobalActionButtonPortal {
    return typeof obj !== 'undefined' && obj.hasOwnProperty('portalRefHandler') && typeof obj.portalRefHandler === 'function';
}
