import React from "react";
import {CrudOptions} from "./types";
import {Outlet, RouteObject} from "react-router-dom";
import prepareHandle from "../helpers/prepareHandle";
import makeCrudRouteChildren from "./makeCrudRouteChildren";
import {isCrudOptionsModal} from "./guards";

export default function makeCrudRoute(options : CrudOptions) : RouteObject {
    return {
        path: options.basePath,
        element: options.baseElement ?? <Outlet />,
        handle: prepareHandle(options, isCrudOptionsModal(options)),
        children: [
            ...makeCrudRouteChildren(options),
            ...(options.children ?? []),
        ]
    }
}

export type { CrudOptions as CrudRouteOptions }
