import * as React from "react";
import {NavMenuCounterConfig} from "../../../../AppHelpers/Navigation/types/MainNavigation";
import NavCounter from "./NavCounter";

type NavCountersProps = {
    data: NavMenuCounterConfig,
}

const NavCounters = ({ data }: NavCountersProps) => {
    return (
        <React.Fragment>
            {Object.entries(data).map(([name, variant]) => (
                <NavCounter name={name} variant={variant} key={`nav-counter-${name}`} />
            ))}
        </React.Fragment>
    )
}

export default NavCounters;