import * as React from 'react';
import {Action, ActionResultHandlers, LoadActionOptions} from "../types";
import {Button} from "react-bootstrap";
import {ButtonVariant} from "react-bootstrap/types";
import {Await} from "react-router-dom";
import useAwaitError from "../../../hooks/useAwaitError";
import stopPropagate from "../../../helpers/stopPropagate";
import {useAppActions} from "../../../Application";

type ButtonProps = {
    className?: string,
    variant?: ButtonVariant;
    size?: 'sm' | 'lg';
}

type ActionButtonBodyProps = ButtonProps & {
    onClick?: (e: React.MouseEvent) => void,
    icon?: string,
    label: string,
    disabled?: boolean,
}

const ActionButtonBody = ({ icon, label, onClick = stopPropagate, ...props } : ActionButtonBodyProps) => (
    <Button {...props} onClick={onClick}>
        {icon && <i className={`mdi mdi-${icon} me-1`} />}
        <span>{label}</span>
    </Button>
)

const LoadingActionButton = (props : ButtonProps) => (
    <ActionButtonBody
        {...props}
        disabled={true}
        icon='loading mdi-spin'
        label='Підготовка'
    />
)

const ErrorActionButtonHidden = () => {
    useAwaitError();
    return null
}

const ErrorActionButton = (props : ButtonProps) => {
    const { icon, text } = useAwaitError();
    return (
        <ActionButtonBody
            {...props}
            disabled={true}
            icon={icon}
            label={text}
        />
    )
}

export type ActionButtonProps = ButtonProps & LoadActionOptions & ActionResultHandlers & {
    showIfDisabled?: true,
}

const ActionButton = ({ variant, size, className, showIfDisabled, onSuccess, onError, onAny, ...options }: ActionButtonProps) => {

    const [ loading, setLoading ] = React.useState(false);

    const appActions = useAppActions();

    const actionAwait = React.useMemo(() => appActions.loadAction(options), [])

    const buttonProps = { variant, size, className }

    const handleClick = React.useCallback(async (e: React.MouseEvent, action: Action) => {
        setLoading(true);
        e.preventDefault();
        e.stopPropagation();
        await appActions.handleAction(action, { onSuccess, onError, onAny });
        setLoading(false)
    }, []);

    return (
        <React.Suspense fallback={showIfDisabled ? <LoadingActionButton {...buttonProps} /> : <span />}>
            <Await resolve={actionAwait} errorElement={showIfDisabled ? <ErrorActionButton {...buttonProps} /> : <ErrorActionButtonHidden />}>
                {(action : Action) => (
                    <ActionButtonBody
                        {...buttonProps}
                        onClick={(e) => handleClick(e, action)}
                        icon={action.icon}
                        label={action.title}
                        disabled={!action.allowed || loading}
                    />
                )}
            </Await>
        </React.Suspense>
    );
}

export default ActionButton
