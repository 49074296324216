import * as React from "react";
import {BsPrefixProps} from "react-bootstrap/helpers";
import {useForm} from "react-hook-form";

export type FormValues = Record<string, any>

export interface BsFormControlProps extends BsPrefixProps {
    htmlSize?: number;
    size?: 'sm' | 'lg';
    plaintext?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
    value?: string | string[] | number;
    onChange?: React.ChangeEventHandler<HTMLInputElement|HTMLTextAreaElement>;
    type?: string;
    isValid?: boolean;
    isInvalid?: boolean;
    className?: string,
}

export type FormInputProps = React.HTMLAttributes<HTMLInputElement>
export type FormTextAreaProps = React.HTMLAttributes<HTMLInputElement> & { type: 'textarea' }
export type ControlProps = FormInputProps | FormTextAreaProps

export type FormControlProps = BsFormControlProps & ControlProps

export type SubmitHandler = (data: FormValues) => Promise<FormValues|void>;

export enum FormStatus {
    Idle,
    Submitting,
    Success,
    Error,
}

export type FormMethods = ReturnType<typeof useForm>