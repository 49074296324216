import React, {useCallback, useMemo, useState} from "react";
import DatatableContext, {DatatableColumnShow, DatatableContextData, SortDirection} from "./DatatableContext";
import {DatatableData, DatatableRow} from "../types/DatatableData";
import {DatatableConfig, DatatableConfigOptional} from "../types/DatatableConfig";
import defaultConfig from "../defaults/config";
import useSearchParams from "../hooks/useSearchParams";
import {ColumnShowType} from "../types/ColumnShowType";
import {DatatableSettings, DisplayColumns} from "../types/DatatableSettings";
import {getLocalStorageData, setLocalStorageData} from "../../helpers/localStorage";
import {RenderConfig, RenderConfigData} from "../types/RenderConfig";
import LoadingHover from "../../components/LoadingHover";

type DatatableContextProviderProps = {
    data: DatatableData,
    config?: DatatableConfigOptional,
    renderConfig?: RenderConfig,
    children: React.ReactNode,
}

const DatatableContextProvider = ({ data, renderConfig: userRenderConfig, config: userConfig, children } : DatatableContextProviderProps) => {

    const { searchString, getSearchParams, setSearchParams } = useSearchParams();

    const settingsLocalStorageKey = useMemo(() => `datatable:settings:${data.name}`, [ data.name ])

    const [ settings, _setSettings ] = useState<DatatableSettings>(() => {

        const defaultDisplayColumns : DisplayColumns = data.columns.reduce((data, col, idx) : DisplayColumns => {
            return {
                ...data,
                [col.key]: {
                    showType: ColumnShowType.Captioned,
                    order: idx,
                }
            }
        }, {})

        const userSettings = getLocalStorageData<DatatableSettings>(settingsLocalStorageKey);

        return {
            displayColumns: {
                ...defaultDisplayColumns,
                ...(userSettings?.displayColumns ?? {}),
            },
            disableMobileLayout: userSettings?.disableMobileLayout ?? false,
        }
    });

    const setSettings = useCallback((data : DatatableSettings) => {
        _setSettings(data);
        setLocalStorageData(settingsLocalStorageKey, data);
    }, []);

    const config = useMemo<DatatableConfig>(() => ({ ...defaultConfig, ...(userConfig ?? {}) }), [ userConfig ])

    const isFilters = useMemo(() => data.filters?.length > 0, [ data.filters ]);
    const activeFilters = useMemo(() => getSearchParams('filter') ?? {}, [ searchString ])
    const isActiveFilters = useMemo(() => Object.entries(activeFilters).length > 0, [ activeFilters ]);

    const showColumns = useMemo<DatatableColumnShow[]>(() => {
        return data.columns
            .filter(c => settings.displayColumns[c.key]?.showType !== ColumnShowType.Hidden)
            .map<DatatableColumnShow>(c => ({
                ...c,
                ...(settings.displayColumns[c.key])
            }))
            .sort((c1, c2) => c1.order - c2.order);
    }, [ data.columns, settings.displayColumns ])

    const [ rowsSelected, setRowsSelected,  ] = useState<DatatableContextData['rowsSelected']>([]);

    const setRowSelected = useCallback<DatatableContextData['setRowSelected']>((id, state) => {

        setRowsSelected((currentSelected) => {
            if (state) {
                return [
                    ...currentSelected,
                    id,
                ]
            } else {
                return currentSelected.filter(i => i !== id);
            }
        })

    }, []);

    const setAllRowsSelected = useCallback<DatatableContextData['setAllRowsSelected']>((state) => {
        return setRowsSelected(state ? (data?.data?.map(row => row._id) ?? []) : []);
    }, [ data?.data ]);

    const isRowSelected = useCallback<DatatableContextData['isRowSelected']>((id) => {
        return rowsSelected.includes(id);
    }, [ rowsSelected ]);

    const isAllRowsSelected = useMemo<DatatableContextData['isAllRowsSelected']>(() => {
        return rowsSelected?.length === data?.data?.length;
    }, [ rowsSelected?.length, data?.data?.length ]);

    const activeSorts = useMemo<DatatableContextData['activeSorts']>(() => {
        const sortQuery = getSearchParams('sort') as string;
        const activeSorts : DatatableContextData['activeSorts'] = {};

        if (!sortQuery) {
            return {};
        }

        sortQuery.split(',')
            .forEach((key, idx) => {
                if (key.substring(0, 1) === '-') {
                    activeSorts[key.substring(1)] = {
                        dir: 'desc',
                        order: idx + 1,
                    };
                } else {
                    activeSorts[key] = {
                        dir: 'asc',
                        order: idx + 1,
                    };
                }
            })

        return activeSorts;

    }, [ searchString ])

    const setSorts = useCallback<DatatableContextData['setSorts']>((state) => {

        const data = Object.entries(state)
            .filter(([ , data ]) => data.dir !== null)
            .map(([ key, data ]) => ((data.dir === 'desc' ? '-' : '') + key));

        setSearchParams({
            sort: data.length > 0 ? data.join(',') : undefined,
        })
    }, [ ]);

    const toggleSort = useCallback<DatatableContextData['toggleSort']>((key) => {

        const currentSort = activeSorts[key];
        const data = { ...activeSorts };

        let newDir : SortDirection = null;
        if (!currentSort?.dir) {
            newDir = 'asc';
        } else if (currentSort.dir === 'asc') {
            newDir = 'desc';
        }

        data[key] = {
            dir: newDir,
            order: 0,
        }

        setSorts(data);

    }, [ activeSorts ]);

    const [ renderConfig, setRenderConfig ] = React.useState<RenderConfigData|null>();

    React.useEffect(() => {
        if (userRenderConfig === undefined) {
            setRenderConfig(null);
            return;
        }

        if (typeof userRenderConfig === 'function') {
            userRenderConfig().then(res => {
                setRenderConfig(res.default);
            })
        } else {
            setRenderConfig(userRenderConfig);
        }

    }, [ userRenderConfig ]);

    const rows = useMemo<DatatableRow[]>(() => {

        if (!data?.data) {
            return [];
        }

        if (!config.clientFilter) {
            return data.data;
        }

        return data.data.filter(config.clientFilter);

    }, [ config.clientFilter, data?.data ])

    if (renderConfig === undefined) {
        return <LoadingHover />
    }

    const contextValue : DatatableContextData = {
        settings,
        setSettings,

        showColumns,

        data,
        rows,

        config,
        renderConfig,
        isFilters,
        activeFilters,
        isActiveFilters,
        setSearchParams,

        isSort: data?.columns?.filter(c => c.isSortable).length > 0,
        activeSorts,
        setSorts,
        toggleSort,


        hasGroupActions: typeof data?.options?.actionsModel === 'string' && config.tableMode !== 'simple',
        rowsSelected,
        setRowSelected,
        isAllRowsSelected,
        setAllRowsSelected,
        isRowSelected,

        isResults: rows.length > 0,
    }

    return (
        <DatatableContext.Provider value={contextValue}>
            {children}
        </DatatableContext.Provider>
    );
}

export default DatatableContextProvider
