import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["children", "onRootClose"];
import useRootClose from './useRootClose';
function RootClose(_ref) {
  var children = _ref.children,
    onRootClose = _ref.onRootClose,
    props = _objectWithoutProperties(_ref, _excluded);
  var rootRef = useRootClose(onRootClose, props);
  return children(rootRef);
}
export default RootClose;