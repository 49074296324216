import {useLayoutEffect, useState} from "react";


export default function useScreenWidth() : number {
    const [width, setWidth] = useState(window.innerWidth);

    useLayoutEffect(() => {
        function updateWidth() {
            setWidth(window.innerWidth);
        }

        window.addEventListener('resize', updateWidth);
        return () => window.removeEventListener('resize', updateWidth);
    }, []);

    return width;
}
