import * as React from 'react';
import {BouncingLoader, Form, Modal, useExternalStore} from "@ova-studio/react-hyper-admin";
import {FolderModalState, MediaFolderFormData} from "../types/MediaFolder";
import {useMediaLibraryService} from "../services/MediaLibraryService";

type FolderDataModalBodyProps = {
    data: MediaFolderFormData,
}

const FolderDataModalBody = ({ data } : FolderDataModalBodyProps) => {

    const manager = useMediaLibraryService().folderManager;
    const { folders } = useExternalStore(manager);

    const foldersOptions = React.useMemo(() => {
        if (!folders) return [];

        return folders.filter(f => f.parent !== null)
            .filter(f => f.id.toString() !== data?.id?.toString())
            .map(f => ({value: f.id, label: f.name}));
    }, [ folders, data.id ]);

    const formData = React.useMemo(() => {
        return {
            id: data.id ?? null,
            name: data.name ?? '',
            parent: data.parent ?? null,
        };
    }, [ data ]);

    return (
        <Form.Callback data={formData} handleSubmit={manager.handleSubmit.bind(manager)} showSuccess={false}>
            <Modal.Body>
                <Form.HiddenInput name='id' />
                <Form.Input name='name' label='Назва папки' />
                <Form.Select
                    name='parent'
                    options={foldersOptions}
                    label='Батьківська папка'
                    allowEmpty={true}
                />
            </Modal.Body>
            <Modal.Footer>
                <Form.Submit>{data?.id ? 'Змінити' : 'Створити'}</Form.Submit>
            </Modal.Footer>
        </Form.Callback>
    )
}

type FolderDataModalProps = {
    state: FolderModalState,
}

const FolderDataModal = ({ state }: FolderDataModalProps) => {

    const title = state.data
        ? (state.data.id ? `Редагувати папку ${state.data.name}` : 'Створити папку')
        : 'Завантаження...'

    return (
        <Modal state={state} title={title} className='modal-nested' backdropClassName='modal-backdrop-nested'>
            {state.data ? (
                <FolderDataModalBody data={state.data} />
            ) : (
                <BouncingLoader />
            )}
        </Modal>
    );
}

export default FolderDataModal
