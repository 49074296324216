import * as React from "react";
import {SearchResultItem} from "../types";
import classNames from "classnames";
import {useAppSearch} from "../../../Application";

type SearchResultRenderProps = {
    result: SearchResultItem,
    isSelected: boolean,
    type: string,
}

const SearchResultRender = ({ result, isSelected, type }: SearchResultRenderProps) => {

    const search = useAppSearch();
    const Render = React.useMemo(() => search.resolveTypeRenderer(type), [type]);
    const icon = React.useMemo(() => search.resolveTypeIcon(type), [type]);

    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (isSelected && ref.current) {
            ref.current.scrollIntoView({ block: 'nearest' });
        }
    }, [ isSelected ])

    return (
        <div className={classNames('search-app-result', isSelected && 'selected')} role='button' onClick={(e) => search.handleResultSelect(result, e.ctrlKey)} ref={ref}>
            {icon && <i className={`mdi mdi-${icon} font-16 lh-1 me-1`} />}
            <Render {...result} />
        </div>
    )
}

export default SearchResultRender;