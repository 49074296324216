import * as React from 'react';
import UploadButton from "./UploadButton";
import TopbarFolderSelector from "./TopbarFolderSelector";
import SearchInput from "./SearchInput";
import {CloseButton} from "react-bootstrap";
import SearchButton from "./SearchButton";
import {useMediaLibraryDataStore} from "../../MediaLibraryContext";

const Topbar = () => {

    const [ isSearch, setIsSearch ] = React.useState(false);
    const store = useMediaLibraryDataStore();

    if (isSearch) {
        const handleSearchClose = () => {
            setIsSearch(false);
            store.search = '';
        }

        return (
            <div className='mb-2 d-flex align-items-center gap-1'>
                <SearchInput show={true} />
                <CloseButton onClick={handleSearchClose} />
            </div>
        )
    }

    return (
        <div className='mb-2 d-flex gap-1 px-1'>
            <TopbarFolderSelector />
            <SearchButton handleClick={() => setIsSearch(true)} />
            <SearchInput />
            <UploadButton />
        </div>
    );
}

export default Topbar
