import * as React from 'react';
import Block, {BlockProps} from "./Block";
import {useAppNavigation} from "../Application";

export type TabBlockProps = Omit<BlockProps, 'title'> & {
    title: string,
    hideTitle?: boolean,
    icon?: string,
    badge?: string|number,
}

const TabBlock = ({ title, hideTitle, icon, badge, children, ...props }: TabBlockProps) => {

    const appNavigation = useAppNavigation();

    const id = React.useId();

    return (
        <Block
            ref={appNavigation.Topbar.Tabs.makeScrollTabRegister({ icon, badge, title })}
            title={hideTitle ? undefined : title}
            id={id}
            {...props}
        >
            {children}
        </Block>
    )
}


export default TabBlock
