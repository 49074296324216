import * as React from 'react';
import {useRouteError} from "react-router-dom";
import {isApiAccessDeniedError, isApiNotFoundError, isApiNotAuthorizedError} from "@ova-studio/api-helper";
import Page404 from "./PageError/Page404";
import Page403 from "./PageError/Page403";
import {useAppAuth} from "../../Application";

const PageErrorBoundary = () => {
    const error = useRouteError();
    const appAuth = useAppAuth();

    if (isApiNotFoundError(error)) {
        return <Page404 />
    }

    if (isApiAccessDeniedError(error)) {
        return <Page403 />
    }

    if (isApiNotAuthorizedError(error)) {
        void appAuth.logout();
        window.location.reload();
    }

    throw error;
}

export default PageErrorBoundary
