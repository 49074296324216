import * as React from "react";
import {SearchResultRenderer} from "../types";
import SearchResultLinks from "./SearchResultLinks";

const defaultSearchResultRender : SearchResultRenderer = (result) => {
    return (
        <React.Fragment>
            <b>{result.data.name ?? result.data.title}</b>

            <span className='ms-auto d-flex align-items-center'>
                <SearchResultLinks result={result} />
            </span>
        </React.Fragment>
    )
}

export default defaultSearchResultRender;