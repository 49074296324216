import ArrayDataStore, { ArrayDataStoreOptions } from "../../store/ArrayDataStore";
import {ArrayData, BasicData} from "../../types";
import useExternalStore from "../../../hooks/useExternalStore";
import {useEffect, useMemo, useRef} from "react";
import {isEqual} from "lodash";

export type UseArrayDataStoreReturn<D extends BasicData> = {
    store: ArrayDataStore<D>;
    data: ArrayData<D>;
};

const useArrayDataStore = <D extends BasicData>(initialData: ArrayData<D>, options: ArrayDataStoreOptions<D>) : UseArrayDataStoreReturn<D> => {
    const initialDataRef = useRef(initialData);

    const store = useMemo(() => new ArrayDataStore(initialData, options), []);
    const data = useExternalStore(store);

    useEffect(() => {
        if (!isEqual(initialDataRef.current, initialData)) {
            store.updateInitialData(initialData);
            initialDataRef.current = initialData;
        }
    }, [ initialData ]);

    return {store, data};
}

export default useArrayDataStore;