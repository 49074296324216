import useGetLatest from "@utilityjs/use-get-latest";
import useRegisterNodeRef from "@utilityjs/use-register-node-ref";
import * as React from "react";
var isTouch = function (event) {
    return TouchEvent ? event instanceof TouchEvent : "touches" in event;
};
var isMouse = function (event) {
    return event instanceof MouseEvent;
};
var calcPosition = function (event) {
    return isTouch(event)
        ? { x: event.touches[0].pageX, y: event.touches[0].pageY }
        : isMouse(event)
            ? { x: event.pageX, y: event.pageY }
            : null;
};
var useLongPress = function (callback, options) {
    if (options === void 0) { options = {}; }
    var _a = options.pressDelay, pressDelay = _a === void 0 ? 500 : _a, _b = options.moveThreshold, moveThreshold = _b === void 0 ? 25 : _b, _c = options.preventContextMenuOnLongPress, preventContextMenuOnLongPress = _c === void 0 ? false : _c, _d = options.preventLongPressOnMove, preventLongPressOnMove = _d === void 0 ? false : _d;
    var callbackRef = useGetLatest(callback);
    var timeoutRef = React.useRef();
    var startPositionsRef = React.useRef(null);
    var isPressedRef = React.useRef(false);
    var startLongPress = function (event) {
        // Ignore events other than mouse and touch
        if (!isMouse(event) && !isTouch(event))
            return;
        if (isPressedRef.current)
            return;
        // Main button pressed
        isPressedRef.current = isMouse(event) ? event.button === 0 : true;
        startPositionsRef.current = calcPosition(event);
        timeoutRef.current = setTimeout(function () { var _a; return void ((_a = callbackRef.current) === null || _a === void 0 ? void 0 : _a.call(callbackRef)); }, pressDelay);
    };
    var stopLongPress = function (event) {
        // Ignore events other than mouse and touch
        if (!isMouse(event) && !isTouch(event))
            return;
        isPressedRef.current = false;
        startPositionsRef.current = null;
        if (timeoutRef.current)
            clearTimeout(timeoutRef.current);
    };
    var preventLongPress = function (event) {
        if (!preventLongPressOnMove)
            return;
        // Ignore events other than mouse and touch
        if (!isMouse(event) && !isTouch(event))
            return;
        if (!startPositionsRef.current)
            return;
        var position = calcPosition(event);
        var initialPosition = startPositionsRef.current;
        if (!position)
            return;
        var dx = Math.abs(position.x - initialPosition.x);
        var dy = Math.abs(position.y - initialPosition.y);
        if (dx > moveThreshold || dy > moveThreshold)
            stopLongPress(event);
    };
    var preventContextMenu = function (event) {
        var _a;
        if (!isPressedRef.current)
            return;
        if (preventContextMenuOnLongPress)
            (_a = event.preventDefault) === null || _a === void 0 ? void 0 : _a.call(event);
    };
    var handleEvents = function (node, unsubscribe) {
        if (unsubscribe === void 0) { unsubscribe = false; }
        node.oncontextmenu = unsubscribe ? null : preventContextMenu;
        var fn = unsubscribe
            ? node.removeEventListener.bind(node)
            : node.addEventListener.bind(node);
        fn("mousedown", startLongPress);
        fn("touchstart", startLongPress);
        fn("mousemove", preventLongPress);
        fn("touchmove", preventLongPress);
        fn("mouseup", stopLongPress);
        fn("mouseleave", stopLongPress);
        fn("touchend", stopLongPress);
    };
    var subscriber = function (node) {
        handleEvents(node);
        return function () { return void handleEvents(node, true); };
    };
    var registerNode = useRegisterNodeRef(subscriber);
    React.useEffect(function () {
        return function () {
            if (timeoutRef.current)
                clearTimeout(timeoutRef.current);
        };
    }, []);
    return { registerNode: registerNode };
};
export default useLongPress;
