import * as React from 'react';
import SubmitButtons from "./SubmitButtons";
import Wrapper, {WrapperProps} from "./Wrapper";
import Body from "./Body";
import initializer from "../initializer";

const ButtonsWrapper = ({ children } : { children: React.ReactNode }) => <div className='mt-2'>{children}</div>

export type MediaLibraryProps = Omit<WrapperProps, 'children'> & {
    showButtons?: true,
}

const MediaLibrary = ({ showButtons, ...props }: MediaLibraryProps) => {

    return (
        <Wrapper {...props}>
            <Body />
            {showButtons && <SubmitButtons wrapper={ButtonsWrapper} />}
        </Wrapper>
    );
}

export default Object.assign(MediaLibrary, {
    Buttons: SubmitButtons,
    Wrapper,
    Body,

    initializer,
})
