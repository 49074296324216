import * as React from 'react';
import {useMobileTableContext} from "./MobileTableContext";
import RowCheckbox from "../Components/RowCheckbox";
import {useDatatableContext} from "../../DatatableContext";

type RowIndexToggleProps = {
    index: number,
    id: number|string,
}

const RowIndexToggle = ({ index, id }: RowIndexToggleProps) => {

    const { isSelectionMode, setSelectionMode } = useMobileTableContext();
    const { setRowSelected } = useDatatableContext();

    const handleSetSelected = () => {
        setSelectionMode(true);
        setRowSelected(id, true);
    }

    if (isSelectionMode) {
        return <RowCheckbox id={id} />
    }

    return <span role='button' onClick={handleSetSelected}>{index}</span>;
}

export default RowIndexToggle
