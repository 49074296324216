import * as React from 'react';
import FormGroupContainer, {FormGroupContainerBaseProps} from "./FormGroupContainer";
import {Spinner} from "react-bootstrap";

type FormGroupContainerFallbackProps = FormGroupContainerBaseProps & {
    body?: React.ReactNode,
}

const LoadingFallback = () => {
    return (
        <div className='d-flex align-items-center gap-1'>
            <Spinner animation='grow' size='sm' />
            <span className='ms-1'>Завантаження, зачекайте...</span>
        </div>
    )
}

const FormGroupContainerFallback = ({ body, ...props }: FormGroupContainerFallbackProps) => {
    return (
        <FormGroupContainer {...props}>
            {() => Boolean(body) ? <React.Fragment>{body}</React.Fragment> : <LoadingFallback />}
        </FormGroupContainer>
    );
}

export default FormGroupContainerFallback
