import AbstractDataStore from "../../abstracts/AbstractDataStore";
import {BasicData} from "../types";

export default class ItemDataStore<D extends BasicData> extends AbstractDataStore<D> {
    private _data : D;

    constructor(initialData: D) {
        super();
        this._data = initialData;
    }

    protected _updateData(data : D) : void {
        this._data = data;
        this._callListeners();
    }

    public updateData(data : Partial<D>) : void {
        this._updateData({
            ...this._data,
            ...data,
        });
    }

    public updateInitialData(data : D) : void {
        this._data = data;
        this._callListeners();
    }

    getData() : D {
        return this._data;
    }
}