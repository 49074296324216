import getOptionProperty from './getOptionProperty';
function getIsOnlyResult(props) {
  var allowNew = props.allowNew,
    highlightOnlyResult = props.highlightOnlyResult,
    results = props.results;
  if (!highlightOnlyResult || allowNew) {
    return false;
  }
  return results.length === 1 && !getOptionProperty(results[0], 'disabled');
}
export default getIsOnlyResult;