import React from "react";
import FormSelect, {FormSelectBaseProps} from "./FormSelect";
import {Await} from "react-router-dom";
import FormGroupContainerFallback from "./FormGroupContainerFallback";
import {FormSelectOption} from "./BaseInputProps";

const FailInfo = () => (
    <div className='text-danger font-18'>
        <i className='mdi mdi-alert me-1' />
        Помилка завантаження
    </div>
)

type FormSelectAwaitRenderProps = FormSelectBaseProps & {
    options: FormSelectOption[] | null,
    nullFallback?: React.ReactNode,
    emptyFallback?: React.ReactNode,
};

const FormSelectAwaitRender = ({ options, nullFallback, emptyFallback, ...props } : FormSelectAwaitRenderProps) => {
    if (nullFallback || emptyFallback) {
        if (options === null) {
            return (
                <FormGroupContainerFallback
                    {...props}
                    body={nullFallback ?? emptyFallback}
                />
            )
        }

        if (options.length === 0) {
            return (
                <FormGroupContainerFallback
                    {...props}
                    body={emptyFallback}
                />
            )
        }
    }

    return (
        <FormSelect
            {...props}
            options={options ?? []}
        />
    )
}

export type FormSelectAwaitProps = FormSelectBaseProps & {
    awaiter: Promise<FormSelectOption[] | null>,
    nullFallback?: React.ReactNode,
    emptyFallback?: React.ReactNode,
};

const FormSelectAwait = ({ awaiter, nullFallback, emptyFallback, ...props } : FormSelectAwaitProps) => {

    return (
        <React.Suspense fallback={<FormGroupContainerFallback {...props} />}>
            <Await
                resolve={awaiter}
                errorElement={(
                    <FormGroupContainerFallback
                        {...props}
                        body={<FailInfo />}
                    />
                )}
                children={(options: FormSelectOption[] | null) => (
                    <FormSelectAwaitRender
                        {...props}
                        options={options}
                    />
                )}
            />
        </React.Suspense>
    )
}

export default FormSelectAwait
