import * as React from "react";
import {isNavMenuChildren, NavMenuItem} from "../../../../../AppHelpers/Navigation";
import {SimpleCallback} from "../../../../../types/SimpleCallback";
import BottomMenuItemWithChildren from "./BottomMenuItemWithChildren";
import BottomMenuItemLink from "./BottomMenuItemLink";

type BottomMenuItemProps = {
    data: NavMenuItem,
    handleClick: SimpleCallback,
}

const BottomMenuItem = ({ data, handleClick }: BottomMenuItemProps) => {
    if (isNavMenuChildren(data)) {
        return <BottomMenuItemWithChildren data={data} handleClick={handleClick} />
    }

    return <BottomMenuItemLink data={data} handleClick={handleClick} />
}

export default BottomMenuItem;