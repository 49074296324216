import * as React from "react";
import {MediaType} from "../../types/MediaType";
import MediaAudioDisplay from "./MediaAudioDisplay";
import {MediaDisplayProps} from "./MediaDisplayProps";
import MediaEmbedDisplay from "./MediaEmbedDisplay";
import MediaEmbedYoutubeDisplay from "./MediaEmbedYoutubeDisplay";
import MediaFileDisplay from "./MediaFileDisplay";
import MediaVideoDisplay from "./MediaVideoDisplay";
import MediaImageDisplay from "./MediaImageDisplay";

const MediaDisplay = ({ data, ...props }: MediaDisplayProps) => {

    if (data.type === MediaType.Audio) {
        return <MediaAudioDisplay data={data} {...props} />
    }

    if (data.type === MediaType.Embed) {
        if (data.mime_type === 'ova-embed/youtube') {
            return <MediaEmbedYoutubeDisplay data={data} {...props} />
        }

        return <MediaEmbedDisplay data={data} {...props} />
    }

    if (data.type === MediaType.File) {
        return <MediaFileDisplay data={data} {...props} />
    }

    if (data.type === MediaType.Video) {
        return <MediaVideoDisplay data={data} {...props} />
    }

    if (data.type === MediaType.Image) {
        return <MediaImageDisplay data={data} {...props} />
    }

    return null
}

export type {MediaDisplayProps}

export default MediaDisplay;
