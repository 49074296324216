import {useLocation, useMatches} from "react-router-dom";
import {HandleData, HandleOptions} from "../route/helpers/prepareHandle";
import {useMemo} from "react";
import {FormValues} from "../Form/types";

export default function useRouteMatches() : HandleData[] {
    const matches = useMatches();

    const location = useLocation();

    return useMemo<HandleData[]>(() => {

        const idx = matches.findIndex(({ pathname }) => pathname === location.pathname)

        return matches
            .slice(0, idx+1)
            .filter(m => typeof m.handle !== 'undefined')
            .map(m => {
                const handle = m.handle as HandleOptions;

                return {
                    name: handle.name(m.data),
                    title: handle.title(m.data),
                    url: m.pathname,
                    breadcrumb: handle.breadcrumb(m.data, m.pathname),
                    titleLinks: handle.titleLinks(m.data),
                    titleEnd: handle.titleEnd(m.data),
                    actionHandler: async (data: FormValues) => {
                        await handle.actionHandler?.(data, m.params);
                    },
                }
            })
    }, [ matches, location.pathname ])
}
