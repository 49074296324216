import {BasicData} from "../../types";
import useItemDataStore, {UseItemDataStoreReturn} from "./useItemDataStore";
import {useEffect} from "react";
import WebsocketItemDataStoreAdapter, {WebsocketItemDataStoreAdapterOptions} from "../../ws/WebsocketItemDataStoreAdapter";
import {useAppWebsocket} from "../../../Application";

export type UseWebsocketItemDataStoreReturn<D extends BasicData> = UseItemDataStoreReturn<D>;

const useWebsocketItemDataStore = <D extends BasicData>(initialData: D, opts: WebsocketItemDataStoreAdapterOptions<D>): UseWebsocketItemDataStoreReturn<D>  => {
    const {store, data} = useItemDataStore(initialData);
    const ws = useAppWebsocket();

    useEffect(() => {
        const adapter = new WebsocketItemDataStoreAdapter(ws, store, opts);

        adapter.init();

        return () => {
            adapter.destroy();
        };
    }, []);

    return {store, data};
}

export default useWebsocketItemDataStore;