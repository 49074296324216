import * as React from "react";
import FormMediaSelectWrapper, {FormMediaSelectWrapperProps} from "./FormMediaSelectWrapper";
import {Card} from "react-bootstrap";
import {BouncingLoader, FlexCentered} from "@ova-studio/react-hyper-admin";
import FormMediaSelectBody from "./FormMediaSelectBody";

type FormMediaSelectCardProps = Omit<FormMediaSelectWrapperProps, 'children'> & {
    aspectRatio?: number | string,
}

const FormMediaSelectCard = ({ aspectRatio = '16/9', ...props }: FormMediaSelectCardProps) => {
    return (
        <FormMediaSelectWrapper {...props}>
            <Card style={{ aspectRatio }}>
                <FormMediaSelectBody.Media>
                    {(data) => data ? (
                        <Card.Img
                            src={data.src}
                            alt={data.name}
                            style={{objectFit: 'cover', height: '100%'}}
                        />
                    ) : (
                        <FlexCentered>
                            <BouncingLoader/>
                        </FlexCentered>
                    )}
                </FormMediaSelectBody.Media>
                <Card.ImgOverlay className='text-end'>
                    <FormMediaSelectBody.Button/>
                </Card.ImgOverlay>
            </Card>
        </FormMediaSelectWrapper>
    )
}

export default FormMediaSelectCard;
