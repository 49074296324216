import * as React from 'react';
import {useLocation} from "react-router-dom";
import {useAppNavigation} from "../../Application";

const NavigationHelper = () => {

    const appNavigation = useAppNavigation();

    const location = useLocation();

    React.useEffect(() => {
        appNavigation.MainNavigation.toggle(false)
    }, [ location ])

    return null;
}

export default NavigationHelper
