import * as React from "react";
import {ProcessFormErrorsReturn} from "../../utils/processFormErrors";

type ErrorToastBodyProps = ProcessFormErrorsReturn

const ErrorToastBody = ({ errorTitle, globalErrors }: ErrorToastBodyProps) => {
    return (
        <React.Fragment>
            {Array.isArray(errorTitle) ? (
                <ul className='my-0'>
                    {errorTitle.map((error, i) => (
                        <li key={`error-${i}`}>{error}</li>
                    ))}
                </ul>
            ) : (
                <div>{errorTitle}</div>
            )}

            {globalErrors && globalErrors.length > 0 && (
                <ul className='mt-1 mb-0'>
                    {globalErrors.map((error, i) => (
                        <li key={`error-${i}`}>{error}</li>
                    ))}
                </ul>
            )}
        </React.Fragment>
    )
}

export default ErrorToastBody;