import * as React from 'react';
import {RowButtonProps} from "./RowButtonProps";
import {SpecialAction, SpecialActions} from "../../../types/TableRowActions";
import RowActionButtonBody from "./RowActionButtonBody";
import useDeleteAction from "../../../../hooks/useDeleteAction";

type RowDeleteActionButtonProps = RowButtonProps<SpecialAction<SpecialActions.Delete>>

const RowDeleteActionButton = ({ action, className }: RowDeleteActionButtonProps) => {

    const { helpers, handleClick } = useDeleteAction({
        endpoint: action.config.endpoint,
        title: action.config.title,
        confirmText: action.config.confirmText,
        method: action.config.method,
    })

    return (
        <React.Fragment>
            {helpers}
            <RowActionButtonBody
                icon={action.icon}
                title={action.title}
                className={className}
                buttonVariant={action.buttonVariant}
                onClick={handleClick}
            />
        </React.Fragment>
    );
}

export default RowDeleteActionButton
