import * as React from "react";
import useModal from "./useModal";
import {Alert, Button} from "react-bootstrap";
import {Variant} from "react-bootstrap/types";
import Modal from "../components/Modal";

export type UseConfirmParams = {
    title?: string,
    text: React.ReactNode,
    submitText?: string,
    cancelText?: string,
    color?: Variant,
    handleContinue: () => void|Promise<any>,
    handleClose?: () => void,
    handleExit?: () => void,
    initialModalState?: boolean,
}

export type UseConfirmReturn = {
    handleClick: () => void,
    ConfirmModal: React.ReactNode,
}

export default function useConfirm(config: UseConfirmParams) : UseConfirmReturn {

    const { title = 'Підтвердження дії', submitText = 'Підтвердити', cancelText = 'Скасувати', color = 'danger', text, handleContinue, handleClose, handleExit, initialModalState = false } = config;

    const confirmModal = useModal({
        initialState: initialModalState,
        onExited: handleExit,
    });
    const [ error, setError ] = React.useState<string>();
    const [ isLoading, setLoading ] = React.useState<boolean>(false);

    const handleClick = React.useCallback(() => {
        confirmModal.open();
    }, [])

    const handleCancel = React.useCallback(() => {
        confirmModal.close();
        handleClose && handleClose();
    }, [])

    const handleSubmit = React.useCallback(() => {

        setLoading(true);
        setError(undefined);

        const result = handleContinue();

        if (result instanceof Promise) {
            result.then(() => confirmModal.close())
                .catch(e => setError(e))
                .finally(() => setLoading(false));
        } else {
            confirmModal.close();
            setLoading(false);
        }

    }, [ handleContinue ])

    const ConfirmModal = React.useMemo(() => {
        return (
            <Modal state={confirmModal} title={title} titleBg={color} centered={true} lockClose={isLoading} backdropClassName='modal-backdrop-nested' className='modal-nested'>
                <Modal.Body>
                    {error && <Alert variant='danger'>{error}</Alert>}
                    <div>{text}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={color} onClick={handleSubmit} disabled={isLoading}>{submitText}</Button>
                    <Button variant='secondary' onClick={handleCancel} disabled={isLoading}>{cancelText}</Button>
                </Modal.Footer>
            </Modal>
        )
    }, [ confirmModal.state, title, color, error, text, isLoading, submitText, cancelText, handleSubmit, handleCancel ])

    return { handleClick, ConfirmModal };

}
