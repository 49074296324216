import * as React from 'react';
import {Modal} from "react-bootstrap";
import useModal from "../hooks/useModal";
import {Link, useMatches, useNavigate} from "react-router-dom";
import {useMemo} from "react";
import {HandleOptions} from "../route/helpers/prepareHandle";

type ModalRouteWrapperProps = {
    closeNavigatePath: string,
    children: React.ReactNode,
}

const ModalRouteWrapper = ({ closeNavigatePath, children }: ModalRouteWrapperProps) => {

    const matches = useMatches();

    const navigate = useNavigate();

    const onClose = () => {
        navigate(closeNavigatePath);
    }

    const data = useMemo(() => {
        const match = matches.pop();

        if (!match) {
            return undefined;
        }

        const handle = match.handle as HandleOptions;

        return {
            title: handle.title(match.data),
            titleLinks: handle.titleLinks(match.data),
            titleEnd: handle.titleEnd(match.data),
        }
    }, [matches])

    const modal = useModal({ initialState: true, onClose })

    if (!data) {
        return null;
    }

    return (
        <Modal show={modal.state} onHide={modal.close} size='lg' centered>
            <Modal.Header className='py-0' closeButton>
                <Modal.Title>
                    {data.title}
                    {data.titleLinks.map((link, idx) => (
                        <Link key={`title-link-${idx}`} to={link.to} className={link.className}>{link.text}</Link>
                    ))}
                </Modal.Title>
                {data.titleEnd && <div className='ms-auto'>{data.titleEnd}</div>}
            </Modal.Header>
            {children}
        </Modal>
    );
}

export default ModalRouteWrapper
