import * as React from 'react';
import {ActionDisplayProps} from "./ActionDisplayProps";
import {ActionStatus, CustomFormActionCheckData, FormActionCheckData} from "../../types";
import useModal from "../../../../hooks/useModal";
import Form from "../../../../Form";
import Modal from "../../../../components/Modal";
import {Alert, Button} from "react-bootstrap";
import {FieldValues} from "react-hook-form/dist/types/fields";
import TopOverlayLoading from "../../../../components/TopOverlayLoading";
import ReactMarkdown from "react-markdown";
import {isCustomFormActionCheckData} from "../../guards";
import {useAppActions} from "../../../../Application";

type CustomFormDisplayProps = {
    name: string,
}

const CustomFormDisplay = ({ name } : CustomFormDisplayProps) => {
    const appActions = useAppActions();
    const Component = appActions.getCustomFormComponent(name);

    return <Component />
}

type FormDisplayProps = {
    data: FormActionCheckData | CustomFormActionCheckData,
}

const FormDisplay = ({ data }: FormDisplayProps) => {
    if (isCustomFormActionCheckData(data)) {
        return <CustomFormDisplay name={data.formName} />
    }

    return <Form.DynamicBody fields={data.fields} />
}

const ActionDisplayForm = ({ action }: ActionDisplayProps<ActionStatus.WaitData>) => {

    const modalState = useModal({
        initialState: true,
        onClose: action.handleCancel,
    })

    const handleSubmit = React.useCallback((data : FieldValues) => new Promise<void>((resolve, reject) => {
        action.handleRun(data)
            .then(() => resolve())
            .catch(reject)
    }), [])

    return (
        <Modal state={modalState} title={action.action.title} titleBg={action.data.color}>
            {action.isRunning && <TopOverlayLoading />}
            <Form.Callback data={action.data.values ?? {}} handleSubmit={handleSubmit} showSuccess={false}>
                <Modal.Body>
                    {action.data.message && <Alert variant='info'><ReactMarkdown className='markdown-text' children={action.data.message} /></Alert>}
                    <FormDisplay data={action.data} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' type='button' onClick={modalState.close}>{action.data.cancelText ?? 'Скасувати'}</Button>
                    <Form.Submit variant={action.data.color ?? 'primary'}>{action.data.submitText ?? 'Продовжити'}</Form.Submit>
                </Modal.Footer>
            </Form.Callback>
        </Modal>
    );
}

export default ActionDisplayForm
