import * as React from 'react';
import BouncingLoader from "./BouncingLoader";

const LoadingHover = () => {
    React.useEffect(() => {
        document.body?.classList.add('body-lock-scroll');
        return () => document.body?.classList.remove('body-lock-scroll');
    })

    return (
        <div className='page-loader'>
            <BouncingLoader />
        </div>
    )
}

export default LoadingHover
