import * as React from "react";

export default function useSavedState<T>(defaultValue : T, key : string) : [ T, React.Dispatch<React.SetStateAction<T>> ]  {

    const [value, setValue] = React.useState<T>(() => {
        const stickyValue = window.localStorage.getItem(key);
        return stickyValue !== null
            ? JSON.parse(stickyValue)
            : defaultValue;
    });

    React.useEffect(() => {
        window.localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);

    return [value, setValue];
}
