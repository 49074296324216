import * as React from 'react';
import {Modal, UseModalReturn} from "@ova-studio/react-hyper-admin";
import MediaLibrary, {MediaLibraryProps} from "./MediaLibrary";
import {MediaSelectHandler} from "./types/MediaSelectHandler";

export type MediaLibraryModalProps = MediaLibraryProps & {
    state: UseModalReturn<any>,
    closeOnSelect?: boolean,
    closeOnSubmit?: boolean,
}

const MediaLibraryModal = ({ state, handleSelect: _handleSelect, handleSubmit: _handleSubmit, closeOnSelect, closeOnSubmit, selectAction, ...props }: MediaLibraryModalProps) => {

    const handleSelect = React.useCallback<MediaSelectHandler>((media) => {
        _handleSelect && _handleSelect(media);
        closeOnSelect && state.close();
    }, [])

    const handleSubmit = React.useCallback<MediaSelectHandler>((media) => {
        _handleSubmit && _handleSubmit(media);
        closeOnSubmit && state.close();
    }, [])

    return (
        <Modal title="Медіа бібліотека" size='xl' state={state} className='media-library-modal'>
            <MediaLibrary.Wrapper {...props} handleSelect={handleSelect} handleSubmit={handleSubmit} selectAction={selectAction}>
                <Modal.Body>
                    <MediaLibrary.Body />
                </Modal.Body>
                <MediaLibrary.Buttons wrapper={Modal.Footer} />
            </MediaLibrary.Wrapper>
        </Modal>
    );
}

export default MediaLibraryModal
