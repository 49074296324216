import {
    AuthenticatorData,
    AuthenticatorState, AuthResponse,
    AuthResponseStatus,
    isAuthenticatorData,
    isAuthResponse
} from "./types/Authenticator";
import {FieldValues} from "react-hook-form/dist/types/fields";
import AbstractDataStore from "../../abstracts/AbstractDataStore";
import Auth from "./Auth";
import {AuthConfig} from "./types/AuthConfig";

export default class Authenticator extends AbstractDataStore<AuthenticatorData> {

    private _data: AuthenticatorData = {
        state: AuthenticatorState.Credentials,
    };

    private readonly _config: AuthConfig;
    private readonly _auth: Auth;
    private resendTimeout: NodeJS.Timeout|null = null;

    constructor(config: AuthConfig, auth: Auth) {
        super();
        this._config = config;
        this._auth = auth;

        this._auth.subscribe(() => {
            this._refresh();
        });
    }

    private _refresh() {
        this._data = {
            state: AuthenticatorState.Credentials,
        }

        this._callListeners();
    }

    private _makeResendTimeout(time: number) {
        if (this.resendTimeout) {
            clearTimeout(this.resendTimeout);
            this.resendTimeout = null;
        }

        if (!isAuthenticatorData(this._data, AuthenticatorState.SecondFactor)) {
            this._refresh();
            return;
        }

        this.resendTimeout = setTimeout(() => {
            this._data = {
                ...this._data,
                allowResend: true,
            };

            this._callListeners();
        }, time * 1000);
    }

    private _setWaitSecondFactor(data: AuthResponse<AuthResponseStatus.WaitSecondFactor>) {
        this._data = {
            state: AuthenticatorState.SecondFactor,
            token: data.data.token,
            info: data.data.info,
            allowResend: false,
        };

        this._makeResendTimeout(data.data.time);

        this._callListeners();
    }

    private _markSuccess() {
        this._data = {
            state: AuthenticatorState.Success,
        };

        this._callListeners();
        void this._auth.refresh();
    }

    public login(data: FieldValues) : Promise<void> {
        return new Promise(async (resolve, reject) => {

            if (!isAuthenticatorData(this._data, AuthenticatorState.Credentials)) {
                this._refresh();
                reject('Невідома помилка');
                return;
            }

            try {
                const response = await this._config.loginHandler(data);

                if (isAuthResponse(response, AuthResponseStatus.Success)) {
                    this._markSuccess();
                    resolve();
                    return;
                }

                if (isAuthResponse(response, AuthResponseStatus.WaitSecondFactor)) {
                    this._setWaitSecondFactor(response);
                    resolve();
                    return;
                }

                this._refresh();
                reject('Невідома помилка');

            } catch (e) {
                reject(e);
            }
        });
    }

    public loginSecondFactor(data: FieldValues) : Promise<void> {
        return new Promise(async (resolve, reject) => {
            if (!isAuthenticatorData(this._data, AuthenticatorState.SecondFactor)) {
                this._refresh();
                reject('Невідома помилка');
                return;
            }

            if (!this._config.loginSecondFactorHandler) {
                reject('Помилка конфігурації');
                return;
            }

            try {
                const response = await this._config.loginSecondFactorHandler({ ...data, token: this._data.token });

                if (isAuthResponse(response, AuthResponseStatus.Success)) {
                    this._markSuccess();
                    resolve();
                    return;
                }

                this._refresh();
                reject('Невідома помилка');

            } catch (e) {
                reject(e);
            }
        });
    }

    public resendSecondFactor() : Promise<void> {
        return new Promise(async (resolve, reject) => {
            if (!isAuthenticatorData(this._data, AuthenticatorState.SecondFactor) || !this._data.allowResend) {
                this._refresh();
                reject('Невідома помилка');
                return;
            }

            if (!this._config.resendSecondFactorHandler) {
                reject('Помилка конфігурації');
                return;
            }

            try {
                const response = await this._config.resendSecondFactorHandler(this._data.token);

                if (isAuthResponse(response, AuthResponseStatus.WaitSecondFactor)) {
                    this._setWaitSecondFactor(response);
                    resolve();
                    return;
                }

                this._refresh();
                reject('Невідома помилка');

            } catch (e) {
                this._refresh();
                reject(e);
            }

        });
    }

    getData(): AuthenticatorData {
        return this._data;
    }
}
