import * as React from "react";
import ModalRouteWrapper from "../../AppWrapper/ModalRouteWrapper";

export default function wrapElement(element: React.ReactNode, isModal : boolean, closeNavigatePath : string) {

    if (isModal) {
        return (
            <ModalRouteWrapper closeNavigatePath={closeNavigatePath}>
                {element}
            </ModalRouteWrapper>
        )
    }

    return (
        <React.Fragment>
            {element}
        </React.Fragment>
    )


}
