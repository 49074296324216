import * as React from 'react';
import {Link, Outlet} from "react-router-dom";
import {Card, Col, Container, Row} from "react-bootstrap";
import AppCopyright from "../Copyright/AppCopyright";
import DevCopyright from "../Copyright/DevCopyright";
import {useBaseAppConfig} from "../../Application";
import {LogoComponent} from "../../types/LogoProps";
import DefaultLogo from "../AppLayout/DefaultLogo";

const LoginLayout = () => {

    const { appName, logoComponent } = useBaseAppConfig();

    React.useEffect(() => {
        document.body.classList.add('authentication-bg');

        const savedTitle = document.title;
        document.title = `Авторизація • ${appName}`;

        return () => {
            document.body.classList.remove('authentication-bg');
            document.title = savedTitle;
        };
    }, []);

    const Logo : LogoComponent = React.useMemo<LogoComponent>(() => logoComponent ?? DefaultLogo, [])

    return (
        <React.Fragment>
            <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5} xxl={4}>
                            <Card>
                                <Card.Header className="p-2 pt-3 text-center bg-primary">
                                    <Link to="/">
                                        <Logo height='45px' />
                                    </Link>
                                </Card.Header>
                                <Card.Body className="px-3 py-4 position-relative">
                                    <Outlet />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <footer className="footer footer-alt">
                <div>
                    <AppCopyright />
                    {' · '}
                    <DevCopyright />
                </div>
            </footer>
        </React.Fragment>
    );
}

export default LoginLayout
