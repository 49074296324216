import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["referenceElement", "isMenuShown"];
import PropTypes from 'prop-types';
import useOverlay from './useOverlay';
import { ALIGN_VALUES } from '../../constants';
import { noop } from '../../utils';

// `Element` is not defined during server-side rendering, so shim it here.
/* istanbul ignore next */
var SafeElement = typeof Element === 'undefined' ? noop : Element;
var propTypes = {
  /**
   * Specify menu alignment. The default value is `justify`, which makes the
   * menu as wide as the input and truncates long values. Specifying `left`
   * or `right` will align the menu to that side and the width will be
   * determined by the length of menu item values.
   */
  align: PropTypes.oneOf(ALIGN_VALUES),
  children: PropTypes.func.isRequired,
  /**
   * Specify whether the menu should appear above the input.
   */
  dropup: PropTypes.bool,
  /**
   * Whether or not to automatically adjust the position of the menu when it
   * reaches the viewport boundaries.
   */
  flip: PropTypes.bool,
  isMenuShown: PropTypes.bool,
  positionFixed: PropTypes.bool,
  // @ts-ignore
  referenceElement: PropTypes.instanceOf(SafeElement)
};
var Overlay = function Overlay(_ref) {
  var referenceElement = _ref.referenceElement,
    isMenuShown = _ref.isMenuShown,
    props = _objectWithoutProperties(_ref, _excluded);
  var overlayProps = useOverlay(referenceElement, props);
  if (!isMenuShown) {
    return null;
  }
  return props.children(overlayProps);
};
Overlay.propTypes = propTypes;
export default Overlay;