import * as React from 'react';
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

type ErrorPageBodyProps = {
    title: string,
    info?: string,
}

const ErrorPageBody = ({ title, info }: ErrorPageBodyProps) => {
    return (
        <Row className='justify-content-center'>
            <Col lg={4} className='text-center'>

                <h1 className="text-error mt-4">{title}</h1>
                <h4 className="text-uppercase text-danger mt-3">{info}</h4>

                <Link to='/' className='btn btn-info mt-3'>
                    <i className="mdi mdi-reply me-2" />
                    Повернутись на початок
                </Link>

            </Col>
        </Row>
    );
}

export default ErrorPageBody
