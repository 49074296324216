import * as React from "react";
import {MediaDisplayProps} from "./MediaDisplayProps";

const MediaAudioDisplay = ({ data, caption }: MediaDisplayProps) => {
    return (
        <div className='ova-engine-audio'>
            <audio preload="none">
                <source src={data.url ?? ''} type={data.mime_type}/>
            </audio>
            <div className="ova-engine-audio-player">
                <button className="ova-engine-audio-player__play">Відтворити</button>
                <input
                    type="range"
                    aria-label="position"
                    className="ova-engine-audio-player__progress"
                    min={0}
                    max={100}
                    value={0}
                    readOnly
                />
                <div className="ova-engine-audio-player__time">
                    00:00 / {data.meta_data?.duration ?? '00:00'}
                </div>
                <button className="ova-engine-audio-player__volume-button">Гучність</button>
                <input
                    type="range"
                    aria-label="volume"
                    className="ova-engine-audio-player__volume-range"
                    min={0}
                    max={1}
                    value={1}
                    step={0.01}
                    readOnly
                />
            </div>
            {caption && <figcaption>{caption}</figcaption>}
        </div>
    )
}

export default MediaAudioDisplay;
