import * as React from 'react';
import SimpleBar from "simplebar-react";
import SideMenuItems from "./SideMenuItems";
import SideMenuWrapper from "./SideMenuWrapper";
import AuthUser from "./AuthUser";
import {NavigationMenu} from "../../../../AppHelpers";
import SideMenuFallback from "./SideMenuFallback";

type SideMenuProps = {
    isOpen: boolean,
    data: NavigationMenu,
}

const SideMenu = ({ isOpen, data }: SideMenuProps) => {
    return (
        <SideMenuWrapper isOpen={isOpen}>
            <SimpleBar style={{ height: '100%' }} scrollbarMaxSize={320}>
                <SideMenuItems items={data} />
            </SimpleBar>
            <AuthUser />
        </SideMenuWrapper>
    );
}

export { SideMenuFallback }
export default SideMenu
