import * as React from 'react';
import {DatatableRow} from "../../../types/DatatableData";
import RowActions from "../../Components/RowActions";

type ActionsCellProps = {
    row: DatatableRow,
}

const ActionsCell = ({ row }: ActionsCellProps) => {
    return (
        <td className='text-end align-middle'>
            <RowActions
                row={row}
                size='sm'
            />
        </td>
    );
}

export default ActionsCell
