import {createContext} from "react";
import {MobileTableContextData} from "./MobileTableContextData";

const MobileTableContext = createContext<MobileTableContextData>({
    isSelectionMode: false,
    setSelectionMode: () => {},
    cardConfig: {
        fixed: [],
    }
});

MobileTableContext.displayName = 'MobileTableContext';

export default MobileTableContext
