import * as React from 'react';
import {OverlayTrigger, Tooltip as BsTooltip, TooltipProps as BsTooltipProps} from "react-bootstrap";
import {OverlayProps} from "react-bootstrap/Overlay";

export type TooltipProps = {
    text: React.ReactNode,
    children: React.ReactElement,
    placement?: OverlayProps['placement'],
}

const Tooltip = ({ text, children, placement = 'top' }: TooltipProps) => {

    const renderTooltip = (props: BsTooltipProps) => (
        <BsTooltip {...props} style={{ position: 'relative', ...props.style }}>
            {text}
        </BsTooltip>
    );

    return (
        <OverlayTrigger
            defaultShow={false}
            trigger={['hover', 'focus']}
            delay={{ show: 0, hide: 0 }}
            flip={undefined}
            onHide={undefined}
            onToggle={undefined}
            popperConfig={undefined}
            show={undefined}
            target={undefined}
            placement={placement}
            overlay={renderTooltip}
        >
            {children}
        </OverlayTrigger>
    );
}

export default Tooltip
