import React from 'react'
import {DatatableContextData} from "../../../DatatableContext/DatatableContext";
import {isBasePaginationData, isCursorPaginationData} from "../../../types/DatatableData";
import BasePaginationDisplay from "./BasePaginationDisplay";
import CursorPaginationDisplay from "./CursorPaginationDisplay";

type PaginationProps = {
    pagination: DatatableContextData['data']['pagination'],
}

const Pagination = ({ pagination } : PaginationProps) => {

    if (isBasePaginationData(pagination)) {
        return <BasePaginationDisplay data={pagination} />
    }

    if (isCursorPaginationData(pagination)) {
        return <CursorPaginationDisplay data={pagination} />
    }

    return null;
}

export default Pagination
