import * as React from "react";
import {useAppSearch} from "../../../Application";
import useExternalStore from "../../../hooks/useExternalStore";
import {Alert, Nav} from "react-bootstrap";
import SearchResultWrapper from "./SearchResultWrapper";
import classNames from "classnames";

type TabItemProps = {
    eventKey: string,
    name: string,
    icon?: string,
    active?: boolean,
    cnt?: number,
}

const TabItem = ({ eventKey, name, icon, active, cnt } : TabItemProps) => {

    const ref = React.useRef<HTMLAnchorElement>(null);

    React.useEffect(() => {
        if (active && ref.current) {
            ref.current.scrollIntoView({ block: 'nearest', inline: 'nearest' });
        }
    }, [ active ])

    return (
        <Nav.Item>
            <Nav.Link eventKey={eventKey} className={classNames('py-md-1 px-md-2 d-flex align-items-center', { [active ? 'text-secondary' : 'text-muted']: !cnt })} ref={ref}>
                {icon && <i className={`mdi mdi-${icon} font-16 lh-1 me-1`} />}
                {name}
                {(!!cnt && cnt > 0) && <span className={classNames('ms-1 font-12', active ? 'text-secondary' : 'text-muted')}>{cnt}</span>}
            </Nav.Link>
        </Nav.Item>
    )
}

const SearchDropdownBlock = () => {

    const search = useAppSearch();
    const { selectedType, types, typesCnt, error} = useExternalStore(search);

    return (
        <div className='search-dropdown bg-light'>
            <Nav variant="pills" className='search-type-selector' activeKey={selectedType || 'none'}
                 onSelect={search.handleTypeSelect.bind(search)}>
                {types && Object.entries(types).map(([type, {name, icon}]) => (
                    <TabItem key={type} eventKey={type} name={name} icon={icon} cnt={typesCnt[type]} active={selectedType === type} />
                ))}
            </Nav>

            {error && (
                <Alert variant='danger' className='my-2'>{error}</Alert>
            )}

            <div className='search-results custom-scrollbar'>
                <SearchResultWrapper/>
            </div>
        </div>
    )
}

export default SearchDropdownBlock;