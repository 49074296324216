import * as React from 'react';
import {GlobalActionButtonHandler} from "../../../../../AppHelpers/Navigation";
import GlobalBasicButton from "./GlobalBasicButton";

type GlobalHandlerButtonProps = {
    className: string,
    data: GlobalActionButtonHandler,
}

const GlobalHandlerButton = ({ className, data }: GlobalHandlerButtonProps) => {
    return (
        <GlobalBasicButton
            className={className}
            onClick={data.handler}
            options={data}
        />
    );
}

export default GlobalHandlerButton
