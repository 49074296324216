import React from "react";
import {useDatatableContext} from "../../DatatableContext";
import {DatatableRow} from "../../types/DatatableData";
import Cell from "./Cell";
import ActionsCell from "./Cell/ActionsCell";
import useRowIndex from "../MobileTable/useRowIndex";

type RowProps = {
    row: DatatableRow,
    index: number,
}

const IndexCell = ({ row, index } : RowProps) => {
    const indexBody = useRowIndex(row._id, index);

    return (
        <td className='align-middle'>
            {indexBody}
        </td>
    );
}

const Row = ({ row, index } : RowProps) => {

    const { showColumns, hasGroupActions, data: { options: { hasIndexes, hasRowActions } } } = useDatatableContext();

    return (
        <tr>
            {(hasIndexes || hasGroupActions) && <IndexCell row={row} index={index} />}
            {showColumns.map((column) => (
                <Cell
                    key={`cell-${column.key}`}
                    col={column}
                    data={row}
                />
            ))}
            {hasRowActions && <ActionsCell row={row} />}
        </tr>
    );
}

export default Row
