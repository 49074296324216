import * as React from 'react';
import {GlobalActionButtonPortal} from "../types/GlobalButtons";
import {createPortal} from "react-dom";
import {useAppNavigation} from "../../../Application";

export type GlobalButtonPortalProps = {
    options: Omit<GlobalActionButtonPortal, 'portalRefHandler'>,
    children: React.ReactNode,
}

const GlobalButtonPortal = ({ children, options }: GlobalButtonPortalProps) => {

    const appNavigation = useAppNavigation();

    const id = React.useId();

    const [portalRef, setPortalRef] = React.useState<HTMLDivElement | null>(null);

    const handlePortalRef = React.useCallback((ref: HTMLDivElement|null) => {
        setPortalRef(ref);
    }, []);

    React.useEffect(() => {
        const unregister = appNavigation.GlobalButtons.registerCustomButton(id, {
            ...options,
            portalRefHandler: handlePortalRef,
        })

        return () => {
            unregister();
        }
    }, [ id ]);

    return (
        <React.Fragment>
            {!!portalRef && createPortal(children, portalRef)}
        </React.Fragment>
    );
}

export default GlobalButtonPortal
