import {defer, json, LoaderFunction, LoaderFunctionArgs} from "react-router-dom";
import QueryString from "qs";
import API from "@ova-studio/api-helper";
import makeEndpoint, {MakeEndpointOptions} from "../helpers/makeEndpoint";

export type LoaderCondition = (data : Record<string, any>) => boolean;

export type LoaderConfigRequest = MakeEndpointOptions & {
    condition?: LoaderCondition,
    queryString?: boolean,
    params?: Record<string, any>,
    asResponse?: boolean,
}

export type LoaderConfigData = {
    data: Record<string, any>,
    condition?: LoaderCondition,
}

function isLoaderConfigData(config : LoaderConfig) : config is LoaderConfigData {
    return (config as LoaderConfigData).data !== undefined;
}

export type LoaderConfig = LoaderConfigRequest | LoaderConfigData;

export type ConfigLoaderDefinition = {
    sync?: Record<string, LoaderConfig>,
    defer?: Record<string, LoaderConfig>,
}

function makeLoader(opts : LoaderConfig, { params, request } : LoaderFunctionArgs) : Promise<any> {
    if (isLoaderConfigData(opts)) {
        return Promise.resolve(opts.data);
    }

    return new Promise(async (resolve, reject) => {

        let endpoint = makeEndpoint({
            endpoint: opts.endpoint,
            paramName: opts.paramName,
            subEndpoint: opts.subEndpoint,
        }, params)

        let query = {
            ...opts.params
        };

        if (opts.queryString) {
            query = {
                ...query,
                ...QueryString.parse((new URL(request.url)).searchParams.toString())
            };
        }

        if (Object.keys(query).length > 0) {
            endpoint+= '?' + QueryString.stringify(query);
        }

        try {
            const response = await API.get(endpoint)
            resolve(opts.asResponse ? response : response.data)
        } catch (e) {
            reject(e);
        }
    })
}

export default function makeLoaderFromConfig(config : ConfigLoaderDefinition) : LoaderFunction {
    return async (args) => {

        const loaders : Record<string, Promise<any>|any> = {};
        let isDefer = false;

        if (config.sync) {
            for (const key in config.sync) {
                loaders[key] = await makeLoader(config.sync[key], args);
            }
        }

        if (config.defer) {
            for (const key in config.defer) {
                const conf = config.defer[key];

                if (conf.condition) {
                    if (!conf.condition(loaders)) {
                        continue;
                    }
                }

                isDefer = true;
                loaders[key] = makeLoader(conf, args);
            }
        }

        if (isDefer) {
            return defer(loaders);
        } else {
            return json(loaders);
        }
    }
}
