import * as React from 'react';
import {Variant} from "react-bootstrap/types";
import FlexCentered from "./FlexCentered";

export type FlexCenteredInfoProps = {
    icon: string,
    text: React.ReactNode,
    color?: Variant,
}

const FlexCenteredInfo = ({ icon, text, color }: FlexCenteredInfoProps) => {
    return (
        <FlexCentered addClassName={color ? `text-${color}` : undefined}>
            <i className={`mdi mdi-${icon} fs-2`} />
            <div className='mt-1'>{text}</div>
        </FlexCentered>
    );
}

export default FlexCenteredInfo
