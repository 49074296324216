import * as React from 'react';
import CustomDynamicFormFieldRegistry from "./CustomDynamicFormFieldRegistry";

export type CustomDynamicFormFieldProps = {
    type: string,
    props: Record<string, any>,
}

const CustomDynamicFormField = ({ type, props }: CustomDynamicFormFieldProps) => {

    const Component = React.useMemo(() => CustomDynamicFormFieldRegistry.getField(type), [ type ]);

    if (!Component) {
        return null;
    }

    return (
        <Component {...props} />
    );
}

export default CustomDynamicFormField
