import * as React from 'react';
import {Col, Row} from "react-bootstrap";
import {BouncingLoader, FlexCentered, FlexCenteredInfo} from "@ova-studio/react-hyper-admin";
import {useMediaLibraryData} from "../MediaLibraryContext";
import MediaCard from "./MediaCard";
import {MediaConnectedContextMenu} from "./MediaCard/MediaContextMenu";

const MediaListBody = () => {

    const { media, loading, error } = useMediaLibraryData();

    if (media === null) {
        return <FlexCentered><BouncingLoader /></FlexCentered>
    }

    if (media.length === 0) {
        return <FlexCenteredInfo text='Немає медіа' icon='image-off-outline' />
    }

    return (
        <React.Fragment>
            <MediaConnectedContextMenu />
            <div className='p-1'>
                <Row className='gx-1 gy-1'>
                    {media.map(item => (
                        <Col xs={6} sm={4} md={3} xl={2} key={`item-${item.id}`}>
                            <MediaCard data={item} />
                        </Col>
                    ))}
                </Row>
            </div>

            {loading && <div className='pt-3'><BouncingLoader /></div>}

            {error && (
                <FlexCenteredInfo
                    color='danger'
                    text={error}
                    icon='alert-circle-outline'
                />
            )}
        </React.Fragment>
    );
}

export default MediaListBody
