import React, {useMemo} from "react";
import {Button, Modal} from "react-bootstrap";
import Form from "../../../Form";
import {useForm} from "react-hook-form";
import {useDatatableContext} from "../../DatatableContext";
import FilterRow from "./FilterRow";
import {FilterValues} from "../../types/DatatableFilters";

type FiltersModalProps = {
    open: boolean,
    closeModal: () => void,
}

const FiltersModal = ({ open, closeModal } : FiltersModalProps) => {

    const { data: { name, filters }, activeFilters, setSearchParams } = useDatatableContext();

    const defaultValues = useMemo(() => {

        const values : FilterValues = {};
        filters?.map(filter => {
            switch (filter.type) {
                case 'date':
                case 'datetime':
                case 'date-range':
                case 'datetime-range': values[filter.name] = ''; break;

                case 'select':
                    values[filter.name] = [];
                    break;

                default:
                    values[filter.name] = '';
                    break;
            }
        })

        return values;

    }, [ filters ]);

    const currentValues = useMemo(() => ({ ...defaultValues, ...activeFilters }), [ defaultValues, activeFilters ])

    const formMethods = useForm({ defaultValues: currentValues });

    const handleClose = () => {
        formMethods.reset(currentValues)
        closeModal();
    }

    const handleClear = () => {
        setSearchParams({ filter: undefined });
        formMethods.reset({ ...defaultValues })
        closeModal();
    }

    const handleSubmit = async (data : FilterValues) => {
        const newValues = Object.fromEntries(Object.entries(data).filter(([_, v]) => (v != null && v?.length > 0)));
        setSearchParams({ filter: newValues, page: undefined });
        formMethods.reset({ ...defaultValues, ...newValues })
        closeModal();
    }

    return (
        <Modal show={open} onHide={handleClose} centered>
            <Modal.Header className='py-0' closeButton>
                <Modal.Title>Фільтр</Modal.Title>
            </Modal.Header>
            <Form.Body name={`filters-${name}`} methods={formMethods} handleSubmit={handleSubmit}>
                <Modal.Body>
                    <div className='grid-form grid-form-col-2'>
                        {filters.map(filter => <FilterRow key={`filters-${name}-${filter.name}`} filter={filter} />)}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='outline-secondary' onClick={handleClear}>Скинути всі</Button>
                    <Form.Submit>Фільтрувати</Form.Submit>
                </Modal.Footer>
            </Form.Body>
        </Modal>
    );
}

export default FiltersModal
