import {useAsyncError} from "react-router-dom";
import {useMemo} from "react";
import {isApiAccessDeniedError, isApiNotFoundError} from "@ova-studio/api-helper";
import {Variant} from "react-bootstrap/types";

export type UseAwaitErrorReturn = {
    text: string,
    icon: string,
    color: Variant,
}

export default function useAwaitError(fallbackError : string = 'Помилка завантаження') : UseAwaitErrorReturn {

    const error = useAsyncError();

    return useMemo<UseAwaitErrorReturn>(() : UseAwaitErrorReturn => {

        console.warn('Await error', error);

        if (isApiAccessDeniedError(error)) {
            return { text: 'У вас немає доступу до цього', icon: 'lock-outline', color: 'muted' }
        }

        if (isApiNotFoundError(error)) {
            return { text: 'Не знайдено', icon: 'file-alert-outline', color: 'muted' }
        }

        return { text: fallbackError, icon: 'alert-circle-outline', color: 'danger' }

    }, [ error, fallbackError ])

}
