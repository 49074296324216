import * as React from 'react';
import {
    GlobalButton,
    isGlobalActionButtonDropdown,
    isGlobalActionButtonHandler, isGlobalActionButtonNavigate, isGlobalActionButtonPortal
} from "../../../../../AppHelpers/Navigation";
import GlobalDropdownButton from "./GlobalDropdownButton";
import GlobalHandlerButton from "./GlobalHandlerButton";
import GlobalNavigateButton from "./GlobalNavigateButton";
import GlobalPortalButton from "./GlobalPortalButton";

type GlobalButtonProps = {
    className: string,
    data: GlobalButton,
}

const GlobalButton = ({ data, className }: GlobalButtonProps) => {

    if (isGlobalActionButtonDropdown(data)) {
        return <GlobalDropdownButton data={data} className={className} />
    }

    if (isGlobalActionButtonHandler(data)) {
        return <GlobalHandlerButton data={data} className={className} />
    }

    if (isGlobalActionButtonNavigate(data)) {
        return <GlobalNavigateButton data={data} className={className} />
    }

    if (isGlobalActionButtonPortal(data)) {
        return <GlobalPortalButton data={data} className={className} />
    }

    return null
}

export default GlobalButton
