import React, {useCallback} from "react";
import {Button, Modal} from "react-bootstrap";
import {useDatatableContext} from "../../DatatableContext";
import Form, {FormSelectOption} from "../../../Form";
import {ColumnShowType} from "../../types/ColumnShowType";

const ColumnShowTypeOptions : FormSelectOption[] = [
    { value: ColumnShowType.Fixed, label: 'закріплено (без підпису)' },
    { value: ColumnShowType.Captioned, label: 'закріплено (з підписом)' },
    { value: ColumnShowType.Collapsed, label: 'згорнуто' },
    { value: ColumnShowType.Hidden, label: 'приховано' },
]

type TableSettingsProps = {
    state: boolean,
    close: () => void,
}

const TableSettingsModal = ({ state, close } : TableSettingsProps) => {

    const { settings, setSettings, data: { columns } } = useDatatableContext();

    const handleSubmit = useCallback((data : any) => new Promise<void>(resolve => {
        setSettings(data);
        resolve();
        close();
    }), [])

    return (
        <Modal show={state} onHide={close} centered size='lg'>
            <Modal.Header className='py-0' closeButton>
                <Modal.Title>Налаштування таблиці</Modal.Title>
            </Modal.Header>
            <Form.Callback data={settings} handleSubmit={handleSubmit}>
                <Modal.Body>
                    <div className='font-16 fw-bold'>Поля</div>
                    <div className='grid-form grid-form-col-3'>
                        {columns.map(column => (
                            <React.Fragment key={`column-config-${column.key}`}>
                                <div>{column.title}</div>
                                <div>
                                    <Form.BasicSelect name={`displayColumns.${column.key}.showType`} options={ColumnShowTypeOptions} groupClass='' />
                                </div>
                                <div>
                                    <Form.Input type='number' name={`displayColumns.${column.key}.order`} groupClass='' />
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                    <div className='mt-3'>
                        <Form.Check
                            name='disableMobileLayout'
                            label='Завжди показувати як таблицю'
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={close}>Скасувати</Button>
                    <Form.Submit>Зберегти</Form.Submit>
                </Modal.Footer>
            </Form.Callback>
        </Modal>
    );
}

export default TableSettingsModal
