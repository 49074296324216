import * as React from 'react';

type SmallColProps = {
    children?: React.ReactNode,
}

const SmallCol = ({ children }: SmallColProps) => {
    return (
        <th style={{ width: '1px' }} className='align-middle'>
            {children}
        </th>
    );
}

export default SmallCol
