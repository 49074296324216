import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { getOptionLabel } from '../utils';
export function getInitialState(props) {
  var defaultInputValue = props.defaultInputValue,
    defaultOpen = props.defaultOpen,
    defaultSelected = props.defaultSelected,
    maxResults = props.maxResults,
    multiple = props.multiple;
  var selected = props.selected ? props.selected.slice() : defaultSelected.slice();
  var text = defaultInputValue;
  if (!multiple && selected.length) {
    // Set the text if an initial selection is passed in.
    text = getOptionLabel(selected[0], props.labelKey);
    if (selected.length > 1) {
      // Limit to 1 selection in single-select mode.
      selected = selected.slice(0, 1);
    }
  }
  return {
    activeIndex: -1,
    activeItem: undefined,
    initialItem: undefined,
    isFocused: false,
    selected: selected,
    showMenu: defaultOpen,
    shownResults: maxResults,
    text: text
  };
}
export function clearTypeahead(state, props) {
  return _objectSpread(_objectSpread({}, getInitialState(props)), {}, {
    isFocused: state.isFocused,
    selected: [],
    text: ''
  });
}
export function clickOrFocusInput(state) {
  return _objectSpread(_objectSpread({}, state), {}, {
    isFocused: true,
    showMenu: true
  });
}
export function hideMenu(state, props) {
  var _getInitialState = getInitialState(props),
    activeIndex = _getInitialState.activeIndex,
    activeItem = _getInitialState.activeItem,
    initialItem = _getInitialState.initialItem,
    shownResults = _getInitialState.shownResults;
  return _objectSpread(_objectSpread({}, state), {}, {
    activeIndex: activeIndex,
    activeItem: activeItem,
    initialItem: initialItem,
    showMenu: false,
    shownResults: shownResults
  });
}
export function toggleMenu(state, props) {
  return state.showMenu ? hideMenu(state, props) : _objectSpread(_objectSpread({}, state), {}, {
    showMenu: true
  });
}