import * as React from 'react';
import useExternalStore from "../hooks/useExternalStore";
import {useAppAuth, useAppHelpers} from "../Application";
import {isAuthUserData} from "./Auth";

const AppHelpers = () => {
    const appAuth = useAppAuth();
    const auth = useExternalStore(appAuth);
    const isAuth = React.useMemo(() => isAuthUserData(auth), [ auth ]);

    const appHelpers = useAppHelpers();
    const components = useExternalStore(appHelpers);

    const filteredComponents = React.useMemo(() => {
        return components.filter(data => {
            if (data.mode === 'auth' && isAuth) {
                return true;
            }
            if (data.mode === 'guest' && !isAuth) {
                return true;
            }
            return data.mode === 'both';
        }).map(data => data.component)
    }, [ components, isAuth ])

    if (auth === null) {
        return null;
    }

    return (
        <React.Fragment>
            {filteredComponents.map((Component, index) => <Component key={`helper-${index}`} />)}
        </React.Fragment>
    );
}

export default AppHelpers
