import React, {FormEvent} from "react";
import {FormProvider} from "react-hook-form";
import {UseFormReturn} from "react-hook-form/dist/types/form";
import {BaseFormOptions, FormOptionsContextProvider} from "./FormOptionsContext";
import {FormStatus, SubmitHandler} from "../../types";
import {useAppToasts} from "../../../Application";
import {ToastCreateFunctionResult} from "../../../AppHelpers";
import processFormErrors from "../../utils/processFormErrors";
import ErrorToastBody from "./ErrorToastBody";

export type FormBodyProps = BaseFormOptions & {
    handleSubmit: SubmitHandler,
    showSuccess?: boolean | string,
    methods: UseFormReturn,
    children: React.ReactNode,
}

const FormBody = ({ name, handleSubmit : submitHandler, feedbackType, showSuccess = true, methods, children, ...props } : FormBodyProps) => {

    const restoreTimeout = React.useRef<ReturnType<typeof setTimeout>>();
    const formToast = React.useRef<ToastCreateFunctionResult>();

    const [ formStatus, setFormStatus ] = React.useState<FormStatus>(FormStatus.Idle);

    const appToasts = useAppToasts();

    const clearFormStatus = React.useCallback(() => {
        setFormStatus(FormStatus.Idle);

        if (restoreTimeout.current) {
            clearTimeout(restoreTimeout.current);
            restoreTimeout.current = undefined;
        }

        if (formToast.current) {
            formToast.current.delete();
            formToast.current = undefined;
        }
    }, []);

    const handleSubmit = async (e : FormEvent) => {
        e.preventDefault();
        e.stopPropagation();

        clearFormStatus();

        setFormStatus(FormStatus.Submitting);

        try {
            await methods.handleSubmit(submitHandler)(e);
            setFormStatus(FormStatus.Success);

            if (showSuccess) {
                formToast.current = appToasts.createToast({
                    body: typeof showSuccess === 'string' ? showSuccess : 'Дані успішно збережені',
                    variant: 'success',
                })
            }
        } catch (e) {
            setFormStatus(FormStatus.Error);

            const formErrors = processFormErrors(e, methods);

            formToast.current = appToasts.createToast({
                body: <ErrorToastBody {...formErrors} />,
                variant: 'danger',
            });
        } finally {
            restoreTimeout.current = setTimeout(clearFormStatus, 5000);
        }
    }

    return (
        <FormOptionsContextProvider name={name} formStatus={formStatus} feedbackType={feedbackType}>
            <FormProvider {...props} {...methods}>
                <form onSubmit={handleSubmit}>
                    {children}
                </form>
            </FormProvider>
        </FormOptionsContextProvider>
    );
}

export default FormBody
