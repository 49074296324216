import * as React from 'react';
import FormCheckInput from "react-bootstrap/FormCheckInput";
import {useDatatableContext} from "../../DatatableContext";

type RowCheckboxProps = {
    id: number|string,
}

const RowCheckbox = ({ id }: RowCheckboxProps) => {

    const { isRowSelected, setRowSelected } = useDatatableContext();

    return (
        <FormCheckInput
            onChange={(e) => setRowSelected(id, e.target.checked)}
            checked={isRowSelected(id)}
        />
    );
}

export default RowCheckbox
