export enum MediaType {
    Image = 'image',
    Video = 'video',
    Audio = 'audio',
    File = 'file',
    Embed = 'embed',
}

export const isMediaType = (type: unknown): type is MediaType => {
    return Object.values(MediaType).includes(type as MediaType);
}

export const allTypes = [ MediaType.Image, MediaType.Video, MediaType.Audio, MediaType.File, MediaType.Embed ];

export const MediaTypeIcon : Record<MediaType, string> = {
    [MediaType.Image]: 'image-outline',
    [MediaType.Video]: 'video-outline',
    [MediaType.Audio]: 'volume-high',
    [MediaType.File]: 'file-document-outline',
    [MediaType.Embed]: 'link',
}
