import * as React from 'react';
import {useNavigation} from "react-router-dom";
import LoadingHover from "../../components/LoadingHover";

type PageLoaderProps = {
    children: React.ReactNode,
}

const PageLoader = ({ children }: PageLoaderProps) => {
    const { state } = useNavigation();

    return (
        <React.Fragment>
            {state === 'loading' && <LoadingHover />}
            {children}
        </React.Fragment>
    );
}

export default PageLoader
