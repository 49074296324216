import * as React from "react";
import classNames from "classnames";
import {useAppSearch} from "../../../Application";
import useExternalStore from "../../../hooks/useExternalStore";

const HistoryDisplay = () => {

    const search = useAppSearch();
    const { history, selectedHistoryIdx} = useExternalStore(search);

    if (history.length === 0) {
        return null
    }

    return (
        <div>
            <div className='text-muted small'>Останні запити:</div>
            {history.map((query, idx) => (
                <div key={`suggest-${idx}`} className={classNames('search-app-result', selectedHistoryIdx === idx && 'selected')} role='button'
                     onClick={() => search.setQuery(query)}>
                    {query}
                </div>
            ))}
        </div>
    )
}

export default HistoryDisplay;