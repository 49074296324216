import {ReactNode} from "react";

export type BaseTabDefinition = {
    icon?: string,
    badge?: ReactNode,
    title: string,
}

export type ScrollTabDefinition = BaseTabDefinition & {
    ref: HTMLElement,
}

export type LinkTabDefinition = BaseTabDefinition & {
    link: string,
}

export type PageTabDefinition = BaseTabDefinition & {
    id: string,
}

export type TabDefinition = ScrollTabDefinition|LinkTabDefinition|PageTabDefinition;

export type Tab = TabDefinition & {
    handleClick: () => void,
    key: string,
}

export type Tabs = Tab[];

export function isScrollTab(obj : any) : obj is ScrollTabDefinition {
    return typeof obj !== 'undefined' && obj.hasOwnProperty('ref') && obj.ref instanceof HTMLElement;
}

export function isLinkTab(obj : any) : obj is LinkTabDefinition {
    return typeof obj !== 'undefined' && obj.hasOwnProperty('link') && typeof obj.link === 'string';
}

export function isPageTab(obj : any) : obj is PageTabDefinition {
    return typeof obj !== 'undefined' && obj.hasOwnProperty('id') && typeof obj.id === 'string';
}
