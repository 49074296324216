import * as React from 'react';
import classNames from "classnames";
import {Link} from "react-router-dom";
import {useAppNavigation, useBaseAppConfig} from "../../../../Application";
import {LogoComponent} from "../../../../types/LogoProps";
import DefaultLogo from "../../DefaultLogo";
import DefaultLogoSmall from "../../DefaultLogoSmall";

type SideMenuWrapperProps = {
    isOpen: boolean,
    children: React.ReactNode,
}

const SideMenuWrapper = ({ isOpen, children }: SideMenuWrapperProps) => {

    const appNavigation = useAppNavigation();

    const { dashboardLink, logoComponent, logoComponentSmall } = useBaseAppConfig();

    const Logo : LogoComponent = React.useMemo<LogoComponent>(() => logoComponent ?? DefaultLogo, [])
    const LogoSmall : LogoComponent = React.useMemo<LogoComponent>(() => logoComponentSmall ?? DefaultLogoSmall, [])

    const handleBackdropClick = React.useCallback(() => {
        appNavigation.MainNavigation.toggle(false);
    }, []);

    return (
        <>
            <div className={classNames('leftside-menu-backdrop', { 'open' : isOpen })} onClick={handleBackdropClick}></div>

            <div className={classNames('leftside-menu', { 'open' : isOpen })}>
                <div className='h-100' id="leftside-menu-container">

                    <Link to={dashboardLink ?? '/'} className="logo text-center logo-dark">
                        <span className="logo-lg">
                            <Logo height={40} fill='var(--ct-link-color)' />
                        </span>
                        <span className="logo-sm">
                            <LogoSmall height={25} fill='var(--ct-link-color)' />
                        </span>
                    </Link>

                    <Link to={dashboardLink ?? '/'} className="logo text-center logo-light">
                        <span className="logo-lg">
                            <Logo height={40} />
                        </span>
                        <span className="logo-sm">
                            <LogoSmall height={25} />
                        </span>
                    </Link>

                    {children}

                    <div className="clearfix" />

                </div>
            </div>
        </>
    );
}

export default SideMenuWrapper
