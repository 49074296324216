import {FolderID, FolderModalState, MediaFolderFormData, MediaFolderInline} from "../types/MediaFolder";
import MediaLibraryService from "./MediaLibraryService";
import API, {getApiErrorMessage} from "@ova-studio/api-helper";
import {AbstractDataStore, SimpleCallback} from "@ova-studio/react-hyper-admin";

type FolderManagerData = {
    folders: MediaFolderInline[]|null,
    selectedFolder: FolderID|null,
    error?: string,
}

export default class FolderManager extends AbstractDataStore<FolderManagerData> {

    public static readonly CONTEXT_FOLDER = '**context-folder**';

    private readonly _service: MediaLibraryService;

    private _folders: MediaFolderInline[]|null = null;
    private _selectedFolder: FolderID|null = null;
    private _error: string|undefined = undefined;

    private _folderChangeListeners: (() => void)[] = [];

    constructor(service: MediaLibraryService) {
        super();
        this._service = service;
    }

    public get selectedFolder() : FolderID|null {
        return this._selectedFolder;
    }

    public get realSelectedFolder() : FolderID|null {
        const folder = this.selectedFolder;

        if (folder === FolderManager.CONTEXT_FOLDER) {
            return null;
        }

        return folder;
    }

    public set selectedFolder(folder: FolderID|null) {
        this._selectedFolder = folder;
        this._callListeners();
        this._folderChangeListeners.forEach(l => l());
    }

    public onFolderChange(listener: SimpleCallback) : SimpleCallback {
        this._folderChangeListeners.push(listener);

        return () => {
            this._folderChangeListeners = this._folderChangeListeners.filter(l => l !== listener);
        }
    }

    private async _loadFolders() {
        try {
            const data = await API.getData<MediaFolderInline[]>(this._service.getEndpoint('folder'));

            const rootNode : MediaFolderInline = {
                id: 0,
                parent: null,
                name: '/',
                children: data.filter(f => f.parent === null).map(f => f.id),
            };

            this._folders = [
                rootNode,
                ...(data.map(i => ({ ...i, parent: i.parent ? i.parent : 0 }))),
            ];
        } catch (e) {
            console.error(e);
            this._error = getApiErrorMessage(e);
            this._folders = null;
        }

        this._callListeners();
    }

    public async init() {
        if (this._folders !== null) return;
        await this._loadFolders();
    }

    private get _editModalState() : FolderModalState {
        if (!this._service.states) {
            throw new Error('Modals states not set');
        }

        return this._service.states.folderEditModal;
    }

    public async openFolderEdit(id: FolderID) {
        this._editModalState.setData(null);
        this._editModalState.open();

        await this.init();

        try {
            const data = await API.getData<MediaFolderFormData>(this._service.getEndpoint(`folder/${id}`), { type: 'edit' });
            this._editModalState.setData(data);
        } catch (e) {
            this._editModalState.close();
            this._service.app.toasts.createToast({
                title: 'Помилка завантаження даних',
                body: getApiErrorMessage(e),
                variant: 'danger',
            });
        }
    }

    public openFolderCreate(parent: FolderID|null) {
        this._editModalState.setData({
            name: '',
            parent: parent,
        });
        this._editModalState.open();
    }

    public async handleSubmit(data: Record<string, any>) : Promise<void> {
        if (data.id) {
            await API.put(this._service.getEndpoint(`folder/${data.id}`), data);
        } else {
            await API.post(this._service.getEndpoint('folder'), data);
        }

        await this._loadFolders();
        this._editModalState.close();
    }

    public async deleteFolder(id: FolderID) : Promise<void> {
        await API.delete(this._service.getEndpoint(`folder/${id}`));

        await this._loadFolders();

        if (this._selectedFolder === id) {
            this.selectedFolder = null;
        }
    }

    public openFolderDelete(id: FolderID, name: string) {
        if (!this._service.states) {
            throw new Error('Modals states not set');
        }

        this._service.states.deleteModal.setData({
            text: `Ви впевнені, що хочете видалити папку "${name}"? Це не призведе до видалення файлів, які в ній знаходяться.`,
            onConfirm: () => this.deleteFolder(id),
        });

        this._service.states.deleteModal.open();
    }

    private _data: FolderManagerData = {
        folders: null,
        selectedFolder: null,
    }

    protected _callListeners() {
        this._data = {
            folders: this._folders,
            selectedFolder: this._selectedFolder,
            error: this._error,
        }

        super._callListeners();
    }

    getData(): FolderManagerData {
        return this._data;
    }
}
