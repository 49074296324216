import {Media} from "./types/Media";
import {useEffect, useMemo, useState} from "react";
import {useMediaLibraryService} from "./services/MediaLibraryService";

export function useMedia(id: number) : Promise<Media>;
export function useMedia(id: number[]) : Promise<Media[]>;
export function useMedia(id: null) : Promise<null>;

export function useMedia(id: number | number[] | null) : Promise<Media | Media[] | null> {
    const service = useMediaLibraryService();

    return useMemo(() => {
        if (!id) {
            return Promise.resolve(null);
        }

        if (Array.isArray(id)) {
            return service.loadMediaList(id);
        }

        return service.loadMedia(id);
    }, [ id ]);
}

export function useMediaData(id: number|null) : Media|null|undefined {

    const service = useMediaLibraryService();

    const [ media, setMedia ] = useState<Media|null>();

    useEffect(() => {
        setMedia(undefined);

        if (!id) {
            setMedia(null);
            return;
        }

        service.loadMedia(id)
            .then((media) => setMedia(media))
            .catch((e) => console.error(e));
    }, [ id ]);

    return media;

}
