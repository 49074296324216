import {
    RouteWithLoader,
    RouteWithPath,
    TitlingRouteProps
} from "./types";
import {RouteObject} from "react-router-dom";
import prepareHandle from "./helpers/prepareHandle";
import prepareLoader from "./helpers/prepareLoader";
import Datatable, {RenderConfigData} from "../Datatable";
import React, {FC, ReactNode} from "react";
import {LazyLoadedUserRenderConfig} from "../Datatable/types/RenderConfig";

export type DatatableRouteOptions = TitlingRouteProps & RouteWithLoader & RouteWithPath & {
    renderConfig?: RenderConfigData|LazyLoadedUserRenderConfig,
    wrapper?: FC<{ children: ReactNode }>,
};

export default function makeDatatableRoute(options : DatatableRouteOptions) : RouteObject {
    const { name, title, titleLinks, docsLink, titleEnd, breadcrumb, loader, ...globalOptions } = options

    const element = (() => {
        if (options.wrapper) {
            const Wrapper = options.wrapper;
            return (
                <Wrapper>
                    <Datatable renderConfig={options.renderConfig} />
                </Wrapper>
            )
        }

        return <Datatable renderConfig={options.renderConfig} />;
    })();

    return {
        ...globalOptions,
        loader: prepareLoader(loader),
        handle: prepareHandle({ name, title, titleLinks, docsLink, titleEnd, breadcrumb }),
        element: element,
    }
}
