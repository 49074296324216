import * as React from 'react';
import BouncingLoader from "../../../../components/BouncingLoader";
import FlexCentered from "../../../../components/FlexCentered";
import {NavFallbackProps} from "../NavFallbackProps";

const BottomMenuFallback = ({ state }: NavFallbackProps) => {
    return (
        <div className='bottom-menu'>
            <FlexCentered>
                {state === 'error' ? (
                    <React.Fragment>
                        <i className='mdi mdi-alert fs-1 lh-1 text-danger' />
                        <div className='font-16 text-danger'>Помилка завантаження</div>
                    </React.Fragment>
                ) : (
                    <BouncingLoader />
                )}
            </FlexCentered>
        </div>
    );
}

export default BottomMenuFallback
