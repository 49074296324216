export function getLocalStorageData<T>(key: string) : T|null {
    const data = localStorage.getItem(key);

    if (data) {
        try {
            return JSON.parse(data) as T;
        } catch {}
    }

    return null;
}

export function setLocalStorageData<T>(key: string, data : T) : void {
    localStorage.setItem(key, JSON.stringify(data));
}
