import * as React from 'react';
import FullTable from "./FullTable";
import MobileTable from "./MobileTable";
import {useDatatableContext} from "../DatatableContext";
import TableEmpty from "./TableEmpty";

const desktopWidth : number = 1450;

const TableBody = () => {

    const { settings: { disableMobileLayout } } = useDatatableContext();

    const [isDesktop, setDesktop] = React.useState(() => window.innerWidth > desktopWidth);

    const updateMedia = () => {
        setDesktop(window.innerWidth > desktopWidth);
    };

    React.useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    const { isResults } = useDatatableContext();

    if (!isResults) {
        return <TableEmpty />
    }

    if (disableMobileLayout) {
        return <FullTable />
    }

    return isDesktop ? <FullTable /> : <MobileTable />;
}

export default TableBody
