import * as React from 'react';
import {useMediaLibraryData, useMediaLibraryDataStore} from "../MediaLibraryContext";
import {Button} from "react-bootstrap";

type SubmitButtonsProps = {
    wrapper: React.FunctionComponent<{ children: React.ReactNode }>,
}

const SubmitButtons = ({ wrapper: Wrapper = React.Fragment } : SubmitButtonsProps) => {

    const store = useMediaLibraryDataStore();
    const { selectedMedia } = useMediaLibraryData();


    if (!store.allowSelect) {
        return null;
    }

    return (
        <Wrapper>
            <span className='me-1 small text-muted'>{selectedMedia.length > 0 ? `Вибрано ${selectedMedia.length} медіа` : 'Нічого не вибрано'}</span>
            <Button disabled={selectedMedia.length === 0} onClick={store.handleSubmit.bind(store)}>
                Вибрати
            </Button>
        </Wrapper>
    );
}

export default SubmitButtons
