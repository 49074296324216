import * as React from "react";
import {Actions, Auth, Navigation, Toasts, Services, PageContext, Search, Websocket} from "../AppHelpers";
import {AppOptions} from "./types/AppOptions";
import HelpersRegistry from "../AppHelpers/HelpersRegistry";
import {Router} from "../Router";
import * as Sentry from "@sentry/react";
import {createRoutesFromChildren, matchRoutes, useLocation, useNavigationType} from "react-router-dom";
import {AppConfig} from "./types/AppConfig";

export default class App {
    public readonly config: AppConfig;

    public readonly actions: Actions;
    public readonly auth: Auth;
    public readonly navigation: Navigation;
    public readonly toasts: Toasts;
    public readonly helpers: HelpersRegistry;
    public readonly router: Router;
    public readonly search: Search;
    public readonly services: Services;
    public readonly pageContext: PageContext;
    public readonly websocket: Websocket;

    constructor(options: AppOptions) {
        this.config = options.config;
        this._initSentry();

        this.actions = new Actions(this, this.config.actions);
        this.auth = new Auth(this.config.auth);
        this.navigation = new Navigation(this, this.config.navigation);
        this.toasts = new Toasts();
        this.helpers = new HelpersRegistry();
        this.router = new Router(this, options?.appRoutes ?? [], options?.guestRoutes ?? []);
        this.search = new Search(this, this.config.search);
        this.services = new Services();
        this.pageContext = new PageContext();
        this.websocket = new Websocket(this);

        if (options?.onAppReady) {
            options.onAppReady(this);
        }
    }

    private _initSentry() {
        if (!this.config.base.sentryDsn) {
            return;
        }

        Sentry.init({
            dsn: this.config.base.sentryDsn,
            release: this.config.base.appRelease,
            integrations: [
                Sentry.reactRouterV6BrowserTracingIntegration({
                    useEffect: React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                }),
            ],
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });
    }
}
