import {useSearchParams as useRouterSearchParams} from "react-router-dom";
import QueryString from "qs";
import {useMemo} from "react";
import {DatatableQueryParams, DatatableQueryString} from "../types/DatatableQueryString";

function parseSearchParams(searchParams : URLSearchParams) {
    return QueryString.parse(searchParams.toString());
}

function stringifySearchParams(searchParams : object) {
    return QueryString.stringify(searchParams);
}

export default function useSearchParams() {
    const [routerSearchParams, setRouterSearchParams] = useRouterSearchParams();

    const searchString = routerSearchParams.toString();

    const searchParams = useMemo<DatatableQueryString>(() => parseSearchParams(routerSearchParams), [ searchString ]);

    const getSearchParams = <T extends DatatableQueryParams>(key : T) : DatatableQueryString[T] => {
        return searchParams[key];
    }

    const setSearchParams = (data? : DatatableQueryString) => {

        if (!data) {
            setRouterSearchParams(undefined);
            return;
        }

        setRouterSearchParams(currentSearchParams => stringifySearchParams({
            ...parseSearchParams(currentSearchParams),
            ...data,
        }))
    }

    return { searchString, searchParams, getSearchParams, setSearchParams }
}
