import TopbarTabs from "./TopbarTabs";
import TopbarRow from "./TopbarRow";
import AbstractDataStore from "../../abstracts/AbstractDataStore";

export type TopbarData = {
    isVisible: boolean,
}

export default class Topbar extends AbstractDataStore<TopbarData> {

    public readonly Tabs: TopbarTabs;
    public readonly Row: TopbarRow;

    private _prevScroll: number = 0;

    private _data : TopbarData = {
        isVisible: true,
    }

    constructor() {
        super();

        this.Tabs = new TopbarTabs();
        this.Row = new TopbarRow();

        window.addEventListener('scroll', () => this._handleScroll());
    }

    private _handleScroll() {
        this.Tabs.handleScroll();

        if (this.Tabs.isScrollLocked()) {
            return;
        }

        const currentScroll = window.scrollY;
        const isVisible = this._prevScroll > currentScroll || currentScroll < 10;

        this._prevScroll = currentScroll;

        if (isVisible !== this._data.isVisible) {
            this._data = {
                ...this._data,
                isVisible,
            }
            this._callListeners();
        }
    }

    getData(): TopbarData {
        return this._data;
    }
}
