import * as React from 'react';
import useBreadcrumbs from "../../../hooks/useBreadcrumbs";
import classNames from "classnames";
import {Link} from "react-router-dom";

const Breadcrumbs = () => {

    const breadcrumbs = useBreadcrumbs();

    if (!breadcrumbs) {
        return null;
    }

    return (
        <ol className="breadcrumb m-0 d-none d-lg-flex ps-2">
            {breadcrumbs.map((breadcrumb, idx) => {
                const active = idx === (breadcrumbs.length - 1);

                return (
                    <li key={`breadcrumb-${idx}`} className={classNames("breadcrumb-item", { 'active' : active })}>
                        {active ? breadcrumb.text : (
                            <Link to={breadcrumb.to} className={breadcrumb.className}>
                                {breadcrumb.text}
                            </Link>
                        )}
                    </li>
                )
            })}
        </ol>
    );
}

export default Breadcrumbs
