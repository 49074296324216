import * as React from "react";
import {MediaType} from "../../../types/MediaType";
import {useModal} from "@ova-studio/react-hyper-admin";
import MediaLibraryModal from "../../../MediaLibraryModal";
import {MediaSelectAction} from "../../../types/MediaSelectAction";
import {useMediaData} from "../../../useMedia";
import FormMediaSelectContext, {useFormMediaSelectContext} from "./FormMediaSelectContext";
import {Button, ButtonProps} from "react-bootstrap";
import noImage from "./noImage";

type FormMediaSelectBodyMediaPayload = {
    src: string,
    name: string,
}

type FormMediaSelectBodyMediaProps = {
    children: (data: FormMediaSelectBodyMediaPayload|undefined) => React.ReactNode,
}

const FormMediaSelectBodyMedia = ({ children } : FormMediaSelectBodyMediaProps) => {
    const { media } = useFormMediaSelectContext();

    const payload = React.useMemo<FormMediaSelectBodyMediaPayload|undefined>(() => {
        if (media === undefined) {
            return undefined;
        }

        if (media === null) {
            return {
                src: noImage,
                name: 'не вибрано',
            }
        }

        return {
            src: media.image?.url ?? noImage,
            name: media.filename ?? 'Немає зображення',
        }
    }, [media])

    return (
        <React.Fragment>
            {children(payload)}
        </React.Fragment>
    )
}

type FormMediaSelectBodyButtonProps = Omit<ButtonProps, 'onClick'>;

const FormMediaSelectBodyButton = ({ variant = 'secondary', size = 'sm', children, ...props }: FormMediaSelectBodyButtonProps) => {
    const { openModal } = useFormMediaSelectContext();

    return (
        <Button variant={variant} size={size} {...props} onClick={openModal}>
            {children ?? <i className='mdi mdi-pencil font-16'/>}
        </Button>
    )
}

type FormMediaSelectWrapperBodyProps = {
    mediaId: number | null,
    onChange: (media: number | string | null) => void,
    types?: MediaType[],
    children: React.ReactNode,
}

const FormMediaSelectBody = ({ mediaId, onChange, types, children }: FormMediaSelectWrapperBodyProps) => {
    const mediaSelectModalState = useModal();

    const media = useMediaData(mediaId);

    return (
        <React.Fragment>
            <MediaLibraryModal
                state={mediaSelectModalState}
                handleSubmit={data => onChange(data[0].id)}
                closeOnSubmit={true}
                selectAction={MediaSelectAction.SelectSingle}
                types={types}
            />

            <FormMediaSelectContext.Provider value={{ media, mediaId, openModal: mediaSelectModalState.open }}>
                {children}
            </FormMediaSelectContext.Provider>
        </React.Fragment>
    )
}

export default Object.assign(FormMediaSelectBody, {
    Media: FormMediaSelectBodyMedia,
    Button: FormMediaSelectBodyButton,
});
