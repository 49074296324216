import * as React from 'react';
import {useAppAuth, useAppRouter} from "../Application";
import Preloader from "../components/Preloader";
import {RouterProvider} from "react-router-dom";
import useExternalStore from "../hooks/useExternalStore";
import {isAuthUserData} from "../AppHelpers";

const AppRouter = () => {
    const appAuth = useAppAuth();
    const auth = useExternalStore(appAuth);
    const isAuth = React.useMemo(() => isAuthUserData(auth), [ auth ]);

    const appRouter = useAppRouter();

    if (auth === null) {
        return <Preloader />
    }

    return (
        <RouterProvider
            router={appRouter.getRouter(isAuth)}
            fallbackElement={<Preloader />}
        />
    );
}

export default AppRouter
