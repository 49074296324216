import * as React from "react";
import {BasePaginationData} from "../../../types/DatatableData";
import {Pagination as BsPagination} from "react-bootstrap";
import usePaginationLinks from "../../../hooks/usePaginationLinks";
import useSearchParams from "../../../hooks/useSearchParams";

type BasePaginationDisplayProps = {
    data: BasePaginationData,
}

const BasePaginationDisplay = ({ data: { currentPage, perPage, total } }: BasePaginationDisplayProps) => {

    const links = usePaginationLinks({ currentPage, perPage, total });

    let { setSearchParams } = useSearchParams();

    const handlePageSelect = (page : number) => {
        setSearchParams({ page })
    };

    return (
        <BsPagination className='mb-0 table-pagination' size='sm'>
            {links.map((link, index) => (
                link.type === 'item'
                    ? (
                        <BsPagination.Item
                            key={'p-' + index}
                            active={link.current}
                            onClick={() => handlePageSelect(link.page)}
                        >
                            {link.page}
                        </BsPagination.Item>
                    )
                    : (
                        <BsPagination.Ellipsis key={'p-' + index} disabled />
                    )
            ))}
        </BsPagination>
    )
}

export default BasePaginationDisplay;