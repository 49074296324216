import * as React from 'react';
import {DatatableColumn} from "../../../../types/DatatableData";
import SortableColHeaderCell from "./SortableColHeaderCell";
import {useDatatableContext} from "../../../../DatatableContext";

type ColHeaderCellProps = {
    data: DatatableColumn,
}

const ColHeaderCell = ({ data }: ColHeaderCellProps) => {

    const { config: { tableMode } } = useDatatableContext();

    if (data.isSortable && tableMode !== 'simple') {
        return <SortableColHeaderCell data={data} />
    }

    return (
        <th>
            {data.title}
        </th>
    );
}

export default ColHeaderCell
