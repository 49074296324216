import {CreateAndUpdateTitlingProps} from "./types";
import {isCreateAndUpdateTitlingPropsSimple} from "./guards";
import {TitlingRouteProps} from "../types";

export default function makeTitlesForSingleForm(props : CreateAndUpdateTitlingProps, action : 'create'|'update') : TitlingRouteProps {

    if (isCreateAndUpdateTitlingPropsSimple(props)) {
        return { name: action === 'create' ? props.createName : props.updateName }
    }

    return action === 'create' ? props.create : props.update;

}
