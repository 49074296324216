import * as React from 'react';
import {Outlet} from "react-router-dom";
import Topbar from "./Topbar";
import Footer from "./Footer";
import MainNav from "./MainNav";
import NavigationHelper from "../../AppHelpers/Navigation/NavigationHelper";

const AppLayout = () => {

    return (
        <div className='wrapper'>
            <MainNav />

            <div className="content-page">

                <div className="content">
                    <Topbar />
                    <Outlet />
                </div>

                <Footer />
            </div>

            <NavigationHelper />
        </div>
    )

}

export default AppLayout
