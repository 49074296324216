import * as React from 'react';
import useExternalStore from "../../../hooks/useExternalStore";
import {Nav, NavProps} from "react-bootstrap";
import useBreakpoint, {Breakpoint} from "../../../hooks/useBreakpoint";
import {useAppNavigation} from "../../../Application";

export type TabsBreakpointDisplayProps = {
    justify?: NavProps['justify'],
    variant?: NavProps['variant'],
    breakpoint?: Breakpoint,
}

const TabsBreakpointDisplay = ({ breakpoint = 'md', ...props }: TabsBreakpointDisplayProps) => {

    const appNavigation = useAppNavigation();

    const { tabs, activeTab } = useExternalStore(appNavigation.Topbar.Tabs);

    const isDesktop = useBreakpoint(breakpoint);

    if (!isDesktop) {
        return null;
    }

    return (
        <Nav {...props} activeKey={activeTab}>
            {tabs.map(tab => (
                <Nav.Item key={`tab-${tab.key}`}>
                    <Nav.Link eventKey={tab.key} onClick={tab.handleClick}>
                        {tab.icon && <i className={`mdi mdi-${tab.icon} me-1`} />}
                        {tab.title}
                        {tab.badge !== undefined && <span className='ms-1 text-muted'>{tab.badge}</span>}
                    </Nav.Link>
                </Nav.Item>
            ))}
        </Nav>
    );
}

export default TabsBreakpointDisplay
