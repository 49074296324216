import useItemDataStore, { UseItemDataStoreReturn } from "./useItemDataStore";
import {BasicData} from "../../types";
import useLoaderData from "../useLoaderData";

export type UseLoaderItemDataStoreReturn<D extends BasicData> = UseItemDataStoreReturn<D>;

export const useLoaderItemDataStore = <D extends BasicData>(dataKey: string) : UseLoaderItemDataStoreReturn<D> => {
    const initialData = useLoaderData<D>(dataKey);
    return useItemDataStore(initialData);
}

export default useLoaderItemDataStore;