import * as React from "react";
import {Form} from "@ova-studio/react-hyper-admin";
import {Controller} from "react-hook-form";
import {MediaType} from "../../../types/MediaType";
import FormMediaSelectBody from "./FormMediaSelectBody";

export type FormMediaSelectWrapperProps = {
    name: string,
    types?: MediaType[],
    children: React.ReactNode,
}

const FormMediaSelectWrapper = ({ name, types, children }: FormMediaSelectWrapperProps) => {
    const { control } = Form.useFormContext();

    return (
        <Controller
            control={control}
            name={name}
            render={({field: {value, onChange}}) => (
                <React.Fragment>
                    <FormMediaSelectBody
                        mediaId={value}
                        onChange={onChange}
                        types={types}
                        children={children}
                    />
                </React.Fragment>
            )}
        />
    )
}

export default FormMediaSelectWrapper;
