import * as React from 'react';
import ErrorPageBody from "./ErrorPageBody";

const Page404 = () => {
    return (
        <ErrorPageBody
            title='404'
            info='Сторінку не знайдено'
        />
    );
}

export default Page404
