import * as React from "react";
import FormMediaSelectWrapper, {FormMediaSelectWrapperProps} from "./FormMediaSelectWrapper";
import {Image} from "react-bootstrap";
import {BouncingLoader, Form, FormGroupContainerBaseProps} from "@ova-studio/react-hyper-admin";
import FormMediaSelectBody from "./FormMediaSelectBody";
import {MediaType} from "../../../types/MediaType";

type FormMediaSelectInlineProps = FormGroupContainerBaseProps & Omit<FormMediaSelectWrapperProps, 'children'> & {
    types?: MediaType[],
    maxWidth?: number | string,
}

const FormMediaSelectInline = ({ name, types, maxWidth, ...props }: FormMediaSelectInlineProps) => {
    return (
        <FormMediaSelectWrapper name={name} types={types}>
            <Form.GroupContainer name={name} {...props}>
                {({ isError, errorMessage }) => (
                    <div className='d-flex align-items-start gap-2'>
                        <div className='flex-grow-1'>
                            <FormMediaSelectBody.Media>
                                {(data) => data ? (
                                    <React.Fragment>
                                        <Image
                                            thumbnail
                                            className={isError ? 'border border-danger' : ''}
                                            src={data.src}
                                            alt={data.name}
                                            style={{maxWidth}}
                                        />
                                        {isError && <div className='small text-danger'>{errorMessage}</div>}
                                    </React.Fragment>
                                ) : <BouncingLoader/>}
                            </FormMediaSelectBody.Media>
                        </div>
                        <FormMediaSelectBody.Button/>
                    </div>
                )}
            </Form.GroupContainer>
        </FormMediaSelectWrapper>
    )
}

export default FormMediaSelectInline;
