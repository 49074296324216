import useScreenWidth from "./useScreenWidth";

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

const breakpoints : Record<Breakpoint, number> = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
}

export default function useBreakpoint(breakpoint: Breakpoint, up: boolean = true) : boolean {
    const width = useScreenWidth();

    if (up) {
        return width >= breakpoints[breakpoint];
    } else {
        return width < breakpoints[breakpoint];
    }
}
