import * as React from 'react';
import {
    isNavigateAction,
    isSpecialAction,
    SpecialActions, TableRowAction,
} from "../../../types/TableRowActions";
import RowNavigateActionButton from "./RowNavigateActionButton";
import RowDeleteActionButton from "./RowDeleteActionButton";

type RowActionButtonProps = {
    className?: string,
    action: TableRowAction,
}

const RowActionButton = ({ className, action }: RowActionButtonProps) => {

    if (isNavigateAction(action)) {
        return <RowNavigateActionButton action={action} className={className} />
    }

    if (isSpecialAction(action, SpecialActions.Delete)) {
        return <RowDeleteActionButton action={action} className={className} />
    }

    return null;
}

export default RowActionButton
