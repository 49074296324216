import {getLocalStorageData} from "./helpers/localStorage";

export type LayoutColor = "dark" | "light";
export type LayoutMode = "fluid" | "boxed";
export type LeftbarTheme = "default" | "light" | "dark";
export type LeftbarCompactMode = "fixed" | "condensed" | "scrollable";

type ArrayOneOrMore<T> = [T, ...T[]];

export type CurrentLayoutConfig = {
    layoutColor: LayoutColor,
    layoutMode: LayoutMode,
    leftbarTheme: LeftbarTheme,
    leftbarCompactMode: LeftbarCompactMode,
}

export type UserLayoutConfig = Partial<CurrentLayoutConfig>;

type LayoutParam = keyof CurrentLayoutConfig;

export type LayoutConfig = {
    [key in LayoutParam]: ArrayOneOrMore<CurrentLayoutConfig[key]>;
}

type LayoutConfigAttributeMap = {
    [key in LayoutParam]: string;
};

class AppLayout {

    private static _attributeMap : LayoutConfigAttributeMap = {
        layoutColor: 'data-layout-color',
        layoutMode: 'data-layout-mode',
        leftbarTheme: 'data-leftbar-theme',
        leftbarCompactMode: 'data-leftbar-compact-mode',
    }

    private static _layoutConfigDefaults : LayoutConfig = {
        layoutColor: ['dark'],
        layoutMode: ['fluid'],
        leftbarTheme: ['dark'],
        leftbarCompactMode: ['fixed'],
    }

    private readonly _layoutConfig : LayoutConfig;

    private _layoutUserSettings : Partial<CurrentLayoutConfig> = {};

    private constructor(config: Partial<LayoutConfig>) {
        this._layoutConfig = {
            ...AppLayout._layoutConfigDefaults,
            ...config,
        }

        this.loadUserSettings();

        this.updateAttributes();
    }

    private isAllowedByConfig(key: LayoutParam, value : string|undefined) : boolean {
        if (!value) {
            return false;
        }

        const param = this._layoutConfig[key] as ArrayOneOrMore<string>;

        return param.includes(value);
    }

    public setUserSettings(settings: UserLayoutConfig, silent : boolean = false) : void {
        let updateSettings : boolean = !silent;

        for (const _key in settings) {
            const key = _key as LayoutParam;
            const value = settings[key];

            if (!this.isAllowedByConfig(key, value)) {
                console.warn(`Layout param '${key}' = '${value}' in not allowed in config`)
                delete settings[key];
                updateSettings = true;
            }
        }

        this._layoutUserSettings = {
            ...this._layoutUserSettings,
            ...settings,
        }

        if (!silent) {
            this.updateAttributes();
        }

        if (updateSettings) {
            this.writeUserSettings();
        }
    }

    get<T extends LayoutParam>(key: T) : LayoutConfig[T][0] {
        return this._layoutUserSettings[key] ?? this._layoutConfig[key][0];
    }

    private updateAttributes() {
        Object.entries<string>(AppLayout._attributeMap)
            .forEach(([key, attr]) => {
                document.body.setAttribute(attr, this.get(key as LayoutParam))
            })
    }

    private loadUserSettings() : void {
        const settings = getLocalStorageData<UserLayoutConfig>('ova-admin-app:layout-settings');
        if (settings) {
            this.setUserSettings(settings, true);
        }
    }

    private writeUserSettings() : void {
        localStorage.setItem('ova-admin-app:layout-settings', JSON.stringify(this._layoutUserSettings))
    }

    // noinspection JSUnusedGlobalSymbols
    public clearUserSettings() : void {
        localStorage.removeItem('ova-admin-app:layout-settings')
    }

    private static instance : AppLayout;

    public static singleton(config?: Partial<LayoutConfig>) : AppLayout {
        if (typeof AppLayout.instance === 'undefined') {
            AppLayout.instance = new AppLayout(config ?? {});
        } else if (typeof config !== 'undefined') {
            console.warn('Layout is already configured');
        }

        return AppLayout.instance;
    }
}

export default AppLayout
