import * as React from "react";
import {BouncingLoader, Form, Modal, UseModalReturn} from "@ova-studio/react-hyper-admin";
import {Button} from "react-bootstrap";

export type DeleteModalData = {
    text: string,
    onConfirm: () => Promise<void>,
}

export type DeleteModalState = UseModalReturn<DeleteModalData>;

type DeleteModalProps = {
    state: DeleteModalState,
}

const DeleteModal = ({ state }: DeleteModalProps) => {
    const handleSubmit = async () => {
        await state.data?.onConfirm();
        state.close();
    }

    return (
        <Modal state={state} title='Підтверждення дії' titleBg='danger' className='modal-nested' backdropClassName='modal-backdrop-nested'>
            {state.data ? (
                <Form.Callback handleSubmit={handleSubmit} showSuccess={false}>
                    <Modal.Body>
                        <div>{state.data?.text}</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Form.Submit variant='danger' enable={true}>Підтвердити</Form.Submit>
                        <Button variant='secondary' onClick={state.close}>Скасувати</Button>
                    </Modal.Footer>
                </Form.Callback>
            ) : (
                <BouncingLoader />
            )}
        </Modal>
    )
}

export default DeleteModal;
