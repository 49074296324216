import * as React from "react";
import PictureRender from "../PictureRender";
import {MediaDisplayProps} from "./MediaDisplayProps";

const MediaEmbedYoutubeDisplay = ({ data, caption, lazy }: MediaDisplayProps) => {
    if (!data.image) {
        return null;
    }

    return (
        <figure className='ova-engine-video ova-engine-video-youtube' data-video-youtube={data.meta_data?.videoId ?? ''} data-video-youtube-start={data.meta_data?.startTime?.toString() ?? '0'}>
            <PictureRender data={data.image} alt={caption} lazy={lazy} className='ova-engine-video-placeholder' />
            {caption && <figcaption>{caption}</figcaption>}
        </figure>
    )
}

export default MediaEmbedYoutubeDisplay;
