import * as React from "react";
import {CursorPaginationData} from "../../../types/DatatableData";
import {Pagination as BsPagination} from "react-bootstrap";
import useSearchParams from "../../../hooks/useSearchParams";

type CursorPaginationItemProps = {
    onClick: () => void,
    disabled: boolean,
    type: 'prev' | 'next',
}

const CursorPaginationItem = ({ disabled, onClick, type } : CursorPaginationItemProps) => (
    <BsPagination.Item onClick={onClick} disabled={disabled} >
        <span className='d-flex align-items-center user-select-none'>
            {type === 'prev' ? (
                <React.Fragment>
                    <i className='mdi mdi-chevron-left' />
                    <span className='ms-1 d-none d-md-inline-block small'>попередня</span>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <span className='me-1 d-none d-md-inline-block small'>наступна</span>
                    <i className='mdi mdi-chevron-right' />
                </React.Fragment>
            )}
        </span>
    </BsPagination.Item>
)

type CursorPaginationDisplayProps = {
    data: CursorPaginationData
}

const CursorPaginationDisplay = ({ data: { name, prev, next } }: CursorPaginationDisplayProps) => {

    let { setSearchParams } = useSearchParams();

    const handlePageSelect = (cursor : string|null) => {
        setSearchParams({ [name]: cursor })
    }

    return (
        <BsPagination className='mb-0 table-pagination' size='sm'>
            <CursorPaginationItem onClick={() => handlePageSelect(prev)} disabled={prev === null} type='prev' />
            <CursorPaginationItem onClick={() => handlePageSelect(next)} disabled={next === null} type='next' />
        </BsPagination>
    )
}

export default CursorPaginationDisplay;