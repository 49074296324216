import * as React from 'react';
import {InputGroup} from 'react-bootstrap'
import {FormErrorReturn} from "../hooks/useFormError";
import {UseFormReturn} from "react-hook-form/dist/types";
import FormGroupContainer, {FormGroupContainerBaseProps} from "./FormGroupContainer";
import ErrorFeedback from "./ErrorFeedback";

export type InputGroupContainerChildrenParams = {
    isError: FormErrorReturn['isError'],
    controlId: string,
    register: UseFormReturn['register'],
    control: UseFormReturn['control'],
    props: any,
}

export type InputGroupContainerBaseProps = FormGroupContainerBaseProps & {
    prepend?: React.ReactNode,
    append?: React.ReactNode,
}

export type InputGroupContainerProps = InputGroupContainerBaseProps & {
    children: (params: InputGroupContainerChildrenParams) => JSX.Element
}

const InputGroupContainer = ({ name, prepend, append, children, label, text, groupClass, layout, tooltip, tooltipIcon, tooltipPlacement, ...props } : InputGroupContainerProps) => {

    return (
        <FormGroupContainer {...{ name, label, text, groupClass, layout, tooltip, tooltipIcon, tooltipPlacement }}>
            {({ isError, errorMessage, controlId, register, control }) => (
                <InputGroup hasValidation>
                    {Boolean(prepend) && (
                        <InputGroup.Text id={controlId + '.prepend'}>
                            {prepend}
                        </InputGroup.Text>
                    )}
                    {children({ isError, controlId, register, control, props })}
                    {Boolean(append) && (
                        <InputGroup.Text id={controlId + '.append'}>
                            {append}
                        </InputGroup.Text>
                    )}
                    <ErrorFeedback isError={isError} errorMessage={errorMessage} />
                </InputGroup>
            )}
        </FormGroupContainer>
    )
}

export default InputGroupContainer
