import * as React from "react";
import {Navigate, RouteObject} from "react-router-dom";
import ErrorBoundary from "../AppWrapper/InfoPages/ErrorBoundary";
import PageErrorBoundary from "../AppWrapper/AppPageLayout/PageErrorBoundary";
import {makeSimpleRoute} from "../route";
import prepareHandle from "../route/helpers/prepareHandle";

const Page404 = React.lazy(() => import('../AppWrapper/AppPageLayout/PageError/Page404'))
const AppLayout = React.lazy(() => import('../AppWrapper/AppLayout'))
const AppPageLayout = React.lazy(() => import('../AppWrapper/AppPageLayout'))

function makeGuestRoutesRedirects(routes : RouteObject[]) : RouteObject[] {
    return routes.map(route => {
        if (route.children) {
            return {
                name: 'Перенаправлення',
                path: route.path,
                element: <Navigate to='/' />,
                children: makeGuestRoutesRedirects(route.children),
            }
        }

        return {
            name: 'Перенаправлення',
            path: route.path,
            element: <Navigate to='/' />,
        }
    })
}

export default function wrapApplicationRoutes(appName: string, routes : RouteObject[], guestRoutes : RouteObject[]) : RouteObject[] {
    return [
        {
            element: <AppLayout />,
            errorElement: <ErrorBoundary />,
            children: [
                {
                    element: <AppPageLayout />,
                    errorElement: <PageErrorBoundary />,
                    handle: prepareHandle({ name: appName }),
                    children: [
                        ...routes,
                        ...makeGuestRoutesRedirects(guestRoutes),
                        makeSimpleRoute({
                            path: 'login',
                            name: 'Перенаправлення',
                            element: <Navigate to='/' />,
                        }),
                        makeSimpleRoute({
                            path: '*',
                            name: 'Сторінку не знайдено',
                            element: <Page404 />,
                        }),
                    ],

                }
            ],
        },
    ];
}
