import * as React from 'react';
import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import {Dropdown} from "react-bootstrap";
import {Link} from "react-router-dom";

const TopbarBreadcrumbs = () => {

    const breadcrumbs = useBreadcrumbs();

    if (!breadcrumbs) {
        return null;
    }

    return (
        <Dropdown className='d-block d-lg-none'>
            <Dropdown.Toggle variant='link' className='arrow-none px-1'>
                <i className='mdi mdi-chevron-triple-left font-24 lh-1' />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {breadcrumbs.map((breadcrumb, idx) => {
                    const active = idx === (breadcrumbs.length - 1);

                    return (
                        <Dropdown.Item key={`breadcrumb-${idx}`} disabled={active} as={Link} to={breadcrumb.to}>{breadcrumb.text}</Dropdown.Item>
                    )
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default TopbarBreadcrumbs
