import * as React from 'react';
import {GlobalActionButtonNavigate} from "../../../../../AppHelpers/Navigation";
import GlobalBasicButton from "./GlobalBasicButton";
import {useNavigate} from "react-router-dom";

type GlobalNavigateButtonProps = {
    className: string,
    data: GlobalActionButtonNavigate,
}

const GlobalNavigateButton = ({ className, data }: GlobalNavigateButtonProps) => {
    const navigate = useNavigate();

    return (
        <GlobalBasicButton
            className={className}
            onClick={() => navigate(data.url)}
            options={data}
        />
    );
}

export default GlobalNavigateButton
