import * as React from 'react';
import MediaListBody from "./MediaListBody";
import {useMediaLibraryDataStore} from "../MediaLibraryContext";

const MediaListWrapper = () => {

    const store = useMediaLibraryDataStore();

    const handleScroll = React.useCallback<React.UIEventHandler<HTMLDivElement>>((e) => {
        const scrollHeight = e.currentTarget.scrollHeight;
        const scrollBottom = e.currentTarget.scrollTop + e.currentTarget.clientHeight;

        if ((scrollHeight - scrollBottom) < 40) {
            store.loadMore();
        }
    }, [])

    return (
        <div className='custom-scrollbar scroll-vertical position-absolute top-0 bottom-0 start-0 end-0' onScroll={handleScroll}>
            <MediaListBody />
        </div>
    )
}

export default MediaListWrapper
