import * as React from 'react';
import {DatatableRow} from "../../../types/DatatableData";
import {ButtonGroup} from "react-bootstrap";
import {ButtonGroupProps} from "react-bootstrap/ButtonGroup";
import RowActionButton from "./RowActionButton";

export type RowActionsProps = {
    row: DatatableRow,
    size?: ButtonGroupProps['size'],
    vertical?: ButtonGroupProps['vertical'],
    className?: string,
    buttonClassName?: string,
}

const RowActions = ({ row, size, vertical, className, buttonClassName }: RowActionsProps) => {

    if (!row._actions || row._actions.length === 0) {
        return null;
    }

    return (
        <ButtonGroup size={size} vertical={vertical} className={className}>
            {row._actions.map((action, idx) => (
                <RowActionButton
                    key={`action-${idx}`}
                    action={action}
                    className={buttonClassName}
                />
            ))}
        </ButtonGroup>
    );
}

export default RowActions
