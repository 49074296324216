import * as React from "react";
import {UploadItem, UploadStatus} from "../types/UploadFile";
import {ProgressBar, Spinner} from "react-bootstrap";

export type UploadToastBodyProps = {
    data: Omit<UploadItem, 'toast'>,
}

const UploadStatusDisplay = ({ data }: UploadToastBodyProps) => {
    if (data.status === UploadStatus.Uploading) {
        if (data.progress > 99) {
            return (
                <div className='d-flex align-items-center gap-1'>
                    <Spinner animation='border' size='sm' />
                    <span>Іде обробка, зачекайте...</span>
                </div>
            )
        }

        return <ProgressBar variant='success' now={data.progress} />
    }

    if (data.status === UploadStatus.Success) {
        return <div>Успішно завантажено</div>
    }

    return <div>{data.error ?? 'Помилка завантаження'}</div>
}

const UploadToastBody = ({ data }: UploadToastBodyProps) => {
    return (
        <div>
            <div className='w-100 overflow-hidden text-truncate fw-bold mb-1 font-16'>{data.name}</div>
            <UploadStatusDisplay data={data} />
        </div>
    );
}

export default UploadToastBody;
