import {useLoaderData as useLoaderDataBase} from "react-router-dom";
import usePromise from "../../hooks/usePromise";

type LoaderData<T> = T | Promise<T>;

const useLoaderData = <T extends unknown>(dataKey: string) : T => {
    const { [dataKey]: data } = useLoaderDataBase() as Record<string, LoaderData<T>>
    return data instanceof Promise ? usePromise(data, [ dataKey ]) : data;
}

export default useLoaderData;