import { default as Form } from "./Forms/LoaderForm";
export type { LoaderFormProps } from "./Forms/LoaderForm";

import { default as DataForm } from "./Forms/DataForm";
export type { DataFormProps } from "./Forms/DataForm";

import { default as CallbackForm } from "./Forms/CallbackForm";
export type { CallbackFormProps } from "./Forms/CallbackForm";

import { default as FormBody } from "./Forms/FormBody";
export type { FormBodyProps } from "./Forms/FormBody";

import { default as FieldBlock } from "./Fields/FieldBlock";
export type { FieldBlockProps } from "./Fields/FieldBlock";

import { default as FormGroupContainer } from "./Fields/FormGroupContainer";
export type { FormGroupContainerBaseProps, FormGroupContainerProps, FormGroupContainerChildrenParams } from "./Fields/FormGroupContainer";

import { default as InputGroupContainer } from "./Fields/InputGroupContainer";
export type { InputGroupContainerBaseProps, InputGroupContainerProps, InputGroupContainerChildrenParams } from "./Fields/InputGroupContainer";

import { default as FormInput } from "./Fields/FormInput";
export type { FormInputProps } from "./Fields/FormInput";

import { default as FormCheck } from "./Fields/FormCheck";
export type { FormCheckProps } from "./Fields/FormCheck";

import { default as FormSelectBasic } from "./Fields/FormSelectBasic";
export type { FormSelectBasicProps } from "./Fields/FormSelectBasic";

import { default as FormSelect } from "./Fields/FormSelect";
export type { FormSelectProps } from "./Fields/FormSelect";

import { default as FormTypeaheadSelect } from "./Fields/FormTypeaheadSelect";
export type { FormTypeaheadSelectProps } from "./Fields/FormTypeaheadSelect";

import { default as FormDropdownSelect } from "./Fields/FormDropdownSelect";
export type { FormDropdownSelectProps, RenderOptionFunc } from "./Fields/FormDropdownSelect";

import { default as FormSelectAwait } from "./Fields/FormSelectAwait";
export type { FormSelectAwaitProps } from "./Fields/FormSelectAwait";

import { default as FormSelectAwaitLoader } from "./Fields/FormSelectAwaitLoader";
export type { FormSelectAwaitLoaderProps } from "./Fields/FormSelectAwaitLoader";

import { default as FormSubmit } from "./Fields/FormSubmit";
export type { FormSubmitProps } from "./Fields/FormSubmit";

import { default as FormMaskInput } from "./Fields/FormMaskInput";
export type { FormMaskInputProps } from "./Fields/FormMaskInput";

import { default as FormDatetimeInput } from "./Fields/FormDatetimeInput";
export type { FormDatetimeInputProps } from "./Fields/FormDatetimeInput";

import { default as FormDatetimeRangeInput } from "./Fields/FormDatetimeRangeInput";
export type { FormDatetimeRangeInputProps } from "./Fields/FormDatetimeRangeInput";

import { default as FormMap } from "./Fields/FormMap";
export type { FormMapProps } from "./Fields/FormMap";

import { default as FormHiddenInput } from "./Fields/FormHiddenInput";
export type { FormHiddenInputProps } from "./Fields/FormHiddenInput";

import { default as ErrorAlert } from "./Forms/ErrorAlert";
export type { ErrorAlertProps } from "./Forms/ErrorAlert";

import { default as GlobalSubmit } from "./Forms/GlobalSubmit";
export type { GlobalSubmitProps } from "./Forms/GlobalSubmit";

export type { FormSelectOption } from "./Fields/BaseInputProps";

import { default as Label } from "./Fields/Label";
export type { LabelProps } from "./Fields/Label";

import { default as LabelTooltip } from "./Fields/LabelTooltip";
export type { LabelTooltipProps } from "./Fields/LabelTooltip";

import { default as DynamicFormBody } from "./dynamic-form/DynamicFormBody";
export type { DynamicFormBodyProps } from "./dynamic-form/DynamicFormBody";

import { default as DynamicFormField } from "./dynamic-form/DynamicFormField";
export type { DynamicFormFieldProps } from "./dynamic-form/DynamicFormField";

import { ControlType } from "./dynamic-form/types";
export type { ControlProps, DynamicFormField, DynamicFormFields } from "./dynamic-form/types";

import CustomDynamicFormFieldRegistry from "./dynamic-form/CustomDynamicFormFieldRegistry";

import {useFormContext, useWatch, useController, useFormState, useFieldArray} from "react-hook-form";
import useDefaultValues from "./hooks/useDefaultValues";
import useFormError from "./hooks/useFormError";

export { FormStatus, type FormValues, type SubmitHandler } from "./types";

// noinspection JSUnusedGlobalSymbols
export default Object.assign(Form, {
    Callback: CallbackForm,
    Data: DataForm,
    Body: FormBody,

    DynamicBody: DynamicFormBody,
    DynamicField: DynamicFormField,
    DynamicControlType: ControlType,
    DynamicControlRegistry: CustomDynamicFormFieldRegistry,

    FieldBlock,

    GroupContainer: FormGroupContainer,
    InputGroupContainer: InputGroupContainer,

    ErrorAlert: ErrorAlert,

    GlobalSubmit: GlobalSubmit,

    Label: Label,
    LabelTooltip: LabelTooltip,

    Input: FormInput,
    HiddenInput: FormHiddenInput,
    MaskInput: FormMaskInput,
    Datetime: FormDatetimeInput,
    DatetimeRange: FormDatetimeRangeInput,
    Check: FormCheck,
    BasicSelect: FormSelectBasic,
    Select: FormSelect,
    SelectAwait: FormSelectAwait,
    SelectAwaitLoader: FormSelectAwaitLoader,
    TypeaheadSelect: FormTypeaheadSelect,
    DropdownSelect: FormDropdownSelect,
    Map: FormMap,
    Submit: FormSubmit,

    useFormContext,
    useDefaultValues,
    useFormError,
    useWatch,
    useController,
    useFormState,
    useFieldArray,
})
