import {useFormContext} from "react-hook-form";
import {useMemo} from "react";
import dot from "dot-object";

export type FormErrorReturn = {
    isError: boolean,
    errorMessage?: string,
}

export default function useFormError(name : string) : FormErrorReturn {
    const { formState: { errors } } = useFormContext();

    const error = dot.pick(name, errors);

    const message = useMemo(() => {
        if (!error) {
            return null;
        }

        if (Array.isArray(error)) {
            return error.map(i => i.message).join('; ');
        } else {
            return error.message;
        }
    }, [ error ]);

    return {
        isError: !!message,
        errorMessage: message,
    }
}
