import * as React from 'react';
import {Modal as BsModal, ModalProps as BsModalProps} from "react-bootstrap";
import {Variant} from "react-bootstrap/types";
import {UseModalReturn} from "../hooks/useModal";
import classNames from "classnames";
import {useMemo} from "react";

type TitleTextVariant = 'white'|'dark'|undefined;

export type ModalProps = {
    size?: BsModalProps['size'],
    fullscreen?: BsModalProps['fullscreen'],
    centered?: boolean,
    scrollable?: BsModalProps['scrollable'],
    backdropClassName?: BsModalProps['backdropClassName'],
    className?: BsModalProps['className'],
    title: React.ReactNode,
    titleBg?: Variant,
    children: React.ReactNode,
    state: UseModalReturn<any>,
    lockClose?: boolean,
}

const Modal = ({ title, titleBg, children, centered = true, state, lockClose, ...props }: ModalProps) => {

    const titleTextVariant = useMemo<TitleTextVariant>(() => {

        if (titleBg && [ 'success', 'danger', 'primary', 'info' ].includes(titleBg)) {
            return 'white';
        }

        return undefined;

    }, [ titleBg ])

    const handleHide = () => {
        if (!lockClose) {
            state.close();
        }
    }

    return (
        <BsModal show={state.state} onHide={handleHide} onExited={state.onExited} centered={centered} backdrop={lockClose ? 'static' : true} {...props}>
            <BsModal.Header  className={classNames('py-0', { [`text-bg-${titleBg}`] : !!titleBg })} closeButton={!lockClose} closeVariant={titleTextVariant}>
                <BsModal.Title>
                    {title}
                </BsModal.Title>
            </BsModal.Header>
            {children}
        </BsModal>
    );
}

export default Object.assign(Modal, {
    Body: BsModal.Body,
    Footer: BsModal.Footer,
});
