import React from "react";
import {Table as BsTable} from 'react-bootstrap'
import Header from "./Header";
import Body from "./Body";

const FullTable = () => {

    return (
        <BsTable responsive>
            <Header />
            <Body />
        </BsTable>
    );
}

export default FullTable
