import * as React from 'react';
import {Button} from "react-bootstrap";
import usePlural from "../../../hooks/usePlural";
import {useDatatableContext} from "../../DatatableContext";
import {useDeferredValue} from "react";
import {ActionsDropdown} from "../../../AppHelpers";

const GroupActions = () => {

    const { rowsSelected, setAllRowsSelected, isAllRowsSelected, data: { options: { actionsModel } } } = useDatatableContext();

    const countName1 = usePlural(rowsSelected.length, 'З вибраним', 'З вибраними')
    const countName2 = usePlural(rowsSelected.length, 'записом', 'записами')

    const selectedIds = useDeferredValue(rowsSelected)

    if (rowsSelected.length === 0) {
        return null;
    }

    return (
        <React.Fragment>
            <Button variant='outline-secondary' className='p-0 px-1 d-md-none' size='sm' onClick={() => setAllRowsSelected(!isAllRowsSelected)}>
                <i className={`mdi mdi-${isAllRowsSelected ? 'collapse-all-outline' : 'expand-all-outline'} lh-1 font-20`} />
            </Button>

            <span className='text-nowrap small'>{countName1} {rowsSelected.length} {countName2}</span>
            {selectedIds.length > 0 && actionsModel && <ActionsDropdown ids={selectedIds as number[]} model={actionsModel} />}
        </React.Fragment>
    );
}

export default GroupActions
