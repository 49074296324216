import * as React from "react";

type CustomDynamicFormFieldComponent = React.ElementType;

export default class CustomDynamicFormFieldRegistry {

    private static fields: Record<string, CustomDynamicFormFieldComponent> = {};

    // noinspection JSUnusedGlobalSymbols
    public static registerField(type: string, component: CustomDynamicFormFieldComponent) {
        this.fields[type] = component;
    }

    public static getField(type: string): CustomDynamicFormFieldComponent|undefined {
        return this.fields[type] ?? undefined;
    }
}
