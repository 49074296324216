import { parseISO } from 'date-fns'

export default function convertDate(date: Date|string|null|undefined) : Date|undefined {
    if (date instanceof Date) {
        return date;
    }

    if (typeof date === 'string') {
        return parseISO(date);
    }

    return undefined;
}
