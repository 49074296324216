import {AuthUserData} from "./AuthUserData";

export enum AuthenticatorState {
    Loading = 'loading',
    Credentials = 'credentials',
    SecondFactor = 'second-factor',
    Success = 'success',
    Error = 'error',
}

export type SecondFactorData = {
    token: string,
    info: string,
    time: number,
}

export type AuthenticatorDataMap = {
    [AuthenticatorState.Loading]: {},
    [AuthenticatorState.Credentials]: {},
    [AuthenticatorState.SecondFactor]: Omit<SecondFactorData, 'time'> & {
        allowResend: boolean,
    },
    [AuthenticatorState.Success]: {},
    [AuthenticatorState.Error]: {
        error: string,
    }
}

export type AuthenticatorData<S = AuthenticatorState> = { state: S } & (S extends keyof AuthenticatorDataMap ? AuthenticatorDataMap[S] : {});

export function isAuthenticatorData<S extends AuthenticatorState>(data: any, state: S) : data is AuthenticatorData<S> {
    return typeof data === 'object' && data !== null && 'state' in data && data.state === state;
}

export enum AuthResponseStatus {
    WaitSecondFactor = 'wait-second-factor',
    Success = 'success',
}

type AuthResponseDataMap = {
    [AuthResponseStatus.WaitSecondFactor]: SecondFactorData,
    [AuthResponseStatus.Success]: AuthUserData,
}

export type AuthResponse<S = AuthResponseStatus> = { status: S } & (S extends keyof AuthResponseDataMap ? { data: AuthResponseDataMap[S] } : {});

export function isAuthResponse<S extends AuthResponseStatus>(data: any, status: S) : data is AuthResponse<S> {
    return typeof data === 'object' && data !== null && 'status' in data && data.status === status;
}
