import * as React from 'react';
import {Dropdown} from "react-bootstrap";
import {GlobalActionSimpleButton} from "../../../../../AppHelpers";
import GlobalButtonIcon from "./GlobalButtonIcon";

type GlobalDropdownButtonItemBasicProps = {
    options: GlobalActionSimpleButton,
    onClick: () => void,
}

const GlobalDropdownButtonItemBasic = ({ options, onClick }: GlobalDropdownButtonItemBasicProps) => {
    return (
        <Dropdown.Item className='px-2 py-1 font-16 d-flex gap-1 align-items-center' onClick={onClick}>
            <GlobalButtonIcon icon={options.icon} className='font-18' />
            <span>{options.title}</span>
            {options.badge !== undefined && <span className='rounded ms-auto bg-danger px-1 text-light'>{options.badge ?? ' '}</span>}
        </Dropdown.Item>
    );
}

export default GlobalDropdownButtonItemBasic
