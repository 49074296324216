import * as React from "react";
var useRegisterNodeRef = function (callback, deps) {
    if (deps === void 0) { deps = []; }
    var cleanupRef = React.useRef(null);
    var registerRef = React.useCallback(function (node) {
        if (cleanupRef.current !== null) {
            cleanupRef.current();
            cleanupRef.current = null;
        }
        if (node) {
            var cleanup = callback(node);
            if (cleanup)
                cleanupRef.current = cleanup;
            else
                cleanupRef.current = null;
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps);
    return registerRef;
};
export default useRegisterNodeRef;
