import * as React from "react";
import {ActionStatus} from "../../types";
import {ActionDisplayProps} from "./ActionDisplayProps";
import ActionDisplayConfirm from "./ActionDisplayConfirm";
import {isActiveActionStatus} from "../../guards";
import ActionDisplayForm from "./ActionDisplayForm";
import ActionDisplayResult from "./ActionDisplayResult";

const ActionDisplay = ({ action } : ActionDisplayProps) => {

    if (isActiveActionStatus(action, ActionStatus.Confirming)) {
        return <ActionDisplayConfirm action={action} />
    }

    if (isActiveActionStatus(action, ActionStatus.WaitData)) {
        return <ActionDisplayForm action={action} />
    }

    if (isActiveActionStatus(action, ActionStatus.Success) || isActiveActionStatus(action, ActionStatus.Error)) {
        return <ActionDisplayResult action={action} />
    }

    return null
}

export default ActionDisplay
