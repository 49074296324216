import React, {forwardRef, LegacyRef} from "react";
import {Button} from "react-bootstrap";

type DatepickerInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    handleChange?: (value: Date) => void,
};

const DatepickerInput = forwardRef<HTMLInputElement, DatepickerInputProps>(({ handleChange, disabled, ...props }, ref) => {

    const handleSetCurrent = () => {
        handleChange && handleChange(new Date());
    }

    return (
        <React.Fragment>
            {handleChange && (
                <Button
                    variant='link'
                    className='position-absolute end-0 top-0 bottom-0 mdi mdi-clock-in font-20 p-0 px-1'
                    disabled={disabled}
                    onClick={handleSetCurrent}
                />
            )}
            <input
                {...props}
                type="text"
                ref={ref as LegacyRef<HTMLInputElement>}
                disabled={disabled}
            />
        </React.Fragment>
    );
});

export default DatepickerInput
