import * as React from "react";
import TableBody from "./TableBody";
import {DatatableContextProvider} from "../DatatableContext";
import {DatatableData} from "../types/DatatableData";
import {DatatableConfigOptional} from "../types/DatatableConfig";
import {RenderConfig} from "../types/RenderConfig";
import Footer from "./Footer";
import TableGlobalButtons from "./Components/TableGlobalButtons";

export type DatatableProps = {
    data: DatatableData,
    config?: DatatableConfigOptional,
    renderConfig?: RenderConfig,
}

const Datatable = ({ data, renderConfig, config } : DatatableProps) => {

    return (
        <DatatableContextProvider key={data.name} data={data} renderConfig={renderConfig} config={config}>
            <TableBody />
            <Footer />
            <TableGlobalButtons />
        </DatatableContextProvider>
    );
}

export default Datatable
