import {CrudActionIndexExtend} from "../types";
import {RouteObject} from "react-router-dom";
import prepareHandle from "../../helpers/prepareHandle";
import prepareLoader from "../../helpers/prepareLoader";

export default function makeExtendCrudIndex(options : CrudActionIndexExtend) : RouteObject {
    return {
        index: true,
        handle: prepareHandle({ ...options, breadcrumb: false }),
        loader: prepareLoader(options.loader),
        element: options.element,
    }
}
