import * as React from 'react';
import SmallCol from "./SmallCol";
import useSearchParams from "../../../hooks/useSearchParams";
import {Link} from "react-router-dom";
import {useDatatableContext} from "../../../DatatableContext";

const IndexCell = () => {

    const { getSearchParams, setSearchParams } = useSearchParams();

    const { config: { tableMode } } = useDatatableContext();

    const handleClick = React.useCallback<React.MouseEventHandler>((e) => {
        e.preventDefault();
        e.stopPropagation();
        setSearchParams({ sort: undefined })
    }, [])

    const isDefaultSort = !getSearchParams('sort');

    return (
        <SmallCol>
            {tableMode === 'full' ? (
                <Link
                    to='#'
                    className={isDefaultSort ? 'link-primary' : 'link-secondary'}
                    onClick={handleClick}
                >
                    #
                </Link>
            ) : (
                '#'
            )}
        </SmallCol>
    );
}

export default IndexCell
