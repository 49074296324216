import React from "react";
import {LinkDefinition, TitleDataCallback, TitlingRouteProps} from "../types";
import dot from "dot-object";
import {ActionSubmitHandler} from "../actions";
import {SubmitHandler} from "../../Form/types";

export type HandleOptions = {
    name: (data : any) => string,
    title: (data : any) => string,
    breadcrumb: (data : any, pathname : string) => LinkDefinition[],
    titleLinks: (data : any) => LinkDefinition[],
    titleEnd: (data : any) => React.ReactNode,
    actionHandler?: ActionSubmitHandler,
}

export type HandleData = {
    name: string,
    title: string,
    url: string,
    breadcrumb: LinkDefinition[],
    titleLinks: LinkDefinition[],
    titleEnd: React.ReactNode,
    actionHandler: SubmitHandler,
}

export function replacePlaceholders(str : string, data : any) {
    return str.replace(/{([a-zA-Z0-9.\-_]+)}/gm,
        (_, key) => dot.pick(key, data) ?? '...')
}

function resolveHandleProp(prop : string|TitleDataCallback<string>, data : any) : string {
    if (typeof prop === 'string') {
        return replacePlaceholders(prop, data);
    }

    return replacePlaceholders(prop(data), data);
}

function prepareTitleLinks(links : TitlingRouteProps['titleLinks'], docsLink : TitlingRouteProps['docsLink'], data : any) : LinkDefinition[] {

    let _linksDef : LinkDefinition[] = [];

    if (typeof links !== 'undefined') {
        const _linksPrepared = typeof links === 'function'
            ? links(data)
            : links;

        _linksDef = (Array.isArray(_linksPrepared) ? _linksPrepared : [ _linksPrepared ])
            .map((l) => ({ ...l, to: replacePlaceholders(l.to, data) }));
    }

    if (docsLink) {
        _linksDef.push({
            to: docsLink,
            className: 'link-secondary font-18',
            text: <i className='mdi mdi-help-circle' />,
        })
    }

    return _linksDef
}

function prepareBreadcrumbs(breadcrumb : TitlingRouteProps['breadcrumb'], nameCallback : HandleOptions['name'], path : string, data : any) : LinkDefinition[] {
    if (breadcrumb === false) {
        return [];
    }

    if (typeof breadcrumb === 'undefined') {
        return [{
            to: path,
            text: nameCallback(data),
        }]
    }

    const _breadcrumbs = typeof breadcrumb === 'function' ? breadcrumb(data) : [ breadcrumb ]

    return _breadcrumbs.map(b => {
        if (typeof b === 'string') {
            return {
                to: path,
                text: replacePlaceholders(b, data),
            }
        }

        return b;
    })

}

export default function prepareHandle({ name, title, titleLinks, docsLink, titleEnd, breadcrumb } : TitlingRouteProps, isModal : boolean = false, actionHandler?: ActionSubmitHandler) : HandleOptions {
    const nameCallback = (data : any) => resolveHandleProp(name, data);
    const titleCallback = title ? (data : any) => resolveHandleProp(title, data) : nameCallback;

    const breadcrumbCallback = (data: any, path : string) => !isModal ? prepareBreadcrumbs(breadcrumb, nameCallback, path, data) : [];

    const titleLinksCallback = (data : any) => prepareTitleLinks(titleLinks, docsLink, data);

    const titleEndCallback = (data : any) : React.ReactNode => {
        if (typeof titleEnd === 'function') {
            return titleEnd(data);
        }

        return titleEnd;
    }

    return {
        name: nameCallback,
        title: titleCallback,
        breadcrumb: breadcrumbCallback,
        titleLinks: titleLinksCallback,
        titleEnd: titleEndCallback,
        actionHandler,
    }
}
