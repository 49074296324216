import API from "@ova-studio/api-helper";
import makeEndpoint from "../../helpers/makeEndpoint";
import {FormValues} from "../../Form/types";
import {ActionSubmitHandler, ConfigActionDefinition} from "./types";

export default function makeActionFromConfig(options : ConfigActionDefinition) : ActionSubmitHandler {
    return async (data, params) => {
        const result = await API[options.method](makeEndpoint({
            endpoint: options.endpoint,
            paramName: options.paramName,
            subEndpoint: options.subEndpoint,
        }, params), data);

        if (result.status < 200 || result.status >= 300) {
            throw new Error(result.statusText);
        }

        return result.data as FormValues;
    }
}
