import * as React from 'react';
import {Card} from "react-bootstrap";

export type BlockProps = {
    id?: string,
    cardClassName?: string,
    title?: React.ReactNode,
    buttons?: React.ReactNode,
    headerClassName?: string,
    className?: string,
    children: React.ReactNode,
    cardStyle?: React.CSSProperties,
    style?: React.CSSProperties,
}

const Block = React.forwardRef<HTMLDivElement, BlockProps>(({ id, cardClassName, headerClassName, title, buttons, className, children, cardStyle, style } : BlockProps, ref) => {
    return (
        <Card className={cardClassName} id={id} ref={ref} style={cardStyle}>
            {title && (
                <Card.Header className={headerClassName ?? "d-flex align-items-center bg-primary-lighten py-2"}>
                    {typeof title === 'string' ? <h4 className="header-title lh-1 text-dark">{title}</h4> : title}
                    {buttons && (
                        <div className='ms-auto d-flex align-items-center gap-2'>
                            {buttons}
                        </div>
                    )}
                </Card.Header>
            )}
            <Card.Body className={className} style={style}>
                {children}
            </Card.Body>
        </Card>
    );
})

export default Block
