import * as React from 'react';
import {ActionDisplayProps} from "./ActionDisplayProps";
import {ActionStatus} from "../../types";
import Modal from "../../../../components/Modal";
import useModal from "../../../../hooks/useModal";
import {Alert} from "react-bootstrap";
import {isDatatableData} from "../../../../Datatable/types/DatatableData";
import Datatable from "../../../../Datatable";
import BouncingLoader from "../../../../components/BouncingLoader";

type ActionDataDisplayProps = {
    action: ActionDisplayProps<ActionStatus.Success | ActionStatus.Error>['action'],
    data: any,
}

const ActionDataDisplay = ({ data, action } : ActionDataDisplayProps) => {

    const ResultRenderer = React.useMemo(() => action.getResultRenderer(), [action]);

    if (ResultRenderer) {
        return (
            <React.Suspense fallback={<BouncingLoader />}>
                <ResultRenderer data={data} />
            </React.Suspense>
        )
    }

    if (isDatatableData(data)) {
        return (
            <Datatable.Base
                data={data}
                config={{ tableMode: 'simple', hideFooter: true, settingsShow: false }}
            />
        )
    }

    return null;
}

const ActionDisplayResult = ({ action }: ActionDisplayProps<ActionStatus.Success | ActionStatus.Error>) => {

    const modalState = useModal({
        initialState: true,
        onClose: action.handleCancel,
    })

    return (
        <Modal state={modalState} title={action.action.title} titleBg={action.status === ActionStatus.Error ? 'danger' : undefined}>
            <Modal.Body>
                {action.data.message && <Alert variant={action.status === ActionStatus.Error ? 'danger' : 'success'}>{action.data.message}</Alert>}
                {action.data.data && <ActionDataDisplay action={action} data={action.data.data} />}
            </Modal.Body>
        </Modal>
    );
}

export default ActionDisplayResult
