import * as React from 'react';
import {RowButtonProps} from "./RowButtonProps";
import {NavigateAction} from "../../../types/TableRowActions";
import {useNavigate} from "react-router-dom";
import RowActionButtonBody from "./RowActionButtonBody";

type RowNavigateActionButtonProps = RowButtonProps<NavigateAction>

const RowNavigateActionButton = ({ action, className }: RowNavigateActionButtonProps) => {

    const navigate = useNavigate()

    const handleClick = () => navigate(action.route);

    return (
        <RowActionButtonBody
            icon={action.icon}
            title={action.title}
            className={className}
            buttonVariant={action.buttonVariant}
            onClick={handleClick}
        />
    );
}

export default RowNavigateActionButton
