import {ArrayData, BasicData} from "../../types";
import useArrayDataStore, { UseArrayDataStoreReturn } from "./useArrayDataStore";
import {ArrayDataStoreOptions} from "../../store/ArrayDataStore";
import useLoaderData from "../useLoaderData";

export type UseLoaderArrayDataStoreReturn<D extends BasicData> = UseArrayDataStoreReturn<D>;

const useLoaderArrayDataStore = <D extends BasicData>(dataKey: string, options: ArrayDataStoreOptions<D>) : UseLoaderArrayDataStoreReturn<D> => {
    const initialData = useLoaderData<ArrayData<D>>(dataKey);
    return useArrayDataStore(initialData, options);
}

export default useLoaderArrayDataStore;