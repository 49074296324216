import * as React from 'react';
import MediaLibraryContext from "./MediaLibraryContext";
import MediaLibraryDataStore, {MediaLibraryOptions} from "./MediaLibraryDataStore";
import {useMediaLibraryService} from "../services/MediaLibraryService";

export type MediaLibraryContextProviderProps = MediaLibraryOptions & {
    children: React.ReactNode,
}

const MediaLibraryContextProvider = ({ children, ...options }: MediaLibraryContextProviderProps) => {

    const service = useMediaLibraryService();
    const store = React.useMemo(() => new MediaLibraryDataStore(service, options), [])

    React.useEffect(() => {
        store.init();
        return () => store.destroy();
    }, [])

    return (
        <MediaLibraryContext.Provider value={store}>
            {children}
        </MediaLibraryContext.Provider>
    );
}

export default MediaLibraryContextProvider
