import * as React from 'react';
import {
    isNavMenuChildren,
    isNavMenuIcon,
    isNavMenuLink, NavMenuChildren,
    NavMenuItem
} from "../../../../AppHelpers/Navigation";
import classNames from "classnames";
import {LegacyRef, MouseEventHandler} from "react";
import {NavLink} from "react-router-dom";
import useBoolean from "../../../../hooks/useBoolean";
import {Collapse} from "react-bootstrap";
import NavCounters from "../NavCounters";

type SideMenuItemProps = {
    item: NavMenuItem,
}

const SideMenuItem = ({ item }: SideMenuItemProps) => {
    const isLink = isNavMenuLink(item);

    const handleClick = React.useCallback<MouseEventHandler>((e) => {
        if (!isLink) {
            e.preventDefault();
            e.stopPropagation();
        }
    }, [ isLink ])

    return (
        <li className='side-nav-item'>
            <NavLink to={isLink ? item.url : '#'} className={classNames('side-nav-link', isLink && 'side-nav--has-link')} onClick={handleClick}>
                {isNavMenuIcon(item) && <i className={`mdi mdi-${item.icon}`} />}
                <span>{item.name}</span>
                {item.counters && <NavCounters data={item.counters} />}
            </NavLink>
        </li>
    );
}

type SideMenuItemWithChildrenProps = {
    item: NavMenuItem & NavMenuChildren,
    level: number,
}

const SideMenuItemWithChildren = ({ item, level }: SideMenuItemWithChildrenProps) => {

    const childToggle = useBoolean();
    const isLink = isNavMenuLink(item);

    const handleClick = React.useCallback<MouseEventHandler>((e) => {
        if (!isLink) {
            e.preventDefault();
            e.stopPropagation();
        }

        childToggle.toggle();
    }, [ item ])

    return (
        <li className={classNames('side-nav-item', { 'menuitem-open' : childToggle.state })}>
            <NavLink to={isLink ? item.url : '#'} className={classNames('side-nav-link has-arrow', isLink && 'side-nav--has-link', { 'collapsed' : !childToggle.state })} onClick={handleClick}>
                {isNavMenuIcon(item) && <i className={`mdi mdi-${item.icon}`} />}
                <span>{item.name}</span>
                {item.counters && <NavCounters data={item.counters} />}
                <span className="menu-arrow"></span>
            </NavLink>
            <Collapse in={childToggle.state}>
                <SideMenuItems items={item.children} level={level + 1} />
            </Collapse>
        </li>
    );
}

type SideMenuItemsProps = {
    items: NavMenuItem[],
    level?: number,
    className?: string,
}

const SideMenuItems = React.forwardRef(({ items, className = '', level = 1 }: SideMenuItemsProps, ref) => {
    return (
        <ul className={classNames({
            'side-nav': level === 1,
            'side-nav-second-level': level === 2,
            'side-nav-third-level': level === 3,
            [className]: !!className,
        })} ref={ref as LegacyRef<HTMLUListElement>}>
            {items.map((item, idx) => (
                isNavMenuChildren(item) && item.children.length > 0
                    ? <SideMenuItemWithChildren item={item} level={level} key={`item-${idx}`} />
                    : <SideMenuItem item={item} key={`item-${idx}`} />
            ))}
        </ul>
    );
})

export default SideMenuItems
