import makeActionFromConfig from "./makeActionFromConfig";
import {ActionDefinition, ActionSubmitHandler} from "./types";

export default function makeAction(actionDef : ActionDefinition) : ActionSubmitHandler {

    if (typeof actionDef === 'function') {
        return actionDef;
    }

    return makeActionFromConfig(actionDef);

}

export type {ActionDefinition, ActionSubmitHandler};
