import * as React from 'react';
import {useDatatableContext} from "../../DatatableContext";
import FooterBody from "./FooterBody";
import FooterShort from "./FooterShort";
import {AsTopbarRow} from "../../../AppHelpers/Navigation";

const Footer = () => {

    const { config: { tableMode, hideFooter } } = useDatatableContext();

    if (hideFooter) {
        return null;
    }

    if (tableMode === 'simple') {
        return <FooterShort />
    }

    return (
        <React.Fragment>
            <AsTopbarRow mobile={true} desktop={false}>
                <FooterBody className='table-footer-topbar' />
            </AsTopbarRow>
            <FooterBody className='table-footer-inline' />
        </React.Fragment>
    )
}

export default Footer
