import * as React from 'react';
import AwaitBlock, {AwaitBlockProps} from "./AwaitBlock";
import FlexCenteredInfo from "./FlexCenteredInfo";

type ListDisplayEmptyProps = {
    icon?: string,
    text?: string,
    nullText?: string,
    variant?: 'inline' | 'block',
    color?: string,
    isNull?: boolean,
}

const ListDisplayEmpty = ({
    icon,
    text = 'Нічого не знайдено',
    nullText,
    variant = 'inline',
    color = 'muted',
    isNull = false
} : ListDisplayEmptyProps) => {
    if (variant === 'block') {
        return (
            <FlexCenteredInfo
                icon={icon ?? 'file-hidden'}
                text={isNull ? (nullText ?? text) : text}
                color={color}
            />
        );
    }

    if (icon) {
        return (
            <div className='d-inline-flex align-items-center gap-1'>
                <i className={`mdi mdi-${icon} font-16 lh-1`} />
                <span>{isNull ? (nullText ?? text) : text}</span>
            </div>
        );
    }

    return (
        <span>{isNull ? (nullText ?? text) : text}</span>
    );
}

export type ListDisplayProps<T, D = T[] | Record<string, T>> = {
    data: D | null,
    emptyProps?: Omit<ListDisplayEmptyProps, 'isNull'>,
    itemRender: (data: T, idx: number|string) => React.ReactNode,
    wrapper?: React.ComponentType<{ children: React.ReactNode }>,
}

const ListDisplay = <T extends unknown>({
    data,
    itemRender,
    wrapper : Wrapper = React.Fragment,
    emptyProps,
}: ListDisplayProps<T>) => {

    if (data === null) {
        return <ListDisplayEmpty {...emptyProps} isNull={true} />;
    }

    if (Array.isArray(data) && data.length === 0) {
        return <ListDisplayEmpty {...emptyProps} />;
    }

    if (typeof data === 'object' && Object.keys(data).length === 0) {
        return <ListDisplayEmpty {...emptyProps} />;
    }

    if (Array.isArray(data)) {
        return (
            <Wrapper>
                {data.map((item, idx) => itemRender(item, idx))}
            </Wrapper>
        );

    }

    return (
        <Wrapper>
            {Object.entries(data).map(([key, item]) => itemRender(item, key))}
        </Wrapper>
    );
}

export type AwaitListDisplayProps<T, D = T[] | Record<string, T>> =
    Omit<ListDisplayProps<T, D>, 'data'> &
    Omit<AwaitBlockProps<D>, 'resolve' | 'children'> & {
        resolve: Promise<D | null>,
    };

const AwaitListDisplay = <T extends unknown>({ resolve, emptyProps, variant, ...props }: AwaitListDisplayProps<T>) => {
    return (
        <AwaitBlock resolve={resolve} variant={variant} {...props}>
            {(data) => (
                <ListDisplay data={data} {...props} emptyProps={{ variant: variant, ...emptyProps }} />
            )}
        </AwaitBlock>
    );
}

export default Object.assign(ListDisplay, { Await: AwaitListDisplay });
