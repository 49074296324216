import * as React from "react";
import PictureRender from "./PictureRender";
import clsx from "clsx";
import {MediaType} from "../types/MediaType";
import {Media} from "../types/Media";
import {Picture} from "../types/Picture";

export type MediaConversionProps = {
    data: Media,
    conversion: string,
    fallbackType: 'none' | 'image' | 'preview',
    alt?: string,
    lazy?: boolean,
}

const MediaConversion = ({ data, conversion, alt, fallbackType, lazy }: MediaConversionProps) => {

    const picture : Picture|null = React.useMemo(() => {
        if (data.conversions[conversion]) {
            return data.conversions[conversion];
        }

        if (fallbackType === 'image' && data.image) {
            return data.image;
        }

        if (fallbackType === 'preview' && data.preview) {
            return data.preview;
        }

        return null;
    }, []);

    if (!picture) {
        return null;
    }

    return (
        <PictureRender
            data={picture}
            alt={alt}
            lazy={lazy}
            className={clsx('ova-engine-image-conversion', `ova-engine-image-conversion-${conversion}`, {
                ['ova-engine-image-conversion--video'] : data.type === MediaType.Video || data.mime_type === 'ova-embed/youtube',
            })}
        />
    )
}

export default MediaConversion;
