import * as React from 'react';
import {Dropdown} from "react-bootstrap";
import useExternalStore from "../../../../hooks/useExternalStore";
import {useAppAuth} from "../../../../Application";
import Avatar from "../../../../components/Avatar";

const AuthUser = () => {

    const appAuth = useAppAuth();
    const authUser = useExternalStore(appAuth);

    const handleLogout = () => {
        void appAuth.logout();
    }

    if (!authUser) {
        return null;
    }

    return (
        <div className='user-profile-container'>
            <Dropdown align='end'>
                <Dropdown.Toggle as={'div'} className='user-profile arrow-none' role='button'>
                    <Avatar
                        name={authUser.name}
                        avatarUrl={authUser.avatar}
                        className='user-profile-avatar'
                    />
                    <div className='user-profile-name'>{authUser.name}</div>
                    <div className='user-profile-position'>{authUser.position}</div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={handleLogout}>
                        <i className='mdi mdi-logout-variant me-1' />
                        Вийти
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

export default AuthUser
