import React from "react";
import {Form} from "react-bootstrap";
import {useFormContext} from "react-hook-form";
import {BaseInputProps} from "./BaseInputProps";
import useFormError from "../hooks/useFormError";
import {useFormOptionsContext} from "../Forms/FormBody/FormOptionsContext";
import {FormCheckType} from "react-bootstrap/FormCheck";
import classNames from "classnames";

export type FormCheckProps = BaseInputProps & {
    type?: FormCheckType,
    value?: string|number,
    disabled?: boolean,
    readOnly?: boolean,
    className?: string,
    controlId?: string,
}

const FormCheck = ({ type = 'checkbox', name, label, value = 1, disabled, readOnly, className, controlId } : FormCheckProps) => {
    const { register } = useFormContext();
    const { name: formName, feedbackType } = useFormOptionsContext();

    const { isError, errorMessage } = useFormError(name);

    return (
        <Form.Check
            className={classNames('position-relative user-select-none', className)}
            type={type}
            label={label}
            value={value}
            id={controlId ?? `${formName}.${name}.${value}`}
            isInvalid={isError}
            feedback={errorMessage}
            feedbackType='invalid'
            feedbackTooltip={feedbackType === 'tooltip'}
            disabled={disabled}
            readOnly={readOnly}
            {...register(name)}
        />
    );
}

export default FormCheck
