import getOptionLabel from './getOptionLabel';
import { isFunction } from './nodash';
function addCustomOption(results, props) {
  var allowNew = props.allowNew,
    labelKey = props.labelKey,
    text = props.text;
  if (!allowNew || !text.trim()) {
    return false;
  }

  // If the consumer has provided a callback, use that to determine whether or
  // not to add the custom option.
  if (isFunction(allowNew)) {
    return allowNew(results, props);
  }

  // By default, don't add the custom option if there is an exact text match
  // with an existing option.
  return !results.some(function (o) {
    return getOptionLabel(o, labelKey) === text;
  });
}
export default addCustomOption;