import * as React from 'react';
import {LogoProps} from "../../types/LogoProps";

const DefaultLogoSmall = (props : LogoProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.07 35.48" {...props}>
            <path d="M30,5.29a17.52,17.52,0,0,0-5.35-3.62c0,.88.2,3.14.8,3.83Q29,9.75,29,18.37q0,8-3.29,11.65a10.65,10.65,0,0,1-8.34,3.7,9.52,9.52,0,0,1-7.6-3.37Q6,25.91,6.05,17.67c0-4.59.91-8.21,2.68-10.9a1.09,1.09,0,0,1-.11-.1,1.82,1.82,0,0,1-.52-.8h0L7.72,5.5l-.35-.24c-.2-.06-.4-.11-.59-.18a4,4,0,0,1-.9-.43l-.07.06A16.52,16.52,0,0,0,0,17.77,17.05,17.05,0,0,0,17.46,35.48a17.14,17.14,0,0,0,12.46-5.06A17.06,17.06,0,0,0,35.07,17.7,16.58,16.58,0,0,0,30,5.29Z"/>
            <path d="M24.54,20.68a1.18,1.18,0,0,0-.1-.63l0,0h0l-.18-.72a.46.46,0,0,0,.15-.29.5.5,0,0,0-.23-.46.59.59,0,0,0-.14-.06c0-.08-.86-3.23-1.05-4.85a5.86,5.86,0,0,1,0-.93,4.73,4.73,0,0,0-.05-1.33,4.83,4.83,0,0,0-.38-.95,4,4,0,0,1-.43-1.2A2.45,2.45,0,0,0,22,8.85a1.59,1.59,0,0,1-.09-.6,2.6,2.6,0,0,1,.14-.53,2.67,2.67,0,0,0,.15-.48.67.67,0,0,0,.1-.44v0h-.1a2.54,2.54,0,0,0-.5-1.33c-.16-.19-.71-.83-.84-.93.09-.14.2-.28.28-.42a6.93,6.93,0,0,0,.71-1.8h0a8.66,8.66,0,0,0,.51-1.21c.12-.33.24-.64.29-.73l.05-.1h-.12a2.7,2.7,0,0,0-.68.12c0-.09,0-.19-.05-.29V0h-.06a4.49,4.49,0,0,0-2.62,1.44c0-.38-.46-.63-.92-.67A9.13,9.13,0,0,0,13.62,2a21.45,21.45,0,0,1-2,.83,19.88,19.88,0,0,1-2.92.67l-.54.1c-.43.08-.86.18-1.29.29A9.18,9.18,0,0,0,3.6,8.16a19.32,19.32,0,0,0-1.23,5.78,28,28,0,0,0-.08,7.26,20.13,20.13,0,0,0,.65,2.68A21.59,21.59,0,0,1,7.5,19.54c1.4-1,2.55-1.32,3.52-1a1.3,1.3,0,0,0,.1.15,7.55,7.55,0,0,0,1.31,1.27c.18.15.35.29.5.43h0a.38.38,0,0,0-.07.46,1,1,0,0,0,.3.3,1.13,1.13,0,0,0,.52.27h.05a3.72,3.72,0,0,1,.84,1,.5.5,0,0,0,0,.13c-.09.41.3.74.67.91a2,2,0,0,0,.63.16.38.38,0,0,0,.15,0l.16.07c.15.19.36.42.58.65a9.63,9.63,0,0,1,.9,1.06A2.42,2.42,0,0,1,18,26.58a4.35,4.35,0,0,0,.8,2.06,2.14,2.14,0,0,0,1.39.65,1.23,1.23,0,0,0,.61-.09,2.89,2.89,0,0,0,2.74-1.05c.12-.14.34-.35.47-.47l.15-.15a.36.36,0,0,0,.12-.08.6.6,0,0,0,.09-.13,2.31,2.31,0,0,0,.25-.36A3.92,3.92,0,0,0,25,25.25c0-.1,0-.2,0-.29A16.25,16.25,0,0,0,24.54,20.68Z"/>
        </svg>
    );
}

DefaultLogoSmall.defaultProps = {
    fill: '#fff',
}

export default DefaultLogoSmall
