import * as React from 'react';
import {DatatableColumn} from "../../../../types/DatatableData";
import {Link} from "react-router-dom";
import classNames from "classnames";
import {useCallback, useMemo} from "react";
import {Badge} from "react-bootstrap";
import {useDatatableContext} from "../../../../DatatableContext";

type SortableColHeaderCellProps = {
    data: DatatableColumn,
}

const SortableColHeaderCell = ({ data }: SortableColHeaderCellProps) => {

    const { activeSorts, toggleSort } = useDatatableContext();

    const handleClick = useCallback<React.MouseEventHandler>((e) => {
        e.preventDefault();
        e.stopPropagation();
        toggleSort(data.key);
    }, [ toggleSort ])

    const activeSort = useMemo(() => activeSorts[data.key], [ activeSorts ])

    return (
        <th>
            <Link to='#' onClick={handleClick} className={`d-inline-flex align-items-center`}>
                <span>{data.title}</span>
                {activeSort && (
                    <Badge bg='primary' className='ms-1 d-inline-flex align-items-center small'>
                        {activeSort.order}
                        <i className={classNames('mdi', {
                            'mdi-arrow-down-bold': activeSort.dir === 'asc',
                            'mdi-arrow-up-bold': activeSort.dir === 'desc',
                        })} />
                    </Badge>
                )}
            </Link>
        </th>
    );
}

export default SortableColHeaderCell
