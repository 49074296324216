import * as React from 'react';
import FlexCentered from "../../components/FlexCentered";
import BouncingLoader from "../../components/BouncingLoader";
import LoginFormCredentials from "./LoginFormCredentials";
import LoginFormCode from "./LoginFormCode";
import useExternalStore from "../../hooks/useExternalStore";
import { isAuthenticatorData, AuthenticatorState } from "../../AppHelpers";
import FlexCenteredInfo from "../../components/FlexCenteredInfo";
import {useAppAuth} from "../../Application";

const LoginForm = () => {

    const appAuth = useAppAuth();
    const state = useExternalStore(appAuth.authenticator);

    if (isAuthenticatorData(state, AuthenticatorState.Loading)) {
        return <FlexCentered><BouncingLoader /></FlexCentered>
    }

    if (isAuthenticatorData(state, AuthenticatorState.Success)) {
        return <FlexCenteredInfo icon='check-all' text='Успішно авторизовані. Зачекайте перенаправлення...' />
    }

    if (isAuthenticatorData(state, AuthenticatorState.Error)) {
        return <FlexCenteredInfo icon='alert-circle-outline' text={state.error} />
    }

    if (isAuthenticatorData(state, AuthenticatorState.Credentials)) {
        return <LoginFormCredentials />
    }

    if (isAuthenticatorData(state, AuthenticatorState.SecondFactor)) {
        return <LoginFormCode data={state} />
    }

    return null;
}

export default LoginForm
