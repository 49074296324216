import * as React from 'react';
import {Await} from "react-router-dom";
import useAwaitError, {UseAwaitErrorReturn} from "../hooks/useAwaitError";
import AwaitFallback from "./AwaitFallback";
import AwaitError from "./AwaitError";

type AwaitBlockErrorProps = {
    variant?: 'block' | 'inline',
    errorFallback?: React.ReactNode | ((error: UseAwaitErrorReturn) => React.ReactNode),
}

const AwaitBlockError = ({ variant, errorFallback }  :AwaitBlockErrorProps) => {

    const error = useAwaitError();

    if (errorFallback) {
        return (
            <React.Fragment>
                {typeof errorFallback === 'function' ? errorFallback(error) : errorFallback}
            </React.Fragment>
        )
    }

    return (
        <AwaitError variant={variant} error={error} />
    )
}

export type AwaitBlockProps<T extends unknown> = {
    resolve: Promise<T>,
    children: (data: T) => React.ReactNode,
    variant?: 'block' | 'inline',
    loadFallback?: React.ReactNode,
    errorFallback?: React.ReactNode | ((error: UseAwaitErrorReturn) => React.ReactNode),
}

const AwaitBlock = <T extends unknown>({ resolve, children, loadFallback, errorFallback, variant }: AwaitBlockProps<T>) => {
    return (
        <React.Suspense fallback={loadFallback ?? <AwaitFallback variant={variant} />}>
            <Await
                resolve={resolve}
                errorElement={<AwaitBlockError variant={variant} errorFallback={errorFallback} />}
                children={children}
            />
        </React.Suspense>
    );
}

export default AwaitBlock
