import * as React from 'react';
import DisplayInfo from "./DisplayInfo";
import Pagination from "./Pagination";
import GroupActions from "./GroupActions";
import {useDatatableContext} from "../../DatatableContext";

type FooterBodyProps = {
    className?: string,
}

const FooterBody = ({ className }: FooterBodyProps) => {

    const { rowsSelected, data : { data, pagination } } = useDatatableContext();

    const rowsCnt = data?.length ?? 0;

    if (!rowsCnt) {
        return null;
    }

    return (
        <div className={`table-footer ${className}`}>
            {rowsSelected?.length > 0 ? (
                <GroupActions />
            ) : (
                <React.Fragment>
                    <DisplayInfo pagination={pagination} rowsCnt={rowsCnt} />
                    <Pagination pagination={pagination} />
                </React.Fragment>
            )}

        </div>
    );
}

export default FooterBody
