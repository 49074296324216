import * as React from 'react';
import {DatatableRow} from "../../../types/DatatableData";
import {DatatableColumnShow} from "../../../DatatableContext/DatatableContext";
import {useMemo} from "react";
import CellBody from "../../Components/CellBody";

type CellProps = {
    col: DatatableColumnShow,
    data: DatatableRow,
}

const Cell = ({ col, data }: CellProps) => {

    const value = useMemo<any>(() => data[col.key] ?? null, [ data, col.key ]);

    return (
        <td>
            <CellBody name={col.key} value={value} data={data} />
        </td>
    );
}

export default Cell
