import * as React from 'react';
import {Form, Modal, UseModalReturn} from "@ova-studio/react-hyper-admin";
import {useMediaLibraryService} from "../services/MediaLibraryService";
import {Media} from "../types/Media";

export type UploadLinkModalStateData = {
    handler?: (promise: Promise<Media[]>) => void,
}

export type UploadLinkModalState = UseModalReturn<UploadLinkModalStateData>

type UploadYoutubeModalProps = {
    state: UploadLinkModalState,
}

const Services = () => (
    <React.Fragment>
        Підтримувані сервіси:{' '}
        <b>YouTube</b>{', '}
        <b>Facebook</b>{', '}
        <b>Instagram</b>{', '}
        <b>Telegram</b>{', '}
        <b>Twitter (X)</b>{', '}
        <b>TikTok</b>
    </React.Fragment>
)

const UploadLinkModal = ({ state }: UploadYoutubeModalProps) => {

    const uploadService = useMediaLibraryService().upload;

    const handleSubmit = React.useCallback(async (data: Record<string, any>) => {
        await uploadService.addFromLinks([ data.link ], state.data?.handler);
        state.close();
    }, [ state.data?.handler ]);

    return (
        <Modal state={state} title='Додати за посиланням' className='modal-nested' backdropClassName='modal-backdrop-nested'>
            <Form.Callback handleSubmit={handleSubmit} showSuccess={false}>
                <Modal.Body>
                    <Form.Input name='link' label='Посилання' text={<Services />} groupClass='' />
                </Modal.Body>
                <Modal.Footer>
                    <Form.Submit>Додати</Form.Submit>
                </Modal.Footer>
            </Form.Callback>
        </Modal>
    );
}

export default UploadLinkModal
