import AbstractDataStore from "../../abstracts/AbstractDataStore";
import {ArrayData, BasicData} from "../types";

export enum AddItemMode {
    Prepend = "prepend",
    Append = "append",
}

export type ArraySortFunction<D extends BasicData> = (a: D, b: D) => number;
export type ArrayFilterFunction<D extends BasicData> = (item: D) => boolean;

export type ArrayDataStoreOptions<D extends BasicData> = {
    addMode?: AddItemMode;
    keyName: string;
    sortFunction?: ArraySortFunction<D>;
    filterFunction?: ArrayFilterFunction<D>;
}

export default class ArrayDataStore<D extends BasicData> extends AbstractDataStore<ArrayData<D>> {
    private _data : ArrayData<D>;
    private readonly _options : ArrayDataStoreOptions<D>;

    constructor(initialData: ArrayData<D>, options : ArrayDataStoreOptions<D>) {
        super();
        this._options = options;
        this._data = initialData;
    }

    public updateInitialData(data : ArrayData<D>) : void {
        this._updateData(data);
    }

    protected _updateData(data : ArrayData<D>) : void {
        this._data = data;

        if (this._options.filterFunction) {
            this._data = this._data.filter(this._options.filterFunction);
        }

        if (this._options.sortFunction) {
            this._data = this._data.toSorted(this._options.sortFunction);
        }

        this._callListeners();
    }

    protected get _keyName() : string {
        return this._options.keyName;
    }

    protected _resolveKey(key : string|Partial<D>) : string {
        return typeof key === "string" ? key : key[this._keyName] as string;
    }

    protected _resolveAddMode(mode?: AddItemMode) : AddItemMode {
        return mode ?? this._options.addMode ?? AddItemMode.Append;
    }

    public hasItem(key : string|Partial<D>) : boolean {
        const _key = this._resolveKey(key);
        return this._data.some(item => item[this._keyName] === _key);
    }

    public addItem(data : D, mode?: AddItemMode) : void {
        const _mode = this._resolveAddMode(mode);
        switch (_mode) {
            case AddItemMode.Prepend:
                this._updateData([data, ...this._data]);
                break;
            case AddItemMode.Append:
                this._updateData([...this._data, data]);
                break;
        }
    }

    public updateItemData(data : Partial<D>) : void {
        const key = data[this._keyName];

        if (key === undefined) {
            throw new Error(`Key ${this._keyName} not found in data`);
        }

        this._updateData(this._data.map(item => {
            if (item[this._keyName] === key) {
                return {
                    ...item,
                    ...data,
                }
            }
            return item;
        }));
    }

    public removeItem(key : string|Partial<D>) : void {
        const _key = this._resolveKey(key);
        this._updateData(this._data.filter(item => item[this._keyName] !== _key));
    }

    public updateOrAddItem(data : D, mode?: AddItemMode) : void {
        const key = data[this._keyName];

        if (key === undefined) {
            throw new Error(`Key ${this._keyName} not found in data`);
        }

        if (this.hasItem(key)) {
            this.updateItemData(data);
        } else {
            this.addItem(data, mode);
        }
    }

    public addItems(data : ArrayData<D>, mode?: AddItemMode) : void {
        const _mode = this._resolveAddMode(mode);
        switch (_mode) {
            case AddItemMode.Prepend:
                this._updateData([...data, ...this._data]);
                break;
            case AddItemMode.Append:
                this._updateData([...this._data, ...data]);
                break;
        }
    }

    getData() : ArrayData<D> {
        return this._data;
    }
}