import {useMemo} from "react";

type PaginationLink = {
    type: 'item',
    page: number,
    current: boolean
} | {
    type: 'ellipsis',
}

type UsePaginationLinksParams = {
    currentPage: number,
    perPage: number,
    total: number,
}

function range(start : number, end : number) : number[] {
    const size = end - start + 1;
    return Array(size).fill(0).map((_, i) => i + start);
}

export default function usePaginationLinks({currentPage, perPage, total} : UsePaginationLinksParams) : PaginationLink[] {
    const pagesCount = useMemo(
        () => Math.ceil(total / perPage),
        [total, perPage]
    )

    if (pagesCount < 2) return []

    const startCurrent : number = Math.max(currentPage - 1, 1);
    const endCurrent : number = Math.min(currentPage + 2, pagesCount);

    const pagesShow = range(startCurrent, endCurrent);

    if (pagesShow.indexOf(1) === -1) {
        pagesShow.unshift(1);
    }

    if (pagesShow.indexOf(pagesCount) === -1) {
        pagesShow.push(pagesCount);
    }

    const list: PaginationLink[] = [];
    let prevPage = 0;

    pagesShow.forEach(page => {
        if ((page - prevPage) > 1) {
            list.push({type: 'ellipsis'})
        }

        list.push({type: 'item', page, current: currentPage === page})

        prevPage = page;
    })

    return list
}
