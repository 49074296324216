import {RouteObject} from "react-router-dom";
import {CrudOptions} from "./types";
import {
    isCrudOptionsExtend,
    isCrudOptionsModal,
    isCrudOptionsSimple,
    isHasCreateForm, isHasInitialData,
    isHasSingleForm,
    isHasUpdateForm
} from "./guards";
import makeSimpleCrudIndex from "./simple/makeSimpleCrudIndex";
import makeSimpleCrudShow from "./simple/makeSimpleCrudShow";
import makeSimpleCrudCreateForm from "./simple/makeSimpleCrudCreateForm";
import makeSimpleCrudUpdateForm from "./simple/makeSimpleCrudUpdateForm";
import makeExtendCrudIndex from "./extend/makeExtendCrudIndex";
import makeExtendCrudShow from "./extend/makeExtendCrudShow";
import makeExtendCrudCreateForm from "./extend/makeExtendCrudCreateForm";
import makeExtendCrudUpdateForm from "./extend/makeExtendCrudUpdateForm";
import {SimpleCrudActionParams} from "./simple/SimpleCrudActionParams";
import makeTitlesForSingleForm from "./makeTitlesForSingleForm";

export default function makeCrudRouteChildren(options : CrudOptions) : RouteObject[] {

    const children : RouteObject[] = [];

    const isSimple = isCrudOptionsSimple(options) || isCrudOptionsModal(options);

    if (!isSimple && !isCrudOptionsExtend(options)) {
        return [];
    }

    const params : SimpleCrudActionParams = {
        baseEndpoint: options.baseEndpoint,
        isModal: isCrudOptionsModal(options),
        paramName: options.paramName ?? 'id',
    }

    if (!isCrudOptionsModal(options) && options.index) {
        if (isSimple) {
            children.push(makeSimpleCrudIndex(options.index, params));
        } else {
            children.push(makeExtendCrudIndex(options.index));
        }
    }

    if (options.show) {
        if (isSimple) {
            children.push(makeSimpleCrudShow(options.show, params));
        } else {
            children.push(makeExtendCrudShow(options.show));
        }
    }

    if (isHasSingleForm(options)) {
        if (isSimple) {
            children.push(makeSimpleCrudCreateForm({ ...options.forms, ...makeTitlesForSingleForm(options.forms, 'create') }, { ...params, initialData: isHasInitialData(options.forms) ? options.forms.initialData : undefined }));
            children.push(makeSimpleCrudUpdateForm({ ...options.forms, ...makeTitlesForSingleForm(options.forms, 'update') }, params));
        } else {
            children.push(makeExtendCrudCreateForm({ ...options.forms, ...makeTitlesForSingleForm(options.forms, 'create') }));
            children.push(makeExtendCrudUpdateForm({ ...options.forms, ...makeTitlesForSingleForm(options.forms, 'update') }));
        }
    } else {
        if (isHasCreateForm(options)) {
            if (isSimple) {
                children.push(makeSimpleCrudCreateForm(options.create, { ...params, initialData: isHasInitialData(options.create) ? options.create.initialData : undefined }));
            } else {
                children.push(makeExtendCrudCreateForm(options.create));
            }
        }
        if (isHasUpdateForm(options)) {
            if (isSimple) {
                children.push(makeSimpleCrudUpdateForm(options.update, params));
            } else {
                children.push(makeExtendCrudUpdateForm(options.update));
            }
        }
    }

    return children
}
