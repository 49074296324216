import React from "react";

export type FieldBlockProps = {
    title: string,
    titleSize?: number,
    info?: string,
    children: React.ReactNode,
}

const FieldBlock = ({ title, titleSize = 3, info, children } : FieldBlockProps) => {
    return (
        <div>
            <div className='mb-2'>
                <div className={`fs-${titleSize} fw-bold`}>{title}</div>
                {info && <div className='text-muted small'>{info}</div>}
            </div>
            {children}
        </div>
    );
}

export default FieldBlock
