import {CrudActionFormsSimple} from "../types";
import {RouteObject} from "react-router-dom";
import prepareHandle from "../../helpers/prepareHandle";
import prepareLoader from "../../helpers/prepareLoader";
import prepareAction from "../../helpers/prepareAction";
import {SimpleCrudActionParams} from "./SimpleCrudActionParams";
import wrapElement from "../../helpers/wrapElement";
import {TitlingRouteProps} from "../../types";
import prepareLoaderConfig from "./prepareLoaderConfig";

export default function makeSimpleCrudUpdateForm(options : TitlingRouteProps & CrudActionFormsSimple, params : SimpleCrudActionParams) : RouteObject {
    return {
        path: `:${params.paramName}/edit`,
        handle: prepareHandle(
            options,
            params.isModal,
            prepareAction({
                endpoint: params.baseEndpoint,
                paramName: params.paramName,
                method: 'update',
            }),
        ),
        loader: prepareLoader(
            prepareLoaderConfig({
                helpers: options.helpers,
                relations: options.relations,
            }, {
                baseEndpoint: params.baseEndpoint,
                paramName: params.paramName,
                dataType: 'edit',
            })
        ),
        element: wrapElement(options.element, params.isModal, '../../'),
    }
}
