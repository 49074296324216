import * as React from 'react';
import {GlobalActionButtonDropdown} from "../../../../../AppHelpers/Navigation";
import {Dropdown} from "react-bootstrap";
import classNames from "classnames";
import GlobalButtonBadge from "./GlobalButtonBadge";
import GlobalDropdownButtonItem from "./GlobalDropdownButtonItem";
import GlobalButtonIcon from "./GlobalButtonIcon";

type GlobalDropdownButtonProps = {
    className: string,
    data: GlobalActionButtonDropdown,
}

const GlobalDropdownButton = ({ data, className }: GlobalDropdownButtonProps) => {
    return (
        <Dropdown drop='up-centered'>
            <Dropdown.Toggle variant={data.variant ?? 'secondary'} className={classNames('arrow-none rounded-btn', className)} disabled={data.disabled} title={data.title}>
                <GlobalButtonIcon icon={data.icon} />
                <GlobalButtonBadge data={data.badge} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {Object.entries(data.items ?? {}).map(([ key, action ]) => (
                    <GlobalDropdownButtonItem key={`btn-${key}`} data={action} />
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default GlobalDropdownButton
