import * as React from 'react';
import {ActionButtonConfig} from "../../../types/TableRowActions";
import {Button} from "react-bootstrap";

type RowActionButtonBodyProps = ActionButtonConfig & {
    className?: string,
    onClick: () => void,
}

const RowActionButtonBody = ({ icon, title, className, buttonVariant: variant, onClick }: RowActionButtonBodyProps) => {
    return (
        <Button title={title} onClick={onClick} variant={variant} className={className}>
            {icon && <i className={`mdi mdi-${icon}`}/>}
        </Button>
    );
}

export default RowActionButtonBody
