import * as React from 'react';
import {GlobalActionButtonPortal} from "../../../../../AppHelpers/Navigation";
import {CloseButton} from "react-bootstrap";
import GlobalBasicButton from "./GlobalBasicButton";
import useBoolean from "../../../../../hooks/useBoolean";
import classNames from "classnames";

type GlobalPortalButtonProps = {
    className: string,
    data: GlobalActionButtonPortal,
}

const GlobalPortalButton = ({ className, data: { openIcon, icon, title, portalTitle, registerRemoteStateHandler, portalRefHandler, scrollable = true, popoverClassName, popoverHeaderClassName, popoverBodyClassName, ...options } }: GlobalPortalButtonProps) => {

    const openState = useBoolean();

    React.useEffect(() => {
        if (!registerRemoteStateHandler) {
            return;
        }

        const unregister = registerRemoteStateHandler(openState);
        return () => {
            unregister();
        }
    }, []);

    const currentIcon = openState.state ? openIcon : icon;

    const displayTitle = portalTitle ?? title;

    return (
        <div>
            <div className={classNames('button-portal', { 'show' : openState.state })}>
                <div className={classNames('button-portal-popover', popoverClassName)}>
                    {portalTitle !== false && (
                        <div className={classNames('button-portal-popover-header', popoverHeaderClassName)}>
                            {typeof displayTitle === 'string' ? <h5 className='my-0 me-auto'>{displayTitle}</h5> : displayTitle}
                            <CloseButton onClick={openState.setFalse} />
                        </div>
                    )}
                    <div className={classNames('button-portal-popover-body', popoverBodyClassName, { 'button-portal-popover-body-scroll' : scrollable })} ref={portalRefHandler} />
                </div>
            </div>
            <div className='button-portal-backdrop' role='button' onClick={openState.setFalse} />
            <GlobalBasicButton
                className={className}
                onClick={() => openState.toggle()}
                options={{
                    title,
                    icon: currentIcon,
                    ...options,
                }}
            />
        </div>
    );
}

export default GlobalPortalButton
