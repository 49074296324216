import React, {useCallback} from "react";
import {Controller} from "react-hook-form";
import Select, {OnChangeValue} from "react-select";
import classNames from "classnames";
import FormGroupContainer, {FormGroupContainerBaseProps} from "./FormGroupContainer";
import {FormSelectOption} from "./BaseInputProps";
import ErrorFeedback from "./ErrorFeedback";

export type FormSelectBaseProps = FormGroupContainerBaseProps & {
    isMulti?: boolean,
    allowEmpty?: boolean,
    disableValues?: string[],
    disabled?: boolean,
};

export type FormSelectProps = FormSelectBaseProps & {
    options: FormSelectOption[],
    placeholder?: React.ReactNode,
}

const normalizeValue = (value : any) => {
    if (!value) {
        return [];
    }

    return (Array.isArray(value) ? value : [value]).map(v => v?.toString());
}

const FormSelect = ({ name, options, placeholder, isMulti = false, allowEmpty, disableValues, disabled, ...props } : FormSelectProps) => {

    const handleIsOptionDisabled = useCallback((row : FormSelectOption) => {
        if (!disableValues) {
            return false;
        }

        return disableValues.indexOf(row.value.toString()) !== -1
    }, [ disableValues ])

    return (
        <FormGroupContainer name={name} {...props}>
            {({ isError, errorMessage, control }) => (
                <Controller
                    name={name}
                    control={control}
                    render={({ field: { value, onChange, ref } }) => {
                        const handleChange = (val: OnChangeValue<FormSelectOption, typeof isMulti>) => {
                            if (!val) {
                                onChange(isMulti ? [] : null);
                                return;
                            }

                            if (isMulti) {
                                onChange((val as OnChangeValue<FormSelectOption, true>)?.map(v => v.value) ?? []);
                                return;
                            }

                            onChange(val ? (val as OnChangeValue<FormSelectOption, false>)?.value : null);
                        }

                        const valueNormalized = normalizeValue(value);

                        return (
                            <div className='position-relative'>
                                <Select
                                    ref={ref}
                                    options={options}
                                    isClearable={allowEmpty}
                                    isMulti={isMulti}
                                    isOptionSelected={handleIsOptionDisabled}
                                    value={(options ?? []).filter(c => valueNormalized.indexOf(c.value.toString()) !== -1)}
                                    onChange={handleChange}
                                    className={classNames("react-select", { 'is-invalid' : isError })}
                                    classNamePrefix="react-select"
                                    placeholder={placeholder}
                                    isDisabled={disabled}
                                />
                                <ErrorFeedback isError={isError} errorMessage={errorMessage} />
                            </div>
                        );
                    }}
                />
            )}
        </FormGroupContainer>
    )
}

export default FormSelect
