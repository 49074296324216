import React, {useEffect} from "react";
import {Controller} from "react-hook-form";
import {FormControl} from "react-bootstrap";
import classNames from "classnames";
import FormInput, {FormInputProps} from "./FormInput";
import {useIMask} from "react-imask";
import {FormControlProps} from "react-bootstrap/FormControl";
import InputGroupContainer from "./InputGroupContainer";
import PropTypes from "prop-types";

export type FormMaskInputProps = FormInputProps & FormControlProps & {
    mask: string,
};

type MaskedInputProps = FormControlProps & {
    mask: string,
    onChange: (value: string) => void,
}

const MaskedInput = ({ onChange, mask, value, ...props } : MaskedInputProps) => {

    const { ref, maskRef } = useIMask({ mask: mask });

    useEffect(() => {
        maskRef.current?.on('accept', () => onChange(maskRef.current?.unmaskedValue ?? ''));
    }, [ maskRef.current ])

    return (
        <FormControl
            ref={ref}
            {...props}
            defaultValue={value ?? ''}
        />
    )
};

const FormMaskInput = ({ type, name, mask, className: inputClass, ...props } : FormMaskInputProps) => {

    return (
        <InputGroupContainer name={name} {...props}>
            {({ control, isError, props }) => (
                <Controller
                    name={name}
                    control={control}
                    render={({ field,  }) => (
                        <MaskedInput
                            {...props}
                            className={classNames('form-control', { 'is-invalid' : isError, [inputClass ?? '']: !!inputClass })}
                            mask={mask}
                            value={field.value}
                            onChange={field.onChange}
                        />
                    )}
                />
            )}
        </InputGroupContainer>
    )
}

FormMaskInput.propTypes = {
    ...FormInput.propTypes,
    mask: PropTypes.string.isRequired,
}

export default FormMaskInput
