import * as React from 'react';
import {MouseEventHandler} from "react";
import classNames from "classnames";
import BottomMenuFallback from "./BottomMenuFallback";
import useExternalStore from "../../../../hooks/useExternalStore";
import GlobalButtons from "./GlobalButtons";
import {useAppNavigation} from "../../../../Application";
import {NavMenuItem} from "../../../../AppHelpers/Navigation";
import BottomMenuItem from "./BottomMenuItem";

type BottomMenuProps = {
    items: NavMenuItem[],
}

const BottomMenu = ({ items }: BottomMenuProps) => {

    const appNavigation = useAppNavigation();

    const { isOpen } = useExternalStore(appNavigation.MainNavigation)
    const globalButtons = useExternalStore(appNavigation.GlobalButtons)

    const handleItemClick = () => {
        appNavigation.MainNavigation.toggle(false);
    }

    const handleMenuToggle : MouseEventHandler<HTMLAnchorElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
        appNavigation.MainNavigation.toggle();
    }

    const actionButtonsCnt = Object.keys(globalButtons).length;

    return (
        <React.Fragment>
            {!isOpen && <GlobalButtons data={globalButtons} />}

            <div className={classNames('bottom-menu', !isOpen && {
                'with-rounded-buttons': actionButtonsCnt > 0,
                'with-rounded-buttons-two': actionButtonsCnt > 1,
            })}>
                {items.map((item, idx) => (
                    <BottomMenuItem data={item} key={`item-${idx}`} handleClick={handleItemClick} />
                ))}
                <a href='#' className='bottom-menu-item' onClick={handleMenuToggle}>
                    <i className={classNames('mdi', isOpen ? 'mdi-close' : 'mdi-menu')} />
                    <span>Меню</span>
                </a>
            </div>

        </React.Fragment>

    );
}

export { BottomMenuFallback }
export default BottomMenu
