import * as React from 'react';
import { useRouteError } from 'react-router-dom';
import BaseInfoPage from "./BaseInfoPage";

import image from '../../assets/images/app-error.png'

type Error = {
    message?: string,
    data?: {
        message?: string,
    },
}
const ErrorBoundary = () => {
    const error = useRouteError() as Error;

    const message = error?.message || error?.data?.message || null

    return (
        <BaseInfoPage
            image={image}
            title='Щось пішло не так...'
            message={
                message
                    ? <>Під час виконання програми відбулась критична помилка: {message}</>
                    : <>Під час виконання програми відбулась невідома критична помилка</>
            }
            btnLink='/'
            btnText='Повернутись на початок'
        />
    );
}

export default ErrorBoundary
