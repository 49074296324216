import * as React from 'react';
import Title from "./Title";
import Breadcrumbs from "./Breadcrumbs";

const PageTitle = () => {

    return (
        <div className="row">
            <div className="col-12">
                <div className="page-title-box">
                    <div className="page-title-right">
                        <Breadcrumbs />
                    </div>
                    <Title />
                </div>
            </div>
        </div>
    );
}

export default PageTitle
