import {ArrayData, BasicData} from "../../types";
import useArrayDataStore , {UseArrayDataStoreReturn} from "./useArrayDataStore";
import {useEffect} from "react";
import {useAppWebsocket} from "../../../Application";
import WebsocketArrayDataStoreAdapter, { WebsocketArrayDataStoreAdapterOptions } from "../../ws/WebsocketArrayDataStoreAdapter";
import {ArrayDataStoreOptions} from "../../store/ArrayDataStore";

export type UseWebsocketArrayDataStoreOptions<D extends BasicData> = ArrayDataStoreOptions<D> & WebsocketArrayDataStoreAdapterOptions;

export type UseWebsocketArrayDataStoreReturn<D extends BasicData> = UseArrayDataStoreReturn<D>;

const useWebsocketArrayDataStore = <D extends BasicData>(initialData: ArrayData<D>, opts: UseWebsocketArrayDataStoreOptions<D>): UseWebsocketArrayDataStoreReturn<D>  => {
    const {store, data} = useArrayDataStore(initialData, opts);
    const ws = useAppWebsocket();

    useEffect(() => {
        const adapter = new WebsocketArrayDataStoreAdapter(ws, store, opts);

        adapter.init();

        return () => {
            adapter.destroy();
        };
    }, []);

    return {store, data};
}

export default useWebsocketArrayDataStore;