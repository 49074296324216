import * as React from 'react';
import TopOverlay from "./TopOverlay";
import BouncingLoader from "./BouncingLoader";

const TopOverlayLoading = () => {
    return (
        <TopOverlay>
            <BouncingLoader />
        </TopOverlay>
    );
}

export default TopOverlayLoading
