import * as React from 'react';
import BouncingLoader from "./BouncingLoader";
import {Spinner} from "react-bootstrap";
import FlexCentered from "./FlexCentered";

export type AwaitFallbackProps = {
    variant?: 'block' | 'inline',
}

const AwaitFallback = ({ variant = 'inline' }: AwaitFallbackProps) => {

    if (variant === 'block') {
        return (
            <FlexCentered>
                <BouncingLoader />
            </FlexCentered>
        );
    }

    return (
        <div className='d-inline-flex align-items-center gap-1'>
            <Spinner animation='border' size='sm' />
            <span>Завантаження...</span>
        </div>
    );
}

export default AwaitFallback
