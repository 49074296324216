import * as React from 'react';
import {Button} from "react-bootstrap";
import {Modal, useExternalStore, useModal} from "@ova-studio/react-hyper-admin";
import {FolderView} from "../FolderView";
import {useMediaLibraryService} from "../../services/MediaLibraryService";

const TopbarFolderSelector = () => {

    const folderManager = useMediaLibraryService().folderManager;
    const { folders, selectedFolder: selectedFolderId } = useExternalStore(folderManager);

    const selectorModalState = useModal();

    const selectedFolder = React.useMemo(() => {
        if (!folders || !selectedFolderId) {
            return null;
        }

        return folders.find((folder) => folder.id === selectedFolderId);
    }, [folders, selectedFolderId]);

    return (
        <React.Fragment>
            <Modal title='Вибір папки' state={selectorModalState}>
                <Modal.Body>
                    <FolderView onSelect={selectorModalState.close} />
                </Modal.Body>
            </Modal>

            <div className='d-none d-lg-inline-flex align-items-center'>
                <i className={`mdi mdi-${selectedFolder ? 'folder' : 'folder-multiple-outline'} font-20`} />
                <span className='ms-1 text-truncate'>{selectedFolder?.name ?? 'всі папки'}</span>
            </div>

            <Button variant='outline-secondary' size='sm' className='d-lg-none py-0 d-inline-flex align-items-center' onClick={selectorModalState.open}>
                <i className={`mdi mdi-${selectedFolder ? 'folder' : 'folder-multiple-outline'} font-20 lh-sm`} />
            </Button>
        </React.Fragment>
    );
}

export default TopbarFolderSelector
