import React from "react";
import {RouteObject} from "react-router-dom";
import {CrudActionIndexSimple} from "../types";
import prepareHandle from "../../helpers/prepareHandle";
import prepareLoader from "../../helpers/prepareLoader";
import Datatable from "../../../Datatable";
import {SimpleCrudActionParams} from "./SimpleCrudActionParams";

export default function makeSimpleCrudIndex(options : CrudActionIndexSimple, params : SimpleCrudActionParams) : RouteObject {

    const element = (() => {
        if (options.wrapper) {
            const Wrapper = options.wrapper;
            return (
                <Wrapper>
                    <Datatable renderConfig={options.renderConfig} />
                </Wrapper>
            )
        }

        return <Datatable renderConfig={options.renderConfig} />;
    })();

    return {
        index: true,
        handle: prepareHandle({ ...options, breadcrumb: false }, params.isModal),
        loader: prepareLoader({
            sync: {
                data: {
                    endpoint: params.baseEndpoint,
                    queryString: true,
                }
            }
        }),
        element,
    }
}
