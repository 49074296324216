import * as React from 'react';
import {useFormOptionsContext} from "../Forms/FormBody/FormOptionsContext";
import {Form} from "react-bootstrap";

type ErrorFeedbackProps = {
    isError: boolean,
    errorMessage?: string,
}

const ErrorFeedback = ({ isError, errorMessage }: ErrorFeedbackProps) => {

    const { feedbackType } = useFormOptionsContext();

    if (!isError) {
        return null;
    }

    return (
        <Form.Control.Feedback type='invalid' tooltip={feedbackType === 'tooltip'} className='force-display'>
            {errorMessage ?? 'Невідома помилка'}
        </Form.Control.Feedback>
    );
}

export default ErrorFeedback
