import React from "react";
import PropTypes from "prop-types";
import {FormControl} from 'react-bootstrap'
import InputGroupContainer, {InputGroupContainerBaseProps} from "./InputGroupContainer";
import {FormControlProps} from "../types";

export type FormInputProps = InputGroupContainerBaseProps & FormControlProps

const FormInput = ({ type, name, ...props } : FormInputProps) => {

    return (
        <InputGroupContainer {...props} name={name}>
            {({ isError, register, props }) => (
                <FormControl
                    type={type}
                    as={type === 'textarea' ? 'textarea' : 'input'}
                    {...props}
                    isInvalid={isError}
                    {...register(name)}
                />
            )}
        </InputGroupContainer>
    )
}

FormInput.propTypes = {
    ...FormControl.propTypes,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    groupClass: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    prepend: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    append: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
}

FormInput.defaultProps = {
    label: null,
    type: 'text',
    name: 'name',
    placeholder: null,
    text: null,
    prepend: null,
    append: null,
    options: null,
}

export default FormInput
