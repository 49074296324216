import * as React from "react";
import AbstractDataStore from "../../abstracts/AbstractDataStore";
import {
    Action,
    ActionCheckData,
    ActionDefinition,
    ActionResultData, ActionResultHandlers, ActionsConfig,
    ActionsList,
    ActionStatus, ActiveAction,
    ActiveActions,
    LoadActionOptions,
    LoadActionsOptions
} from "./types";
import API, {getApiErrorMessage} from "@ova-studio/api-helper";
import {v4 as uuid} from "uuid";
import {Spinner} from "react-bootstrap";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {
    isActionResultData,
    isConfirmableActionCheckData,
    isContinueActionCheckData, isCustomFormActionCheckData,
    isFormActionCheckData
} from "./guards";
import ErrorsDisplay from "../../components/ErrorsDisplay";
import {SimpleCallback} from "../../types/SimpleCallback";
import {deleteRecord} from "../../helpers/recordHelpers";
import {makeMessage} from "../../helpers/makeMessage";
import ReactMarkdown from "react-markdown";
import App from "../../Application/App";
import {ChannelListener} from "../Websocket";

export default class Actions extends AbstractDataStore<ActiveActions> {

    private _actions: ActiveActions = [];
    private _websocketUnregister: Record<string, SimpleCallback> = {};

    private _resultRenderers: Record<string, React.ComponentType<{ data: any }>> = {};
    private _customForms: Record<string, React.ComponentType> = {};
    private readonly _app: App;
    private readonly _config: ActionsConfig;

    constructor(app: App, config: ActionsConfig) {
        super();
        this._app = app;
        this._config = config;
    }

    private _makeName(model: string, action: string) : string {
        return model + '.' + action;
    }

    // noinspection JSUnusedGlobalSymbols
    public registerResultRenderer(model: string, action: string, renderer: React.ComponentType<{ data: any }>) : void {
        this._resultRenderers[this._makeName(model, action)] = renderer;
    }

    private _getResultRenderer(model: string, action: string) : React.ComponentType<{ data: any }> | undefined {
        return this._resultRenderers[this._makeName(model, action)];
    }

    // noinspection JSUnusedGlobalSymbols
    public registerCustomForm(name: string, component: React.ComponentType) : void {
        this._customForms[name] = component;
    }

    public getCustomFormComponent(name: string) : React.ComponentType {
        if (!this._customForms[name]) {
            throw new Error('Custom form ' + name + ' not found');
        }

        return this._customForms[name];
    }

    private _makeEndpoint(model: string, action?: string, suffix?: string) : string {
        return this._config.endpoint + '/' + model + (action ? '/' + action : '') + (suffix ? '/' + suffix : '');
    }

    public loadAction(options: LoadActionOptions) : Promise<Action> {
        return new Promise<Action>((resolve, reject) => {
            API.getData<ActionDefinition>(this._makeEndpoint(options.model, options.action), { ids: options.ids })
                .then(action => resolve({ ...action, ids: options.ids, model: options.model }))
                .catch(e => reject(e))
        });
    }

    public loadActions(options: LoadActionsOptions) : Promise<ActionsList> {
        return new Promise<ActionsList>((resolve, reject) => {
            API.getData<ActionDefinition[]>(this._makeEndpoint(options.model), { ids: options.ids, filter: options.filter })
                .then(actions => resolve(actions.map(a => ({ ...a, ids: options.ids, model: options.model }))))
                .catch(e => reject(e))
        });
    }

    public handleAction(action: Action, handlers?: ActionResultHandlers) : Promise<void> {
        const toast = this._app.toasts.createToast({
            icon: action.icon,
            title: action.title,
            body: <><Spinner animation='border' size='sm' className='me-1' /> Завантаження, зачекайте...</>,
            disableClose: true,
        });

        return new Promise((resolve) => {
            const actionId = uuid();
            const endpoint = API._makeUrl(this._makeEndpoint(action.model, action.name), { ids: action.ids });

            this._actions.push({
                actionId,
                endpoint,
                action,
                status: ActionStatus.WaitCheck,
                data: undefined,
                toast,
                handleRun: (data) => this._runAction(actionId, data),
                handleCancel: () => this._cancelAction(actionId),
                getResultRenderer: () => this._getResultRenderer(action.model, action.name),
                isRunning: false,
                handlers,
            })

            this._callListeners();
            void this._checkAction(actionId);
            resolve();
        });
    }

    private _getAction(actionId: string) : ActiveAction | undefined {
        return this._actions.find(a => a.actionId === actionId);
    }

    private _updateAction(actionId: string, action: Partial<ActiveAction>) : void {
        const index = this._actions.findIndex(a => a.actionId === actionId);
        if (index === -1) return;
        this._actions[index] = { ...this._actions[index], ...action };
        this._callListeners();
    }

    private _handleActionError(actionId: string, errors: string) : void {

        const action = this._getAction(actionId);
        if (!action) return;

        this._updateAction(actionId, {
            status: ActionStatus.Error,
            data: {
                message: errors,
            }
        });

        if (typeof errors === 'string') {
            action.toast.update({ variant: 'danger', body: <ReactMarkdown className='markdown-text' children={errors} />, showTime: 10, disableClose: false });
        } else {
            action.toast.update({ variant: 'danger', body: <ErrorsDisplay errors={errors} />, showTime: 10, disableClose: false });
        }

        this._clearWebsocketHandler(actionId);
        this._clearAction(actionId);
    }

    private async _checkAction(actionId: string) : Promise<void> {

        const action = this._getAction(actionId);
        if (!action) return;

        try {
            const checkEndpoint = this._makeEndpoint(action.action.model, action.action.name, 'check');

            const data = await API.getData<ActionCheckData>(checkEndpoint, { ids: action.action.ids });

            if (isConfirmableActionCheckData(data)) {
                this._updateAction(actionId, {
                    status: ActionStatus.Confirming,
                    data,
                });

                action.toast.hide();
                return;
            }

            if (isFormActionCheckData(data) || isCustomFormActionCheckData(data)) {
                this._updateAction(actionId, {
                    status: ActionStatus.WaitData,
                    data,
                });

                action.toast.hide();
                return;
            }

            if (isContinueActionCheckData(data)) {
                await this._runAction(actionId, { continue: true });
                return;
            }

            this._handleActionError(actionId, 'Невідома помилка');
        } catch (e) {
            this._handleActionError(actionId, getApiErrorMessage(e));
        }
    }

    private async _runAction(actionId: string, data: FieldValues) : Promise<void> {

        const action = this._getAction(actionId);
        if (!action) return;

        this._updateAction(actionId, { isRunning: true });

        action.toast.update({
            variant: undefined,
            body: <><Spinner animation='border' size='sm' className='me-1' /> Виконується дія, зачекайте...</>,
            disableClose: true,
        });
        action.toast.show();

        this._makeWebsocketHandler(actionId);

        try {
            const { data: resultData } = Object.values(data).some(v => v instanceof File || v instanceof FileList)
                ? await API.postWithFile(action.endpoint, { ...data, action_id: actionId })
                : await API.post(action.endpoint, { ...data, action_id: actionId });

            await this._handleActionResult(actionId, resultData);
        } catch (e) {
            this._updateAction(actionId, { isRunning: false });
            action.toast.hide();
            throw e;
        }
    }

    private async _handleActionResult(actionId: string, data: ActionResultData) : Promise<void> {

        const action = this._getAction(actionId);
        if (!action) return;

        if (!action.isRunning) return;

        if (isActionResultData(data, ActionStatus.Success)) {
            this._updateAction(actionId, {
                status: ActionStatus.Success,
                data: data,
                isRunning: false,
            });

            action.toast.update({
                variant: 'success',
                body: <ReactMarkdown className='markdown-text' children={makeMessage(data.message, 'Успішно')} />,
                showTime: 10,
                disableClose: false,
            });
            action.toast.show();

            this._clearWebsocketHandler(actionId);

            if (!data.data) {
                this._clearAction(actionId);
            }

            if (data.redirect && data.redirect.length > 0) {
                this._app.navigation.navigate(data.redirect);
            }

            action.handlers?.onSuccess?.();
            action.handlers?.onAny?.();

            return;
        }

        if (isActionResultData(data, ActionStatus.Error)) {
            this._updateAction(actionId, {
                status: ActionStatus.Error,
                data: data,
                isRunning: false,
            });

            action.toast.update({
                variant: 'danger',
                body: <ReactMarkdown className='markdown-text' children={makeMessage(data.message, 'Помилка виконання')} />,
                showTime: 10,
                disableClose: false,
            });
            action.toast.show();

            this._clearWebsocketHandler(actionId);

            if (!data.data) {
                this._clearAction(actionId);
            }

            action.handlers?.onError?.();
            action.handlers?.onAny?.();

            return;
        }

        if (isActionResultData(data, ActionStatus.Queued)) {
            this._updateAction(actionId, {
                status: ActionStatus.Queued,
                data: data,
            })

            action.toast.update({
                variant: undefined,
                body: <><Spinner animation='border' size='sm' className='me-1' /> Дія в черзі, зачекайте...</>,
                disableClose: true,
            });
            action.toast.show();

            return;
        }

        this._handleActionError(actionId, 'Невідома помилка');
    }

    private _makeWebsocketHandler(actionId: string) {
        const handleEvent : ChannelListener = (data : ActionResultData) => {
            void this._handleActionResult(actionId, data);
            this._clearWebsocketHandler(actionId);
        }

        this._websocketUnregister[actionId] = this._app.websocket.listen('Action.' + actionId, '.action.result', handleEvent);
    }

    private _clearWebsocketHandler(actionId: string) {
        if (this._websocketUnregister[actionId]) {
            this._websocketUnregister[actionId]();
            this._websocketUnregister = deleteRecord(this._websocketUnregister, actionId);
        }
    }

    private _clearAction(actionId: string) {
        this._actions = this._actions.filter(a => a.actionId !== actionId);
        this._callListeners();
    }

    private _cancelAction(actionId: string) {
        this._clearWebsocketHandler(actionId);
        this._clearAction(actionId);
    }

    getData(): ActiveActions {
        return this._actions;
    }
}
