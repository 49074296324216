import * as React from "react";
import Form from "../../../Form";
import {DatatableFilter} from "../../types/DatatableFilters";

type FilterInputProps = {
    controlId: string,
    filter: DatatableFilter,
}

const FilterInput = ({ controlId, filter } : FilterInputProps) => {
    switch (filter.type) {
        case 'date':
        case 'datetime': {
            const minDate = filter.values.min_dt ? new Date(filter.values.min_dt) : undefined;
            const maxDate = filter.values.max_dt ? new Date(filter.values.max_dt) : undefined;

            return (
                <Form.Datetime
                    name={filter.name}
                    controlId={controlId}
                    isTime={filter.type === 'datetime'}
                    minDate={minDate}
                    maxDate={maxDate}
                    groupClass=''
                />
            )
        }

        case 'date-range':
        case 'datetime-range':  {
            const minDate = filter.values.min_dt ? new Date(filter.values.min_dt) : undefined;
            const maxDate = filter.values.max_dt ? new Date(filter.values.max_dt) : undefined;

            return (
                <Form.DatetimeRange
                    name={filter.name}
                    controlId={controlId}
                    isTime={filter.type === 'datetime-range'}
                    minDate={minDate}
                    maxDate={maxDate}
                    groupClass=''
                />
            )
        }

        case 'select': {
            return (
                <Form.Select
                    name={filter.name}
                    controlId={controlId}
                    isMulti={true}
                    options={filter.values}
                    groupClass=''
                />
            )
        }

        case 'toggle': {
            return (
                <React.Fragment>
                    {Object.entries(filter.values).map(([key, value]) => (
                        <Form.Check
                            key={key}
                            className='mb-1'
                            name={filter.name}
                            controlId={`${controlId}.${key}`}
                            type='radio'
                            label={value}
                        />
                    ))}
                </React.Fragment>
            )
        }

        case "boolean": {
            return (
                <Form.Check
                    name={filter.name}
                    controlId={controlId}
                    type='switch'
                />
            )
        }

        default: {
            return (
                <Form.Input
                    name={filter.name}
                    controlId={controlId}
                    groupClass=''
                />
            )
        }
    }
}

type FilterRowProps = {
    filter: DatatableFilter,
}

const FilterRow = ({ filter } : FilterRowProps) => {

    const controlId = React.useId();

    if (filter.label === '[hidden]') {
        return null;
    }

    return (
        <React.Fragment>
            <label className='fw-bold pe-2' htmlFor={controlId}>{filter.label}</label>
            <div><FilterInput filter={filter} controlId={controlId} /></div>
        </React.Fragment>
    )
}

export default FilterRow
