import {Validator} from "../../types/Validator";

export default class Services {

    private _services : Record<string, any> = {};

    public register(key: string, service: any) : void {
        this._services[key] = service;
    }

    public get<T>(key: string, validator: Validator<T>) : T {
        if (!this._services[key]) {
            throw new Error(`Service ${key} not found`);
        }

        if (!validator(this._services[key])) {
            throw new Error(`Service ${key} is not of the expected type`);
        }

        return this._services[key] as T;
    }

}
