import {FormInputProps} from "../Fields/FormInput";
import {FormHiddenInputProps} from "../Fields/FormHiddenInput";
import {FormCheckProps} from "../Fields/FormCheck";
import {FormDatetimeInputProps} from "../Fields/FormDatetimeInput";
import {FormDatetimeRangeInputProps} from "../Fields/FormDatetimeRangeInput";
import {FormMapProps} from "../Fields/FormMap";
import {FormMaskInputProps} from "../Fields/FormMaskInput";
import {FormSelectProps} from "../Fields/FormSelect";
import {CustomDynamicFormFieldProps} from "./CustomDynamicFormField";

export enum ControlType {
    Input = 'input',
    Hidden = 'hidden',
    Check = 'check',
    Datetime = 'datetime',
    DatetimeRange = 'datetime-range',
    Map = 'map',
    MaskInput = 'mask-input',
    Select = 'select',
    Custom = 'custom',
}

export type ControlPropsMap = {
    [ControlType.Input]: FormInputProps,
    [ControlType.Hidden]: FormHiddenInputProps,
    [ControlType.Check]: FormCheckProps,
    [ControlType.Datetime]: FormDatetimeInputProps,
    [ControlType.DatetimeRange]: FormDatetimeRangeInputProps,
    [ControlType.Map]: FormMapProps,
    [ControlType.MaskInput]: FormMaskInputProps,
    [ControlType.Select]: FormSelectProps,
    [ControlType.Custom]: CustomDynamicFormFieldProps,
}

export type ControlProps<T> = T extends ControlType ? ControlPropsMap[T] : never;

export type DynamicFormField<T = ControlType, P = ControlProps<T>> = {
    type: T,
    props: P,
}

export function isDynamicFormField(obj: any) : obj is DynamicFormField {
    return typeof obj !== 'undefined'
        && obj.hasOwnProperty('type')
        && Object.values(ControlType).includes(obj.type)
        && obj.hasOwnProperty('props')
}

export function isControlType<T extends ControlType>(obj: any, type: T) : obj is DynamicFormField<T> {
    return typeof obj !== 'undefined'
        && obj.hasOwnProperty('type')
        && obj.type === type;
}

export type DynamicFormFields = DynamicFormField[]

export function isDynamicFormFields(obj: any) : obj is DynamicFormFields {
    return Array.isArray(obj) && obj.every(isDynamicFormField)
}
