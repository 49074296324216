import {WebsocketItemDataStoreAdapterOptions} from "../../ws/WebsocketItemDataStoreAdapter";
import useWebsocketItemDataStore, {UseWebsocketItemDataStoreReturn} from "./useWebsocketItemDataStore";
import {BasicData} from "../../types";
import useLoaderData from "../useLoaderData";

export type UseWebsocketLoaderItemDataStoreReturn<D extends BasicData> = UseWebsocketItemDataStoreReturn<D>;

export const useWebsocketLoaderItemDataStore = <D extends BasicData>(dataKey: string, opts: WebsocketItemDataStoreAdapterOptions<D>) : UseWebsocketLoaderItemDataStoreReturn<D> => {
    const initialData = useLoaderData<D>(dataKey);
    return useWebsocketItemDataStore(initialData, opts);
}

export default useWebsocketLoaderItemDataStore;