import * as React from "react";
import FormControl from "react-bootstrap/FormControl";
import {useAppSearch} from "../../../Application";
import useExternalStore from "../../../hooks/useExternalStore";
import SearchDropdownBlock from "./SearchDropdownBlock";
import classNames from "classnames";

const SearchDisplay = () => {

    const search = useAppSearch();
    const { query, isActive} = useExternalStore(search);

    return (
        <div className={classNames('search-app', {'active': isActive})}>
            <div className='backdrop' onClick={search.close.bind(search)}/>
            <div className='search-app-suggest'>
                <span><b>&larr;</b> / <b>&rarr;</b> - переключення між вкладками,</span>{' '}
                <span><b>&uarr;</b> / <b>&darr;</b> - навігація по списку,</span>{' '}
                <span><b>Enter</b> - переглянути,</span>{' '}
                <span><b>Ctrl+Enter</b> - редагувати,</span>{' '}
                <span><b>Esc</b> - закрити</span>
            </div>
            <FormControl
                ref={search.handleInputRef.bind(search)}
                tabIndex={0}
                inputMode='search'
                placeholder='Пошук...'
                value={query}
                onChange={e => search.setQuery(e.target.value)}
                onFocus={search.activate.bind(search)}
            />
            <SearchDropdownBlock/>
        </div>
    )
}

export default SearchDisplay;