import * as React from "react";
import {useAppSearch} from "../../../Application";
import useExternalStore from "../../../hooks/useExternalStore";
import SearchResultRender from "./SearchResultRender";

const SearchResultDisplay = () => {

    const search = useAppSearch();
    const { query, results, selectedResultIdx, hasMore, isLoadingMore, selectedType} = useExternalStore(search);

    if (results === null) {
        return null;
    }

    if (!results.length) {
        return (
            <div className='text-muted'>
                За запитом "{query}" нічого не знайдено.
            </div>
        );
    }

    return (
        <React.Fragment>
            {results.map((result, idx) => (
                <SearchResultRender key={idx} result={result} isSelected={idx === selectedResultIdx} type={selectedType!} />
            ))}
            {hasMore && (
                <div className='search-app-result' role='button' onClick={search.handleMore.bind(search)} aria-disabled={isLoadingMore}>
                    {isLoadingMore ? <span className='text-muted'>Завантаження...</span> : 'Показати ще'}
                </div>
            )}
        </React.Fragment>
    )
}

export default SearchResultDisplay;