import * as React from 'react';

const BouncingLoader = () => (
    <div className="bouncing-loader">
        <div />
        <div />
        <div />
    </div>
)

export default BouncingLoader
