import * as React from 'react';
import useFormError from "../hooks/useFormError";
import {Alert} from "react-bootstrap";

export type ErrorAlertProps = {
    name: string,
}

const ErrorAlert = ({ name }: ErrorAlertProps) => {

    const { isError, errorMessage } = useFormError(name);

    if (!isError) {
        return null;
    }

    return (
        <Alert variant='danger'>{errorMessage}</Alert>
    )
}

export default ErrorAlert
