import * as React from "react";
import classNames from "classnames";

type FallbackAvatarProps = {
    name: string,
    style?: React.CSSProperties,
    className?: string,
}

const FallbackAvatar = ({ name, style, className }: FallbackAvatarProps) => {

    const initials = React.useMemo<string>(() => {
        return name?.split(/\s+/)
            .map(s => s.substring(0, 1))
            .slice(0, 2)
            .join('').toUpperCase() ?? '??'
    }, [ name ])

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            height="100"
            width="100"
            className={className}
            style={style}
        >
            <circle cx="50" cy="50" r="50" fill="#ccc" style={{ opacity: .5 }} />
            <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="40" fill="#fff">
                {initials}
            </text>
        </svg>
    );
}

export type AvatarProps = {
    name: string,
    avatarUrl?: string,
    color?: string,
    borderColor?: string,
    className?: string,
}

const Avatar = ({ name, avatarUrl, color, borderColor, className: addClassName }: AvatarProps) => {

    const className = classNames(
        'avatar',
        avatarUrl ? 'avatar-image' : 'avatar-fallback',
        addClassName,
        {
            'avatar--bordered': !!borderColor,
            'avatar--colored': !!color,
        }
    );

    const styles = {
        '--avatar-color': color ?? '',
        '--avatar-border-color': borderColor ?? '',
    } as React.CSSProperties

    if (avatarUrl) {
        return (
            <img
                src={avatarUrl}
                alt={name}
                className={className}
                style={styles}
            />
        )
    }

    return (
        <FallbackAvatar
            name={name}
            className={className}
            style={styles}
        />
    )
}

export default Avatar;