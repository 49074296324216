import React from "react";
import {Controller} from "react-hook-form";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import DatepickerInput from "./DatepickerInput";
import FormGroupContainer, {FormGroupContainerBaseProps} from "./FormGroupContainer";
import convertDate from "../../helpers/convertDate";
import ErrorFeedback from "./ErrorFeedback";

export type DatepickerProps = {
    isTime?: boolean,
    minDate?: Date|string,
    maxDate?: Date|string,
    monthsShown?: number,
    showTimeSelectOnly?: boolean,
    inline?: boolean,
    disabled?: boolean,
    readOnly?: boolean,
}

export type FormDatetimeInputBaseProps = FormGroupContainerBaseProps & DatepickerProps

export type FormDatetimeInputProps = FormDatetimeInputBaseProps;

const FormDatetimeInput = ({ name, isTime = false, minDate, maxDate, monthsShown, showTimeSelectOnly, inline, disabled, readOnly, ...props } : FormDatetimeInputProps) => {

    return (
        <FormGroupContainer name={name} {...props}>
            {({ isError, errorMessage, control }) => (
                <Controller
                    name={name}
                    control={control}
                    render={({ field }) => (
                        <div className='position-relative'>
                            <DatePicker
                                customInput={(
                                    <DatepickerInput
                                        handleChange={(value : Date) => field.onChange(value?.toISOString() ?? null)}
                                    />
                                )}
                                className={classNames('form-control', { 'is-invalid' : isError })}
                                onChange={(value : Date) => field.onChange(value?.toISOString() ?? null)}
                                selected={field.value ? new Date(field.value) : null}
                                dateFormat={isTime ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy"}
                                timeFormat="HH:mm"
                                timeInputLabel='Час'
                                minDate={convertDate(minDate)}
                                maxDate={convertDate(maxDate)}
                                monthsShown={monthsShown}
                                inline={inline}
                                showTimeSelectOnly={showTimeSelectOnly}
                                autoComplete="off"
                                disabled={disabled}
                                readOnly={readOnly}
                                showTimeInput={isTime}
                            />
                            <ErrorFeedback isError={isError} errorMessage={errorMessage} />
                        </div>
                    )}
                />
            )}
        </FormGroupContainer>
    )
}

export default FormDatetimeInput
