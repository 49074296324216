import * as React from 'react';
import {useNavigate} from "react-router-dom";
import GlobalDropdownButtonItemBasic from "./GlobalDropdownButtonItemBasic";
import {GlobalActionButtonNavigate} from "../../../../../AppHelpers/Navigation";

type GlobalDropdownButtonItemNavigateProps = {
    data: GlobalActionButtonNavigate,
}

const GlobalDropdownButtonItemNavigate = ({ data }: GlobalDropdownButtonItemNavigateProps) => {
    const navigate = useNavigate();

    return (
        <GlobalDropdownButtonItemBasic
            onClick={() => navigate(data.url)}
            options={data}
        />
    );
}

export default GlobalDropdownButtonItemNavigate
