import {useCallback, useState} from "react";

export interface UseBooleanReturn {
    state: boolean;
    toggle: (newState? : boolean|null) => void;
    setTrue: () => void;
    setFalse: () => void;
}

export default function useBoolean(initialState: boolean = false) : UseBooleanReturn {

    const [ state, setState ] = useState<boolean>(initialState);

    const toggle = useCallback((newState : boolean|null = null) => {
        if (newState === null) {
            setState(currentState => !currentState)
        } else {
            setState(newState);
        }
    }, [])

    const setTrue = useCallback(() => {
        setState(true);
    }, [])

    const setFalse = useCallback(() => {
        setState(false);
    }, [])

    return { state, toggle, setTrue, setFalse }

}
