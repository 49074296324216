import {createContext} from "react";
import {DatatableConfig} from "../types/DatatableConfig";
import {DatatableColumn, DatatableData, DatatableRow} from "../types/DatatableData";
import defaultConfig from "../defaults/config";
import {RenderConfigData} from "../types/RenderConfig";
import {DatatableQueryString} from "../types/DatatableQueryString";
import {ColumnOptions, DatatableSettings} from "../types/DatatableSettings";

export type DatatableColumnShow = DatatableColumn & ColumnOptions

export type SortDirection = 'asc'|'desc'|null;

type SortsList = {
    [key: string]: {
        dir: SortDirection,
        order: number,
    };
}

export type DatatableContextData = {
    settings: DatatableSettings,
    setSettings: (columns: DatatableSettings) => void,

    showColumns: DatatableColumnShow[],

    config: DatatableConfig,
    renderConfig: RenderConfigData|null,

    isResults: boolean,

    isFilters: boolean,
    activeFilters: DatatableQueryString['filter'],
    isActiveFilters: boolean,

    isSort: boolean,
    activeSorts: SortsList,
    toggleSort: (key: string) => void,
    setSorts: (data: SortsList) => void,

    setSearchParams: (data? : DatatableQueryString) => void,

    data: DatatableData,
    rows: DatatableRow[],

    hasGroupActions: boolean,
    rowsSelected: (number|string)[],
    setAllRowsSelected: (state: boolean) => void,
    isAllRowsSelected: boolean,
    setRowSelected: (id : number|string, state: boolean) => void,
    isRowSelected: (id : number|string) => boolean,
}

const DatatableContext = createContext<DatatableContextData>({
    settings: {
        displayColumns: {},
        disableMobileLayout: false,
    },
    setSettings: () => {},

    showColumns: [],

    config: defaultConfig,
    renderConfig: null,

    isResults: false,

    isFilters: false,
    activeFilters: {},
    isActiveFilters: false,

    isSort: false,
    activeSorts: {},
    toggleSort: () => {},
    setSorts: () => {},

    setSearchParams: () => {},

    hasGroupActions: false,
    rowsSelected: [],
    setRowSelected: () => {},
    setAllRowsSelected: () => {},
    isAllRowsSelected: false,
    isRowSelected: () => false,

    data: {
        name: 'unknown',
        options: {
            hasRowActions: false,

            hasIndexes: false,

            actionsModel: 'test',
        },
        columns: [],
        filters: [],
        data: [],
        pagination: null,
    },

    rows: [],
})

export default DatatableContext;
