import {ReactNode, ReactPortal} from "react";
import {createPortal} from "react-dom";

export type TopbarRowOptions = {
    fixed?: boolean,
    desktop?: boolean,
    mobile?: boolean,
    fullSize?: boolean,
}

export default class TopbarRow {

    private _container: HTMLDivElement|null = null;
    private _isNode: boolean = false;
    private _opts: TopbarRowOptions = {};

    public createPortal(node: ReactNode, opts: TopbarRowOptions) : ReactPortal {
        if (!this._container) {
            throw new Error("TopbarRow: container is not set");
        }

        this._isNode = true;
        this._opts = opts;

        this._updateBodyClass();

        return createPortal(node, this._container);
    }

    public handlePortalUnmount() {
        this._isNode = false;
        this._opts = {};
        this._updateBodyClass();
    }

    // noinspection JSUnusedGlobalSymbols
    public setOptions(opts: TopbarRowOptions) {
        this._opts = opts;
        this._updateBodyClass();
    }

    public updateOptions(opts: Partial<TopbarRowOptions>) {
        this._opts = {
            ...this._opts,
            ...opts,
        }
        this._updateBodyClass();
    }

    /**
     * @deprecated use updateOptions instead
     **/
    // noinspection JSUnusedGlobalSymbols
    public setFixed(fixed: boolean) {
        this.updateOptions({ fixed });
        this._updateBodyClass();
    }

    private _updateBodyClass() {
        const isContainer = !!this._container && this._isNode;

        document.body.classList.toggle('has-topbar-row__fixed', isContainer && this._opts.fixed);
        document.body.classList.toggle('has-topbar-row__desktop', isContainer && this._opts.desktop);
        document.body.classList.toggle('has-topbar-row__mobile', isContainer && this._opts.mobile);
        document.body.classList.toggle('has-topbar-row__full-size', isContainer && this._opts.fullSize);
    }

    setContainer(container: HTMLDivElement) {
        this._container = container;
    }
}
