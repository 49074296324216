import * as React from 'react';
import useBoolean from "../../hooks/useBoolean";
import Form from "../../Form";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {useAppAuth} from "../../Application";

const LoginFormCredentials = () => {

    const passwordDisplay = useBoolean();
    const appAuth = useAppAuth();

    React.useEffect(() => {
        if (!passwordDisplay.state) return;
        const timeout = setTimeout(() => passwordDisplay.toggle(false), 3000);
        return () => clearTimeout(timeout);
    }, [ passwordDisplay.state ]);

    const handleSubmit = React.useCallback((data: FieldValues) => appAuth.authenticator.login(data), []);

    return (
        <Form.Callback handleSubmit={handleSubmit} showSuccess={false}>
            <Form.Input name='username' label="Ім'я користувача" />
            <Form.Input
                name='password'
                type={passwordDisplay.state ? 'text' : 'password'}
                label="Пароль"
                append={<i className={`mdi mdi-${passwordDisplay.state ? 'eye-off' : 'eye'}`} role='button' onClick={() => passwordDisplay.toggle()} />}
            />
            <div className='d-flex align-items-center'>
                <Form.Check name='no-remember' label="Чужий комп'ютер" />
                <Form.Submit className='ms-auto' enable={true}>Увійти</Form.Submit>
            </div>
        </Form.Callback>
    );
}

export default LoginFormCredentials
