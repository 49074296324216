import * as React from 'react';

export type FlexCenteredProps = {
    addClassName?: string,
    children: React.ReactNode,
}

const FlexCentered = ({ addClassName, children }: FlexCenteredProps) => {
    return (
        <div className={'h-100 d-flex flex-column align-items-center justify-content-center ' + (addClassName ?? '')}>
            {children}
        </div>
    );
}

export default FlexCentered
