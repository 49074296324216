import {isDynamicFormField} from "../../Form/dynamic-form/types";
import {
    ActionResultData, ActionResultStatus, ActionStatus, ActiveAction,
    ConfirmableActionCheckData,
    ContinueActionCheckData, CustomFormActionCheckData,
    FormActionCheckData
} from "./types";

export function isConfirmableActionCheckData(obj: any) : obj is ConfirmableActionCheckData {
    return typeof obj !== 'undefined'
        && obj.hasOwnProperty('text')
        && typeof obj.text !== 'undefined'
}

export function isFormActionCheckData(obj: any) : obj is FormActionCheckData {
    return typeof obj !== 'undefined'
        && obj.hasOwnProperty('fields')
        && Array.isArray(obj.fields)
        && obj.fields.every((i: any) => isDynamicFormField(i));
}

export function isCustomFormActionCheckData(obj: any) : obj is CustomFormActionCheckData {
    return typeof obj !== 'undefined'
        && obj.hasOwnProperty('formName')
        && typeof obj.formName === 'string';
}

export function isContinueActionCheckData(obj: any) : obj is ContinueActionCheckData {
    return typeof obj !== 'undefined'
        && obj.hasOwnProperty('continue')
        && obj.continue === true;
}

export function isActiveActionStatus<T extends ActionStatus>(obj: any, status: T) : obj is ActiveAction<T> {
    return typeof obj !== 'undefined'
        && obj.hasOwnProperty('status')
        && obj.status === status;
}

export function isActionResultData<T extends ActionResultStatus>(obj: any, status: T) : obj is ActionResultData<T> {
    return typeof obj !== 'undefined'
        && obj.hasOwnProperty('status')
        && obj.status === status;
}
