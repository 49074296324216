import {LoaderFunction} from "react-router-dom";
import makeLoaderFromConfig, {ConfigLoaderDefinition, LoaderConfig} from "./makeLoaderFromConfig";

export type LoaderDefinition = ConfigLoaderDefinition | LoaderFunction;

export default function makeLoader(loaderDef : LoaderDefinition) : LoaderFunction {
    if (typeof loaderDef === 'function') {
        return loaderDef;
    }

    return makeLoaderFromConfig(loaderDef);
}

export type { ConfigLoaderDefinition, LoaderConfig }
