import * as React from 'react';
import Preloader from "../components/Preloader";
import {Outlet, useNavigate} from "react-router-dom";
import {useAppNavigation} from "../Application";

const GlobalAppWrapper = () => {

    const navigate = useNavigate();
    const appNavigation = useAppNavigation();

    React.useEffect(() => {
        return appNavigation.setNavigate(navigate);
    }, []);

    return (
        <React.Suspense fallback={<Preloader />}>
            <Outlet />
        </React.Suspense>
    );
}

export default GlobalAppWrapper
