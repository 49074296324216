import {FormMethods} from "../types";
import {isApiValidationError, mapValidationErrors, getApiErrorMessage, getErrorsListFromError} from "@ova-studio/api-helper";
import dot from "dot-object";

export type ProcessFormErrorsReturn = {
    errorTitle: string | string[],
    globalErrors?: string[],
}

const resolveValidationErrorTitle = (error: unknown) : ProcessFormErrorsReturn['errorTitle'] => {
    if (!isApiValidationError(error)) {
        throw new Error('Invalid error type');
    }

    const errorsList = getErrorsListFromError(error);

    if (errorsList.unknown?.length > 0) {
        return errorsList.unknown.length === 1 ? errorsList.unknown[0] : errorsList.unknown;
    }

    if (errorsList.global?.length > 0) {
        return errorsList.global.length === 1 ? errorsList.global[0] : errorsList.global;
    }

    return 'Дані форми містять помилки або не відповідають вимогам. Будь ласка, перевірте форму та спробуйте ще раз.';
}

const processFormErrors = (error: unknown, methods: FormMethods) : ProcessFormErrorsReturn => {
    const hasField = (name: string) => {
        const field = dot.pick(name, methods.control._fields);
        return field && !Array.isArray(field);
    }

    if (isApiValidationError(error)) {

        const globalErrors: string[] = [];

        mapValidationErrors(error, (fieldKey, errors) => {
            if (fieldKey === 'unknown' || fieldKey === 'global') {
                return;
            }

            if (hasField(fieldKey)) {
                methods.setError(fieldKey as any, { type: 'server', message: errors.join('; ') })
            } else {
                globalErrors.push(...errors);
            }
        });

        return {
            errorTitle: resolveValidationErrorTitle(error),
            globalErrors
        };
    }

    const errorTitle = getApiErrorMessage(error);

    return { errorTitle };
}

export default processFormErrors;