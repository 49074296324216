import {createBrowserRouter, RouteObject} from "react-router-dom";
import {Router as RemixRouter} from "@remix-run/router/dist/router";
import wrapApplicationRoutes from "../helpers/wrapApplicationRoutes";
import wrapGuestRoutes from "../helpers/wrapGuestRoutes";
import wrapRoutes from "../helpers/wrapRoutes";
import * as Sentry from "@sentry/react";
import App from "../Application/App";

const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export default class Router {
    private readonly _app: App;

    private readonly _appRoutes: RouteObject[];
    private readonly _guestRoutes: RouteObject[];

    private _appRouter: RemixRouter|null = null;
    private _guestRouter: RemixRouter|null = null;

    constructor(app: App, appRoutes: RouteObject[], guestRoutes: RouteObject[]) {
        this._app = app;
        this._appRoutes = wrapApplicationRoutes(this._app.config.base.appName, appRoutes, guestRoutes);
        this._guestRoutes = wrapGuestRoutes(guestRoutes);
    }

    public getRouter(isAuth: boolean) : RemixRouter {
        const basename = this._app.config.base.baseName ?? '/';

        if (isAuth) {
            if (!this._appRouter) {
                this._appRouter = sentryCreateBrowserRouter(wrapRoutes(this._appRoutes), { basename });
            }
            return this._appRouter;
        }

        if (!this._guestRouter) {
            this._guestRouter = sentryCreateBrowserRouter(wrapRoutes(this._guestRoutes), { basename });
        }
        return this._guestRouter;
    }


}
