import * as React from "react";
import {GlobalActionButtonBaseProps} from "../../../../../AppHelpers/Navigation";
import classNames from "classnames";

type GlobalButtonIconProps = {
    icon: GlobalActionButtonBaseProps['icon'],
    className?: string,
}

const GlobalButtonIcon = ({ icon, className }: GlobalButtonIconProps) => {

    if (typeof icon === 'string') {
        return <i className={classNames(`mdi mdi-${icon}`, className)}/>;
    }

    return (
        <React.Fragment>
            {icon}
        </React.Fragment>
    )
}

export default GlobalButtonIcon;