import * as React from "react";
import API, {ApiDataMethods} from "@ova-studio/api-helper";
import {FieldValues} from "react-hook-form/dist/types/fields";
import CallbackForm, {CallbackFormProps} from "./CallbackForm";
import {FormValues} from "../types";

export type DataFormProps = Omit<CallbackFormProps, 'handleSubmit'> & {
    submitEndpoint: string,
    submitMethod?: ApiDataMethods,
    successCallback?: (data: FieldValues, result: any) => void,
}

const DataForm = ({ submitEndpoint, submitMethod = 'update', successCallback, children, ...props } : DataFormProps) => {

    const handleSubmit = React.useCallback((data : FieldValues) => new Promise<FormValues|void>(async (resolve, reject) => {
        try {
            const response = await API[submitMethod](submitEndpoint, data)

            if (response.status !== 204) {
                resolve(response.data);
            } else {
                resolve()
            }

            successCallback && successCallback(data, response.data);
        } catch (e) {
            reject(e);
        }
    }), [])

    return (
        <CallbackForm {...props} handleSubmit={handleSubmit}>
            {children}
        </CallbackForm>
    );
}

export default DataForm
