import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["className", "isInvalid", "isValid", "size"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import cx from 'classnames';
import { isSizeLarge, isSizeSmall } from './size';
/**
 * Returns Bootstrap classnames from `size` and validation props, along
 * with pass-through props.
 */
export default function propsWithBsClassName(_ref) {
  var className = _ref.className,
    isInvalid = _ref.isInvalid,
    isValid = _ref.isValid,
    size = _ref.size,
    props = _objectWithoutProperties(_ref, _excluded);
  return _objectSpread(_objectSpread({}, props), {}, {
    className: cx('form-control', 'rbt-input', {
      'form-control-lg': isSizeLarge(size),
      'form-control-sm': isSizeSmall(size),
      'is-invalid': isInvalid,
      'is-valid': isValid
    }, className)
  });
}