import * as React from "react";
import PictureRender from "./PictureRender";
import {Media} from "../types/Media";
import clsx from "clsx";
import {MediaType} from "../types/MediaType";

export type MediaPreviewProps = {
    data: Media,
    alt?: string,
    lazy?: boolean,
}

const MediaPreview = ({ data, alt, lazy }: MediaPreviewProps) => {
    if (!data.preview) {
        return null;
    }

    return (
        <PictureRender
            data={data.preview}
            alt={alt}
            lazy={lazy}
            className={clsx('ova-engine-image-preview', {
                'ova-engine-image-preview-video' : data.type === MediaType.Video || data.mime_type === 'ova-embed/youtube',
            })}
        />
    )
}

export default MediaPreview;
