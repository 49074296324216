import {useEffect, useMemo, useReducer} from "react";
import AbstractDataStore from "../abstracts/AbstractDataStore";

type DataStoreReducer<T> = (state : any, action : T) => { updateIdx: number, data: T };

const dataStoreReducer = <T>(state : any, action : T) : { updateIdx: number, data: T } => {
    return { updateIdx: state.updateIdx + 1, data: action }
}

export type StoreInitializer<T> = (() => AbstractDataStore<T>) | AbstractDataStore<T>;

export default function useExternalStore<T>(storeInitializer : StoreInitializer<T>) : T {

    const store = useMemo(
        () => typeof storeInitializer === 'function'
            ? storeInitializer()
            : storeInitializer,
        []
    );

    const [ state, dispatch ] = useReducer<DataStoreReducer<T>>(dataStoreReducer, { updateIdx: 0, data: store.getData() });

    useEffect(() => {
        const unsubscribe = store.subscribe(() => {
            dispatch(store.getData());
        });

        return () => unsubscribe();
    }, [])

    return state.data;

}
