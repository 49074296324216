import * as React from 'react';
import {DatatableRow} from "../../types/DatatableData";
import {useDatatableContext} from "../../DatatableContext";
import CellDisplay from "./CellDisplay";
import {ColumnShowType} from "../../types/ColumnShowType";
import {useMemo} from "react";
import {DatatableColumnShow} from "../../DatatableContext/DatatableContext";
import {ArrayIsNotEmpty} from "../../../helpers/recordHelpers";
import {Button, Collapse} from "react-bootstrap";
import useBoolean from "../../../hooks/useBoolean";
import useRowIndex from "./useRowIndex";
import classNames from "classnames";
import RowActions from "../Components/RowActions";

type RowProps = {
    data: DatatableRow,
    index: number,
}

const Row = ({ data, index }: RowProps) => {

    const { showColumns, hasGroupActions, data: { options: { hasIndexes } } } = useDatatableContext();

    const colsMap = useMemo(() => {

        const map : Record<ColumnShowType, DatatableColumnShow[]> = {
            [ColumnShowType.Fixed]: [],
            [ColumnShowType.Captioned]: [],
            [ColumnShowType.Collapsed]: [],
            [ColumnShowType.Hidden]: [],
        };

        for (let col of showColumns) {
            map[col.showType]?.push(col);
        }

        return map;

    }, [ showColumns ])

    const hasCollapsed = ArrayIsNotEmpty(colsMap[ColumnShowType.Collapsed]);
    const collapseState = useBoolean();

    const indexNode = useRowIndex(data._id, index);

    const handleRowClick = React.useCallback<React.MouseEventHandler>((e) => {
        const target = e.target as HTMLElement;

        if (target && !target.classList.contains('datatable-mobile-row')) {
            if (['a', 'button', 'input'].includes(target.tagName.toLowerCase())) {
                return;
            }

            if (target.closest('a, button')) {
                return;
            }

            if (target.role && ['dialog', 'button'].includes(target.role.toLowerCase())) {
                return;
            }
        }

        collapseState.toggle()
    }, [collapseState.toggle])

    const hasIndexCell = hasIndexes || hasGroupActions;

    return (
        <div className={classNames('datatable-mobile-row', hasIndexCell && 'datatable-mobile-row-with-index')} role='button' onClick={handleRowClick}>
            {hasIndexCell && (
                <div className='datatable-mobile-row-info'>
                    {indexNode}
                </div>
            )}
            {hasCollapsed && (
                <div className='datatable-mobile-collapse-btn'>
                    <Button variant='link' className='mt-auto p-0'>
                        <i className={`mdi mdi-chevron-double-${collapseState.state ? 'up' : 'down'}`} />
                    </Button>
                </div>
            )}
            <div className='datatable-mobile-row-data'>
                {(!hasCollapsed && !!data._actions?.length) && (
                    <RowActions row={data} size='sm' className='float-end' />
                )}
                {ArrayIsNotEmpty(colsMap[ColumnShowType.Fixed]) && (
                    <div className='datatable-mobile-row-fixed'>
                        <CellDisplay data={data} cols={colsMap[ColumnShowType.Fixed]} />
                    </div>
                )}
                {ArrayIsNotEmpty(colsMap[ColumnShowType.Captioned]) && (
                    <div className='datatable-mobile-row-captioned'>
                        <CellDisplay data={data} cols={colsMap[ColumnShowType.Captioned]} />
                    </div>
                )}
                {ArrayIsNotEmpty(colsMap[ColumnShowType.Collapsed]) && (
                    <Collapse in={collapseState.state}>
                        <div>
                            <div className='datatable-mobile-row-collapsed'>
                                <CellDisplay data={data} cols={colsMap[ColumnShowType.Collapsed]} />
                            </div>
                            {data._actions && (
                                <RowActions row={data} size='sm' />
                            )}
                        </div>
                    </Collapse>
                )}
            </div>
        </div>
    );
}

export default Row
