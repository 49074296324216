import * as React from 'react';
import FlexCenteredInfo from "./FlexCenteredInfo";
import {UseAwaitErrorReturn} from "../hooks/useAwaitError";
import classNames from "classnames";

export type AwaitErrorProps = {
    variant?: 'block' | 'inline',
    error: UseAwaitErrorReturn,
}

const AwaitError = ({ variant = 'inline', error }: AwaitErrorProps) => {

    const { icon, text, color } = error;

    if (variant === 'block') {
        return (
            <FlexCenteredInfo icon={icon} text={text} color={color} />
        )
    }

    return (
        <div className={classNames('d-inline-flex align-items-center gap-1', { [`text-${color}`] : Boolean(color) })}>
            <i className={`mdi mdi-${icon} font-16 lh-1`} />
            <span>{text}</span>
        </div>
    );
}

export default AwaitError
