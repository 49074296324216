import * as React from 'react';
import useExternalStore from "../../../hooks/useExternalStore";
import { Tab as TabData } from "../../../AppHelpers/Navigation";
import {useAppNavigation} from "../../../Application";

type TabProps = {
    data: TabData,
    isActive: boolean,
}

const Tab = ({ data, isActive }: TabProps) => {

    const tabRef = React.useRef<HTMLAnchorElement>();

    React.useEffect(() => {
        if (isActive) {
            tabRef.current?.scrollIntoView({ inline: 'center' })
        }
    }, [ tabRef.current, isActive ])

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        data.handleClick();
    }

    return (
        <a href='#' onClick={handleClick} className={`${isActive ? 'active' : ''}`} ref={ref => tabRef.current = ref ?? undefined}>
            {data.icon && <i className={`mdi mdi-${data.icon} me-1`} />}
            {data.title}
            {data.badge !== undefined && <span className='ms-1 text-muted'>{data.badge}</span>}
        </a>
    );
}

const TopbarTabs = () => {

    const appNavigation = useAppNavigation();

    const { tabs, activeTab } = useExternalStore(appNavigation.Topbar.Tabs);

    return (
        <div className='navbar-tabs-row'>
            {tabs.map(tab => (
                <Tab
                    key={`tab-${tab.key}`}
                    data={tab}
                    isActive={tab.key === activeTab}
                />
            ))}
        </div>
    )
}

export default TopbarTabs
