import React, {useMemo} from "react";
import {Controller} from "react-hook-form";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import DatepickerInput from "./DatepickerInput";
import {DatepickerProps, FormDatetimeInputBaseProps} from "./FormDatetimeInput";
import FormGroupContainer from "./FormGroupContainer";
import convertDate from "../../helpers/convertDate";
import ErrorFeedback from "./ErrorFeedback";

type DateRange = [ Date|null, Date|null ];

type DateRangeObject = {
    startDate: Date|null,
    endDate: Date|null,
};

type DatePickerRangeProps = DatepickerProps & {
    value: string,
    isError?: boolean,
    onChange: (value: string) => void
}

const DatePickerRange = ({ value, onChange, isTime, isError, minDate, maxDate, monthsShown, inline, readOnly, disabled } : DatePickerRangeProps) => {

    const { startDate, endDate } = useMemo<DateRangeObject>(() => {
        let [ startDate, endDate ] = value?.split('/') ?? [ null, null ];

        return {
            startDate: Boolean(startDate) ? new Date(startDate) : null,
            endDate: Boolean(endDate) ? new Date(endDate) : null
        }
    }, [ value ])

    const handleChange = (dates : DateRange) => {
        onChange(dates.map(d => d?.toISOString() ?? '').join('/'));
    };

    return (
        <DatePicker
            customInput={<DatepickerInput />}
            showTimeSelect={isTime}
            className={classNames('form-control', { 'is-invalid' : isError })}
            onChange={handleChange}
            dateFormat={isTime ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy"}
            timeFormat="HH:mm"
            timeCaption='Час'
            minDate={convertDate(minDate)}
            maxDate={convertDate(maxDate)}
            monthsShown={monthsShown}
            inline={inline}
            autoComplete="off"
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            readOnly={readOnly}
            disabled={disabled}
        />
    )
}

export type FormDatetimeRangeInputProps = FormDatetimeInputBaseProps;

const FormDatetimeRangeInput = ({ name, isTime, minDate, maxDate, monthsShown, inline, disabled, readOnly, ...props } : FormDatetimeRangeInputProps) => {

    return (
        <FormGroupContainer name={name} {...props}>
            {({ control, isError, errorMessage }) => (
                <Controller
                    name={name}
                    control={control}
                    render={({ field }) => (
                        <div className='position-relative'>
                            <DatePickerRange
                                onChange={field.onChange}
                                value={field.value}
                                isTime={isTime}
                                isError={isError}
                                minDate={minDate}
                                maxDate={maxDate}
                                monthsShown={monthsShown}
                                inline={inline}
                                disabled={disabled}
                                readOnly={readOnly}
                            />
                            <ErrorFeedback isError={isError} errorMessage={errorMessage} />
                        </div>
                    )}
                />
            )}
        </FormGroupContainer>
    )
}

export default FormDatetimeRangeInput
