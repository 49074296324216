import * as React from 'react';
import {Button, CloseButton, Modal} from "react-bootstrap";
import Form, {FormSelectOption} from "../../../Form";
import {useDatatableContext} from "../../DatatableContext";
import {useCallback, useMemo} from "react";
import {useFieldArray} from "react-hook-form";
import {SortDirection} from "../../DatatableContext/DatatableContext";

const sortDirections : FormSelectOption[] = [
    { value: 'asc',  label: 'за зростанням' },
    { value: 'desc', label: 'за спаданням' },
];

type SortsListProps = {
    list: FormSelectOption[],
}

const SortsList = ({ list } : SortsListProps) => {

    const { fields, append, remove } = useFieldArray({ name: 'sort' });

    const handleAdd = () => append({})

    const handleRemoveAll = () => {
        remove(fields.map((_, key) => key));
    }

    // TODO: order of sort change

    return (
        <React.Fragment>
            <div className='grid-form grid-form-col-3-delete'>
                {fields.map((field, index) => (
                    <React.Fragment key={field.id}>
                        <Form.BasicSelect options={list} name={`sort.${index}.col`} groupClass='' />
                        <Form.BasicSelect options={sortDirections} name={`sort.${index}.dir`} groupClass='' />
                        <CloseButton onClick={() => remove(index)} />
                    </React.Fragment>
                ))}
            </div>
            <div className='d-flex'>
                <Button size='sm' variant='link' onClick={handleAdd} className='p-0'>
                    <i className='mdi mdi-plus me-1' />
                    додати сортування
                </Button>
                {fields.length > 0 && (
                    <Button size='sm' variant='link' onClick={handleRemoveAll} className='p-0 link-danger ms-auto'>
                        <i className='mdi mdi-close-circle-multiple-outline me-1' />
                        очистити
                    </Button>
                )}
            </div>
        </React.Fragment>
    )

}

type TableSortModalProps = {
    state: boolean,
    close: () => void,
}

type FormData = {
    sort?: { col: string, dir: SortDirection }[]
}

const TableSortModal = ({ state, close }: TableSortModalProps) => {

    const { activeSorts, setSorts, data: { columns } } = useDatatableContext();

    const handleSubmit = useCallback((data : FormData) => new Promise<void>(resolve => {
        setSorts(data.sort?.reduce((p, c, i) => ({ ...p, [c.col] : { dir: c.dir, ord: i } }), {}) ?? {})
        resolve();
        close();
    }), [])

    const sort = useMemo(() => Object.entries(activeSorts)
        .map(([ col, { dir } ]) => ({ col, dir })), [activeSorts]);

    const colsSelect = useMemo<FormSelectOption[]>(() => {
        return columns
            .filter(c => c.isSortable)
            .map(c => ({value: c.key, label: c.title as string}));
    }, [ columns ])

    return (
        <Modal show={state} onHide={close} centered>
            <Modal.Header className='py-0' closeButton>
                <Modal.Title>Сортування</Modal.Title>
            </Modal.Header>
            <Form.Callback data={{ sort }} handleSubmit={handleSubmit} showSuccess={false}>
                <Modal.Body>
                    <SortsList list={colsSelect} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={close}>Скасувати</Button>
                    <Form.Submit>Зберегти</Form.Submit>
                </Modal.Footer>
            </Form.Callback>
        </Modal>
    );
}

export default TableSortModal
