import {InternalToastData, ToastCreateFunctionResult, ToastData, ToastsList, ToastUpdateData} from "./types";
import {v4 as uuid} from "uuid";
import AbstractDataStore from "../../abstracts/AbstractDataStore";

export default class Toasts extends AbstractDataStore<ToastsList> {

    private _toasts: ToastsList = [];

    private _getToast(id: string) : InternalToastData | undefined {
        return this._toasts.find((toast) => toast.id === id);
    }

    private _updateToastData(id: string, data: Partial<InternalToastData>) : void {
        if (!this._toasts.some(toast => toast.id === id)) {
            return;
        }

        this._toasts = this._toasts.map((toast) : InternalToastData => {
            if (toast.id !== id) return toast;
            return { ...toast, ...data };
        });
    }

    private _clearShowTimeout(id: string) : void {
        const toast = this._getToast(id);
        if (!toast) return;
        if (toast.showTimeout) {
            clearTimeout(toast.showTimeout);
            this._updateToastData(id, { showTimeout: undefined });
        }
    }

    private _makeShowTimeout(id: string, showTime?: number) : NodeJS.Timeout|undefined {
        if (!showTime) return;

        return setTimeout(() => {
            this.deleteToast(id);
        }, showTime * 1000);
    }

    public createToast(data : ToastData) : ToastCreateFunctionResult {
        const id = uuid();

        this._toasts.push({
            ...data,
            id,
            hidden: false,
            showTimeout: this._makeShowTimeout(id, data.showTime),
        })

        this._callListeners();

        return {
            id,
            update: (data) => this.updateToast(id, data),
            hide: () => this._hideToast(id),
            show: () => this._showToast(id),
            delete: () => this.deleteToast(id),
        }
    }

    public updateToast(id: string, data : ToastUpdateData) : void {
        this._updateToastData(id, data);

        if (data.showTime) {
            this._clearShowTimeout(id);
            this._updateToastData(id, { showTimeout: this._makeShowTimeout(id, data.showTime) });
        }

        this._callListeners();
    }

    public deleteToast(id: string) : void {
        const toast = this._getToast(id);
        if (!toast) return;
        this._clearShowTimeout(id);
        toast.onDelete?.();
        this._toasts = this._toasts.filter(toast => toast.id !== id);
        this._callListeners();
    }

    private _showToast(id: string) : void {
        this._updateToastData(id, { hidden: false });
        this._callListeners();
    }

    private _hideToast(id: string) : void {
        this._updateToastData(id, { hidden: true });
        this._callListeners();
    }

    public getData() : ToastsList {
        return this._toasts;
    }

}
