import React, {useRef, useState} from "react";
import {useFormContext} from "react-hook-form";
import useFormError from "../hooks/useFormError";
import {MapContainer, Marker, TileLayer, useMapEvent} from "react-leaflet";
import {Form} from "react-bootstrap";
import {FormInputTextProps} from "./BaseInputProps";
import {useFormOptionsContext} from "../Forms/FormBody/FormOptionsContext";
import {Icon, Marker as LeafletMarker} from "leaflet";
import defaultIconFile from '../../assets/images/map-default-icon.png'
import ErrorFeedback from "./ErrorFeedback";

const icon = new Icon({ iconUrl: defaultIconFile, shadowUrl: undefined, iconAnchor: [12, 41], popupAnchor: [0, -41] });

export type FormMapProps = FormInputTextProps & {
    label?: string|React.ReactNode,
    nameLat: string,
    nameLng: string,
    disabled?: boolean,
}

type Location = {
    lat: number,
    lng: number,
}

type SetValueCallback = (newValue: Location) => void

const MapClickHandler = ({ setValue } : { setValue: SetValueCallback }) => {
    useMapEvent('click', e => setValue({...e.latlng}))

    return null;
}

const FormMap = ({ nameLat, nameLng, label, text, disabled } : FormMapProps) => {
    const { register, getValues, setValue: setFormValue } = useFormContext();
    const { name: formName } = useFormOptionsContext();

    const { isError: isErrorLat, errorMessage: errorMessageLat } = useFormError(nameLat);
    const { isError: isErrorLng, errorMessage: errorMessageLng } = useFormError(nameLng);

    const isError = isErrorLat || isErrorLng;
    const errorMessage = [ errorMessageLat, errorMessageLng ].filter(i => Boolean(i)).join('; ');

    const initLat = getValues(nameLat);
    const initLng = getValues(nameLng);

    const center : Location = { lat: initLat ?? 49.824421, lng: initLng ?? 23.979513 };

    const [ value, _setValue ] = useState<Location>(center);

    const markerRef = useRef<LeafletMarker>();

    const setValue = (newValue: Location) => {
        if (disabled) return;
        _setValue(newValue);
        setFormValue(nameLat, newValue.lat, { shouldDirty: true })
        setFormValue(nameLng, newValue.lng, { shouldDirty: true })
    }

    const handleDragEnd = () => {
        if (disabled) return;
        markerRef.current && setValue(markerRef.current?.getLatLng())
    }

    // @ts-ignore
    return (
        <div>
            <Form.Group className='mb-3 position-relative' controlId={formName + '-' + nameLat + '-' + nameLng}>
                {label && <Form.Label>{label}</Form.Label>}
                <MapContainer
                    center={center}
                    zoom={10}
                    scrollWheelZoom={true}
                    style={{ height: '300px' }}
                    dragging={!disabled}
                >
                    {disabled && <div className='position-absolute top-0 bottom-0 start-0 end-0' style={{ backgroundColor: 'rgba(0,0,0,.5)', zIndex: 9999 }}></div>}
                    <MapClickHandler setValue={setValue} />
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker
                        ref={ref => ref && (markerRef.current = ref)}
                        draggable={true}
                        eventHandlers={{
                            click: e => setValue(e.latlng),
                            dragend: handleDragEnd,
                        }}
                        position={value}
                        icon={icon}
                    />
                </MapContainer>
                <input type='hidden' {...register(nameLat)} />
                <input type='hidden' {...register(nameLng)} />
                <ErrorFeedback isError={isError} errorMessage={errorMessage} />
                {text && <Form.Text>{text}</Form.Text>}
            </Form.Group>
        </div>
    );
}

export default FormMap
