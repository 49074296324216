import MediaLibraryDataStore, {MediaLibraryData} from "./MediaLibraryDataStore";
import {useContext} from "react";
import MediaLibraryContext from "./MediaLibraryContext";
import {useExternalStore} from "@ova-studio/react-hyper-admin";

export function useMediaLibraryDataStore() : MediaLibraryDataStore {
    const store = useContext<MediaLibraryDataStore|undefined>(MediaLibraryContext);

    if (!store) {
        throw new Error('useMediaLibraryContext must be used within a MediaLibraryContextProvider');
    }

    return store;
}

export function useMediaLibraryData() : MediaLibraryData {
    const store = useMediaLibraryDataStore();
    return useExternalStore(() => store)
}
