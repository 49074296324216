export type NavMenuIcon = {
    icon: string,
}

export type NavMenuCounterValue = string | number | null;
export type NavMenuCounterVariant = string | ((value: NavMenuCounterValue) => string|null) | null;

export type NavMenuCounterUpdateData = {
    name: string,
    value: NavMenuCounterValue,
}

export type NavMenuCounterList = Record<string, NavMenuCounterValue>;

export type NavMenuCounterConfig = Record<string, NavMenuCounterVariant>;

export const isNavMenuCounterValue = (obj: any) : obj is NavMenuCounterValue => {
    return typeof obj === 'string'
        || typeof obj === 'number'
        || obj === true
        || obj === null;
}

export const isNavMenuCounterUpdateData = (obj: any) : obj is NavMenuCounterUpdateData => {
    return typeof obj === 'object'
        && obj !== null
        && obj.hasOwnProperty('name')
        && typeof obj.name === 'string'
        && obj.hasOwnProperty('value')
        && isNavMenuCounterValue(obj.value);
}

export const isNavMenuCounterList = (obj: any) : obj is NavMenuCounterList => {
    if (typeof obj !== 'object' || obj === null) {
        return false;
    }

    for (const key in obj) {
        if (!isNavMenuCounterValue(obj[key])) {
            return false;
        }
    }

    return true;
}

export type NavMenuLink = {
    url: string,
}

export type NavMenuChildren = {
    children: NavMenuItem[],
}

export type NavMenuItem = {
    name: string,
    counters?: NavMenuCounterConfig,
} & (NavMenuChildren | NavMenuLink | (NavMenuLink & NavMenuChildren))

export type FirstLevelNavMenuItem = NavMenuIcon & NavMenuItem

export type NavigationMenu = FirstLevelNavMenuItem[]

export function isNavMenuIcon(obj : any) : obj is NavMenuIcon {
    return typeof obj !== 'undefined'
        && obj.hasOwnProperty('icon')
        && typeof obj.icon === 'string';
}

export function isNavMenuLink(obj : any) : obj is NavMenuLink {
    return typeof obj !== 'undefined'
        && obj.hasOwnProperty('url')
        && typeof obj.url === 'string';
}

export function isNavMenuChildren(obj : any) : obj is NavMenuChildren {
    return typeof obj !== 'undefined'
        && obj.hasOwnProperty('children')
        && Array.isArray(obj.children);
}
