import React, {useMemo} from "react";
import {useFormContext} from "react-hook-form";
import {Button} from "react-bootstrap";
import {ButtonVariant} from "react-bootstrap/types";
import {useFormOptionsContext} from "../Forms/FormBody/FormOptionsContext";
import {FormStatus} from "../types";

export type FormSubmitProps = {
    variant?: ButtonVariant,
    hideOnDisabled?: boolean,
    size?: 'sm' | 'lg',
    icon?: string,
    className?: string,
    enable?: boolean,
    children?: React.ReactNode,
}

const FormSubmit = ({ variant = 'primary', size, icon, className, enable, hideOnDisabled, children = 'Зберегти' } : FormSubmitProps) => {

    const { formState: { isDirty, isValid } } = useFormContext()
    const { formStatus } = useFormOptionsContext();

    const activeIcon = useMemo<string|null>(() => {

        if (formStatus === FormStatus.Submitting) {
            return 'mdi mdi-loading mdi-spin';
        }

        if (icon) {
            return icon;
        }

        return null;

    }, [ icon, formStatus ]);

    const disabled = ((!isDirty || !isValid) && !enable) || formStatus === FormStatus.Submitting;

    if (disabled && hideOnDisabled) {
        return null;
    }

    return (
        <Button
            type='submit'
            disabled={disabled}
            variant={variant}
            size={size}
            className={className}
        >
            {icon && <i className={`${activeIcon} me-2`} />}
            {children}
        </Button>
    );
}

export default FormSubmit
