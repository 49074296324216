import * as React from 'react';
import {ErrorsList} from "../helpers/processApiErrors";

export type ErrorsDisplayProps = {
    errors: ErrorsList,
}

const ErrorsDisplay = ({ errors }: ErrorsDisplayProps) => {

    if (typeof errors === 'string') {
        return (
            <div>{errors}</div>
        );
    }

    return (
        <React.Fragment>
            {errors.map((error, idx) => <div key={`error-${idx}`}><i className='mdi mdi-bullet' />{' '}{error}</div>)}
        </React.Fragment>
    );
}

export default ErrorsDisplay
