import * as React from 'react';
import {CloseButton, Toast} from "react-bootstrap";
import classNames from "classnames";
import DateTimeFormat from "../../components/DateTimeFormat";
import {InternalToastData} from "./types";
import useExternalStore from "../../hooks/useExternalStore";
import {useAppToasts} from "../../Application";

type ToastBodyProps = {
    data: InternalToastData,
    handleClose: () => void,
}

const ToastBody = ({ data, handleClose }: ToastBodyProps) => {

    const textClassName = data.variant ? `text-with-bg-${data.variant}` : undefined;

    return (
        <Toast show={!data.hidden} autohide={false} bg={data.variant}>
            {data.title && (
                <Toast.Header closeButton={false}>
                    {data.icon && <i className={classNames(`mdi mdi-${data.icon} font-18 lh-1 me-1`, textClassName)} />}
                    <strong className={classNames(textClassName, 'me-auto')}>{data.title}</strong>
                    {data.dt && (
                        <DateTimeFormat dt={data.dt}>
                            {dt => dt.toRelative()}
                        </DateTimeFormat>
                    )}
                    {(!data.disableClose) && <CloseButton onClick={handleClose} />}
                </Toast.Header>
            )}
            <Toast.Body className={textClassName}>
                {data.body}
            </Toast.Body>
        </Toast>
    );
}

const ToastsDisplay = () => {

    const appToasts = useAppToasts();

    const toasts = useExternalStore(appToasts);

    return (
        <div className='toast-container'>
            {toasts.map(data => (
                <ToastBody
                    key={`toast-${data.id}`}
                    data={data}
                    handleClose={() => appToasts.deleteToast(data.id)}
                />
            ))}
        </div>
    );
}

export default ToastsDisplay
