import * as React from "react";
import {RouteObject} from "react-router-dom";
import ErrorBoundary from "../AppWrapper/InfoPages/ErrorBoundary";
import GlobalAppWrapper from "../AppWrapper/GlobalAppWrapper";

export default function wrapRoutes(routes : RouteObject[]) : RouteObject[] {
    return [
        {
            element: <GlobalAppWrapper />,
            errorElement: <ErrorBoundary />,
            children: routes,
        },
    ];
}
