import {createContext, useContext} from "react";
import {Media} from "../../../types/Media";

export type FormMediaSelectContextData = {
    openModal: () => void,
    mediaId: number | null,
    media: Media | null | undefined,
}

const FormMediaSelectContext = createContext<FormMediaSelectContextData | undefined>(undefined);

export const useFormMediaSelectContext = () => {
    const context = useContext(FormMediaSelectContext);
    if (!context) {
        throw new Error('useFormMediaSelectContext must be used within a FormMediaSelectContext');
    }
    return context;
}

export default FormMediaSelectContext;
