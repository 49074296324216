import {FC, lazy} from "react";
import AbstractDataStore from "../abstracts/AbstractDataStore";

const ToastsDisplay = lazy(() => import("./Toasts/ToastsDisplay"));
const ActionsDisplay = lazy(() => import("./Actions/ActionsDisplay"));

type DisplayMode = 'auth' | 'guest' | 'both';

type HelpersRegistryData = {
    key: string,
    mode: DisplayMode,
    component: FC,
};

export default class HelpersRegistry extends AbstractDataStore<HelpersRegistryData[]> {

    private _helpers : HelpersRegistryData[] = [
        {
            key: 'toasts',
            mode: 'auth',
            component: ToastsDisplay,
        },
        {
            key: 'actions',
            mode: 'auth',
            component: ActionsDisplay,
        },
    ];

    // noinspection JSUnusedGlobalSymbols
    public register(key: string, component: FC, mode: DisplayMode) : void {
        this._helpers.push({ key, component, mode });
        this._callListeners();
    }

    public getData() : HelpersRegistryData[] {
        return this._helpers;
    }

}
