import * as React from 'react';
import Preloader from "../../components/Preloader";
import PageLoader from "./PageLoader";
import {Outlet} from "react-router-dom";
import PageTitle from "./PageTitle";

const AppPageLayout = () => {
    return (
        <div className="container-fluid">
            <React.Suspense fallback={<Preloader />}>
                <PageLoader>
                    <PageTitle />
                    <Outlet />
                </PageLoader>
            </React.Suspense>
        </div>
    );
}

export default AppPageLayout
