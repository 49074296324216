import AbstractDataStore from "../../abstracts/AbstractDataStore";
import {BaseTabDefinition, isLinkTab, isPageTab, isScrollTab, TabDefinition, Tabs} from "./types/TopbarTabs";
import {SimpleCallback} from "../../types/SimpleCallback";
import {v4 as uuid} from "uuid";

export type TopbarTabsData = {
    tabs: Tabs,
    activeTab: string,
};

export default class TopbarTabs extends AbstractDataStore<TopbarTabsData> {

    private _data: TopbarTabsData = {
        tabs: [],
        activeTab: '',
    }

    private _activeTab: string = '';
    private _tabs: Tabs = [];

    private _scrollLocked: boolean = false;

    public registerTab(data: TabDefinition) : SimpleCallback {
        const key = isPageTab(data) ? data.id : uuid();

        this._tabs = [
            ...this._tabs,
            {
                ...data,
                key,
                handleClick: () => {
                    this._handleTabClick(data);
                    this._setActiveTab(key);
                }
            },
        ]

        this._checkActiveTab();

        this._callListeners();
        this._updateBodyClass();

        if (isScrollTab(data)) {
            this.handleScroll();
        }

        return () => {
            this._unregisterTab(key);
        }
    }

    private _checkActiveTab() : void {
        if (this._tabs.length === 0) {
            return;
        }

        if (this._tabs.some(tab => tab.key === this._activeTab)) {
            return;
        }

        this._activeTab = this._tabs[0].key;
    }

    private _unregisterTab(key: string) {
        this._tabs = this._tabs.filter(tab => tab.key !== key);
        this._checkActiveTab();
        this._callListeners();
        this._updateBodyClass();
    }

    public makeScrollTabRegister(options: BaseTabDefinition) : (ref: HTMLElement|null) => void {
        let unregister: SimpleCallback|null = null;

        return (ref: HTMLElement|null) => {
            if (ref === null) {
                if (unregister !== null) {
                    unregister();
                    unregister = null;
                }
                return;
            }

            unregister = this.registerTab({
                ref,
                ...options,
            })
        }
    }

    private _updateBodyClass() {
        if (this._tabs.length > 0) {
            document.body.classList.add('has-topbar-tabs');
        } else {
            document.body.classList.remove('has-topbar-tabs');
        }
    }

    private _handleTabClick(data: TabDefinition) {
        if (isLinkTab(data)) {
            window.location.href = data.link;
        }

        if (isScrollTab(data)) {
            this._scrollLocked = true;
            window.addEventListener('scrollend', () => this._scrollLocked = false, { once: true })

            const navbarSize = (document.querySelector('.navbar-custom')?.getBoundingClientRect().bottom ?? 30) + 10;

            window.scroll({
                top: data.ref.getBoundingClientRect().top + window.scrollY - navbarSize,
                // @ts-ignore
                behavior: 'instant'
            })
        }
    }

    private _setActiveTab(key: string) {
        if (this._activeTab !== key) {
            this._activeTab = key;
            this._callListeners();
        }
    }

    public handleScroll() {
        if (this._tabs.length === 0) {
            return;
        }

        const screenHeight = window.innerHeight;
        const screenHeightThresh = screenHeight / 4;

        for (const tab of this._tabs) {
            if (isScrollTab(tab)) {
                const bounds = tab.ref.getBoundingClientRect();
                const bottom = bounds.top + bounds.height;

                if (bounds.top < 0 && bottom < screenHeightThresh) {
                    continue;
                }

                this._setActiveTab(tab.key);
                return;
            }
        }
    }

    protected _callListeners() {
        this._data = {
            tabs: [ ...this._tabs ],
            activeTab: this._activeTab,
        }

        super._callListeners();
    }

    public isScrollLocked() : boolean {
        return this._scrollLocked;
    }


    public getData() : TopbarTabsData {
        return this._data;
    }
}
