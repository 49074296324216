import * as React from 'react';
import DisplayInfo from "./DisplayInfo";
import {useDatatableContext} from "../../DatatableContext";

const FooterShort = () => {

    const { data : { data, pagination } } = useDatatableContext();

    return (
        <div>
            <DisplayInfo pagination={pagination} rowsCnt={data?.length ?? 0} />
        </div>
    );
}

export default FooterShort
