import * as React from 'react';
import {ActionDisplayProps} from "./ActionDisplayProps";
import {ActionStatus} from "../../types";
import useConfirm from "../../../../hooks/useConfirm";

const ActionDisplayConfirm = ({ action }: ActionDisplayProps<ActionStatus.Confirming>) => {

    const { ConfirmModal } = useConfirm({
        ...action.data,
        initialModalState: true,
        handleContinue: () => action.handleRun({ confirmed: true }),
        handleExit: action.handleCancel,
    });

    return (
        <React.Fragment>
            {ConfirmModal}
        </React.Fragment>
    );
}

export default ActionDisplayConfirm
