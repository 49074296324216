import * as React from "react";
import {useModal} from "@ova-studio/react-hyper-admin";
import {FolderModalState} from "./types/MediaFolder";
import FolderDataModal from "./modals/FolderDataModal";
import UploadLinkModal, {UploadLinkModalState} from "./modals/UploadLinkModal";
import {useMediaLibraryService} from "./services/MediaLibraryService";
import DeleteModal, {DeleteModalState} from "./modals/DeleteModal";
import MediaEditModal, {MediaModalState} from "./modals/MediaEditModal";

const MediaLibraryHelper = () => {

    const service = useMediaLibraryService();

    const folderEditModal : FolderModalState = useModal();
    const mediaEditModal : MediaModalState = useModal();
    const uploadLinkModal : UploadLinkModalState = useModal();
    const deleteModal : DeleteModalState = useModal();

    React.useEffect(() => {
        service.setStates({
            folderEditModal,
            uploadLinkModal,
            mediaEditModal,
            deleteModal,
        });
    }, []);

    return (
        <React.Fragment>
            <FolderDataModal state={folderEditModal} />
            <UploadLinkModal state={uploadLinkModal} />
            <MediaEditModal state={mediaEditModal} />
            <DeleteModal state={deleteModal} />
        </React.Fragment>
    )
}

export default MediaLibraryHelper;
