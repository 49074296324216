import * as React from 'react';
import {ButtonVariant} from "react-bootstrap/types";
import {useAppNavigation} from "../../Application";
import {useFormOptionsContext} from "./FormBody/FormOptionsContext";
import {FormStatus} from "../types";

export type GlobalSubmitProps = {
    icon?: string,
    title?: string,
    variant?: ButtonVariant,
}

type IconData = {
    iconState: string,
    iconVariant: ButtonVariant,
    disabled?: boolean,
}

const GlobalSubmit = ({ icon = 'check', title = 'Зберегти', variant = 'success' }: GlobalSubmitProps) => {

    const appNavigation = useAppNavigation();

    const ref = React.useRef<HTMLButtonElement|null>(null);

    const { formStatus } = useFormOptionsContext();

    const { iconState, iconVariant, disabled } = React.useMemo<IconData>(() => {
        if (formStatus === FormStatus.Submitting) {
            return { iconState: 'loading mdi-spin', iconVariant: variant, disabled: true };
        }

        if (formStatus === FormStatus.Success) {
            return { iconState: 'check-all', iconVariant: 'success', disabled: true };
        }

        if (formStatus === FormStatus.Error) {
            return { iconState: 'alert-circle-outline', iconVariant: 'danger', disabled: true };
        }

        return { iconState: icon, iconVariant: variant };
    }, [ formStatus, icon ]);

    React.useEffect(() => {

        if (!ref.current) {
            return;
        }

        const unregister = appNavigation.GlobalButtons.registerCustomButton('form-submit-global', {
            icon: iconState,
            title,
            variant: iconVariant,
            order: 101,
            disabled: disabled,
            handler: () => ref.current?.click(),
        });

        return () => {
            unregister();
        };

    }, [ iconState, iconVariant, disabled ])

    return (
        <div className='visually-hidden'>
            <button ref={ref} type='submit' />
        </div>
    );
}

export default GlobalSubmit
