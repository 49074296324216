var idCounter = 0;

// eslint-disable-next-line @typescript-eslint/ban-types
export function isFunction(value) {
  return typeof value === 'function';
}
export function isString(value) {
  return typeof value === 'string';
}
export function noop() {}
export function pick(obj, keys) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  var result = {};
  keys.forEach(function (key) {
    result[key] = obj[key];
  });
  return result;
}
export function uniqueId(prefix) {
  idCounter += 1;
  return (prefix == null ? '' : String(prefix)) + idCounter;
}