import * as React from 'react';
import {useFormContext} from "react-hook-form";

export type FormHiddenInputProps = {
    name: string,
}

const FormHiddenInput = ({ name }: FormHiddenInputProps) => {
    const { register } = useFormContext();
    return (
        <input type='hidden' {...register(name)} />
    );
}

export default FormHiddenInput
