import * as React from 'react';
import {useDatatableContext} from "../../DatatableContext";
import Row from "./Row";
import MobileTableContext from "./MobileTableContext";

type MobileTableProps = {}

const MobileTable = ({}: MobileTableProps) => {

    const { rows } = useDatatableContext();

    return (
        <MobileTableContext>
            {/*<MobileTableSettings />*/}
            <div className='datatable-mobile'>
                {rows.map((row, index) => (
                    <Row
                        data={row}
                        index={index}
                        key={'row-' + row._id}
                    />
                ))}
            </div>
        </MobileTableContext>
    );
}

export default MobileTable
