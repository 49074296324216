import {
    isApiValidationError, getValidationErrorMessages, getApiErrorMessage
} from "@ova-studio/api-helper";

export type ErrorsList = string|string[]

export function errorListToString(errors: ErrorsList) : string {
    if (Array.isArray(errors)) {
        return errors.join(', ');
    }

    return errors;
}

/** @deprecated use getApiErrorMessage instead */
export default function processApiErrors(e: unknown) : ErrorsList {
    if (isApiValidationError(e)) {
        return getValidationErrorMessages(e);
    }

    return getApiErrorMessage(e);
}
