import * as React from 'react';
import {Button} from "react-bootstrap";

type SearchButtonProps = {
    handleClick: () => void
}

const SearchButton = ({ handleClick }: SearchButtonProps) => {

    return (
        <Button variant='outline-secondary' size='sm' className='d-lg-none py-0 d-inline-flex align-items-center me-auto' onClick={handleClick}>
            <i className='mdi mdi-magnify font-20 lh-sm' />
        </Button>
    );
}

export default SearchButton
