// noinspection JSUnusedGlobalSymbols

// Config
export type { AuthUserData } from './types/AuthUserData'
export type { LogoProps } from './types/LogoProps'
export type { SimpleCallback } from './types/SimpleCallback'
export type { Validator } from './types/Validator'

export { default as AppLayout } from "./AppLayout"
export type {
    LayoutColor, LayoutMode, LeftbarTheme, LeftbarCompactMode,
    CurrentLayoutConfig,
} from './AppLayout'

// Loaders
export { default as makeLoader, type LoaderDefinition, type ConfigLoaderDefinition, type LoaderConfig } from './loaders'

// Route helpers
export { makeSimpleRoute, makeCrudRoute, makeDatatableRoute, type SimpleRouteOptions, type CrudRouteOptions, type DatatableRouteOptions } from './route'

// Data helpers
export * from './data'

// App Helpers
export {
    Toasts,
    Actions, ActionButton, ActionsDropdown, useActionHandler,
    Navigation, AsTopbarRow, GlobalButtonPortal, TabsBreakpointDisplay,
    Auth,
    HelpersRegistry,
    SearchResultLinks,
    Websocket,
} from './AppHelpers'

export type {
    AuthResponse,
    ToastData, ToastUpdateData, ToastCreateFunctionResult,
    LoadActionOptions, LoadActionsOptions, Action, ActionsList, ActionButtonProps, ActionsDropdownProps, UseActionHandlerReturn,
    NavigationMenu, GlobalButtonsData, GlobalButton, GlobalActionSimpleButton, NavMenuCounterConfig, NavMenuCounterList, FirstLevelNavMenuItem,
    TabDefinition, BaseTabDefinition, AsTopbarRowProps, GlobalButtonPortalProps, TabsBreakpointDisplayProps,
    SearchResultLinksProps,
    WebsocketConfig, ChannelListener,
} from './AppHelpers'

// Abstracts
export { default as AbstractDataStore } from "./abstracts/AbstractDataStore"

// Application
export { default as Application } from "./Application/Application"

export {
    useAppContext,
    useAppRouter,
    useAppActions,
    useAppAuth,
    useAppNavigation,
    useAppToasts,
    useAppConfig,
    useBaseAppConfig,
    useAppExternalConfig,
    useAppHelpers,
    useAppServices,
    useAppService,
    useAppPageContext,
    useAppPageContextData,
    useAppSearch,
    useAppWebsocket,
    type AppOptions,
    type AppConfig,
    type AppReadyFunction,
    type App,
} from "./Application"

// Pages helper
export { default as BaseInfoPage, type InfoPageProps } from "./AppWrapper/InfoPages/BaseInfoPage"

// Components
export { default as Avatar, type AvatarProps } from "./components/Avatar"
export { default as AwaitBlock, type AwaitBlockProps } from "./components/AwaitBlock"
export { default as AwaitError, type AwaitErrorProps } from "./components/AwaitError"
export { default as AwaitFallback, type AwaitFallbackProps } from "./components/AwaitFallback"
export { default as Block, type BlockProps } from "./components/Block"
export { default as BouncingLoader } from "./components/BouncingLoader"
export { default as DateTimeFormat, type DateTimeFormatProps } from "./components/DateTimeFormat"
export { default as ErrorsDisplay, type ErrorsDisplayProps } from "./components/ErrorsDisplay"
export { default as FlexCentered, type FlexCenteredProps } from "./components/FlexCentered"
export { default as FlexCenteredInfo, type FlexCenteredInfoProps } from "./components/FlexCenteredInfo"
export { default as ListDisplay, type ListDisplayProps, type AwaitListDisplayProps } from "./components/ListDisplay"
export { default as LoadingHover } from "./components/LoadingHover"
export { default as Map, type MapProps } from "./components/Map"
export { default as Modal, type ModalProps } from "./components/Modal"
export { default as Preloader } from "./components/Preloader"
export { default as TabBlock, type TabBlockProps } from "./components/TabBlock"
export { default as Tooltip, type TooltipProps } from "./components/Tooltip"
export { default as TopOverlay, type TopOverlayProps } from "./components/TopOverlay"
export { default as TopOverlayLoading } from "./components/TopOverlayLoading"

// Big components
export { default as Form, FormStatus } from "./Form"
export type {
    LoaderFormProps, DataFormProps, FormBodyProps, FieldBlockProps,
    FormInputProps, FormCheckProps, FormSelectBasicProps,
    FormSelectProps, FormSelectOption, FormSelectAwaitProps,
    FormSelectAwaitLoaderProps, FormSubmitProps, FormMaskInputProps,
    FormDatetimeInputProps, FormDatetimeRangeInputProps, FormMapProps,
    InputGroupContainerBaseProps, InputGroupContainerProps, InputGroupContainerChildrenParams,
    FormGroupContainerBaseProps, FormGroupContainerProps, FormGroupContainerChildrenParams,
    FormHiddenInputProps, LabelProps, LabelTooltipProps, ErrorAlertProps, GlobalSubmitProps,
    DynamicFormBodyProps, DynamicFormFieldProps, FormTypeaheadSelectProps,
    FormValues, SubmitHandler
} from './Form'

export { default as Datatable } from "./Datatable"
export type { DatatableProps, BaseDatatableProps, RenderConfigData } from "./Datatable"

// Hooks
export { default as useAwaitError, type UseAwaitErrorReturn } from './hooks/useAwaitError'
export { default as useBoolean, type UseBooleanReturn } from './hooks/useBoolean'
export { default as useBreadcrumbs, type UseBreadcrumbsReturn } from './hooks/useBreadcrumbs'
export { default as useBreakpoint, type Breakpoint } from './hooks/useBreakpoint'
export { default as useConfirm, type UseConfirmParams, type UseConfirmReturn } from './hooks/useConfirm'
export { default as useExternalStore } from './hooks/useExternalStore'
export { default as useModal, type UseModalReturn, type UseModalOptions } from './hooks/useModal'
export { default as usePlural } from './hooks/usePlural'
export { default as useRouteMatches } from './hooks/useRouteMatches'
export { default as useSavedState } from './hooks/useSavedState'
export { default as useScreenWidth } from './hooks/useScreenWidth'

// Helper / Util functions
export { default as convertDate } from './helpers/convertDate'
export { getLocalStorageData, setLocalStorageData } from './helpers/localStorage'
export { default as processApiErrors, errorListToString, type ErrorsList } from './helpers/processApiErrors'
export { default as stopPropagate } from './helpers/stopPropagate'
export { default as mergeCallbacks } from './helpers/mergeCallbacks'
