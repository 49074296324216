import React from "react";
import {FormSelect, FormSelectProps} from 'react-bootstrap'
import {FormSelectOption} from "./BaseInputProps";
import InputGroupContainer, {InputGroupContainerBaseProps} from "./InputGroupContainer";

export type FormSelectBasicProps = InputGroupContainerBaseProps & FormSelectProps & {
    options: FormSelectOption[],
}

const FormSelectBasic = ({ name, options, ...props } : FormSelectBasicProps) => {

    return (
        <InputGroupContainer name={name} {...props}>
            {({ isError, register, props }) => (
                <FormSelect {...props} {...register(name)} isInvalid={isError}>
                    {options.map((option, idx) => (
                        <option key={'option-' + idx} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </FormSelect>
            )}
        </InputGroupContainer>
    )
}

export default FormSelectBasic
