import * as React from 'react';
import {DynamicFormFields} from "./types";
import DynamicFormField from "./DynamicFormField";

export type DynamicFormBodyProps = {
    fields: DynamicFormFields,
}

const DynamicFormBody = ({ fields }: DynamicFormBodyProps) => {
    return (
        <React.Fragment>
            {fields.map((field, idx) => <DynamicFormField key={`field-${idx}`} field={field} />)}
        </React.Fragment>
    );
}

export default DynamicFormBody
