import {
    CreateAndUpdateTitlingPropsSimple,
    CrudOptionsExtend, CrudOptionsModal,
    CrudOptionsSimple,
    HasCreateForm, HasInitialData,
    HasSingleForm,
    HasUpdateForm,
} from "./types";

export function isCrudOptionsSimple(obj: any) : obj is CrudOptionsSimple {
    return typeof obj !== 'undefined' && obj.hasOwnProperty('type') && obj.type === 'simple';
}

export function isCrudOptionsExtend(obj: any) : obj is CrudOptionsExtend {
    return typeof obj !== 'undefined' && obj.hasOwnProperty('type') && obj.type === 'extend';
}

export function isCrudOptionsModal(obj: any) : obj is CrudOptionsModal {
    return typeof obj !== 'undefined' && obj.hasOwnProperty('type') && obj.type === 'related-modal';
}

export function isHasSingleForm<T>(obj : any) : obj is HasSingleForm<T> {
    return typeof obj !== 'undefined'
        && obj.hasOwnProperty('forms')
        && typeof obj.forms !== 'undefined';
}

export function isHasCreateForm<T>(obj : any) : obj is HasCreateForm<T> {
    return typeof obj !== 'undefined'
        && obj.hasOwnProperty('create')
        && typeof obj.create !== 'undefined';
}

export function isHasUpdateForm<T>(obj : any) : obj is HasUpdateForm<T> {
    return typeof obj !== 'undefined'
        && obj.hasOwnProperty('update')
        && typeof obj.update !== 'undefined';
}

export function isCreateAndUpdateTitlingPropsSimple(obj : any) : obj is CreateAndUpdateTitlingPropsSimple {
    return typeof obj !== 'undefined'
        && obj.hasOwnProperty('createName')
        && typeof obj.createName === 'string'
        && obj.hasOwnProperty('updateName')
        && typeof obj.updateName === 'string';
}

export function isHasInitialData(obj : any) : obj is HasInitialData {
    return typeof obj !== 'undefined'
        && obj.hasOwnProperty('initialData')
        && typeof obj.initialData !== 'undefined';
}
