import React from "react";
import Datatable from "./Table";
import {useLoaderData} from "react-router-dom";
import {DatatableConfigOptional} from "./types/DatatableConfig";
import {DatatableData} from "./types/DatatableData";
import {RenderConfig} from "./types/RenderConfig";

export type LoaderDatatableProps = {
    config?: DatatableConfigOptional,
    renderConfig?: RenderConfig,
}

const LoaderDatatable = (props : LoaderDatatableProps) => {

    const { data } = useLoaderData() as { data: DatatableData };

    if (typeof data !== 'object') {
        return <p>Помилка завантаження</p>
    }

    return (
        <Datatable
            data={data}
            {...props}
        />
    );
}

export default LoaderDatatable
