import AbstractDataStore from "../../abstracts/AbstractDataStore";

enum PromiseState {
    Pending = 'pending',
    Resolved = 'resolved',
    Rejected = 'rejected'
}

export interface TrackedPromise<T> extends Promise<T> {
    _tracked?: boolean;
    _data?: T;
    _error?: any;
}

const isTrackedPromise = <T>(value: unknown) : value is TrackedPromise<T> => {
    return value instanceof Promise && (value as TrackedPromise<T>)._tracked === true
}

export type PromiseArg<T extends unknown> = Promise<T> | TrackedPromise<T>;

export default class WrappedPromise<T extends unknown> extends AbstractDataStore<T> {

    private readonly _promise: PromiseArg<T>;

    private _result: T | undefined;
    private _error: any;

    private _state: PromiseState = PromiseState.Pending;

    constructor(promise: PromiseArg<T>) {
        super();
        this._promise = promise;
        this._init();
    }

    private _init() : void {
        if (isTrackedPromise(this._promise)) {
            this._processTrackedPromise();
        }

        if (this._state !== PromiseState.Pending) {
            return;
        }

        if (!Object.hasOwn(this._promise, "_tracked")) {
            Object.defineProperty(this._promise, "_tracked", { get: () => true });
        }

        this._promise.then(
            result => {
                this._result = result;
                this._state = PromiseState.Resolved;
                if (!Object.hasOwn(this._promise, "_data")) {
                    Object.defineProperty(this._promise, "_data", { get: () => result });
                }
            },
            error => {
                this._error = error;
                this._state = PromiseState.Rejected;
                if (!Object.hasOwn(this._promise, "_error")) {
                    Object.defineProperty(this._promise, "_error", {get: () => error});
                }
            }
        );
    }

    private _processTrackedPromise() : void {
        if (!isTrackedPromise(this._promise)) {
            throw new Error('Promise is not a tracked promise');
        }

        if (this._promise._data) {
            this._result = this._promise._data;
            this._state = PromiseState.Resolved;
            return;
        }

        if (this._promise._error) {
            this._error = this._promise._error;
            this._state = PromiseState.Rejected;
            return;
        }
    }

    public get state() : PromiseState {
        return this._state;
    }

    public get result() : T {
        if (this._state === PromiseState.Pending) {
            throw this._promise;
        }

        if (this._state === PromiseState.Rejected) {
            throw this._error;
        }

        if (this._result === undefined) {
            throw new Error('Result is undefined');
        }

        return this._result;
    }

    getData(): T {
        return this.result;
    }
}