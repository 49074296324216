import {useEffect, useMemo, useRef} from "react";
import ItemDataStore from "../../store/ItemDataStore";
import useExternalStore from "../../../hooks/useExternalStore";
import {BasicData} from "../../types";
import {isEqual} from "lodash";

export type UseItemDataStoreReturn<D extends BasicData> = {
    store: ItemDataStore<D>;
    data: D;
};

const useItemDataStore = <D extends BasicData>(initialData: D) : UseItemDataStoreReturn<D> => {
    const initialDataRef = useRef(initialData);
    const store = useMemo(() => new ItemDataStore(initialData), []);
    const data = useExternalStore(store);

    useEffect(() => {
        if (!isEqual(initialDataRef.current, initialData)) {
            store.updateInitialData(initialData);
            initialDataRef.current = initialData;
        }
    }, [ initialData ]);

    return {store, data};
}

export default useItemDataStore;