import * as React from 'react';
import {useDatatableContext} from "../../DatatableContext";
import {RenderFunctionProps, RenderConfigData} from "../../types/RenderConfig";

const DefaultCellBody = ({ value } : RenderFunctionProps) => {
    if (value === null) {
        return null;
    }

    if (typeof value !== "string") {
        if (value?.toString) {
            return value.toString();
        } else {
            return '[unknown value ' + (typeof value) + ']';
        }
    }

    return value;
}

const CellRenderer = ({ renderConfig, ...props } : RenderFunctionProps & { renderConfig : RenderConfigData }) => {

    if (!renderConfig || !renderConfig[props.name]) {
        return <DefaultCellBody {...props} />
    }

    return (
        <React.Fragment>
            {renderConfig[props.name](props)}
        </React.Fragment>
    );
}

const CellBody = (props : RenderFunctionProps) => {

    const { renderConfig } = useDatatableContext();

    if (!renderConfig) {
        return <DefaultCellBody {...props} />
    }

    return (
        <CellRenderer {...props} renderConfig={renderConfig} />
    );
}

export default CellBody
